import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ShareLinkPopup from '../PatientIntake/ShareLinkPopup';
import Patient from './Patient';

const PatientViewPort = () => {
    const [isMobile, setIsMobile] = useState(false);
    var openPIFSharePopup = useSelector(state => state.patientIntake.openSharePopup);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 767px)');
        setIsMobile(mediaQuery.matches);
        const handleMediaChange = (e) => {
            setIsMobile(e.matches);
        };
        mediaQuery.addListener(handleMediaChange);
        return () => {
            mediaQuery.removeListener(handleMediaChange);
        };
    }, []);
    return (
        <>
            {(isMobile && openPIFSharePopup) ? <ShareLinkPopup /> : <Patient />}
        </>
    )

}

export default PatientViewPort