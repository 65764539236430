import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DataDecode from '../../utils/DataDecode';
import { setNotification } from '../../Redux/features/toast/toastSlice';
import store from '../../API/store';

const Toggle = ({ user, setRefreshData }) => {

  var loggedusertoken = useSelector(state => state.login.userBarerToken);
  var loggeduser = useSelector(state => state.login.loginuserData);
  const dispatch=useDispatch();
  const [selected, setSelected] = useState(user.user_status);
  const [loading, setLoading] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [activateConfirmAlert, setActivateConfirmAlert] = useState(false);
  

  useEffect(() => {
setSelected(user.user_status)
  }, [user])

  const handleSelected = (event, cf) => {
    
    if (cf === 'confirm-box') {
      setConfirmBox(false);
      setActivateConfirmAlert(false);
    }
    if (activateConfirmAlert && cf !== 'confirm-box') {
      setConfirmBox(true);
    } else {
      setSelected(event);
        store.InactiveUser(loggedusertoken, DataDecode.encryptPayload({user:user,updatedby:loggeduser.user_role=="SuperAdmin"?"SuperAdmin":loggeduser.user_name}))
         .then((result) => {
          store.CreateActivityLogs(loggeduser, "Users", `User Updated as Inactive - for ${user.user_name} with role ${user.user_role}`, "Update");
           if (result.status == 200) {
             setRefreshData(r => !r);
             dispatch(setNotification({ message: ('User Updated!'), status: 'success', action: true }));
           }
           else {
             dispatch(setNotification({ message: 'Error Occured!', status: 'error', action: true }));
           }
         })
         .catch(err => {
          dispatch(setNotification({ message: "Error Occurred", status: "error", action: true }));
         }) 
    }
  }

  
  const handleactive = (selected) => {
    
    if (selected == true) {
      store.ActiveUser(loggedusertoken, DataDecode.encryptPayload({user:user,updatedby:loggeduser.user_role=="SuperAdmin"?"SuperAdmin":loggeduser.user_name}))
      .then((result) => {
        setLoading(false)
        if (result.status == 200) {
          store.CreateActivityLogs(loggeduser, "Users", `User Updated as Active - for ${user.user_name} with role ${user.user_role}`, "Update");
          setRefreshData(r => !r);
          dispatch(setNotification({ message: ( 'User Updated!'), status: 'success', action: true }));
        }
        else {
          dispatch(setNotification({ message: 'Error Occured!', status: 'error', action: true }));
        }
      })
      .catch(err => {
        dispatch(setNotification({ message: "Error Occurred", status: "error", action: true }));
      }) 
    }
    if (selected == false) {
      setConfirmBox(true);
    }
  }

  return (
    <>
    
      {confirmBox &&
        <div tabIndex="-1" aria-hidden="true" className="fixed left-1/2 top-1/2 w-full max-w-sm transform  -translate-x-1/2 -translate-y-1/2 sm:w-80 sm:left-[40%]  sm:top-[3rem] sm:translate-x-[50%] sm:translate-y-[50%] z-50">
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
              <p id="TogglePinactiveAccount" className="mb-4 text-gray-500 dark:text-gray-300">Making as Inactive will disable the account </p>
              <div className="flex justify-center items-center space-x-4">
                <button id="ToggleBtncancel" onClick={() => setConfirmBox(false)} type="button" className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                  No, cancel
                </button>
                <button id="ToggleBtnconfirm" type="button" onClick={() => { handleSelected(true, 'confirm-box'); }} className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900 disabled:opacity-50 disabled:cursor-not-allowed">
                  Yes, I'm sure
                </button>
              </div>
            </div>
          </div>
        </div>
      }

      <label className="inline-flex items-center cursor-pointer ">
        <span id="ToggleSpaninActive" className="text-[17px] font-[600] lg:text-sm lg:font-medium text-[#616565] lg:text-gray-900 dark:text-gray-300 me-3">InActive</span>
        <input id="ToggleInputcheckbox" type="checkbox" className="sr-only peer disabled:cursor-not-allowed disabled:opacity-50" disabled={loggeduser.user_name==user.user_name}  checked={selected} onChange={(e) => handleactive(e.target.checked)}/>
        <div className={"relative w-9 h-5 bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" +(loggeduser.user_name==user.user_name?" cursor-not-allowed opacity-50":"")}></div>
        <span id="ToggleSpanactive" className="ms-3 text-[17px] font-[600] lg:text-sm lg:font-medium text-[#616565] lg:text-gray-900 dark:text-gray-300">Active</span>
      </label>
    </>
  )
}

export default Toggle