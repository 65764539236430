import React from 'react';

const SnackBarComponent = ({ message, type, onClose }) => {
  return (
    <div id="SnackBarComponentDivsnackBar"
      className={`fixed top-[70px] right-4 max-w-[250px] w-full p-2 rounded-lg shadow-lg
        ${type === 'success' ? 'bg-[#0493638a]' : ''}
        ${type === 'error' ? 'bg-[#d5000091]' : ''}
        ${type === 'info' ? 'bg-blue-500' : ''}
        ${type === 'warning' ? 'bg-yellow-500' : ''}
        text-white`}
    >
      <div id="SnackBarComponentDivmessage" className="flex justify-between items-center">
        <span id="SnackBarComponentSpanmessage">{message}</span>
        <button id="SnackBarComponentButtonclose" onClick={onClose} className="ml-4">
          &times;
        </button>
      </div>
    </div>
  );
};

export default SnackBarComponent;
