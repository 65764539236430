import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "../API/store";
import { setNotification } from "../Redux/features/toast/toastSlice";
import DataDecode from "../utils/DataDecode";

const ForgotPassword = ({ UserName }) => {
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(true);
  const [isResetPasswordPopupOpen, setIsResetPasswordPopupOpen] =
    useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [timer, setTimer] = useState(30);
  const otpRefs = useRef([]);
  const otpRefDes = useRef([]);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [otpError, setOtpError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  var notification = useSelector((state) => state.toast.notification);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // Optional: Disable the button during the countdown
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [erMessage, setErMessage] = useState(false);
  const [otpMessage, setOtpMessage] = useState("");
  const [errorVerifyOtp, setErrorVerifyOtp] = useState(false);
  const [email, setEmail] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);
  const [passwordTxtError, setPasswordTxtError] = useState([]);
  const dispatch = useDispatch();

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };
  const resetOtpFields = () => {
    setOtp(new Array(otp.length).fill(""));
    otpRefs.current[0]?.focus();
  };
  const handleSendOtp = () => {
    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev === 1) {
          clearInterval(interval);
          setIsButtonDisabled(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleBackspace = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        otpRefs.current[index - 1].focus();
        otpRefDes.current[index - 1].focus();
      }
    }
  };
  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    const validValue = /^\d$/.test(value) ? value : "";

    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      newOtp[index] = validValue;

      if (value !== null && index < otp.length - 1) {
        otpRefs.current[index + 1]?.focus();
        otpRefDes.current[index + 1]?.focus();
      }
      if (!validValue && index > 0) {
        otpRefs.current[index - 1]?.focus();
      }

      return newOtp;
    });
  };


  const handlePaste = (e) => {
    const pastedData = e.clipboardData
      .getData("text")
      .slice(0, otp.length)
      .split("");
    const newOtp = [...otp];

    pastedData.forEach((char, index) => {
      if (index < otp.length && /^\d$/.test(char)) {
        newOtp[index] = char;
      }
    });

    setOtp(newOtp);

    otpRefs.current[pastedData.length - 1]?.focus();

    e.preventDefault();
  };

  const handleResendOtp = () => {
    resetOtpFields();
    OtpApiCall();
    setTimer(30);
  };
  const handleApicallResetPass = async () => {
    const resetPasswordParam = {
      userName: UserName,
      Email: email,
      Password: newPassword,
    };

    try {
      await store
        .ResetPassword(DataDecode.encryptPayload(resetPasswordParam))
        .then((response) => {

          if (response.status === 200) {
            dispatch(
              setNotification({
                message: "Password updated successfully.",
                status: "success",
                action: true,
              })
            );
            window.location.reload();
            setIsResetPasswordPopupOpen(false);
          } else if (response.status === 401) {
            dispatch(
              setNotification({
                message: "Enter valide User information.",
                status: "error",
                action: true,
              })
            );
          } else if (response.status === 404) {
            dispatch(
              setNotification({
                message: "Invalid User Information.",
                status: "error",
                action: true,
              })
            );
          } else if (response.status === 304 || response.status === 400) {
            dispatch(
              setNotification({
                message: "Error While updating the Password.Please try again.",
                status: "error",
                action: true,
              })
            );
          } else {
            dispatch(
              setNotification({
                message: "Failed to update the password. Please try again.",
                status: "error",
                action: true,
              })
            );
          }
        });
    } catch (error) {
      dispatch(
        setNotification({
          message:
            "An error occurred while updating the password. Please try again later.",
          status: "error",
          action: true,
        })
      );
    }
  };

  const handlePasswordReset = () => {
    setErrorVisible(false);
    if (newPassword !== confirmPassword) {
      setPasswordTxtError(["New Password and Confirm Password doesn't match"]);
      setErrorVisible(true);
      return;
    }
    const { errorVisible, passwordTxtError } = validatePassword(newPassword);
    if (!errorVisible) {
      setPasswordTxtError(passwordTxtError);
      setErrorVisible(true);
      return;
    }

    handleApicallResetPass();
  };
  const handleKeyDown = (event, stage) => {
    if (stage === "OtpApi")
      if (event.key === "Enter") {
        OtpApiCall();
      } else if (stage === "Otpverify") {
        if (otp.at(6) != null) {
          handleVerifyOtpClick();
        }
      }
  };
  const validatePassword = (password) => {
    const passwordTxtError = [];

    const minLength = 8;
    const maxLength = 32;
    const hasCapitalLetter = /[A-Z]/.test(password);
    const hasSmallLetter = /[a-z]/.test(password);
    const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasNumber = /[0-9]/.test(password);

    if (password.length < minLength) {
      passwordTxtError.push(`8 to 32 characters long.`);
    }
    if (password.length > maxLength) {
      passwordTxtError.push(`8 to 32 characters long.`);
    }
    if (!hasCapitalLetter) {
      passwordTxtError.push("At least 1 capital letter.");
    }
    if (!hasSmallLetter) {
      passwordTxtError.push("At least 1 lowercase letter.");
    }
    if (!hasSpecialCharacter) {
      passwordTxtError.push("At least 1 special character from !@#$%^&*.");
    }
    if (!hasNumber) {
      passwordTxtError.push("At least 1 number.");
    }
    return {
      errorVisible: passwordTxtError.length === 0,
      passwordTxtError,
    };
  };
  // const validatePassword = (password) => {
  //   const passwordRegex =
  //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  //   return passwordRegex.test(password);
  // };

  const OtpApiCall = async () => {
    const otpGeneraterParam = {
      userName: UserName,
      Email: email,
    };

    await store
      .GetOtp(DataDecode.encryptPayload(otpGeneraterParam))
      .then((result) => {
        if (result.status == 200) {
          setShowOtpPopup(true);
          dispatch(
            setNotification({
              message: "Otp Sent Successfully",
              status: "success",
              action: true,
            })
          );
          setShowForgotPassword(false);
          handleSendOtp();
          setIsButtonDisabled(true);
        } else if (result.status == 404) {
          setErMessage("User not found with Username and email");
          dispatch(
            setNotification({
              message: "User not found with this Username and email",
              status: "error",
              action: true,
            })
          );
        }else if (result.status == 401) {
          setErMessage("Invalid email format.");
          dispatch(
            setNotification({
              message: "Invalid email format.",
              status: "error",
              action: true,
            })
          );
        }  
        else if (result.status == 400) {
          setErMessage("Invalid User emailId");
          dispatch(
            setNotification({
              message: "Invalid User emailId",
              status: "error",
              action: true,
            })
          );
        } else {
          setErMessage("Enter valid User Information");
          dispatch(
            setNotification({
              message: "Enter valid EmailId",
              status: "error",
              action: true,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotification({
            message: "Error encountered while sending mail",
            status: "error",
            action: true,
          })
        );
      });
    setTimeout(() => {}, 2000);
  };

  const handleVerifyOtpClick = async () => {
    const OtpGenerater = {
      Email: email,
      Otp: otp.join(""),
    };
    if (otp !== null) {
      await store
        .validateOtp(DataDecode.encryptPayload(OtpGenerater))
        .then((result) => {
          if (result.status == 200) {
            setShowOtpPopup(false);
            setIsResetPasswordPopupOpen(true);
            dispatch(
              setNotification({
                message: "Otp verified Successfully",
                status: "success",
                action: true,
              })
            );
          } else if (result.status == 400) {
            setOtpMessage("Invalid Otp");
            setErrorVerifyOtp(true);

            dispatch(
              setNotification({
                message: "Invalid Otp",
                status: "error",
                action: true,
              })
            );
          } else {
            setOtpMessage("Otp Expired");
            setErrorVerifyOtp(true);

            dispatch(
              setNotification({
                message: "Otp expired",
                status: "error",
                action: true,
              })
            );
          }
        })
        .catch((error) => {
          dispatch(
            setNotification({
              message: "Error occurred while validiating otp:" + error,
              status: "error",
              action: true,
            })
          );
          console.error("Error occurred while validiating otp:", error);
        });
      setOtpError("");
    } else {
      setOtpError("Invalid OTP");
    }
    setTimeout(() => {}, 2000);
  };

  return (
    <>
      <div className="hidden lg:block">
        {showForgotPassword && (
          <div className="bg-white p-8 rounded-md shadow-lg max-w-sm mx-auto">
            <h2 className="text-xl font-bold text-red-800 mb-4 ">
              Forgot Password
            </h2>
            <div className="relative mb-4">
              <input
                id="txtEmail"
                type="email"
                placeholder="Enter your email"
                value={email}
                onKeyDown={(e) => {
                  handleKeyDown(e, "OtpApi");
                }}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className="w-80 border border-gray-300 px-4 py-2 pl-10 rounded-md text-sm"
                aria-label="Email address"
              />
              <svg
                id="btnMail"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="16"
                viewBox="0 0 21 16"
                fill="none"
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
              >
                <path
                  d="M19.944 2.10384L19.1446 1.30438L11.7391 8.70985C10.8134 9.63553 9.25658 9.63553 8.33089 8.70985L0.92543 1.34646L0.125977 2.14591L5.638 7.65793L0.125977 13.17L0.92543 13.9694L6.43745 8.45739L7.53144 9.55138C8.20466 10.2246 9.08827 10.6033 10.014 10.6033C10.9396 10.6033 11.8232 10.2246 12.4965 9.55138L13.5905 8.45739L19.1025 13.9694L19.9019 13.17L14.3899 7.65793L19.944 2.10384Z"
                  fill="#BABABA"
                />
                <path
                  d="M18.3874 15.4H1.76721C0.799453 15.4 0 14.6005 0 13.6328V1.76721C0 0.799453 0.799453 0 1.76721 0H18.3874C19.3552 0 20.1546 0.799453 20.1546 1.76721V13.6328C20.1546 14.6005 19.3552 15.4 18.3874 15.4ZM1.72514 1.13607C1.38852 1.13607 1.13607 1.38852 1.13607 1.72514V13.5907C1.13607 13.9273 1.38852 14.1798 1.72514 14.1798H18.3454C18.682 14.1798 18.9344 13.9273 18.9344 13.5907V1.72514C18.9344 1.38852 18.682 1.13607 18.3454 1.13607H1.72514Z"
                  fill="#BABABA"
                />
              </svg>
            </div>
            {erMessage ? (
              <p className="text-red-800 mb-4">Enter valid emailId</p>
            ) : (
              ""
            )}
            <button
              id="btnSendOtp"
              className="w-80 bg-red-700 text-white px-4 py-2 rounded-md hover:bg-red-800"
              onClick={OtpApiCall}
            >
              Send OTP
            </button>
          </div>
        )}

        {showOtpPopup && (
          <div className="fixed inset-0 bg-opacity-50 flex justify-center items-center">
            <div className="relative bg-white p-10 rounded-md shadow-lg max-w-md mx-auto">
              <div className="absolute top-2 right-2">
                <button
                  id="btnOtpClose"
                  onClick={() => {
                    setShowOtpPopup(false);
                    window.location.reload();
                  }}
                  className="text-gray-600"
                >
                  <svg
                    id="btnOtpCross"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <h2 className="text-xl font-bold text-red-800 mb-6 text-center">
                Please Enter OTP to Proceed
              </h2>
              <h2 className="text-sm font-semibold text-gray-500">
                An OTP has been sent to your email ending with{" "}
              </h2>
              <h2 className="text-sm font-semibold text-gray-500 text-center mb-4">
                **@gmail.com
              </h2>
              <div className="flex justify-center gap-2 mb-4">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    id={`otp-input-${index}`} // Unique ID for each input
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleOtpChange(e, index)}
                    onKeyDown={(e) => handleBackspace(e, index)}
                    onPaste={(e) => handlePaste(e)}
                    ref={(el) => (otpRefDes.current[index] = el)}
                    className="w-10 h-10 border border-gray-300 px-2 py-2 text-center rounded-md text-lg mr-2"
                    autoFocus={index === 0}
                  />
                ))}
              </div>
              <div
                id="OtpTimerId"
                className="flex items-center mb-4 justify-end"
              >
                <span id={"TImerIdForOtp"} className="text-sm text-gray-600">
                  {isButtonDisabled ? formatTime(timer) : ""}
                </span>
              </div>
              {errorVerifyOtp ? (
                <p id={"OtpErrorMessageId"} className="text-red-800 mb-4">
                  {otpMessage}
                </p>
              ) : (
                ""
              )}
              <button
                id="handleOtpSubmit"
                className="w-full bg-red-700 text-white rounded-md hover:bg-red-800 mt-4 p-2"
                onClick={handleVerifyOtpClick}
              >
                Submit
              </button>
              <center>
                <p
                  id={"MobResendOtpId"}
                  className="text-black mt-4 cursor-pointer"
                  onClick={handleResendOtp}
                >
                  Didn't receive code?
                  <span
                    id={"MObfailsResndId"}
                    className="text-green-600 underline hover:text-green-800 bg-transparent border-none cursor-pointer"
                  >
                    Resend
                  </span>
                </p>
              </center>
            </div>
          </div>
        )}

        {isResetPasswordPopupOpen && (
          <div className="fixed inset-0 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-8 rounded-md shadow-lg w-[380px] mx-auto relative">
              <div className="absolute top-4 right-4">
                <button
                  id="btnResetClose"
                  onClick={() => {
                    setIsResetPasswordPopupOpen(false);
                    window.location.reload();
                  }}
                  className="text-gray-500"
                >
                  <svg
                    id="btnResetCross"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-bold text-red-800 text-center">
                  Reset Password
                </h2>
              </div>
              <h3 className="text-lg font-bold text-black mb-4 text-left">
                New Password
              </h3>
              <div className="relative mb-4">
                <input
                  id="txtNewPassword"
                  type={passwordVisible ? "text" : "password"}
                  placeholder="Enter Your New Password"
                  className="w-full border border-gray-300 p-3 rounded-lg pl-4"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <button
                  id="btnNewPasswordVisible"
                  type="button"
                  className="absolute right-3 top-1/2 transform -translate-y-1/2"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  {passwordVisible ? (
                    <svg
                      id="btnVisible"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M11.8125 9C11.8125 9.74592 11.5162 10.4613 10.9887 10.9887C10.4613 11.5162 9.74592 11.8125 9 11.8125C8.25408 11.8125 7.53871 11.5162 7.01126 10.9887C6.48382 10.4613 6.1875 9.74592 6.1875 9C6.1875 8.25408 6.48382 7.53871 7.01126 7.01126C7.53871 6.48382 8.25408 6.1875 9 6.1875C9.74592 6.1875 10.4613 6.48382 10.9887 7.01126C11.5162 7.53871 11.8125 8.25408 11.8125 9Z"
                        fill="#444444"
                      />
                      <path
                        d="M0 9C0 9 3.375 2.8125 9 2.8125C14.625 2.8125 18 9 18 9C18 9 14.625 15.1875 9 15.1875C3.375 15.1875 0 9 0 9ZM9 12.9375C10.0443 12.9375 11.0458 12.5227 11.7842 11.7842C12.5227 11.0458 12.9375 10.0443 12.9375 9C12.9375 7.95571 12.5227 6.95419 11.7842 6.21577C11.0458 5.47734 10.0443 5.0625 9 5.0625C7.95571 5.0625 6.95419 5.47734 6.21577 6.21577C5.47734 6.95419 5.0625 7.95571 5.0625 9C5.0625 10.0443 5.47734 11.0458 6.21577 11.7842C6.95419 12.5227 7.95571 12.9375 9 12.9375Z"
                        fill="#444444"
                      />
                    </svg>
                  ) : (
                    <svg
                      id="btnHidden"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M8.8725 6.75L11.25 9.12V9C11.25 8.40326 11.0129 7.83097 10.591 7.40901C10.169 6.98705 9.59674 6.75 9 6.75H8.8725ZM5.6475 7.35L6.81 8.5125C6.7725 8.67 6.75 8.8275 6.75 9C6.75 9.59674 6.98705 10.169 7.40901 10.591C7.83097 11.0129 8.40326 11.25 9 11.25C9.165 11.25 9.33 11.2275 9.4875 11.19L10.65 12.3525C10.1475 12.6 9.5925 12.75 9 12.75C8.00544 12.75 7.05161 12.3549 6.34835 11.6517C5.64509 10.9484 5.25 9.99456 5.25 9C5.25 8.4075 5.4 7.8525 5.6475 7.35ZM1.5 3.2025L3.21 4.9125L3.5475 5.25C2.31 6.225 1.335 7.5 0.75 9C2.0475 12.2925 5.25 14.625 9 14.625C10.1625 14.625 11.2725 14.4 12.285 13.995L12.6075 14.31L14.7975 16.5L15.75 15.5475L2.4525 2.25M9 5.25C9.99456 5.25 10.9484 5.64509 11.6517 6.34835C12.3549 7.05161 12.75 8.00544 12.75 9C12.75 9.48 12.6525 9.945 12.48 10.365L14.6775 12.5625C15.8025 11.625 16.7025 10.395 17.25 9C15.9525 5.7075 12.75 3.375 9 3.375C7.95 3.375 6.945 3.5625 6 3.9L7.6275 5.5125C8.055 5.3475 8.5125 5.25 9 5.25Z"
                        fill="#444444"
                      />
                    </svg>
                  )}
                </button>
              </div>
              <h3 className="text-lg font-bold text-black mb-4 text-left">
                Confirm Password
              </h3>
              <div className="relative mb-6">
                <input
                  id="txtConfirmPassword"
                  type={confirmPasswordVisible ? "text" : "password"}
                  placeholder="Re-enter Password"
                  className="w-full border border-gray-300 p-3 rounded-lg pl-4"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <button
                  id="btnConfirmPasswordVisible"
                  type="button"
                  className="absolute right-3 top-1/2 transform -translate-y-1/2"
                  onClick={() =>
                    setConfirmPasswordVisible(!confirmPasswordVisible)
                  }
                >
                  {confirmPasswordVisible ? (
                    <svg
                      id="btnConfirmVisible"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M11.8125 9C11.8125 9.74592 11.5162 10.4613 10.9887 10.9887C10.4613 11.5162 9.74592 11.8125 9 11.8125C8.25408 11.8125 7.53871 11.5162 7.01126 10.9887C6.48382 10.4613 6.1875 9.74592 6.1875 9C6.1875 8.25408 6.48382 7.53871 7.01126 7.01126C7.53871 6.48382 8.25408 6.1875 9 6.1875C9.74592 6.1875 10.4613 6.48382 10.9887 7.01126C11.5162 7.53871 11.8125 8.25408 11.8125 9Z"
                        fill="#444444"
                      />
                      <path
                        d="M0 9C0 9 3.375 2.8125 9 2.8125C14.625 2.8125 18 9 18 9C18 9 14.625 15.1875 9 15.1875C3.375 15.1875 0 9 0 9ZM9 12.9375C10.0443 12.9375 11.0458 12.5227 11.7842 11.7842C12.5227 11.0458 12.9375 10.0443 12.9375 9C12.9375 7.95571 12.5227 6.95419 11.7842 6.21577C11.0458 5.47734 10.0443 5.0625 9 5.0625C7.95571 5.0625 6.95419 5.47734 6.21577 6.21577C5.47734 6.95419 5.0625 7.95571 5.0625 9C5.0625 10.0443 5.47734 11.0458 6.21577 11.7842C6.95419 12.5227 7.95571 12.9375 9 12.9375Z"
                        fill="#444444"
                      />
                    </svg>
                  ) : (
                    <svg
                      id="btnConfirmHidden"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M8.8725 6.75L11.25 9.12V9C11.25 8.40326 11.0129 7.83097 10.591 7.40901C10.169 6.98705 9.59674 6.75 9 6.75H8.8725ZM5.6475 7.35L6.81 8.5125C6.7725 8.67 6.75 8.8275 6.75 9C6.75 9.59674 6.98705 10.169 7.40901 10.591C7.83097 11.0129 8.40326 11.25 9 11.25C9.165 11.25 9.33 11.2275 9.4875 11.19L10.65 12.3525C10.1475 12.6 9.5925 12.75 9 12.75C8.00544 12.75 7.05161 12.3549 6.34835 11.6517C5.64509 10.9484 5.25 9.99456 5.25 9C5.25 8.4075 5.4 7.8525 5.6475 7.35ZM1.5 3.2025L3.21 4.9125L3.5475 5.25C2.31 6.225 1.335 7.5 0.75 9C2.0475 12.2925 5.25 14.625 9 14.625C10.1625 14.625 11.2725 14.4 12.285 13.995L12.6075 14.31L14.7975 16.5L15.75 15.5475L2.4525 2.25M9 5.25C9.99456 5.25 10.9484 5.64509 11.6517 6.34835C12.3549 7.05161 12.75 8.00544 12.75 9C12.75 9.48 12.6525 9.945 12.48 10.365L14.6775 12.5625C15.8025 11.625 16.7025 10.395 17.25 9C15.9525 5.7075 12.75 3.375 9 3.375C7.95 3.375 6.945 3.5625 6 3.9L7.6275 5.5125C8.055 5.3475 8.5125 5.25 9 5.25Z"
                        fill="#444444"
                      />
                    </svg>
                  )}
                </button>
              </div>
              <div className="flex justify-center">
                {errorVisible ? (
                  <ul
                    className="text-red-800 mb-4"
                    id="UnorderListId"
                    style={{ fontSize: "0.875rem" }}
                  >
                    <li id={"desLiOneId"}>Password must be :</li>
                    {passwordTxtError.map((error, index) => (
                      <li id={"desLiTwoId"} key={index}>
                        {"*  " + error}
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
              </div>
              <div className="flex justify-center">
                <button
                  id="btnReset"
                  className="w-full bg-red-700 text-white px-4 py-2 rounded-md hover:bg-red-800"
                  onClick={handlePasswordReset}
                >
                  Reset Password
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* MOBILE VIEW */}

      <div className="lg:hidden block">
        {showForgotPassword && (
          <div className="p-4 fixed inset-0 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-10 rounded-md shadow-lg w-[350px] mx-auto relative">
              <button
                id="btnMobClose"
                onClick={() => {
                  setShowForgotPassword(false);
                  window.location.reload();
                }}
                className="absolute top-4 right-4 text-gray-500"
              >
                <svg
                  id="btnMobForgotCross"
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>

              <h2 className="text-xl font-bold text-red-800 mb-4 ">
                Forgot Password
              </h2>
              <div className="relative mb-4">
                <input
                  id="txtEmail"
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onKeyDown={(e) => {
                    handleKeyDown(e, "OtpApi");
                  }}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  className="w-full border border-gray-300 px-4 py-2 pl-10 rounded-md text-sm"
                  aria-label="Email address"
                />
                <svg
                  id="btnMail"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="16"
                  viewBox="0 0 21 16"
                  fill="none"
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                >
                  <path
                    d="M19.944 2.10384L19.1446 1.30438L11.7391 8.70985C10.8134 9.63553 9.25658 9.63553 8.33089 8.70985L0.92543 1.34646L0.125977 2.14591L5.638 7.65793L0.125977 13.17L0.92543 13.9694L6.43745 8.45739L7.53144 9.55138C8.20466 10.2246 9.08827 10.6033 10.014 10.6033C10.9396 10.6033 11.8232 10.2246 12.4965 9.55138L13.5905 8.45739L19.1025 13.9694L19.9019 13.17L14.3899 7.65793L19.944 2.10384Z"
                    fill="#BABABA"
                  />
                  <path
                    d="M18.3874 15.4H1.76721C0.799453 15.4 0 14.6005 0 13.6328V1.76721C0 0.799453 0.799453 0 1.76721 0H18.3874C19.3552 0 20.1546 0.799453 20.1546 1.76721V13.6328C20.1546 14.6005 19.3552 15.4 18.3874 15.4ZM1.72514 1.13607C1.38852 1.13607 1.13607 1.38852 1.13607 1.72514V13.5907C1.13607 13.9273 1.38852 14.1798 1.72514 14.1798H18.3454C18.682 14.1798 18.9344 13.9273 18.9344 13.5907V1.72514C18.9344 1.38852 18.682 1.13607 18.3454 1.13607H1.72514Z"
                    fill="#BABABA"
                  />
                </svg>
              </div>
              {erMessage ? (
                <p className="text-red-800 mb-4">Enter valid emailId</p>
              ) : (
                ""
              )}
              <button
                id="btnMobSendOtp"
                className="w-full bg-red-700 text-white px-4 py-2 rounded-md hover:bg-red-800"
                onClick={OtpApiCall}
              >
                Send OTP
              </button>
            </div>
          </div>
        )}

        {showOtpPopup && (
          <div className="fixed inset-0 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-10 rounded-md shadow-lg w-[350px] mx-auto">
              <div className="flex justify-end top-10 ">
                <button
                  onClick={() => {
                    setShowOtpPopup(false);
                    window.location.reload();
                  }}
                  className="  text-red-600 hover:text-red-900 "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <h2 className="text-xl font-bold text-red-800 mb-6 text-center">
                Please Enter OTP to Proceed
              </h2>
              <h2 className="text-sm font-semibold text-gray-500">
                An OTP has been sent to your email ending with{" "}
              </h2>
              <h2 className="text-sm font-semibold text-gray-500 text-center mb-4">
                **@gmail.com
              </h2>
              <div className="flex justify-center gap-2 mb-4">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    id={`otp-input-${index}`}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onFocus={(e) => e.target.select}
                    autoFocus={index === 0}
                    onChange={(e) => handleOtpChange(e, index)}
                    onKeyDown={(e) => handleBackspace(e, index)}
                    onPaste={(e) => handlePaste(e)}
                    ref={(el) => (otpRefs.current[index] = el)}
                    className="w-10 h-10 border border-gray-300 px-2 py-2 text-center rounded-md text-lg mr-2"
                  />
                ))}
              </div>
              <div
                id={"OtpTimerIdMobile"}
                className="flex items-center mb-4 justify-end"
              >
                <span id={"MobOTPTimerId"} className="text-sm text-gray-600">
                  {formatTime(timer)}
                </span>
              </div>
              {errorVerifyOtp ? (
                <p id={"MobOtpErrorMessageId"} className="text-red-800 mb-4">
                  {otpMessage}
                </p>
              ) : (
                ""
              )}
              <button
                id="MobhandleOtpSubmit"
                className="w-full bg-red-700 text-white rounded-md hover:bg-red-800 mt-4 p-2"
                onClick={handleVerifyOtpClick}
              >
                Submit
              </button>
              <center>
                <p
                  id={"resendOtpId"}
                  className="text-black mt-4 cursor-pointer"
                  onClick={handleResendOtp}
                >
                  Didn't receive code?{" "}
                  <span
                    id={"OtpfailResendId"}
                    className="text-green-600 underline hover:text-green-800 bg-transparent border-none cursor-pointer"
                  >
                    Resend
                  </span>
                </p>
              </center>
            </div>
          </div>
        )}
        {isResetPasswordPopupOpen && (
          <div className="fixed inset-0 bg-opacity-50 flex justify-center items-center">
             <div className="bg-white p-8 rounded-md shadow-lg w-[350px] mx-auto relative">

              <button
                id="btnMobReset"
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                onClick={() => {
                  setIsResetPasswordPopupOpen(false);
                  window.location.reload();
                }}
              >
                <svg
                  id="btnMobResetCross"
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>

              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-bold text-red-800  text-center">
                  Reset Password
                </h2>
              </div>
              <h3 className="text-lg font-bold text-black mb-4 text-left">
                New Password
              </h3>
              <div className="relative mb-4">
                <input
                  id="txtNewPassword"
                  type={passwordVisible ? "text" : "password"}
                  placeholder="Enter Your New Password"
                  className="w-full border border-gray-300 p-3 rounded-lg pl-4"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <button
                  id="btnMobNewPasswordVisible"
                  type="button"
                  className="absolute right-3 top-1/2 transform -translate-y-1/2"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  {passwordVisible ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M11.8125 9C11.8125 9.74592 11.5162 10.4613 10.9887 10.9887C10.4613 11.5162 9.74592 11.8125 9 11.8125C8.25408 11.8125 7.53871 11.5162 7.01126 10.9887C6.48382 10.4613 6.1875 9.74592 6.1875 9C6.1875 8.25408 6.48382 7.53871 7.01126 7.01126C7.53871 6.48382 8.25408 6.1875 9 6.1875C9.74592 6.1875 10.4613 6.48382 10.9887 7.01126C11.5162 7.53871 11.8125 8.25408 11.8125 9Z"
                        fill="#444444"
                      />
                      <path
                        d="M0 9C0 9 3.375 2.8125 9 2.8125C14.625 2.8125 18 9 18 9C18 9 14.625 15.1875 9 15.1875C3.375 15.1875 0 9 0 9ZM9 12.9375C10.0443 12.9375 11.0458 12.5227 11.7842 11.7842C12.5227 11.0458 12.9375 10.0443 12.9375 9C12.9375 7.95571 12.5227 6.95419 11.7842 6.21577C11.0458 5.47734 10.0443 5.0625 9 5.0625C7.95571 5.0625 6.95419 5.47734 6.21577 6.21577C5.47734 6.95419 5.0625 7.95571 5.0625 9C5.0625 10.0443 5.47734 11.0458 6.21577 11.7842C6.95419 12.5227 7.95571 12.9375 9 12.9375Z"
                        fill="#444444"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M8.8725 6.75L11.25 9.12V9C11.25 8.40326 11.0129 7.83097 10.591 7.40901C10.169 6.98705 9.59674 6.75 9 6.75H8.8725ZM5.6475 7.35L6.81 8.5125C6.7725 8.67 6.75 8.8275 6.75 9C6.75 9.59674 6.98705 10.169 7.40901 10.591C7.83097 11.0129 8.40326 11.25 9 11.25C9.165 11.25 9.33 11.2275 9.4875 11.19L10.65 12.3525C10.1475 12.6 9.5925 12.75 9 12.75C8.00544 12.75 7.05161 12.3549 6.34835 11.6517C5.64509 10.9484 5.25 9.99456 5.25 9C5.25 8.4075 5.4 7.8525 5.6475 7.35ZM1.5 3.2025L3.21 4.9125L3.5475 5.25C2.31 6.225 1.335 7.5 0.75 9C2.0475 12.2925 5.25 14.625 9 14.625C10.1625 14.625 11.2725 14.4 12.285 13.995L12.6075 14.31L14.7975 16.5L15.75 15.5475L2.4525 2.25M9 5.25C9.99456 5.25 10.9484 5.64509 11.6517 6.34835C12.3549 7.05161 12.75 8.00544 12.75 9C12.75 9.48 12.6525 9.945 12.48 10.365L14.6775 12.5625C15.8025 11.625 16.7025 10.395 17.25 9C15.9525 5.7075 12.75 3.375 9 3.375C7.95 3.375 6.945 3.5625 6 3.9L7.6275 5.5125C8.055 5.3475 8.5125 5.25 9 5.25Z"
                        fill="#444444"
                      />
                    </svg>
                  )}
                </button>
              </div>

              <h3 className="text-lg font-bold text-black mb-4 text-left">
                Confirm Password
              </h3>
              <div className="relative mb-6">
                <input
                  id="txtConfirmPassword"
                  type={confirmPasswordVisible ? "text" : "password"}
                  placeholder="Re-enter Password"
                  className="w-full border border-gray-300 p-3 rounded-lg pl-4"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <button
                  id="btnMobConfirmPasswordVisible"
                  type="button"
                  className="absolute right-3 top-1/2 transform -translate-y-1/2"
                  onClick={() =>
                    setConfirmPasswordVisible(!confirmPasswordVisible)
                  }
                >
                  {confirmPasswordVisible ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M11.8125 9C11.8125 9.74592 11.5162 10.4613 10.9887 10.9887C10.4613 11.5162 9.74592 11.8125 9 11.8125C8.25408 11.8125 7.53871 11.5162 7.01126 10.9887C6.48382 10.4613 6.1875 9.74592 6.1875 9C6.1875 8.25408 6.48382 7.53871 7.01126 7.01126C7.53871 6.48382 8.25408 6.1875 9 6.1875C9.74592 6.1875 10.4613 6.48382 10.9887 7.01126C11.5162 7.53871 11.8125 8.25408 11.8125 9Z"
                        fill="#444444"
                      />
                      <path
                        d="M0 9C0 9 3.375 2.8125 9 2.8125C14.625 2.8125 18 9 18 9C18 9 14.625 15.1875 9 15.1875C3.375 15.1875 0 9 0 9ZM9 12.9375C10.0443 12.9375 11.0458 12.5227 11.7842 11.7842C12.5227 11.0458 12.9375 10.0443 12.9375 9C12.9375 7.95571 12.5227 6.95419 11.7842 6.21577C11.0458 5.47734 10.0443 5.0625 9 5.0625C7.95571 5.0625 6.95419 5.47734 6.21577 6.21577C5.47734 6.95419 5.0625 7.95571 5.0625 9C5.0625 10.0443 5.47734 11.0458 6.21577 11.7842C6.95419 12.5227 7.95571 12.9375 9 12.9375Z"
                        fill="#444444"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M8.8725 6.75L11.25 9.12V9C11.25 8.40326 11.0129 7.83097 10.591 7.40901C10.169 6.98705 9.59674 6.75 9 6.75H8.8725ZM5.6475 7.35L6.81 8.5125C6.7725 8.67 6.75 8.8275 6.75 9C6.75 9.59674 6.98705 10.169 7.40901 10.591C7.83097 11.0129 8.40326 11.25 9 11.25C9.165 11.25 9.33 11.2275 9.4875 11.19L10.65 12.3525C10.1475 12.6 9.5925 12.75 9 12.75C8.00544 12.75 7.05161 12.3549 6.34835 11.6517C5.64509 10.9484 5.25 9.99456 5.25 9C5.25 8.4075 5.4 7.8525 5.6475 7.35ZM1.5 3.2025L3.21 4.9125L3.5475 5.25C2.31 6.225 1.335 7.5 0.75 9C2.0475 12.2925 5.25 14.625 9 14.625C10.1625 14.625 11.2725 14.4 12.285 13.995L12.6075 14.31L14.7975 16.5L15.75 15.5475L2.4525 2.25M9 5.25C9.99456 5.25 10.9484 5.64509 11.6517 6.34835C12.3549 7.05161 12.75 8.00544 12.75 9C12.75 9.48 12.6525 9.945 12.48 10.365L14.6775 12.5625C15.8025 11.625 16.7025 10.395 17.25 9C15.9525 5.7075 12.75 3.375 9 3.375C7.95 3.375 6.945 3.5625 6 3.9L7.6275 5.5125C8.055 5.3475 8.5125 5.25 9 5.25Z"
                        fill="#444444"
                      />
                    </svg>
                  )}
                </button>
              </div>
              {errorVisible ? (
                <ul
                  className="text-red-800 mb-4"
                  id="MobUnorderListId"
                  style={{ fontSize: "0.875rem" }}
                >
                  <li id={"liOneMobpswdId"}>Password must be :</li>
                  {passwordTxtError.map((error, index) => (
                    <li id={"litwoMobpswdId"} key={index}>
                      {"*  " + error}
                    </li>
                  ))}
                </ul>
              ) : (
                ""
              )}
              <button
                id="btnResetPasswordSubmit"
                type="submit"
                 className="w-full bg-red-700 text-white px-4 py-2 rounded-md hover:bg-red-800"
                onClick={handlePasswordReset}
              >
                Reset Password
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ForgotPassword;
