import React from "react";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { styled } from "@mui/material/styles";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";

dayjs.extend(isBetween);

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) => prop !== "isSelected" && prop !== "isHovered",
})(({ theme, isSelected, isHovered, day }) => ({
    borderRadius: 0,
    ...(isSelected && {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        "&:hover, &:focus": {
            backgroundColor: theme.palette.primary.main,
        },
    }),
    ...(isHovered && {
        backgroundColor: theme.palette.primary[theme.palette.mode],
        "&:hover, &:focus": {
            backgroundColor: theme.palette.primary[theme.palette.mode],
        },
    }),
    ...(day.day() === 0 && {
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%",
    }),
    ...(day.day() === 6 && {
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%",
    }),
}));

const isInSameWeek = (dayA, dayB) => {
    if (dayB == null) {
        return false;
    }

    return dayA.isSame(dayB, "week");
};

const WeekPicker = ({ value, selectedDate, views = ["day", "month", "year"], setSelectedDate, setDayMonthSelectRef }) => {
    // const dispatch = useDispatch();

    const [hoveredDay, setHoveredDay] = React.useState(null);

    function Day(props) {
        const { day, selectedDay, hoveredDay, ...other } = props;

        return (
            <CustomPickersDay
                {...other}
                day={day}
                sx={{ px: 2.5 }}
                disableMargin
                selected={false}
                isSelected={isInSameWeek(day, selectedDay)}
                isHovered={isInSameWeek(day, hoveredDay)}
            />
        );
    }

    const shouldDisableDate = (date) => {
        const day = date.day();
        return day === 0 || day === 6; // Disable Sunday (0) and Saturday (6)
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
                id="WeekDataCalanderPicker"
                views={views}
                value={value}
                sx={{
                    width: "300px !important",
                    marginTop: "28px !important",
                    backgroundColor: "bg-[#b72424] !important",
                }}
                onChange={(newValue) => {
                    setSelectedDate(newValue)
                    setDayMonthSelectRef(null)
                }}
                showDaysOutsideCurrentMonth
                displayWeekNumber={false}
                shouldDisableDate={shouldDisableDate}
                slots={{ day: Day }}
                slotProps={{
                    day: (ownerState) => ({
                        selectedDay: value,
                        hoveredDay,
                        onPointerEnter: () => {
                            setHoveredDay(ownerState.day);
                        },
                        onPointerLeave: () => {
                            setHoveredDay(null);
                        },
                        // selectedRangeName: selectedDate,
                    }),
                }}
            />
        </LocalizationProvider>
    );
}

export default WeekPicker;