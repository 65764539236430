import React, { useEffect } from 'react'

const PrivacyOpen = () => {
    useEffect(() => {
        const url = `${process.env.REACT_APP_EMRAXIS_MS_AUTH_SPA_REDIRECT_URL}privacy.html`;
        window.open(url, '_blank');
    }, []);
    return (
        <></>
    )
}

export default PrivacyOpen