import React from 'react'
import FileIcon from '../../Patients/Profile/FileIcon';

const BinFilePreview = ({ file, index }) => {
    const fileExtension = file.file_path.split('.').pop().toLowerCase();
    const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(fileExtension);
    const isAudio = ['mp3', 'wav', 'ogg', 'm4a'].includes(fileExtension);
    const isVideo = ['mp4', 'webm', 'ogg', 'mov'].includes(fileExtension);

    return (
        <div id={"binFilePreviewDiv"+index} key={'file-preview-' + index}>
            <a id={"hrefBinFilePreview-" + index} rel="noopener" target="_blank" href={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} className=''>
                {isImage ? (
                    <img
                        id={"binFilePreviewImg" + index}
                        className='max-h-[180px] w-auto h-auto object-contain p-3'
                        src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path}
                        alt="Files"
                    />
                ) : isAudio ? (
                    <div className="flex justify-center items-center h-full">  <audio id={"binAudioFilePreview" + index} className="w-full max-w-[400px] p-3" controls>
                        <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`audio/${fileExtension}`} />
                        Your browser does not support the audio element.
                    </audio></div>
                ) : isVideo ? (
                    <video id={"binVideoFilePreview" + index} className="max-h-[180px] w-auto h-auto object-contain p-3" controls>
                        <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`video/${fileExtension}`} />
                        Your browser does not support the video element.
                    </video>
                ) : (
                    <div className="flex h-full p-3">
                        <FileIcon
                            id={"binUnknownFile" + index}
                            ext={"." + fileExtension}
                            className='max-h-[180px] w-auto h-auto object-contain p-5  '
                        />
                    </div>
                )}
            </a>
        </div>
    );
}
export default BinFilePreview