import React,{useState, useEffect} from 'react'
import MobileRecycleBin from './MobileRecycleBin';
import DesktopRecycleBin from './DesktopRecycleBin';

const RecycleBinViewPort = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 767px)');
        setIsMobile(mediaQuery.matches);
        const handleMediaChange = (e) => {
            setIsMobile(e.matches);
        };
        mediaQuery.addListener(handleMediaChange);
        return () => {
            mediaQuery.removeListener(handleMediaChange);
        };
    }, []);
    return (
        <>
            {isMobile ? <MobileRecycleBin /> : <DesktopRecycleBin />}
        </>
    )
}

export default RecycleBinViewPort