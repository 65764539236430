import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showDocumentUploadPopup: false,
    showUploadPopup: false,
    documentType: {
        doctor_note: false,
        prescription: false,
        lab_reports: false,
        x_ray: false,
        others: false
    },
    isCameraUpload: false,
    refreshNotes: false,
    refreshLabNotes: false,
    refreshRadiologyNotes: false,
    refreshPrescription: false,
    refreshUploadedFiles: false,
    refreshNoAppointmentUploadedData: false,
    refreshDataAvailable: false,
    isWithoutAppointment: false
}

export const uploadDocSlice = createSlice({
    name: 'document_upload',
    initialState,
    reducers: {
        setShowDocumentUploadPopup: (state, action) => {
            state.showDocumentUploadPopup = action.payload
        },
        setShowUploadPopup: (state, action) => {
            state.showUploadPopup = action.payload
        },
        setDocumentType: (state, action) => {
            state.documentType = action.payload
        },
        setIsCameraUpload: (state, action) => {
            state.isCameraUpload = action.payload
        },
        setRefreshNotes: (state, action) => {
            state.refreshNotes = action.payload
        },
        setRefreshPrescription: (state, action) => {
            state.refreshPrescription = action.payload
        },
        setRefreshUploadedFiles: (state, action) => {
            state.refreshUploadedFiles = action.payload
        },
        setRefreshNoAppointmentUploadedData: (state, action) => {
            state.refreshNoAppointmentUploadedData = action.payload
        },
        setIsWithoutAppointment: (state, action) => {
            state.isWithoutAppointment = action.payload
        },
        setRefreshLabNotes: (state, action) => {
            state.refreshLabNotes = action.payload
        },
        setRefreshRadiologyNotes: (state, action) => {
            state.refreshRadiologyNotes = action.payload
        },
        setRefreshDataAvailable: (state, action) => {
            state.refreshDataAvailable = action.payload
        },
    }
})

export const { setShowDocumentUploadPopup,
    setDocumentType,
    setIsCameraUpload,
    setShowUploadPopup,
    setRefreshNotes,
    setRefreshPrescription,
    setRefreshUploadedFiles,
    setRefreshNoAppointmentUploadedData,
    setIsWithoutAppointment,
    setRefreshLabNotes,
    setRefreshRadiologyNotes,
    setRefreshDataAvailable
} = uploadDocSlice.actions;

export default uploadDocSlice.reducer;