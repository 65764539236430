import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { theme } from '../../EmraxisTheme/Theme';
import { ChromePicker } from 'react-color';
import { QRCodeCanvas } from 'qrcode.react';
import { toPng } from 'html-to-image';
import jsPDF from 'jspdf';
import { setNotification } from '../../Redux/features/toast/toastSlice';
import store from '../../API/store';
import DataDecode from '../../utils/DataDecode';

const GenerateQRCode = () => {
    const previewTheme = useSelector(state => state.theme.colors) || theme.filter(a => a.name === "defaultTheme")[0];
    var loggeduser = useSelector(state => state.login.loginuserData);
    var loggedusertoken = useSelector((state) => state.login.userBarerToken);

    const dispatch = useDispatch();
    const qrRef = useRef(null);

    const [openBgPopup, setOpenBgPopup] = useState(false);
    const [openFgPopup, setOpenFgPopup] = useState(false);
    const [initialError, setInitialError] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [facilityQrCode, setfacilityQrCode] = useState("");
    const [facilitySettings, setFacilitySettings] = useState({
        size: 100,
        bgColour: "#FFFFFF",
        fgColour: "#000000",
        clinic_guid: loggeduser.facilityID,
        downloadFileType: ""
    });

    useEffect(() => {
        if (loggeduser && loggeduser.facility_qr_code) {
            setfacilityQrCode(loggeduser.facility_qr_code);
        }
        if (loggeduser && !loggeduser.facility_qr_code) {
            setInitialError(true);
        }
        if (loggeduser && loggeduser.facilityID) {
            setFacilitySettings({ ...facilitySettings, clinic_guid: loggeduser.facilityID })
            setTimeout(() => {
                GetFacilityQrCodeSettings();
            }, 100)
        }
    }, [loggeduser])

    const GetFacilityQrCodeSettings = () => {
        store.GetFacilityQrCodeSettings(loggedusertoken, DataDecode.encryptPayload({ facility_guid: loggeduser.facilityID }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        setFacilitySettings(JSON.parse(response));
                    });
                }
            })
            .catch((err) => {
                dispatch(setNotification({ message: "Error Occurred!", status: "error", action: true, }));
            });
    }

    const UpdateFacilityQrCodeSettings = () => {
        store.UpdateFacilityQrCodeSettings(loggedusertoken, DataDecode.encryptPayload(facilitySettings))
            .then((result) => {
                setDisableBtn(false);
                if (result.status == 204) {
                    dispatch(setNotification({ message: "File Downloaded!", status: "success", action: true, }));
                }
            })
            .catch((err) => {
                setDisableBtn(false);
                dispatch(setNotification({ message: "Error Occurred!", status: "error", action: true, }));
            });
    }

    // Function to download QR code as a JPG
    const downloadJpg = () => {
        setDisableBtn(true);
        UpdateFacilityQrCodeSettings();
        if (qrRef.current === null) return;
        toPng(qrRef.current)
            .then((dataUrl) => {
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = 'qrcode.jpg';
                link.click();
            })
            .catch((err) => {
                dispatch(setNotification({ message: 'Error Occurred', status: 'error', action: true }));
            });
    };

    // Function to download QR code as a PDF
    const downloadPdf = () => {
        setDisableBtn(true);
        UpdateFacilityQrCodeSettings();
        if (qrRef.current === null) return;
        toPng(qrRef.current)
            .then((dataUrl) => {
                const pdf = new jsPDF({
                    orientation: 'p',
                    unit: 'mm',
                    format: 'a4',
                    putOnlyUsedFonts: true
                });

                pdf.addImage(dataUrl, 'JPEG', 15, 15, parseInt(facilitySettings.size * 0.26), parseInt(facilitySettings.size * 0.26));
                pdf.save('qrcode.pdf');
            })
            .catch((err) => {
                dispatch(setNotification({ message: 'Error Occurred', status: 'error', action: true }));
            });
    };

    const handleBgColorChange = (newColor) => {
        setFacilitySettings({ ...facilitySettings, bgColour: newColor.hex })
    }

    const handleFgColorChange = (newColor) => {
        setFacilitySettings({ ...facilitySettings, fgColour: newColor.hex })
    }


    return (
        <div id="MainDivGenerateQRCode" className=" mx-auto px-4 py-2 my-20 w-100% ">
            <div id="HeaderDivGenerateQRCode" className="flex justify-between items-center mb-6">
                <h2 id="HeaderGenerateQrTitle" className={`ml-4 font-bold text-2xl  ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewTheme.fontFamily }}>Generate QR Code</h2>
            </div>
            <div className="flex justify-center items-center">
                <div className='border border-gray-200 rounded-sm shadow mt-2 py-4 px-3 w-full' id="UserProfileDivuserProfileDetails">
                    <div class="grid grid-cols-1 md:grid-cols-[auto,1fr] gap-4">
                        <div class="p-4">
                            <div className="flex flex-col items-start space-y-4">
                                <div className="space-x-4">
                                    <label id="lblGenerateQRCodeSize" className='font-sans text-[15px] sm:text-[16px] font-semibold'>{"Size (W x H)"}</label>
                                    <select id="dropdownGenerateQRCodeSize" className='w-48' value={facilitySettings.size} onChange={(e) => { setFacilitySettings({ ...facilitySettings, size: parseInt(e.target.value) }) }}>
                                        <option value={100}>100 x 100 Pixels</option>
                                        <option value={150}>150 x 150 Pixels</option>
                                        <option value={200}>200 x 200 Pixels</option>
                                        <option value={250}>250 x 250 Pixels</option>
                                        <option value={300}>300 x 300 Pixels</option>
                                        <option value={350}>350 x 350 Pixels</option>
                                        <option value={400}>400 x 400 Pixels</option>
                                        <option value={450}>450 x 450 Pixels</option>
                                        <option value={500}>500 x 500 Pixels</option>
                                    </select>
                                </div>
                                <div>
                                    <label id="lblGenerateQRCodeBgColour" className='font-sans text-[15px] sm:text-[16px] font-semibold'>Background Color</label>
                                    <div className="flex justify-between mt-1 sm:mb-0 w-full px-2 py-[7px] bg-white border border-[#CACACA] rounded  shadow-sm hover:border-black">
                                        {!openBgPopup ?
                                            <div className='flex items-center justify-start'>
                                                <button id="btnGenerateQRCodeBgColour" className='text-nowrap w-full flex-1 pl-4 py-2 rounded' type='button' onClick={() => { setOpenFgPopup(false); setOpenBgPopup(true) }}>Choose Color</button>
                                                <div id="divGenerateQRCodeBgColour" className='ml-2 w-40 h-8 rounded border' style={{ backgroundColor: facilitySettings.bgColour }}></div>
                                            </div>
                                            :
                                            <div className='flex justify-start'>
                                                <ChromePicker id="cpBgColourChange" color={facilitySettings.bgColour} onChange={(e) => { handleBgColorChange(e); }} />
                                                <button id="btnBgColourChange" type='button' onClick={() => { setOpenBgPopup(false) }} className='ml-4 text-red-700 border-2 border-red-700 rounded-full size-6 text-center flex justify-center items-center z-[999999]'>
                                                    <svg id="svgBgColourChange" xmlns="http://www.w3.org/2000/svg" strokeWidth={2} viewBox="0 0 24 24" fill="currentColor" className="size-4 text-center font-semibold">
                                                        <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                                                    </svg>
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="">
                                    <label id="lblGenerateQRCodeFgColour" className='font-sans text-[15px] sm:text-[16px] font-semibold'>Foreground Color</label>
                                    <div className="flex justify-between mt-1 sm:mb-0 w-full px-2 py-[7px] bg-white border border-[#CACACA] rounded  shadow-sm hover:border-black">
                                        {!openFgPopup ?
                                            <div className='flex items-center justify-start'>
                                                <button id="btnGenerateQRCodeFgColour" className='text-nowrap w-full flex-1 pl-4 py-2 rounded' type='button' onClick={() => { setOpenBgPopup(false); setOpenFgPopup(true); }}>Choose Color</button>
                                                <div id="divGenerateQRCodeFgColour" className='ml-2 w-40 h-8 rounded border' style={{ backgroundColor: facilitySettings.fgColour }}></div>
                                            </div>
                                            :
                                            <div className='flex justify-start'>
                                                <ChromePicker id="cpFgColourChange" color={facilitySettings.fgColour} onChange={(e) => { handleFgColorChange(e); }} />
                                                <button id="btnFgColourChange" type='button' onClick={() => { setOpenFgPopup(false) }} className='ml-4 text-red-700 border-2 border-red-700 rounded-full size-6 text-center flex justify-center items-center z-[999999]'>
                                                    <svg id="svgFgColourChange" xmlns="http://www.w3.org/2000/svg" strokeWidth={2} viewBox="0 0 24 24" fill="currentColor" className="size-4 text-center font-semibold">
                                                        <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                                                    </svg>
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button
                                        id="btnGenQrDownloadJPG"
                                        disabled={disableBtn}
                                        onClick={downloadJpg}
                                        className={`px-4 py-2 mx-2 my-1 ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} hover:${previewTheme.saveBtnHoverColor} rounded disabled:opacity-50 disabled:cursor-not-allowed`}
                                    >
                                        Download JPG
                                    </button>
                                    <button
                                        id="btnGenQrDownloadPDF"
                                        disabled={disableBtn}
                                        onClick={downloadPdf}
                                        className={`px-4 py-2 mx-2 my-1 ${previewTheme.otherBtnColor} ${previewTheme.otherBtnBgColor} hover:${previewTheme.otherBtnHoverColor} rounded disabled:opacity-50 disabled:cursor-not-allowed`}
                                    >
                                        Download PDF
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="p-4 border-0 border-gray-200 md:border-l overflow-x-scroll">
                            <h2 id="h2GenQrPreviewTitle" className={`ml-4 font-bold text-2xl  ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewTheme.fontFamily }}>Preview</h2>
                            <div className="p-4 rounded">
                                <div className={`w-[${facilitySettings.size}px] h-[${facilitySettings.size}px] p-2 bg-white`}>
                                    {facilityQrCode && !initialError ?
                                        <QRCodeCanvas
                                            id="MainComponentQRCodeCanvas"
                                            ref={qrRef}
                                            value={`${process.env.REACT_APP_EMRAXIS_MS_AUTH_SPA_REDIRECT_URL}patient-intake-qrlink?path=qrcode&token=${facilityQrCode}`}
                                            size={facilitySettings.size}
                                            bgColor={facilitySettings.bgColour}
                                            fgColor={facilitySettings.fgColour} />
                                        :
                                        <p className='text-red-500'>Please logout and login back</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default GenerateQRCode