import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isInvoiceEnabled: false,
    showAppEditInvoice: true,
    appointmentInvoice: { patient: {}, doctor: {}, appointment: {}, targetFrom: "" },
    isInvoiceExist: false
}

export const popupSlice = createSlice({
    name: 'invoice',
    initialState,
    reducers: {
        setIsInvoiceEnabled: (state, action) => {
            state.isInvoiceEnabled = action.payload
        },
        setAppointmentInvoice: (state, action) => {
            state.appointmentInvoice = action.payload
        },
        setShowAppEditInvoice: (state, action) => {
            state.showAppEditInvoice = action.payload
        },
        setIsInvoiceExist: (state, action) => {
            state.isInvoiceExist = action.payload
        }
    }
})

export const { setIsInvoiceEnabled, setAppointmentInvoice, setShowAppEditInvoice, setIsInvoiceExist } = popupSlice.actions;

export default popupSlice.reducer;