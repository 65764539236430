import { createSlice } from '@reduxjs/toolkit'

const initialState = {
 facility_name:"",
 facility_id:"",
 facility_email:"",
 facility_color:"",
 facility_logo:null
}

export const facilitySlice = createSlice({
  name: 'facility',
  initialState,
  reducers: {
    addFacilityName: (state, action) => {
      state.facility_name = action.payload
    },
    addFacilityID:(state,action)=>{
      state.facility_id=action.payload
    },
    addFacilityEmail:(state,action)=>{
      state.facility_email=action.payload
    },
    addFacilityColor:(state,action)=>{
      state.facility_color=action.payload
    },
    addFacilityLogo:(state,action)=>{
      state.facility_logo=action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { addFacilityName, addFacilityID,addFacilityEmail,addFacilityColor, addFacilityLogo} = facilitySlice.actions

export default facilitySlice.reducer