import React, { useMemo, useState, useEffect, useRef } from 'react'
import { Tooltip, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import DateFunction from '../../../utils/DateFunctions';
import Select from "react-select";
import { setNotification } from '../../../Redux/features/toast/toastSlice';
import store from '../../../API/store';
import DataDecode from '../../../utils/DataDecode';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-input-2";
import { setAppGridViewAppointmentData } from '../../../Redux/features/appointmentGridView/gridviewSlice';
import dayjs from "dayjs";

const AppDetailsPopover = ({ doctors, doctorsTimeslot, setRefreshData, setAppointmentDetailRef, deleteAppointment }) => {
    const appointment = useSelector(state => state.appGridView.appGridViewAppointmentData);
    const isDefaultGridView = useSelector(state => state.appGridView.isDefaultGridView);
    var loggeduser = useSelector(state => state.login.loginuserData);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    const textareaRef = useRef(null);

    const dispatch = useDispatch();

    const [isEditWindow, setIsEditWindow] = useState(false);
    const [appointmentType, setAppointmentType] = useState("First Time");
    const [startDate, setStartDate] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [timeOptions, setTimeOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [submitDisable, setSubmitDisable] = useState(false);
    const [originalAppointment, setOriginalAppointment] = useState({
        appointment_id: appointment && appointment.appointment_id,
        appointment_time: appointment && appointment.appointment_time,
        appointment_type: appointmentType,
        doctor_id: appointment && appointment.doctor_id,
        clinic_guid: appointment && appointment.clinic_guid,
        mobile_number: appointment && appointment.mobile_number,
        purpose_of_visit: appointment && appointment.purpose_of_visit,
        patient_name: "",
        patient_id: appointment && appointment.patient_id,
        reffered_by: appointment && appointment.reffered_by,
        app_time: appointment && appointment.app_time ? appointment.app_time : null,

    })
    const [filtertimeOptions, setFilterTimeOptions] = useState([]);
    function deepEqual(obj1, obj2) {
        if (obj1 === obj2) return true; // Compare primitive types or same reference

        if (typeof obj1 !== "object" || obj1 === null || typeof obj2 !== "object" || obj2 === null) {
            return false; // Handle cases where they are not objects
        }
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        // Compare each key recursively
        for (let key of keys1) {
            if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
                return false;
            }
        }
        return true;
    }

    const [newAppointment, setNewAppointment] = useState({
        appointment_id: appointment && appointment.appointment_id ? appointment.appointment_id : 0,
        patient_id: appointment && appointment.patient_id ? appointment.patient_id : 0,
        patient_name: "",
        doctor_id: appointment && appointment.doctor_id ? appointment.doctor_id : 0,
        doctor_name: appointment && appointment.doctor_name ? appointment.doctor_name : "",
        clinic_guid: "",
        mobile_number: appointment && appointment.mobile_number ? appointment.mobile_number : "",
        appointment_time: appointment && appointment.appointment_time ? appointment.appointment_time : null,
        appointment_type: appointmentType,
        purpose_of_visit: appointment && appointment.purpose_of_visit ? appointment.purpose_of_visit : "",
        reffered_by: appointment && appointment.reffered_by ? appointment.reffered_by : "",
        app_time: appointment && appointment.app_time ? appointment.app_time : null,
        isOverideExistingAppointment: false
    });

    const allDOctors = useMemo(() => {
        return doctors.flatMap((group) => group.options);
    }, [doctors]);

    const [appointmentFormError, setAppointmentFormError] = useState({
        patient_id: false,
        appointment_date: false,
        mobile: false,
    });

    //For Editing the appointment
    useEffect(() => {
        if (appointment && appointment.appointment_id > 0) {
            setAppointmentType(appointment.appointment_type ? appointment.appointment_type : "First Time")
            setStartDate(appointment.appointment_time ? new Date(appointment.appointment_time) : null)
            setStartTime(appointment.app_time ? { label: convertToAmPm(appointment.app_time), value: convertToAmPm(appointment.app_time) } : null);
            setNewAppointment({
                appointment_time: appointment.appointment_time,
                clinic_guid: loggeduser.facilityID,
                mobile_number: appointment.mobile_number,
                appointment_id: appointment.appointment_id,
                doctor_id: appointment.doctor_id,
                purpose_of_visit: appointment.purpose_of_visit,
                appointment_type: appointment.appointment_type,
                patient_name: appointment.patient_name,
                patient_op_number: appointment.patient_op_number,
                reffered_by: appointment.reffered_by,
                app_time: appointment.app_time,
            });
            setTimeout(() => {
                if (appointment.doctor_id > 0 && appointment.appointment_time) {
                    var day = DateFunction.getFullDayName(appointment.appointment_time);
                    var drOptions = doctorsTimeslot.filter(dr => dr.doctor_id == appointment.doctor_id)[0]?.doctorAvailableTimings;
                    var options = drOptions.filter(d => d.dayOfTheWeek === day)[0];
                    if (options?.options?.length > 0) {
                        setTimeOptions(options.options)
                        setStartTime(appointment.app_time ? { label: convertToAmPm(appointment.app_time), value: convertToAmPm(appointment.app_time) } : options.options[0]);
                        const selectedTime = new Date(`1970-01-01T${convertTo24Hour(options.options[0]?.value)}`);
                        const timeString = selectedTime.toTimeString()?.split(' ')[0];
                        setNewAppointment((prev) => ({
                            ...prev,
                            app_time: timeString,
                        }));
                    } else {
                        SetInitialTimeOptions();
                    }
                }
            }, [500])

        }
    }, [appointment]);

    const convertToAmPm = timeString => {
        const date = new Date(`1970-01-01T${timeString}`);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    }

    useEffect(() => {
        if (loggeduser && loggeduser.facilityID) {
            setNewAppointment({
                ...newAppointment,
                clinic_guid: loggeduser.facilityID,
            });
        }
    }, [loggeduser]);

    const handleAppointmentType = (event) => {
        setAppointmentType(event.target.value);
        setNewAppointment({
            ...newAppointment,
            appointment_type: event.target.value,
        });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setNewAppointment({
            ...newAppointment,
            [name]: value,
        });
    };

    const generateTimeOptions = (ranges, interval = 10) => {
        const options = [];
        ranges.forEach(({ start, end }) => {
            let startTime = new Date(`1970-01-01T${start}`);
            const endTime = new Date(`1970-01-01T${end}`);

            while (startTime <= endTime) {
                options.push({ value: startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), label: startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) });
                startTime.setMinutes(startTime.getMinutes() + interval);
            }
        });
        return options;
    };

    const SetInitialTimeOptions = () => {
        let timeRanges = [];
        if (loggeduser && loggeduser?.open_time && loggeduser?.close_time) {
            timeRanges = [
                { start: loggeduser?.open_time, end: loggeduser?.close_time }
            ];
        }
        else if (loggeduser && loggeduser?.open_time) {
            timeRanges = [
                { start: loggeduser?.open_time, end: '23:55:00' }
            ];
        } else {
            timeRanges = [
                { start: '00:00:00', end: '23:55:00' }
            ];
        }
        let times = generateTimeOptions(timeRanges);
        setTimeOptions(times);
        setStartTime(times.length > 0 ? times[0] : null)
    }

    useEffect(() => {
        SetInitialTimeOptions();
    }, [loggeduser])


    // Handle date changes
    const handleDateChange = (date) => {
        setStartDate(date);
        setNewAppointment((prev) => ({
            ...prev,
            appointment_time: DateFunction.SetDbDateOfBirth(date),

        }));
        if (newAppointment.doctor_id > 0) {
            var day = DateFunction.getFullDayName(date);
            var drOptions = doctorsTimeslot.filter(dr => dr.doctor_id == newAppointment.doctor_id)[0]?.doctorAvailableTimings;
            var options = drOptions.filter(d => d.dayOfTheWeek === day)[0];
            if (options?.options?.length > 0) {
                setTimeOptions(options.options)
                setStartTime(options.options[0])
            } else {
                SetInitialTimeOptions();
            }
        }
    };

    const handleSelectDoctor = (selectedOption) => {
        setNewAppointment({
            ...newAppointment,
            doctor_id: selectedOption ? selectedOption.value : 0,
            doctor_name: selectedOption ? selectedOption.label : ""
        });
        if (selectedOption && selectedOption.value > 0 && newAppointment.appointment_time) {
            var day = DateFunction.getFullDayName(newAppointment.appointment_time);
            var drOptions = doctorsTimeslot.filter(dr => dr.doctor_id == selectedOption.value)[0]?.doctorAvailableTimings;
            var options = drOptions.filter(d => d.dayOfTheWeek === day)[0];
            if (options?.options?.length > 0) {
                setTimeOptions(options.options)
                setStartTime(options.options[0])
            } else {
                SetInitialTimeOptions();
            }
        }
        else if (!selectedOption) {
            SetInitialTimeOptions();
        }
    };

    const handleEnableEdit = (evt) => {
        setIsEditWindow(true);
    }

    const handleSubmitAppointment = () => {
        const appointmentDate = newAppointment.appointment_time ? new Date(newAppointment.appointment_time).toISOString().split('T')[0] : '';

        setAppointmentFormError({
            ...appointmentFormError,
            appointment_date: !appointmentDate,
            mobile: !newAppointment.mobile_number.trim(),
            patient_id: newAppointment.patient_id === 0,
        });

        if (newAppointment.patient_id !== 0 &&
            appointmentDate &&
            newAppointment.mobile_number.trim() !== "") {
            setSubmitDisable(true);

            const isChanged = !deepEqual(newAppointment, originalAppointment);

            if (!isChanged) {

                setSubmitDisable(false);
                dispatch(
                    setNotification({
                        message: "No Changes to Update",
                        status: "info",
                        action: true,
                    })
                );
                return; // No changes, exit the function
            }
            store
                .updateAppointment(
                    loggedusertoken,
                    DataDecode.encryptPayload(newAppointment)
                )
                .then((result) => {
                    setSubmitDisable(false);
                    if (result.status == 204) {
                        dispatch(setAppGridViewAppointmentData({
                            ...appointment,
                            doctor_id: newAppointment.doctor_id,
                            app_time: newAppointment.app_time,
                            appointment_time: newAppointment.appointment_time,
                            appointment_type: newAppointment.appointment_type,
                            mobile_number: newAppointment.mobile_number,
                            purpose_of_visit: newAppointment.purpose_of_visit,
                            reffered_by: newAppointment.reffered_by
                        }))
                        store.CreateActivityLogs(loggeduser, (isDefaultGridView ? "Appointment Grid View" : "Appointment List View"), `Appointment Updated - ${(newAppointment.doctor_name ? "for " + newAppointment.doctor_name?.trim():"")} with patient ${appointment.FirstName} ${appointment.MiddleName?.charAt(0)} ${appointment.LastName}(${appointment.patient_op_number}) on ${DateFunction.GetDate(newAppointment.appointment_time)} ${(newAppointment.app_time ? " at " + DateFunction.Convert12Hour(newAppointment.app_time) : "")}`,"Update");
                        dispatch(
                            setNotification({
                                message: "Appointment Updated Successfully!",
                                status: "success",
                                action: true,
                            })
                        );
                        setIsEditWindow(false);
                        setRefreshData((r) => !r);
                        setAppointmentDetailRef(null)
                    } else if (result.status == 409) {
                        dispatch(
                            setNotification({
                                message: "Appointment Not Available!",
                                status: "info",
                                action: true,
                            })
                        );
                    } else {
                        dispatch(
                            setNotification({
                                message: "Please fill the mandatory fields!",
                                status: "info",
                                action: true,
                            })
                        );
                    }
                })
                .catch((err) => {
                    setLoading(false);
                });
        } else {
            dispatch(
                setNotification({
                    message: "Please fill the mandatory fields!",
                    status: "info",
                    action: true,
                })
            );
        }
    }

    const autoResizeTextarea = e => {
        const textarea = e.target;
        if (!textarea) return;  // Add this null check to avoid the error
        textarea.style.height = "auto";  // Reset the height
        textarea.style.height = `${textarea.scrollHeight}px`;  // Set height based on content
    }

    useEffect(() => {
        setTimeout(() => {
            if (textareaRef.current) {
                textareaRef.current.style.height = "auto";
                textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
            }
        }, 100)
    }, [appointment.purpose_of_visit, isEditWindow])

    const convertTo24Hour = (time12) => {
        if (!time12) return null;
    
        const [timeStr, period] = time12.split(' ');
        let [hours, minutes] = timeStr.split(':');
        hours = parseInt(hours);
    
        if (period === 'PM' && hours !== 12) {
          hours += 12;
        } else if (period === 'AM' && hours === 12) {
          hours = 0;
        }
    
        return `${hours.toString().padStart(2, '0')}:${minutes}:00`;
      };

    const handleTimeChange = (time) => {
        if (time) {
            const selectedTime = new Date(`1970-01-01T${convertTo24Hour(time.value)}`);
            const timeString = selectedTime.toTimeString().split(' ')[0];
            setStartTime(time);
            setNewAppointment((prev) => ({
                ...prev,
                app_time: timeString,
            }));
        } else {
            setStartTime(null);
            setNewAppointment((prev) => ({
                ...prev,
                app_time: null,
            }));
        }
    };

    const handleClosePopup = () => {
        setIsEditWindow(false);
        setAppointmentDetailRef(null);
    }

    const addMonths = (date, months) => {
        var newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() + months);
        return newDate;
    };

    const updateTimeSlots = () => {
        const today = dayjs().startOf('day');
        const now = dayjs();
        if (dayjs(startDate).isSame(today, 'day')) {
            const isToday = dayjs(startDate).isSame(today, 'day');

            const newOptions = timeOptions.filter(option => {
                const optionTime = dayjs(`${today.format('YYYY-MM-DD')} ${option.value}`, 'YYYY-MM-DD hh:mm A');
                return isToday ? optionTime.isAfter(now) : true;
            });
            setFilterTimeOptions(newOptions);
            setStartTime(newOptions[0])
            const selectedTime = new Date(`1970-01-01T${convertTo24Hour(newOptions[0]?.value)}`);
            const timeString = selectedTime.toTimeString().split(' ')[0];
            setNewAppointment((prev) => ({
                ...prev,
                app_time: timeString,
            }));
        } else {
            setFilterTimeOptions(timeOptions);
            setStartTime(timeOptions[0])
            const selectedTime = new Date(`1970-01-01T${convertTo24Hour(timeOptions[0]?.value)}`);
            const timeString = selectedTime.toTimeString().split(' ')[0];
            setNewAppointment((prev) => ({
                ...prev,
                app_time: timeString,
            }));
        }
    }

    useEffect(() => {
        updateTimeSlots();
        const intervalId = setInterval(updateTimeSlots, 60000);
        return () => clearInterval(intervalId);
    }, [timeOptions, startDate]);

    const editVisitHistory = (appointment_id, patient_id, doctor_id) => {
        const data = btoa(
            JSON.stringify({
                appointment_id: appointment_id,
                patient_id: patient_id,
                doctor_id: doctor_id,
                clinic_guid: loggeduser.facilityID,
                page_src: "appointment",
            })
        );
        window.location.href = `/patient-profile?profile=${data}`;
    };

    const openPatientDetails = (app) => {
        if (app.status === "Completed") {
            editVisitHistory(app.appointment_id, app.patient_id, app.doctor_id || 0)
        } else {
            localStorage.setItem("NavigationScreen", "appointments");
            let data = btoa(
                JSON.stringify({
                    appointment_id: app.appointment_id,
                    patient_id: app.patient_id,
                    doctor_id: app.doctor_id || 0,
                    clinic_guid: app.clinic_guid,
                    page_src: "appointment",
                })
            );
            window.location.href = `/patient-profile?profile=${data}`;
        }
    };

    const handleCancelButton = (app_id) => {
        if (isEditWindow) {
            setIsEditWindow(false);
        } else {
            deleteAppointment(app_id);
            setAppointmentDetailRef(null)
        }

    }

    return (
        <div id="appointmentPatientDetailsPopup">
            <div className="flex justify-between items-center p-4 bg-[#A31B1B] text-[#FFFFFF] text-base font-semibold z-[999999]">
                <span id="appointmentPatientDetailsPopupTitle">Appointment Details</span>
                <span id="appointmentPatientDetailsPopupStatus" className="text-[11px] font-normal text-[#AFE7E4] uppercase">
                    {appointment.status}
                </span>
            </div>

            <div className="flex flex-col justify-start items-center bg-[#FFFFFF]">
                <div className="flex justify-start items-center px-6 py-4 w-full border-b border-[#A6A6A6]">
                    <h5 id="appointmentPatientDetailsPopupNameLabel" className="!text-[#282358] !font-medium text-[15px] pr-1">
                        Patient{" "}
                    </h5>
                    <h5 id="appointmentPatientDetailsPopupNameText" className="!text-[#5C5C5C] !font-normal !text-[15px] flex">
                        :{" "}
                        <Tooltip
                            title={
                                <div className="w-[40] h-[50]">
                                    <Typography variant="body2">
                                        {appointment.FirstName + " " + appointment.MiddleName?.charAt(0) + " " + appointment.LastName}
                                    </Typography>
                                </div>
                            }
                            placement="top-start"
                        >
                            <span className="block truncate w-[inherit] !ml-[2px] pt-0.5">
                                <Typography variant="body1" className='cursor-pointer text-blue-500 hover:underline' onClick={() => { openPatientDetails(appointment) }}>
                                    {appointment.FirstName + " " + appointment.MiddleName?.charAt(0) + " " + appointment.LastName}
                                </Typography>
                            </span>
                        </Tooltip>
                    </h5>
                </div>
                <div className="flex justify-start items-center px-6 py-4 w-full border-b border-[#A6A6A6]">
                    <h5 id="appointmentPatientDetailsPopupDrNameLabel" className="!text-[#282358] !font-medium text-[15px] pr-1">
                        Doctor{" "}
                    </h5>
                    {isEditWindow ?
                        <div className="w-full relative ml-2">
                            <Select
                                id="appointmentPatientDetailsPopupNameSelectDr"
                                styles={{
                                    input: (base) => ({
                                        ...base,
                                        "input:focus": {
                                            boxShadow: "none",
                                        },
                                    }),
                                }}
                                placeholder="Select Doctor"
                                isClearable={true}
                                isSearchable={true}
                                options={doctors}
                                onChange={handleSelectDoctor}
                                value={
                                    newAppointment.doctor_id > 0
                                        ? allDOctors.find(
                                            (option) => option.value === newAppointment.doctor_id
                                        )
                                        : null
                                }
                            />
                        </div>
                        : <h5 id="appointmentPatientDetailsPopupDrNameText" className="!text-[#5C5C5C] !font-normal !text-[15px] flex">
                            :{" "}{appointment.doctor_name}
                        </h5>}

                </div>
                <div className="px-6 py-4 w-full border-b border-[#A6A6A6]">
                    {isEditWindow ?
                        <>
                            <div className='flex justify-start'>
                                <h5 id="appointmentPatientDetailsPopupDateLabel" className="!text-[#282358] !font-medium text-[15px] pr-1 pt-2">
                                    Date{" "}
                                </h5>

                                <div id="AddAppointmentDivInputTheDate" className="relative ml-2 w-full">
                                    <DatePicker
                                        id="AddAppointmentInputTheDate"
                                        selected={startDate}
                                        onChange={handleDateChange}
                                        minDate={new Date()}
                                        maxDate={addMonths(new Date(), 3)}
                                        className="block w-full border rounded-md focus:outline-none focus:border-gray-500 pr-2"
                                        dateFormat="MMM dd, yyyy"
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={100}
                                        showMonthDropdown
                                        placeholderText="Select Date"
                                        onBlur={() => setAppointmentFormError({ ...appointmentFormError, appointment_date: !startDate })}
                                    />
                                    <svg id="AddAppointmentInputSvgDate" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400">
                                        <path d="M19 4H18V2H16V4H8V2H6V4H5C3.89 4 3 4.9 3 6V20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 20H5V10H19V20ZM19 8H5V6H19V8ZM7 12H12V17H7V12Z" fill="#666666" />
                                    </svg>
                                </div>
                            </div>
                            <div className='flex justify-start mt-2'>
                                <h5 id="appointmentPatientDetailsPopupTimeLabel" className="!text-[#282358] !font-medium text-[15px] pr-1 pt-2">
                                    Time{" "}
                                </h5>
                                <div id="AddAppointmentDivInputTheTime" className="relative w-full ml-2">
                                    <Select
                                        styles={{
                                            input: (base) => ({
                                                ...base,
                                                padding: '5px 0px',
                                                cursor: 'pointer',
                                                "input:focus": {
                                                    boxShadow: "none",
                                                },
                                            }),
                                        }}
                                        selected={startTime}
                                        onChange={handleTimeChange}
                                        placeholder="Select Time"
                                        isClearable={true}
                                        isSearchable={true}
                                        options={filtertimeOptions}
                                        value={startTime}
                                    />
                                </div>
                            </div>
                        </>
                        :
                        <div className='flex justify-start items-center'>
                            <h5 id="appointmentPatientDetailsPopupDateLabel" className="!text-[#282358] !font-medium text-[15px] pr-1">
                                Date{" "}
                            </h5>
                            <h5 id="appointmentPatientDetailsPopupDateText" className="!text-[#5C5C5C] !font-normal !text-[15px] flex">
                                :{" "}
                                {DateFunction.GetDate(appointment.appointment_time)}
                            </h5>
                            <h5 id="appointmentPatientDetailsPopupTimeLabel" className="!text-[#282358] !font-medium text-[15px] pr-1 ml-4">
                                Time{" "}
                            </h5>
                            <h5 id="appointmentPatientDetailsPopupTimeText" className="!text-[#5C5C5C] !font-normal !text-[15px] flex">
                                :{" "}
                                {DateFunction.Convert12Hour(appointment.app_time)}
                            </h5>
                        </div>
                    }
                </div>
                <div className="flex justify-start items-center px-6 py-4 w-full border-b border-[#A6A6A6]">
                    <h5 id="appointmentPatientDetailsPopupMobileLabel" className="!text-[#282358] !font-medium text-[15px] pr-1">
                        Mobile{" "}
                    </h5>
                    {isEditWindow ?
                        <div id="AddAppointmentDivphoneInputContainer" className="relative">
                            <PhoneInput
                                country={"in"}
                                disableDropdown={true}
                                onlyCountries={["in"]}
                                value={newAppointment.mobile_number}
                                enableSearch={false}
                                onChange={(value) => {
                                    handleChange({ target: { name: "mobile_number", value } });
                                }}
                                inputProps={{
                                    name: "mobile_number",
                                    id: "Inputmobile",
                                    className:
                                        "block w-full border rounded-md focus:outline-none focus:border-gray-400",
                                }}
                                specialLabel=""
                                title=""
                                containerStyle={{ position: "relative", width: "100%" }}
                                buttonStyle={{
                                    position: "absolute",
                                    left: "0",
                                    top: "0",
                                    height: "100%",
                                }}
                                inputStyle={{ paddingLeft: "56px" }}
                            />
                            <svg id="AddAppointmentSvgdropdownIcon"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="w-4 h-4 absolute top-1/2 right-3 -translate-y-1/2 text-gray-500"
                            >
                                <path id="AddAppointmentPathdropdownIcon"
                                    fillRule="evenodd"
                                    d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </div> :
                        <h5 className="!text-[#5C5C5C] !font-normal !text-[15px] flex">
                            :{" "}
                            {appointment.mobile_number}
                        </h5>}
                </div>
                <div className="flex justify-start items-center px-6 py-4 w-full border-b border-[#A6A6A6]">
                    <h5 className="!text-[#282358] !font-medium text-[15px] pr-1">
                        Appointment Type{" "}
                    </h5>
                    {isEditWindow ?
                        <div id="AddAppointmentDivradioContainer" className="relative">
                            <div id="AddAppointmentDivradioOptions" className="flex pt-2">
                                <div id="AddAppointmentDivfirstTime" className="flex items-center me-4">
                                    <input
                                        id="AddAppointmentInputfirstTime"
                                        checked={appointmentType === "First Time"}
                                        onChange={handleAppointmentType}
                                        type="radio"
                                        value="First Time"
                                        name="inline-radio-group"
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 ring-0 cursor-pointer"
                                    />
                                    <label
                                        id="AddAppointmentLabelfirstTime"
                                        htmlFor="inline-radio"
                                        className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        First Time
                                    </label>
                                </div>
                                <div id="AddAppointmentDivfollowUp" className="flex items-center me-4">
                                    <input
                                        id="AddAppointmentInputfollowUp"
                                        checked={appointmentType === "Follow Up"}
                                        onChange={handleAppointmentType}
                                        type="radio"
                                        value="Follow Up"
                                        name="inline-radio-group"
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 right-0 cursor-pointer"
                                    />
                                    <label
                                        id="AddAppointmentLabelfollowUp"
                                        htmlFor="inline-2-radio"
                                        className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Follow Up
                                    </label>
                                </div>
                            </div>
                        </div>
                        :
                        <h5 className="!text-[#5C5C5C] !font-normal !text-[15px] flex">
                            :{" "}
                            {appointment.appointment_type}
                        </h5>}
                </div>
                <div className="flex justify-start items-center px-6 py-4 w-full border-b border-[#A6A6A6]">
                    <h5 id="appointmentPatientDetailsPopupPurposeLabel" className="!text-[#282358] !font-medium text-[15px] pr-1">
                        Purpose of Visit{" "}
                    </h5>
                    {isEditWindow ?

                        <div className="relative">
                            <textarea
                                id="AddAppointmentInputpurposeVisit"
                                ref={textareaRef}
                                style={{ height: "auto", minHeight: "40px", resize: "none" }}
                                value={newAppointment.purpose_of_visit}
                                name="purpose_of_visit"
                                onChange={(e) => {
                                    handleChange(e);
                                    autoResizeTextarea(e);
                                }}
                                className="overflow-y-hidden block w-full border text-sm rounded-md focus:outline-none focus:border-gray-500 px-2 py-2 resize-none overflow-hidden"
                                rows="1"
                            />
                        </div>
                        :
                        <h5 className="!text-[#5C5C5C] !font-normal !text-[15px] flex">
                            :{" "}
                            {appointment.purpose_of_visit}
                        </h5>}
                </div>
                <div className="flex justify-start items-center px-6 py-4 w-full border-b border-[#A6A6A6]">
                    <h5 id="appointmentPatientDetailsPopupRefLabel" className="!text-[#282358] !font-medium text-[15px] pr-1">
                        Referred By{" "}
                    </h5>
                    {isEditWindow ?
                        <div className="relative">
                            <input
                                type="text"
                                id="AddAppointmentInputrefferedby"
                                onChange={handleChange}
                                value={newAppointment.reffered_by}
                                name="reffered_by"
                                placeholder="Refered By"
                                className="block w-full border text-sm rounded-md focus:outline-none focus:border-gray-500 px-2 py-2"
                            />
                        </div> :
                        <h5 className="!text-[#5C5C5C] !font-normal !text-[15px] flex">
                            :{" "}
                            {appointment.reffered_by}
                        </h5>}
                </div>
                {appointment.status !== "Completed" &&
                    <div className="flex justify-center px-6 py-4 w-full">
                        <button id="BtnAppointmentGridPopupCancel" onClick={() => handleCancelButton(appointment)} className='py-1 px-5 text-md bg-[#616161] text-white rounded-md mt-2 font-semibold mr-2'>{isEditWindow ? "Cancel" : "Delete"}</button>
                        {isEditWindow ?
                            <button id="BtnAppointmentGridPopupSaveChanges" onClick={handleSubmitAppointment} className='py-1 px-5 text-md bg-[#A31B1B] text-white rounded-md mt-2 font-semibold'>Save Changes</button>
                            :
                            <button id="BtnAppointmentGridPopupReschedule" onClick={handleEnableEdit} className='py-1 px-5 text-md bg-[#A31B1B] text-white rounded-md mt-2 font-semibold'>Reschedule</button>
                        }
                    </div>}
            </div>
        </div>
    )
}

export default AppDetailsPopover