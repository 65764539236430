// components/ClearSessionStorageListener.js
import React, { useEffect } from 'react';

const ClearSessionStorageListener = () => {
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'clearSessionStorage') {
        sessionStorage.clear(); // Clear session storage
        window.location.reload(); //reload the page reflects in all the tab
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return null;
};

export default ClearSessionStorageListener;
