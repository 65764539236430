import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Footer from './Footer';
import MobileFooter from './MobileFooter';
import { Outlet } from 'react-router';
import { CiCircleChevLeft, CiCircleChevRight } from 'react-icons/ci';
import MobileSideNavBar from './MobileSideNavBar';
import { useSelector, useDispatch } from 'react-redux';
import ToastMessage from './ToastMessage';
import Feedback from '../Pages/Feedback/Feedback';



function MainPage(props) {
  const { ishomepage, toggleSidebar, isSidebarOpen, setIsSidebarOpen } = props;

  const dispatch = useDispatch();
  var notification = useSelector(state => state.toast.notification);
  var feedback = useSelector(state => state.feedback.feedback_status);

  return (
    <>
      {
        ishomepage === false ?
          <>
            <div className=' h-screen sticky top-0'>
              <div className={` overflow-hidden hidden lg:block`}>
                <Sidebar id="MainSideBar" isSidebarOpen={isSidebarOpen} />
                <div className={isSidebarOpen ? ' mt-24  hidden lg:block absolute top-0 right-[-23px]' : "mt-24  hidden lg:block absolute top-0 right-[-26px]"}>
                  {isSidebarOpen ? (
                    <CiCircleChevLeft
                      id="MainLeftIcon"
                      className=' text-gray-600 cursor-pointer rounded-[50%] bg-[#fff]'
                      onClick={toggleSidebar}
                      size={30} />) : (
                    <CiCircleChevRight
                      id="MainRightIcon"
                      className=' text-gray-600 cursor-pointer rounded-[50%] bg-[#fff]'
                      onClick={toggleSidebar}
                      size={30} />)}
                  <div className="pl-10">

                    <Outlet  id="MainOutlet" />
                    {

                      feedback &&

                      <Feedback id="MainFeedback" />
                    }
                  </div>
                </div>
              </div>

              {/* <div className='mt-[88px] lg:hidden xs:block w-10'>
          <MobileSideNavBar/>
        </div> */}
            </div>

            <div className=' block lg:hidden'>
              <MobileFooter id="MainMobileFooter" />
            </div>

            <div className='hidden lg:block'></div>
            <Footer id="MainFooter" />
            {
              notification.action &&
              <ToastMessage message={notification.message} type={notification.status} />
            }


          </>
          :
          <div>
            <section id="MainSectionbakgroundImage" className='my-10 min-h-screen' style={{ backgroundImage: "url('./images/home.png)" }}></section>
          </div>
      }
    </>
  );
}

export default MainPage;