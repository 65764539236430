import { createSlice } from "@reduxjs/toolkit";

const gridViewTodaySlice = createSlice({
    name: "appGridViewToday",
    initialState: {
        sideDateToShow: new Date().toISOString()
    },
    reducers: {
        setSideDateToShow: (state, action) => {
            state.sideDateToShow = action.payload;
        },
    },
});

export const { setSideDateToShow } = gridViewTodaySlice.actions;
export default gridViewTodaySlice.reducer;
