import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from '../../../../Redux/features/toast/toastSlice';
import store from '../../../../API/store';
import DataDecode from '../../../../utils/DataDecode';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../../../overrides.css';
import PrescriptionPrintPreview from '../PrescriptionPrintPreview';
import UploadPrescription from '../UploadPrescription';
import CreatableSelect from 'react-select/creatable';
import { setAddAppointmentPopup, setPatientAppointment } from '../../../../Redux/features/popup/popupSlice';
import FileIcon from '../FileIcon';
import DateFunction from '../../../../utils/DateFunctions';
import { setIsGlobalSaveRequired } from '../../../../Redux/features/profileDrawer/profileSideNavbarSlice';
import { theme } from '../../../../EmraxisTheme/Theme';

const NewPrescriptions = ({ appointment, clinicGuid, patient, patient_id, doctor_id, appointment_id, page_src }) => {
    const dispatch = useDispatch();
    const [file, setFile] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [submitDisable, setSubmitDisable] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingSavePrint, setLoadingSavePrint] = useState(false);
    const [nextReview, setNextReview] = useState(null);
    const [expandRow, setExpandRow] = useState(true);
    const [uploadPrescription, setUploadPrescription] = useState(false);
    const [refreshImage, setRefreshImage] = useState(false);
    const [typeScript, setTypeScript] = useState(true);
    const [confirmBox, setConfirmBox] = useState(false);
    const [confirmBoxDeleteAll, setConfirmBoxDeleteAll] = useState(false);
    const [loadingDeleteImage, setLoadingDeleteImage] = useState(false);
    const [deleteFile, setDeleteFile] = useState([]);
    const [printPreview, setPrintPreview] = useState(false);
    const [medicineOptions, setMedicineOptions] = useState([]);
    const [allPrescriptions, setAllPrescriptions] = useState([]);
    const [medicineDetails, setMedicineDetails] = useState("");
    const [inputValue, setInputValue] = useState('');
    const [isPrescriptionOpen, setIsPrescriptionOpen] = useState(false);
    const [isMessagePopupOpen, setIsMessagePopupOpen] = useState(null);
    const [isAnyChanges, setIsAnyChanges] = useState(false);
    const [dataLoading, setDataLoading] = useState(true);
    const [medicineSelectedValues, setMedicineSelectedValues] = useState({});
    const [loadInitialMedidcineStatus, setLoadInitialMedidcineStatus] = useState({ medicine: false, existingRows: false });
    const [viewMore, setViewMore] = useState(false);

    const [checkNewPrescription, setCheckNewPrescription] = useState({
        file: 'initial',
        entry: 'initial'
    });

    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    var loggeduser = useSelector(state => state.login.loginuserData);
    var autoSaveAll = useSelector(state => state.profileSideNavbar.autoSaveAll);

    const [rows, setRows] = useState([]);
    const [enterPrescriptionExist, setEnterPrescriptionExist] = useState(false);
    var refreshPrescription = useSelector(state => state.document_upload.refreshPrescription);
    var patientAddAppointment = useSelector(state => state.popup.setPatientAddAppointment);
    const previewTheme = useSelector(state => state.theme.colors) || theme.filter(a => a.name === "defaultTheme")[0];

    const handleMessagePopupOpen = () => {
        setIsMessagePopupOpen(!isMessagePopupOpen);
    }
    const openAppointmentPopup = () => {
        dispatch(setAddAppointmentPopup(true));
        dispatch(setPatientAppointment({ isOpenAppointment: true, patient: patient, path: 'appointment' }))
    };
    const openPopup = () => {
        if (appointment_id == 0 || (appointment && (appointment.status == 'Completed'))) {
            handleMessagePopupOpen(true);
        } else {
            setIsPrescriptionOpen(true);
            setTypeScript(true);
        }
    }
    useEffect(() => {
        if (patientAddAppointment?.isOpenAppointment) {
            dispatch(setAddAppointmentPopup(true));
        }
    }, [patientAddAppointment])


    useEffect(() => {
        if (patient?.patient_id > 0 && appointment?.appointment_id > 0) {
            GetPrescription();
            GetPatientFiles();
            setNextReview(appointment.nextReview ? appointment.nextReview : null)
        }
    }, [appointment, patient])

    const GetPrescription = () => {
        if (appointment && appointment?.appointment_id) {
            store.GetPrescription(loggedusertoken, DataDecode.encryptPayload({ appointment_id: appointment.appointment_id, 'clinic_guid': clinicGuid }))
                .then((result) => {
                    if (result.status == 200) {
                        result.json().then((res) => {
                            let response = DataDecode.decryptResponse(res);
                            let val = JSON.parse(response);
                            setRows(val);
                            if (val.length > 0) {
                                setLoadInitialMedidcineStatus({ ...loadInitialMedidcineStatus, existingRows: true })
                                setTimeout(() => {
                                    setCheckNewPrescription({
                                        ...checkNewPrescription,
                                        entry: 'exist'
                                    })
                                    setExpandRow(true);
                                }, 100)
                                // setIsPrescriptionOpen(true);
                                setEnterPrescriptionExist(true)
                                setTypeScript(true);
                            } else {
                                setTimeout(() => {
                                    setCheckNewPrescription({
                                        ...checkNewPrescription,
                                        entry: 'na'
                                    })
                                }, 100)
                            }
                        })
                    } else {
                        setCheckNewPrescription({
                            ...checkNewPrescription,
                            entry: 'na'
                        })
                        setRows([{ order_id: 1, medicine: '', medicine_type: 'Tablet', when_medidcine: false, morning: '', noon: '', evening: '', night: '', duration: '', total: 'N/A', other: '' }])
                    }
                })
                .catch(err => {
                    dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
                })
        }
    }

    const GetPatientFiles = () => {
        store.GetPatientFiles(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': clinicGuid, 'patient_id': patient.patient_id, 'appointment_id': appointment.appointment_id }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        let data = JSON.parse(response);
                        let pres = data.filter(a => a.file_type === 'Prescription');
                        setFile(pres.length > 0 ? pres[0] : null);
                        setFileList(pres.length > 0 ? pres : []);
                        setExpandRow(pres.length > 0 ? true : false);
                        setCheckNewPrescription({
                            ...checkNewPrescription,
                            file: (pres.length > 0 ? 'exist' : 'na')
                        })
                    })
                }
                else if (result.status == 404) {
                    setFile(null)
                    setFileList([])
                    setCheckNewPrescription({
                        ...checkNewPrescription,
                        file: 'na'
                    })
                }
            })
            .catch(err => {
                dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
            })
    }

    const GetAllPatientPrescriptions = () => {
        if (allPrescriptions.length == 0) {
            setDataLoading(true);
        }
        store.GetAllPatientPrescriptions(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': clinicGuid, 'patient_id': patient_id }))
            .then((result) => {
                setDataLoading(false);
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        setAllPrescriptions(JSON.parse(response));
                    })
                } else {
                    setAllPrescriptions([])
                }
            })
            .catch(err => {
                setDataLoading(false);
                setAllPrescriptions([])
                dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
            })
    }

    useEffect(() => {
        if (page_src === "patient") {
            GetAllPatientPrescriptions();
        }
    }, [])

    useEffect(() => {
        if (clinicGuid && patient?.patient_id > 0 && appointment?.appointment_id > 0) {
            GetPrescription();
            GetPatientFiles();
        }
    }, [refreshImage, clinicGuid, patient, appointment, refreshPrescription])

    const addRow = () => {
        const maxValue = Math.max(...rows.map(obj => obj.order_id));
        const newRow = { order_id: maxValue < rows.length + 1 ? rows.length + 1 : maxValue + 1, medicine: '', medicine_type: 'Tablet', when_medidcine: false, morning: '', noon: '', evening: '', night: '', duration: '', total: 'N/A', other: '' };
        setRows([...rows, newRow]);
    };


    const deleteRow = (id) => {
        const updatedRows = rows.filter(row => row.order_id !== id);
        if (updatedRows.length > 0) {
            setRows(updatedRows);
            setIsAnyChanges(true);
            dispatch(setIsGlobalSaveRequired(true));
            setMedicineSelectedValues(prevValues => {
                const newValues = { ...prevValues };
                delete newValues[id];
                return newValues;
            });
        } else {
            dispatch(setNotification({ message: 'Minimum one row required!', status: 'error', action: true }));
        }        
    };
    const fetchMedicineData = () => {
        try {
            store.GetAllMedicine(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': clinicGuid }))
                .then(response => {
                    if (response.status == 200) {
                        response.json().then(res => {
                            const decryptedResponse = DataDecode.decryptResponse(res);
                            const data = JSON.parse(decryptedResponse);
                            if (data.length > 0) {
                                const options = data.map(val => ({
                                    value: val.medicine_id,
                                    label: val.medicine_name,

                                }));
                                setMedicineOptions(options);
                                setLoadInitialMedidcineStatus({ ...loadInitialMedidcineStatus, medicine: true })
                            }
                        })
                    } else {
                        setMedicineOptions([]);
                    }
                })
        } catch (err) {
            dispatch(setNotification({ message: 'Error occurred', status: 'error', action: true }));
            setMedicineOptions([]);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if (loadInitialMedidcineStatus.existingRows && loadInitialMedidcineStatus.medicine) {
                let updatedMedicine = {};
                rows.map((r) => {
                    let seletedMedicine = medicineOptions.filter(a => a.label?.trim()?.toLowerCase() === r.medicine?.trim()?.toLowerCase())
                    if (seletedMedicine.length > 0) {
                        updatedMedicine[r.order_id] = seletedMedicine[0];
                    }
                });
                setTimeout(() => {
                    setMedicineSelectedValues({
                        ...medicineSelectedValues,
                        ...updatedMedicine
                    });
                }, 100)
            }
        }, 100)
    }, [loadInitialMedidcineStatus])

   
    async function validateMedicine(inputValue) {
        const apiUrl = process.env.REACT_APP_EMRAXIS_MEDICINEVALIDATION + encodeURIComponent(inputValue);
        const response = await fetch(apiUrl);
        if (response.status == 200) {
            return true;
        }else {
            return false;
        }
    }

    useEffect(() => {
        fetchMedicineData()
    }, [clinicGuid, loggedusertoken]);

    const handleMedicineChange = (id, selectedOption) => {
        const { value, label } = selectedOption;
        const updatedRows = rows.map(row =>
            row.order_id == id ? { ...row, medicine: label } : row
        );
        setIsAnyChanges(true);
        dispatch(setIsGlobalSaveRequired(true));
        setRows(updatedRows);
        const isNewOption = !medicineOptions.some(option => option.label === label);
        if (isNewOption) {
            const newOption = { value, label };
            setMedicineOptions([...medicineOptions, newOption]);
        }
        setMedicineSelectedValues({
            ...medicineSelectedValues,
            [id]: selectedOption
        });
    }    
    const handleCreateChange = async (id, inputValue) => {
        try {
            let checkExixtingRow = rows.filter(a=>a.medicine?.toLowerCase() === inputValue?.toLowerCase())
            if(checkExixtingRow?.length > 0){
                dispatch(setNotification({ message: 'Medicine already added in the list!', status: 'info', action: true }));
            }else{
                const isValidMedicine = await validateMedicine(inputValue);
                if (isValidMedicine) {
                    const updatedRows = rows.map(row =>
                        row.order_id === id ? { ...row, medicine: inputValue } : row
                    );
                    setIsAnyChanges(true);
                    dispatch(setIsGlobalSaveRequired(true));
                    setRows(updatedRows);
                } else {
                    dispatch(setNotification({ message: 'Please enter valid medicine name!', status: 'error', action: true }));
                }
            }
        } catch (error) {
            dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
        }
    };
    const handleChange = (id, event) => {
        const { name, value, type, checked } = event.target;
        const updatedRows = rows.map(row =>
            row.order_id === id ? { ...row, [name]: type === 'checkbox' ? checked : value } : row
        );
        setIsAnyChanges(true);
        setRows(updatedRows);
    };


    const savePrescription = (save_type) => {
        let exist = rows.filter(a => a.medicine.trim() === "" || a.duration == 0 || a.duration == "")
        if (exist.length > 0 && typeScript) {
            dispatch(setNotification({ message: '* Fields are required!', status: 'error', action: true }));
        }
        else if (!file && !enterPrescriptionExist && exist > 0) {
            dispatch(setNotification({ message: 'Please add prescription!', status: 'error', action: true }));
        }
        else {
            setSubmitDisable(true);
            if (save_type === 'save') {
                setLoadingSave(true);
            } else {
                setLoadingSavePrint(true);
            }
            let payload = { appointment_id: appointment.appointment_id, 'clinic_guid': clinicGuid, 'prescriptions': rows, patient_id: patient.patient_id, doctor_id: doctor_id, nextReview: nextReview, type_script: typeScript, medicineDetails: medicineDetails, inputValue: inputValue }
            store.AddPrescription(loggedusertoken, DataDecode.encryptPayload(payload))
                .then((result) => {
                    setSubmitDisable(false);
                    setIsAnyChanges(false);
                    if (result.status == 201) {
                        if (save_type === 'save') {
                            setLoadingSave(false);
                        } else {
                            setPrintPreview(true);
                            setLoadingSavePrint(false);
                        }
                        store.CreateActivityLogs(loggeduser, "Appointment to Patient Profile", `Prescription Updated - ${(appointment?.doctor_name ? "for " + appointment?.doctor_name?.trim():"")} with patient ${patient.FirstName} ${patient.MiddleName?.charAt(0)} ${patient.LastName}(${patient?.patient_op_number}) on ${DateFunction.GetDate(appointment?.appointment_time)} ${(appointment?.app_time ? " at " + DateFunction.Convert12Hour(appointment?.app_time) : "")}`,"Update");
                        dispatch(setNotification({ message: "Prescription Added", status: 'success', action: true }));
                    }
                    else {
                        setLoadingSave(false);
                        setLoadingSavePrint(false);
                        dispatch(setNotification({ message: 'Error Occured!', status: 'error', action: true }));
                    }
                })
                .catch(err => {
                    dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                });
        }
    };

    useEffect(() => {
        let exist = rows.filter(a => a.medicine.trim() !== "" || a.duration != 0 || a.duration !== "")
        if (isAnyChanges && exist.length > 0) {
            savePrescription('save')
        }
    }, [autoSaveAll])

    const addMonths = (date, months) => {
        var newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() + months);
        return newDate;
    }


    const deletePrescriptionFile = () => {
        setLoadingDeleteImage(true);
        store.DeletePatientFile(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': clinicGuid, 'patient_id': patient.patient_id, files: deleteFile }))
            .then((result) => {
                setLoadingDeleteImage(false)
                setConfirmBox(false)
                if (result.status == 204) {
                    store.CreateActivityLogs(loggeduser, "Appointment to Patient Profile", `Prescription File Deleted - ${(appointment?.doctor_name ? "for " + appointment?.doctor_name?.trim():"")} with patient ${patient.FirstName} ${patient.MiddleName?.charAt(0)} ${patient.LastName}(${patient?.patient_op_number}) on ${DateFunction.GetDate(appointment?.appointment_time)} ${(appointment?.app_time ? " at " + DateFunction.Convert12Hour(appointment?.app_time) : "")}`,"Delete");
                    setRefreshImage(o => !o);
                    dispatch(setNotification({ message: 'Deleted Successfully!', status: 'success', action: true }));
                }
                else {
                    dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                }

            })
            .catch((error) => {
                dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
            });
    }

    const deleteEnteredPrescriptions = () => {
        setLoadingDeleteImage(true);
        store.DeleteEnteredPrescriptions(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': clinicGuid, 'patient_id': patient.patient_id, appointment_id: appointment.appointment_id }))
            .then((result) => {
                setLoadingDeleteImage(false)
                setConfirmBoxDeleteAll(false);
                if (result.status == 204) {
                    GetPrescription();
                    setEnterPrescriptionExist(false);
                    store.CreateActivityLogs(loggeduser, "Appointment to Patient Profile", `Prescription Deleted - ${(appointment?.doctor_name ? "for " + appointment?.doctor_name?.trim():"")} with patient ${patient.FirstName} ${patient.MiddleName?.charAt(0)} ${patient.LastName}(${patient?.patient_op_number}) on ${DateFunction.GetDate(appointment?.appointment_time)} ${(appointment?.app_time ? " at " + DateFunction.Convert12Hour(appointment?.app_time) : "")}`,"Delete");
                    dispatch(setNotification({ message: 'Deleted Successfully!', status: 'success', action: true }));
                }
                else {
                    dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                }
            })
            .catch((error) => {
                console.error("Error occurred while saving patient details:", error);
            });
    }

    const handleTimingChange = (id, event) => {
        const updatedRows = rows.map(row =>
            row.order_id === id ? { ...row, [event]: row[event] === "" ? "1" : "" } : row
        );
        setRows(updatedRows);
    }

    const getAvailableOptions = (id) => {
        const selectedOptions = Object.values(medicineSelectedValues).map(option => option?.value);
        return medicineOptions.filter(option => !selectedOptions.includes(option.value));
    };

    return (
        <>
            {printPreview && <PrescriptionPrintPreview setPrintPreview={setPrintPreview} patient={patient} medicines={rows} nextReview={nextReview} appointment={appointment} file={file} />}
            {confirmBox &&
                <div id="delete" tabIndex="-1" aria-hidden="true" className="fixed z-[9999] lg:left-[40%] lg:w-80 top-[10rem] lg:top-[3rem] lg:translate-x-[50%] translate-y-[50%]">

                    <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                        <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                            <svg className="text-red-600 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
                            <p className="mb-4 text-gray-500 dark:text-gray-300">Are you sure you want to delete this file?</p>
                            <div className="flex justify-center items-center space-x-4">
                                <button id="btnCancelCfmdDeleteFile" onClick={() => setConfirmBox(false)} disabled={loadingDeleteImage} type="button" className={`py-2 px-3 text-sm font-medium rounded-lg border border-gray-200  focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10 ${previewTheme.cancelBtnColor} ${previewTheme.cancelBtnBgColor} hover:${previewTheme.cancelBtnHoverColor}`}>
                                    No, cancel
                                </button>
                                <button id="btnYesCfmdDeleteFile" type="button" onClick={deletePrescriptionFile} disabled={loadingDeleteImage} 
                                className={`py-2 px-3 text-sm font-medium rounded-lg text-center disabled:opacity-50 disabled:cursor-not-allowed ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} hover:${previewTheme.saveBtnHoverColor}`}>
                                    Yes, I'm sure {loadingDeleteImage &&
                                        <svg aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                        </svg>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {confirmBoxDeleteAll &&
                <div id="delete" tabIndex="-1" aria-hidden="true" className="fixed z-[9999] lg:left-[40%] lg:w-80 top-[10rem] lg:top-[3rem] lg:translate-x-[50%] translate-y-[50%]">
                    <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                        <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                            <svg className="text-red-600 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
                            <p className="mb-4 text-gray-500 dark:text-gray-300">Are you sure you want to delete all prescriptions?</p>
                            <div className="flex justify-center items-center space-x-4">
                                <button id="btnCancelCfmdDeleteAllPrescription" onClick={() => setConfirmBoxDeleteAll(false)} disabled={loadingDeleteImage} type="button" className={`py-2 px-3 text-sm font-medium ring-0 rounded-lg border border-gray-200 focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10 ${previewTheme.cancelBtnColor} ${previewTheme.cancelBtnBgColor} hover:${previewTheme.cancelBtnHoverColor}`}>
                                    No, cancel
                                </button>
                                <button id="btnYesCfmdDeleteAllPrescription" type="button" onClick={deleteEnteredPrescriptions} disabled={loadingDeleteImage} 
                                className={`py-2 px-3 text-sm font-medium text-center rounded-lg disabled:opacity-50 disabled:cursor-not-allowed ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} hover:${previewTheme.saveBtnHoverColor}`}>
                                    Yes, I'm sure {loadingDeleteImage &&
                                        <svg aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                        </svg>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {isMessagePopupOpen &&
                <div className="fixed lg:left-[40%] w-80 left-[10%] top-[30%] z-[50]">
                    <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                        <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
                            <div className="mb-4 text-gray-500 dark:text-gray-300">
                                <p>No Appointments scheduled.</p>
                                <p>Do you want to schedule an appointment?</p>
                            </div>
                            <div className="flex justify-center items-center space-x-4">
                                <button id="btnCancelScheduleAppointment" onClick={() => handleMessagePopupOpen(false)} className={`py-2 px-3 text-sm font-medium rounded-lg border border-gray-200 focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10 ${previewTheme.cancelBtnColor} ${previewTheme.cancelBtnBgColor} hover:${previewTheme.cancelBtnHoverColor}`}>
                                    No, cancel
                                </button>
                                <button id="btnYesScheduleAppointment" type="button" onClick={() => { handleMessagePopupOpen(false); openAppointmentPopup(); }} className={`py-2 px-3 text-sm font-medium text-center rounded-lg disabled:opacity-50 disabled:cursor-not-allowed ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} hover:${previewTheme.saveBtnHoverColor}`}>
                                    Yes, Proceed</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {page_src === "appointment" ?
                <div className='shadow-md rounded-lg'>
                    <>
                        {appointment_id > 0 ?
                            <div className={'pb-4 px-2 w-[90vw] lg:w-full'}>
                                {appointment.status !== "Completed" ?
                                    <>

                                        <table className='hidden lg:block table-auto w-[100vh] lg:w-full border rounded-md focus:outline-none'>
                                            <thead>
                                                <tr className='odd:bg-white even:bg-gray-100 border-b-2 border-gray-200'>
                                                    <th className='text-center py-2 text-sm font-semibold'>#</th>
                                                    <th className='text-center py-2 text-sm font-semibold'>Medicine*</th>
                                                    <th className='text-center py-2 text-sm font-semibold'>Form</th>
                                                    <th className='text-center py-2 text-sm font-semibold text-nowrap px-2'>Before Food</th>
                                                    <th className='text-center py-2 text-sm font-semibold'>Timing</th>
                                                    <th className='text-center py-2 text-sm font-semibold'>Duration(D)*</th>
                                                    <th className='text-center py-2 text-sm font-semibold'>Action</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rows.map((row, index) => (
                                                    <tr key={row.order_id} className='odd:bg-white even:bg-gray-100'>
                                                        <td style={{ width: '5%' }} className='text-center p-2'>{index + 1}</td>
                                                        <td style={{ width: '20%' }}>
                                                            <CreatableSelect
                                                                isClearable={rows[index].selectedOption}
                                                                id={"selectMedicine" + index}
                                                                className='h-10 rounded-md pl-1 w-full'
                                                                name="medicine"
                                                                styles={{
                                                                    input: (base) => ({
                                                                        ...base,
                                                                        'input:focus': {
                                                                            boxShadow: 'none',
                                                                        },
                                                                    }),
                                                                    control: (provided, state) => ({
                                                                        ...provided,
                                                                        height: '40px',
                                                                        minHeight: '15px',
                                                                        borderColor: state.isFocused ? 'blue' : '#6B7280',
                                                                        '&:hover': {
                                                                            borderColor: state.isFocused ? 'blue' : 'gray',
                                                                        },
                                                                    }),

                                                                    dropdownIndicator: (provided) => ({
                                                                        ...provided,
                                                                        padding: '4px',
                                                                    }),
                                                                }}
                                                                value={{ value: row.medicine, label: row.medicine }}
                                                                onChange={(selectedOption) => handleMedicineChange(row.order_id, selectedOption)}
                                                                options={getAvailableOptions(row.id)}
                                                                onCreateOption={(inputValue) => handleCreateChange(row.order_id, inputValue)}
                                                                getOptionLabel={(option) => option.label}
                                                                placeholder=""

                                                            />
                                                        </td>

                                                        <td style={{ width: '20%' }}>
                                                            <select name="medicine_type" className='h-10 ml-2 pl-2 rounded-md w-full text-left'
                                                                id={"selectMedicineType" + index}
                                                                value={row.medicine_type}
                                                                onChange={(event) => handleChange(row.order_id, event)}>
                                                                <option value="Tablet">Tablet</option>
                                                                <option value="Syrup">Syrup</option>
                                                                <option value="Capsule">Capsule</option>
                                                                <option value="Ointment">Ointment</option>
                                                            </select>
                                                        </td>

                                                        <td className='w-[10%]'>
                                                            <input
                                                                id={"chkWhenMedicine" + index}
                                                                type="checkbox"
                                                                className="mx-auto flex justify-center items-center"
                                                                name="when_medidcine"
                                                                checked={row.when_medidcine}
                                                                onChange={(event) => handleChange(row.order_id, event)}
                                                            />
                                                        </td>

                                                        <td className='w-[25%]'>
                                                            <div className='flex justify-center'>
                                                                <button id={"btnTimingMorning" + index} onClick={() => handleTimingChange(row.order_id, "morning")} className={row.morning === "" ? "px-2 rounded-full bg-gray-300 text-gray-700 py-1" : "px-2 rounded-full bg-red-700 text-white py-1"}>Morning</button>
                                                                <button id={"btnTimingNoon" + index} onClick={() => handleTimingChange(row.order_id, "noon")} className={row.noon === "" ? "px-2 rounded-full bg-gray-300 text-gray-700 py-1 mx-3" : "p-2 rounded-full bg-red-700 text-white py-1 mx-3"}>Noon</button>
                                                                <button id={"btnTimingNight" + index} onClick={() => handleTimingChange(row.order_id, "night")} className={row.night === "" ? "px-2 rounded-full bg-gray-300 text-gray-700 py-1" : "px-2 rounded-full bg-red-700 text-white py-1"}>Night</button>
                                                            </div>
                                                        </td>
                                                        <td className='w-[10%]'>
                                                            <input type='text'
                                                                id={"textDuration" + index}
                                                                className='h-10 rounded-md w-full text-center'
                                                                name="duration"
                                                                value={row.duration}
                                                                onChange={(event) => handleChange(row.order_id, event)} />
                                                        </td>
                                                        <td className='text-center'>
                                                            <button id={"btnMedicineDeleteRow" + index} disabled={submitDisable} onClick={() => deleteRow(row.order_id)} title='Delete Row'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-red-500 hover:text-red-700">
                                                                    <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z" clipRule="evenodd" />
                                                                </svg>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                                <tr className='border-gray-200 border-t-[1px]'>
                                                    <td colSpan={4} className='w-full text-left p-2'>
                                                        {rows.filter(a => a.medicine !== "").length > 0 &&
                                                            <div className="hidden lg:w-full w-full lg:text-right text-left gap-2 lg:inline-flex justify-start mt-4">
                                                                {/* <p className='mt-2 font-medium text-sm'>Next Review On</p>
                                                                <DatePicker
                                                                    id={"txtNextreviewDate"}
                                                                    placeholderText='Next Review Date'
                                                                    selected={nextReview}
                                                                    onKeyDown={(e) => e.preventDefault()}
                                                                    onChange={(date) => { setNextReview(date) }}
                                                                    minDate={new Date()}
                                                                    maxDate={addMonths(new Date(), 3)}
                                                                    className='h-8 rounded-md w-48 border border-gray-300'
                                                                    dateFormat="dd-MM-yyyy"
                                                                /> */}
                                                            </div>
                                                        }
                                                    </td>
                                                    <td colSpan={3} className='w-full text-right p-2'>
                                                        {rows.length > 0 &&
                                                            <button id={"btnDeleteAllMedicineRow"} title='Delete All' disabled={submitDisable} onClick={() => setConfirmBoxDeleteAll(true)}>
                                                                <span className='flex justify-end text-red-600 font-semibold hover:underline pr-2'>Delete All
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-red-500 hover:text-red-700">
                                                                        <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z" clipRule="evenodd" />
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        }
                                                        <button id={"btnAddMedicineRow"} title='Add Row' disabled={submitDisable} onClick={addRow}>
                                                            <span className='flex justify-end text-blue-600 font-semibold hover:underline'>Add row <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-blue-600">
                                                                <path fillRule="evenodd" d="M5.625 1.5H9a3.75 3.75 0 0 1 3.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 0 1 3.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 0 1-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875ZM12.75 12a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V18a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V12Z" clipRule="evenodd" />
                                                                <path d="M14.25 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 16.5 7.5h-1.875a.375.375 0 0 1-.375-.375V5.25Z" />
                                                            </svg></span>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2 mt-2">

                                            {fileList.map((file, index) => {
                                                const fileExtension = file.file_path.split('.').pop().toLowerCase();
                                                const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(fileExtension);
                                                const isAudio = ['mp3', 'wav', 'ogg', 'm4a'].includes(fileExtension);
                                                const isVideo = ['mp4', 'webm', 'ogg', 'mov'].includes(fileExtension);

                                                return (
                                                    <div key={index} className="relative p-2 border rounded-sm h-[220px]">
                                                        <a rel="noopener" target="_blank" href={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} className="block h-full">
                                                            <div className="flex justify-center items-center h-full">
                                                                {isImage ? (
                                                                    <img
                                                                        id={"imgPrescriptionFile" + index}
                                                                        className='max-h-[180px] max-w-full w-auto h-auto object-contain'
                                                                        src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path}
                                                                        alt={file.file_name || "Image"}
                                                                    />
                                                                ) : isAudio ? (
                                                                    <audio id={"audioPrescriptionFile" + index} className="w-full max-w-[400px]" controls>
                                                                        <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`audio/${fileExtension}`} />
                                                                    </audio>
                                                                ) : isVideo ? (
                                                                    <video id={"videoPrescriptionFile" + index} className="max-h-[180px] max-w-full w-auto h-auto object-contain" controls>
                                                                        <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`video/${fileExtension}`} />
                                                                    </video>
                                                                ) : (
                                                                    <FileIcon
                                                                        id={"filePrescriptionFile" + index}
                                                                        ext={"." + fileExtension}
                                                                        className='max-h-[180px] w-auto h-auto object-contain'
                                                                    />
                                                                )}
                                                            </div>
                                                        </a>
                                                        <button
                                                            id={"btnDeletePrescriptionFIle" + index}
                                                            className="absolute top-2 left-2 bg-gray-100 rounded-full p-1 shadow"
                                                            onClick={() => { setConfirmBox(true); setDeleteFile([file]); }}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="size-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {rows.filter(a => a.medicine !== "").length > 0 &&
                                            <div className="hidden lg:w-full w-full lg:text-right text-left gap-2 lg:inline-flex justify-end mt-4">
                                                <button
                                                    id="btnSaveAndPrintPrescription"
                                                    disabled={submitDisable}
                                                    onClick={() => { savePrescription('save-print') }}
                                                    className="items-center px-2 py-0 h-8 border bg-blue-600 hover:bg-blue-700 text-white rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
                                                >
                                                    Print
                                                    {loadingSavePrint &&
                                                        <svg aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                        </svg>
                                                    }
                                                </button>
                                            </div>}
                                    </>
                                    :
                                    <p className='text-green-500 text-center font-semibold'>Appointment already Signed off!</p>
                                }
                            </div> :
                            <p className='text-center text-red-600 p-5'>Prescription not available!</p>
                        }

                    </>

                </div> :
                <>
                    {allPrescriptions.length > 0 ?
                        <div className="w-full rounded-lg bg-gray-50">
                            {
                                allPrescriptions.map((data, index) => (
                                    <div className={'my-4 border border-gray-200' + (index > 2 && !viewMore ? " hidden" : "")} key={'all-prescriptions-' + index}>
                                        <div className='bg-red-300 h-6'>
                                            <p className='text-center font-semibold'><span className='px-1'>{DateFunction.GetDate(data.date)}</span></p>
                                        </div>
                                        {data?.prescriptions?.length > 0 &&
                                            <div className='p-2'>
                                                <table className='table-auto w-full border rounded-md focus:outline-none'>
                                                    <thead>
                                                        <tr className='odd:bg-white even:bg-gray-100 border-b-2 border-gray-200'>
                                                            <th className='text-center py-2 text-sm font-semibold'>#</th>
                                                            <th className='text-left py-2 text-sm font-semibold'>Medicine*</th>
                                                            <th className='text-left py-2 text-sm font-semibold'>Form</th>
                                                            <th className='text-center py-2 text-sm font-semibold text-nowrap'>Before Food</th>
                                                            <th className='text-center py-2 text-sm font-semibold'>Morning</th>
                                                            <th className='text-center py-2 text-sm font-semibold'>Noon</th>
                                                            <th className='text-center py-2 text-sm font-semibold'>Night</th>
                                                            <th className='text-center py-2 text-sm font-semibold'>Duration(D)*</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data?.prescriptions?.map((row, index) => (
                                                            <tr key={row.order_id} className='odd:bg-white even:bg-gray-100'>
                                                                <td style={{ width: '5%' }} className='text-center p-2'>{index + 1}</td>
                                                                <td style={{ width: '30%' }}>
                                                                    {row.medicine}
                                                                </td>

                                                                <td style={{ width: '20%' }}>
                                                                    {row.medicine_type}
                                                                </td>

                                                                <td className='w-[10%] text-center'>
                                                                    {row.when_medidcine ? "Yes" : "No"}
                                                                </td>
                                                                <td className='w-[8%] text-center'>
                                                                    {row.morning}
                                                                </td>
                                                                <td className='w-[8%] text-center'>
                                                                    {row.noon}
                                                                </td>
                                                                <td className='w-[8%] text-center'>
                                                                    {row.night}
                                                                </td>
                                                                <td className='w-[10%] text-center'>
                                                                    {row.duration}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>}
                                        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-2 p-2">
                                            {
                                                data?.files?.map((file, index) => {
                                                    const fileExtension = file.file_path.split('.').pop().toLowerCase();
                                                    const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(fileExtension);
                                                    const isAudio = ['mp3', 'wav', 'ogg', 'm4a'].includes(fileExtension);
                                                    const isVideo = ['mp4', 'webm', 'ogg', 'mov'].includes(fileExtension);

                                                    return (
                                                        <div className="relative border rounded-sm" key={'image-pres-' + index}>
                                                            <a rel="noopener" target="_blank" href={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} className=''>
                                                                {isImage ? (
                                                                    <img
                                                                        className='max-h-[180px] w-auto h-auto object-contain p-5'
                                                                        src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path}
                                                                        alt="Prescription"
                                                                    />
                                                                ) : isAudio ? (
                                                                    <div className="flex justify-center items-center h-full">  <audio className="w-full max-w-[400px]" controls>
                                                                        <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`audio/${fileExtension}`} />
                                                                        Your browser does not support the audio element.
                                                                    </audio></div>
                                                                ) : isVideo ? (
                                                                    <video className="max-h-[180px] w-auto h-auto object-contain p-5" controls>
                                                                        <source src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} type={`video/${fileExtension}`} />
                                                                        Your browser does not support the video element.
                                                                    </video>
                                                                ) : (
                                                                    <div className="flex justify-center items-center h-full">
                                                                        <FileIcon
                                                                            ext={"." + fileExtension}
                                                                            className='max-h-[180px] w-auto h-auto object-contain p-5  '
                                                                        />
                                                                    </div>
                                                                )}
                                                            </a>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                            <div className='flex justify-end'>
                                {allPrescriptions.length > 3 &&
                                    <button id="btnViewMorePrescription" className='text-red-600 p-2' onClick={() => { setViewMore(vm => !vm) }}>{viewMore ? "View Less" : "View More"}</button>
                                }
                            </div>
                        </div> :
                        <>
                            {dataLoading &&
                                <p className='text-center p-5'>
                                    <svg id="imgDataLoading" aria-hidden="true" role="status" className="size-6 ml-2 text-red-600 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                    </svg>
                                </p>
                            }
                        </>
                    }
                </>
            }
        </>
    )
}

export default NewPrescriptions