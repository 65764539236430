import CryptoJS from 'crypto-js';

const DataDecode = {
    encryptPayload: (payload) => {
        const keyHex = CryptoJS.enc.Hex.parse(process.env.REACT_APP_ENCRYPTED_HEX_SALT); // Convert key to WordArray
        const iv = CryptoJS.lib.WordArray.random(16); // Generate a random IV
        const encryptedData = CryptoJS.AES.encrypt(
            JSON.stringify(payload),
            keyHex,
            {
                iv: iv, // Use the generated IV
                mode: CryptoJS.mode.CBC, // Use CBC mode
                padding: CryptoJS.pad.Pkcs7, // Use PKCS#7 padding
            }
        );

        const encrypted = {
            iv: CryptoJS.enc.Base64.stringify(iv), // Convert IV to Base64 for transmission
            payload: encryptedData.toString(), // Convert ciphertext to string for transmission
        };

        return JSON.stringify(encrypted);
    },
    decryptResponse: (response) => {
        const key = CryptoJS.enc.Hex.parse(process.env.REACT_APP_ENCRYPTED_HEX_SALT);
        const iv = CryptoJS.enc.Hex.parse(response.iv);
        const encryptedBytes = CryptoJS.enc.Base64.parse(response.response);
        const decryptedBytes = CryptoJS.AES.decrypt(
            { ciphertext: encryptedBytes },
            key,
            { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
        );
        return decryptedBytes.toString(CryptoJS.enc.Utf8);
    }
}

export default DataDecode;