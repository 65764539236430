import { useState, useEffect } from 'react';

const useMicrophonePermission = () => {
    const [permissionStatus, setPermissionStatus] = useState(null);

    const requestPermission = async () => {
        try {
            await navigator.mediaDevices.getUserMedia({ audio: true });
            setPermissionStatus('granted');
        } catch (error) {
            setPermissionStatus('denied');
            console.error('Error requesting microphone permission:', error);
        }
    };

    useEffect(() => {
        const checkPermission = async () => {
            try {
                const result = await navigator.permissions.query({ name: 'microphone' });
                setPermissionStatus(result.state);
                if(result.state !== "granted"){
                    requestPermission();
                }
                result.onchange = () => {
                    setPermissionStatus(result.state);
                };
            } catch (error) {
                console.error('Error checking microphone permission:', error);
            }
        };

        checkPermission();
    }, []);

    return permissionStatus;
};

export default useMicrophonePermission;
