import { createSlice } from "@reduxjs/toolkit";

const gridviewSlice = createSlice({
  name: "appgridview",
  initialState: {
    appGridViewSelection: "week",
    appGridViewAppointmentData: {},
    appShowTodaySection: true,
    isDefaultGridView: true,
  },
  reducers: {
    setAppGridViewSelection: (state, action) => {
      state.appGridViewSelection = action.payload;
    },
    setAppGridViewAppointmentData: (state, action) => {
      state.appGridViewAppointmentData = action.payload;
    },
    setAppShowTodaySection: (state, action) => {
      state.appShowTodaySection = action.payload;
    },
    setIsDefaultGridView: (state, action) => {
      state.isDefaultGridView = action.payload;
    },
  },
});

export const {
  setAppGridViewSelection,
  setAppGridViewAppointmentData,
  setAppShowTodaySection,
  setIsDefaultGridView
} = gridviewSlice.actions;
export default gridviewSlice.reducer;
