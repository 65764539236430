import { createSlice } from '@reduxjs/toolkit';

const profileDrawerSlice = createSlice({
  name: 'drawer',
  initialState: {
    isProfileDrawerOpen: false,
  },
  reducers: {
    setIsProfileDrawerOpen: (state,action) => {
      state.isProfileDrawerOpen = action.payload;
    },
    
  },
});

export const {setIsProfileDrawerOpen} = profileDrawerSlice.actions;
export default profileDrawerSlice.reducer;