import React, { useEffect, useRef, useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { theme } from '../../EmraxisTheme/Theme';
import DateFunction from '../../utils/DateFunctions';
import { useLocation } from 'react-router-dom';
import { setNotification } from '../../Redux/features/toast/toastSlice';
import { useDispatch, useSelector } from 'react-redux';
import DataDecode from '../../utils/DataDecode';
import store from '../../API/store';
import PhoneInput from 'react-phone-input-2';
import ToastMessage from '../../components/ToastMessage';
import { data } from 'autoprefixer';

const IntakeForm = () => {
  const previewTheme = theme.filter((a) => a.name === "defaultTheme")[0];
  const notification = useSelector((state) => state.toast.notification);
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParamValue = queryParams.get("link_token");
  let link_token, phone_number, email, clinic_guid;
  try {
    ({ link_token, phone_number, email, clinic_guid } = JSON.parse(atob(queryParamValue)));
  } catch (er) {
    link_token = phone_number = email = clinic_guid = null;
  }

  const [disableMobile, setDisableMobile] = useState(false);
  const [disableEmail, setDisableEmail] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [file, setFile] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [sharedData, setSharedData] = useState({});
  const [loading, setLoading] = useState(true);
  const [loading2ndData, setLoading2ndData] = useState(true);
  const [alert, setAlert] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [clinicGuid, setClinicGuid] = useState("");
  const [errors, setErrors] = useState({});
  const [submitDisable, setSubmitDisable] = useState(false);
  const [newPatient, setNewPatient] = useState({
    patient_id: 0,
    FirstName: '',
    MiddleName: "",
    LastName: "",
    email_id: '',
    gender: '',
    dob: '',
    blood_group: '',
    mobile_number: '',
    status: 'New',
    address: '',
    patient_pic: '',
    clinic_guid: ""
  });

  const [facilityData, setFacilityData] = useState({
    facility_guid: "",
    facility_name: "",
    facility_address: "",
    facility_logo: "",
    facility_email: "",
    facility_phone: ""
  });

  const textareaRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    }, 100)
  }, [newPatient.address])

  const resetData = () => {
    setFacilityData({
      facility_guid: "",
      facility_name: "",
      facility_address: "",
      facility_logo: "",
      facility_email: "",
      facility_phone: ""
    })
  }

  const GetFacilityData = () => {
    store.GetFacilityinfo("", DataDecode.encryptPayload(clinic_guid))
      .then((result) => {
        setLoading(false);
        if (result.status == 201) {
          result.json().then((res) => {
            let response = JSON.parse(DataDecode.decryptResponse(res));
            setFacilityData({
              facility_guid: response.clinic_guid,
              facility_name: response.facility_name,
              facility_address: response.facility_address,
              facility_logo: response.facility_logo,
              facility_email: response.email_id,
              facility_phone: response.phonenumber
            });
            setClinicGuid(data.clinic_guid);
          })
        } else {
          resetData();
        }
      })
      .catch(err => {
        setLoading(false);
        setClinicGuid("");
        resetData();
        dispatch(setNotification({ message: "Error Occurred!", status: "error", action: true, }));
      })
  }

  const GetPatientIntakeLinkStatus = () => {
    store.GetPatientIntakeLinkStatus(DataDecode.encryptPayload({ 'link_token': link_token }))
      .then((result) => {
        setLoading2ndData(false);
        if (result.status == 200) {
          result.json().then((res) => {
            let response = JSON.parse(DataDecode.decryptResponse(res));
            setSharedData(response);
            if (response.expiry_status) {
              setAlert(true)
              setErrorMessage("The link has expired! Please contact the front desk!")
            } else {
              setDisableMobile(response.phone_number ? true : false);
              setDisableEmail(response.email ? true : false);
              setNewPatient({ ...newPatient, clinic_guid: response.clinic_guid, email_id: response.email, mobile_number: response.phone_number });
              setClinicGuid(data.clinic_guid);
              setAlert(false);
            }
          })
        }
        else {
          setAlert(true);
          setErrorMessage("Error Occurred! Please contact the front desk!")
          resetData();
          setClinicGuid("");
        }
      })
      .catch(err => {
        setLoading2ndData(false);
        setAlert(true);
        dispatch(setNotification({ message: "Error Occurred!", status: "error", action: true, }));
      })

  }

  useEffect(() => {
    if (clinic_guid)
      GetFacilityData();
  }, [clinic_guid])

  useEffect(() => {
    if (link_token)
      GetPatientIntakeLinkStatus();
  }, [link_token])

  useEffect(() => {
    setNewPatient({ ...newPatient, dob: DateFunction.SetDbDateOfBirth(dateOfBirth) })
  }, [dateOfBirth])

  function chooseImage() {
    document.getElementById('patifuploadimage').click();
  }

  const handleProfilePicChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setNewPatient(prevPatient => ({
        ...prevPatient,
        patient_pic: selectedFile.name
      }));
    }
  };

  const handleChange = (e) => {
    setNewPatient(prevPatient => ({
      ...prevPatient,
      [e.target.name]: e.target.value
    }));
  };

  const validateFields = () => {
    let valid = true;
    const errors = { FirstName: '', MiddleName: '', LastName: '', mobile_number: '', email_id: '', gender: '' };
    if (!newPatient.FirstName) {
      errors.FirstName = 'First Name is required';
      valid = false;
    }

    else if (/^[a-zA-Z\s'-]+$/.test(newPatient.FirstName) == false) {
      errors.FirstName = 'First Name must contain only letters ';
      valid = false;
    }

    if (!newPatient.LastName) {
      errors.LastName = 'Last Name is required';
      valid = false;
    }

    else if (/^[a-zA-Z\s'-]+$/.test(newPatient.LastName) == false) {
      errors.LastName = 'Last Name must contain only letters ';
      valid = false;
    }

    if (/^[a-zA-Z\s'-]+$/.test(newPatient.MiddleName) == false && newPatient.MiddleName.length > 0) {
      errors.MiddleName = 'Middle Name must contain only letters';
      valid = false;
    }

    if (newPatient.mobile_number.trim() === "" || newPatient.mobile_number.length < 10) {
      errors.mobile_number = 'Mobile number is required';
      valid = false;
    }

    if (newPatient.email_id && !/\S+@\S+\.\S+/.test(newPatient.email_id)) {
      errors.email_id = 'Email is invalid';
      valid = false;
    }

    if (!newPatient.gender) {
      errors.gender = 'Gender is required';
      valid = false;
    }
    setErrors(errors);
    return valid;
  }

  const createPatient = () => {
    const error = validateFields();
    if (!error) {
      dispatch(setNotification({ message: 'Please fill the mandatory fields!', status: 'info', action: true }));
      return;
    }

    setSubmitDisable(true);
    const formData = new FormData();
    if (file) {
      formData.append("files", file);
    }
    newPatient.mobile_number = newPatient.mobile_number;
    const encData = JSON.parse(DataDecode.encryptPayload({ patients: newPatient, sharedLinkData: sharedData }));
    formData.append('iv', encData.iv);
    formData.append('payload', encData.payload);

    store.SaveSharedPatient(formData)
      .then((result) => {
        setSubmitDisable(false);
        if (result.status === 201) {
          dispatch(setNotification({ message: 'Details registered. Please meet the front desk.', status: 'success', action: true }));
          setSubmitted(true);
        } 
        else if(result.status === 410){
          dispatch(setNotification({ message: 'The link has expired! Please meet the front desk!', status: 'error', action: true }));
        }
        else {
          dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
        }
      })
      .catch((error) => {
        setSubmitDisable(false);
        dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
      });
  };

  return (
    <>
      {!link_token &&
        <div id="divIFormAlertError" className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
          <svg id="svgIFormAlertError" className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
          </svg>
          <span className="sr-only">Info</span>
          <div>
            <span id="spanIFormAlertError" className="font-medium">Error Occurred!</span> Please meet the front desk!
          </div>
        </div>}

      {submitted &&
        <div id="divIFormAlertSuccessRegister" className="flex items-center p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
          <svg id="svgIFormAlertSuccessRegister" className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
          </svg>
          <span className="sr-only">Info</span>
          <div>
            <span id="spanIFormAlertSuccessRegister" className="font-medium">Details registered!</span> Please meet the front desk!
          </div>
        </div>}

      {link_token && !submitted && <>
        {!loading && !loading2ndData && alert ?
          <div id="divIFormAlertErrorMessage" className="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 dark:text-red-400 dark:bg-gray-800 dark:border-red-800" role="alert">
            <svg id="svgIFormAlertErrorMessage"  className="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <div id="spanIFormAlertErrorMessage"  className="ms-3 text-lg font-medium">
              {errorMessage}
            </div>
          </div> :
          <>
            {(loading || loading2ndData) ?
              <svg
                id="svgLoadingiCON" aria-hidden="true" role="status" className="inline size-6 m-4 text-red-500 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
              </svg>
              :
              <div id="divMainIntakeForm" className="flex items-center justify-center bg-gray-100">
                <div className="w-full max-w-[40rem] bg-white p-4 rounded-lg shadow-lg my-10">
                  <div className='text-center'>
                    <h2 id="h2IntakeFormHeaderFacilityName" className='font-semibold text-lg'>{facilityData.facility_name}</h2>
                    {facilityData.facility_address && <h2 className='font-semibold text-md'>{facilityData.facility_address}</h2>}
                    {facilityData.facility_phone && <h2 className='font-semibold text-md'>Ph: {facilityData.facility_phone}</h2>}
                  </div>
                  <h2 id="h2IntakeFormHeader" className={`${previewTheme.headingTitleColor} font-semibold text-lg py-2 my-2`}>Patient Intake Form</h2>
                  <div className="grid grid-cols-2 gap-2">
                    {/* First Name */}
                    <div>
                      <label id="lblpatifFirstName" className="block text-md mb-2" htmlFor="patifFirstName">
                        First Name <span className="text-red-500">*</span>
                      </label>
                      <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400" id="patifFirstName" type="text" name="FirstName" value={newPatient.FirstName} onChange={(event) => { handleChange(event) }} />
                      {errors.FirstName && <p id="AddPatientPerrorFirstName" className="text-red-500 text-sm">{errors.FirstName}</p>}
                    </div>
                    {/* Middle Name */}
                    <div>
                      <label id="lblpatiMiddleName" className="block text-md  mb-2" htmlFor="patiMiddleName">
                        Middle Name
                      </label>
                      <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400" id="patiMiddleName" name="MiddleName" value={newPatient.MiddleName} onChange={(event) => { handleChange(event) }} type="text" />
                      {errors.MiddleName && <p id="AddPatientPerrorMiddleName" className="text-red-500 text-sm">{errors.MiddleName}</p>}
                    </div>
                    {/* Last Name */}
                    <div>
                      <label id="lblpatifLastName" className="block text-md mb-2" htmlFor="patifLastName">
                        Last Name <span className="text-red-500">*</span>
                      </label>
                      <input className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400" id="patifLastName" type="text" name="LastName" value={newPatient.LastName} onChange={(event) => { handleChange(event) }} />
                      {errors.LastName && <p id="AddPatientPerrorLastName" className="text-red-500 text-sm">{errors.LastName}</p>}
                    </div>
                    {/* Mobile Number */}
                    <div>
                      <label id="lblPatIfmobileNumber" className="block text-md mb-2" htmlFor="patIfmobileNumber">
                        Mobile Number <span className="text-red-500">*</span>
                      </label>
                      <PhoneInput id="patIfmobileNumber"
                        country={'in'}
                        onlyCountries={['in']}
                        value={newPatient.mobile_number}
                        countryCodeEditable={false}
                        disableDropdown={true}
                        enableSearch={false}
                        onChange={(value) => { handleChange({ target: { name: 'mobile_number', value } }) }}
                        inputProps={{
                          disabled: disableMobile,
                          name: 'mobile_number',
                          id: 'mobile',
                          className: "block w-full border rounded-md focus:outline-none focus:border-gray-400 pl-14"
                        }}
                        specialLabel=""
                      />
                      {errors.mobile_number && <p id="AddPatientPerrorMobNumber" className="text-red-500 text-sm">{errors.mobile_number}</p>}
                    </div>
                    {/* Email */}
                    <div>
                      <label id="lblpatIfEmail" className="block text-md mb-2" htmlFor="patIfEmail">
                        Email
                      </label>
                      <input disabled={disableEmail} className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400 disabled:cursor-not-allowed" id="patIfEmail" type="email" name="email_id" onChange={(event) => { handleChange(event) }} value={newPatient.email_id} />
                      {errors.email_id && <p id="AddPatientPerrorEmail" className="text-red-500 text-sm">{errors.email_id}</p>}
                    </div>
                    {/* Gender */}
                    <div>
                      <label id="lblpatiGender" className="block text-md mb-2" htmlFor="patiGender">
                        Gender <span className="text-red-500">*</span>
                      </label>
                      <select className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400" id="patiGender" name="gender" value={newPatient.gender} onChange={handleChange}>
                        <option value="">Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Others">Others</option>
                      </select>
                      {errors.gender && <p id="AddPatientPerrorGender" className="text-red-500 text-sm">{errors.gender}</p>}
                    </div>
                    {/* Date of Birth */}
                    <div>
                      <label id="lblpatIfdob" className="block text-md mb-2" htmlFor="patIfdob">
                        Date of Birth
                      </label>
                      <DatePicker id='patIfdob'
                        selected={dateOfBirth}
                        onKeyDown={(e) => e.preventDefault()}
                        onChange={(date) => { setDateOfBirth(date) }}
                        maxDate={new Date()}
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={100}
                        showMonthDropdown
                        scrollableMonthYearDropdown
                        className='block w-full border rounded-md focus:outline-none focus:border-gray-500 pr-2'
                        dateFormat="dd-MM-yyyy"
                        calendarClassName="patient-datepicker"
                      />
                    </div>
                    {/* Blood Group */}
                    <div>
                      <label id="lblPatIfbloodGroup" className="block text-md mb-2" htmlFor="patIfbloodGroup">
                        Blood Group
                      </label>
                      <select className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400" id="patIfbloodGroup" name="blood_group" value={newPatient.blood_group} onChange={(event) => { handleChange(event) }} >
                        <option value="">Select</option>
                        <option value="A+">A+</option>
                        <option value="A-">A-</option>
                        <option value="B+">B+</option>
                        <option value="B-">B-</option>
                        <option value="AB+">AB+</option>
                        <option value="AB-">AB-</option>
                        <option value="O+">O+</option>
                        <option value="O-">O-</option>
                      </select>
                    </div>
                    {/* Address */}
                    <div className='col-span-2'>
                      <label id="lblpatIfAddress" className="block text-md mb-2" htmlFor="patIfAddress">
                        Address
                      </label>
                      <textarea className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400" id="patIfAddress" ref={textareaRef} style={{ height: "auto", minHeight: "40px", resize: "none" }} placeholder="Enter Address" onChange={(event) => { handleChange(event) }} name="address" value={newPatient.address}></textarea>
                    </div>
                  </div>
                  <div className="flex mt-5 lg:gap-[4.75rem]">
                    <div className="w-[100%]">
                      <div id="divPatientImgfileUrl" className="focus:border-gray-500 cursor-pointer w-[100%] border rounded-md focus:outline-none p-2 pr-2 flex">
                        {file ?
                          <img
                            id="AddPatientImgfileUrl"
                            src={URL.createObjectURL(file)}
                            alt={file.name}
                            className="object-cover w-24 h-24"
                          /> :
                          <svg id="AddPatientSvgplaceholder" xmlns="http://www.w3.org/2000/svg" className="w-24 h-24 bg-gray-300 rounded-sm p-4" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"                  >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75L7.409 10.591a2.25 2.25 0 013.182 0l5.159 5.159M15.75 12.75l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909M3 21h16.5a1.5 1.5 0 001.5-1.5V6A1.5 1.5 0 0019.5 4.5H3.75A1.5 1.5 0 002.25 6v12A1.5 1.5 0 003 21z" />
                          </svg>}
                        <div>
                          <label id="AddPatientLabeluploadText" htmlFor="AddPatientInputuploadimage" className="block text-gray-900 mb-2 italic text-base ml-4">
                            Choose Profile Photo
                          </label>
                          <div className="relative">
                            <button id="AddPatientBtnchoseImage" type="button" onClick={chooseImage} className={`py-1 px-2 ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} transition rounded-md ml-4 hover:${previewTheme.saveBtnHoverColor}`}                    >
                              Choose Image
                            </button>
                            <input type="file" id="patifuploadimage" name="patient_pic" onChange={handleProfilePicChange} className="hidden" accept="image/*" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex justify-end space-x-2">
                    <button id="patifSubmitBtn" onClick={createPatient} disabled={submitDisable} className={`px-4 py-2 rounded-md ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} transition rounded-md ml-4 hover:${previewTheme.saveBtnHoverColor} disabled:opacity-50 disabled:cursor-not-allowed`} type="button">
                      Submit
                      {submitDisable && (
                        <svg id="AddPatientSvgsubmitIcon" aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                        </svg>
                      )}
                    </button>
                  </div>
                </div>
              </div>}
          </>}

      </>}
      {notification.action && (
        <ToastMessage
          message={notification.message}
          type={notification.status}
        />
      )}
    </>
  )
}

export default IntakeForm