import React, { useState, useEffect } from 'react';

function TimeLineIndicator() {
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        const calculatePercentage = () => {
            const now = new Date();
            const minutes = now.getMinutes();
            let percent = 0;
            if (minutes <= 30) {
                // First half-hour
                percent = (minutes / 30) * 100;
            } else {
                // Second half-hour
                percent = ((minutes - 30) / 30) * 100;
            }

            setPercentage(Math.round(percent)); // Round the result for display
        };

        // Calculate percentage when the component mounts
        calculatePercentage();

        // Update the percentage every minute
        const interval = setInterval(calculatePercentage, 10000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="absolute inset-0 z-10" id="lineInidicator">
            <div
                className="absolute left-0 right-0 h-0.5 bg-red-500 z-10"
                style={{ top: `${percentage}%` }}
            ></div>
        </div>
    );
}

export default TimeLineIndicator;
