import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './rootReducer'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';


// add the reducers that need to be persisted, add comma separated values
//It allows only whitelisted reducers to store in local storage
const reducersToPersist = ['login','facility','theme','drawer','appGridView'];


const persistConfig = {
    key: 'root',
    storage,
    whitelist: reducersToPersist
  }

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  export const store = configureStore({
      reducer: persistedReducer
  })
  
  export const persistor = persistStore(store)