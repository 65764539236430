import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showAddPatient: false,
    showAddAppointment: false,
    showAddDoctor: false,
    refreshAppointment: false,
    showAppointmentInvoicePrint: false,
    setPatientAddAppointment: { isOpenAppointment: false, patient: {}, path: 'patient' },
    showAddMasterTariff: false,
    showUpdateInvoiceTerms: false,
    patientProfileAppointmentData:{}
}

export const popupSlice = createSlice({
    name: 'popup',
    initialState,
    reducers: {
        setAddPatientPopup: (state, action) => {
            state.showAddPatient = action.payload
        },
        setAddAppointmentPopup: (state, action) => {
            state.showAddAppointment = action.payload
        },
        setAddDoctorPopup: (state, action) => {
            state.showAddDoctor = action.payload
        },
        setPatientAppointment: (state, action) => {
            state.setPatientAddAppointment = action.payload
        },
        setAppointmentInvoicePrint: (state, action) => {
            state.showAppointmentInvoicePrint = action.payload
        },
        setRefreshAppointment: (state, action) => {
            state.refreshAppointment = action.payload
        },
        setAddMasterTariff: (state, action) => {
            state.showAddMasterTariff = action.payload
        },
        setUpdateInvoiceTerms: (state, action) => {
            state.showUpdateInvoiceTerms = action.payload
        },
        setPatientProfileAppointment:(state, action) => {
            state.patientProfileAppointmentData = action.payload
        }
    }
})

export const { setAddAppointmentPopup, setAddDoctorPopup,setPatientProfileAppointment, setAddPatientPopup, setPatientAppointment, setRefreshAppointment, setAppointmentInvoicePrint, setAddMasterTariff, setUpdateInvoiceTerms } = popupSlice.actions;

export default popupSlice.reducer;