import React from 'react';
import { addFeedback } from '../Redux/features/feedback/feedbackSlice';
import { useSelector, useDispatch } from 'react-redux';
import FooterImg from './Assets/homeImages/footerbgImage.jpg';
import MobileFooterImg from './Assets/homeImages/mobileFooterbgImage.jpg';
import DateFunction from '../utils/DateFunctions';

const Footer = () => {
	const dispatch = useDispatch();
	var loggeduser = useSelector(state => state.login.loginuserData);
	const handleFeedback = () => {

		dispatch(addFeedback(true));
	}
	const currentYear = new Date().getFullYear();
	return (
		<>
			{
				loggeduser != null && loggeduser.length != 0   ?
					<> 
						<footer id="FooterFooterfooterTab" className="shadow fixed bottom-0 w-full z-10">

							<div className="mx-auto">
								<div className="bg-black text-white flex justify-between items-center h-10">
									<div className="flex-shrink-0">
									</div>
									<div id="FooterDivcopyRights" className="text-center w-full">
										<p id="FooterPcopyRights" className="text-sm text-white">&copy; Copyrights {DateFunction.GetYear()} EMR AXIS, LLC All rights reserved.</p>
									</div>
								</div>
							</div>

						</footer></> :
					loggeduser.user_role != "SuperAdmin"&& <>
						<div className=" custom-bg bg-cover bg-center h-[45vh] min-[400px]:h-[37vh] min-[520px]:h-[38vh] md:h-[30vh]  lg:h-[55vh]  w-[100%] pt-8 px-4 block min-[900px]:hidden" style={{
							backgroundImage: `url(${MobileFooterImg})`,
						}}>
							<div className="w-[100%] ">
								<div id="FooterDivtotalPatient" className="text-[18px] md:text-[20px] text-white pt-2 font-inter">Total Patient Engagement and Medical Records management solution with automated voice based clinical notes</div>
								<div id="FooterDivtermsofService" className="text-[white] font-semibold text-[16px] pt-4 hover:cursor-pointer font-inter">
									<a id="FooterAtermsofService"
										className="!text-[white] !font-semibold"
										href="/terms-of-service"
										rel="Terms of Service"
									>
										Terms of Service
									</a>
								</div>
								<div id="FooterDivprivacyPolicy" className="text-[white] font-semibold text-[16px] pt-4 hover:cursor-pointer font-inter">
									<a  id="FooterAprivacyPolicy"
										className="!text-[white] !font-semibold"
										href="/privacy"
										rel="Privacy Policy"
									>
										Privacy Policy
									</a>
								</div>
								<div id="FooterDivcopyRights2" className="mb-4 mt-12 flex justify-center items-center text-[#a31b1b] text-[16px] font-semibold font-inter">
									&copy; Copyrights {DateFunction.GetYear()} EMR AXIS, LLC All rights reserved.

								</div>
							</div>
						</div>
						<div className="bg-center h-[45vh] w-[100%] hidden  min-[900px]:block" style={{
							backgroundImage: `url(${FooterImg})`,
						}}>

							<div className="px-[7.5rem] pt-[5.5rem] 4xl:pt-[7.5rem] 5xl:pt-[10.5rem]  6xl:pt-[13rem] pb-4 ">
								<div className="flex border-b-2 border-white w-[100%] pb-8">
									<div className="w-[50%]  border-r-2 border-[#003569] pb-4">
										<div id="FooterDivtotalPatientEngagement" className="block text-[20px] lg:text-[15px] 2xl:text-[20px] 5xl:text-[24px]  text-[#fff] font-normal font-inter">Total Patient Engagement and Medical Records management solution with automated voice based clinical notes.</div>
									</div>
									<div className="flex justify-end items-center w-[50%] mb-[32px] ml-16">
										<div className="text-[white] font-semibold text-[16px] 2xl:text-[20px] 4xl:text-[24px] min-[3000px]:text-[36px] hover:cursor-pointer px-4 border-r-2 border-white font-inter">
											<a id='AtermsofService2'
												className="!text-[white] !font-semibold"
												href="/terms-of-service"
												rel="Terms of Service"
											>
												Terms of Service
											</a>
										</div>
										<div className="text-[white] font-semibold text-[16px] 2xl:text-[20px] 4xl:text-[24px] min-[3000px]:text-[36px] hover:cursor-pointer px-4 font-inter">
											<a id="FooterAprivacyPolicy2"
												className="!text-[white] !font-semibold"
												href="/privacy"
												rel="Privacy Policy"
											>
												Privacy Policy
											</a>
										</div>

									</div>
								</div>
								<div id="FooterDivcopyRightsText" className="my-2 float-right text-red-800 text-[14px] 2xl:text-[20px] 4xl:text-[24px] min-[3000px]:text-[36px] font-inter">
									&copy; Copyrights {DateFunction.GetYear()} EMR AXIS, LLC All rights reserved.

								</div>
							</div>
						</div>
					</>

			}

		</>

	);
};

export default Footer;
