import React, { useEffect, useState } from 'react';
import store from '../../API/store';
import { useDispatch, useSelector } from 'react-redux';
import DataDecode from '../../utils/DataDecode';
import Select from "react-select";
import { setNotification } from '../../Redux/features/toast/toastSlice';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../overrides.css'
import DateFunction from '../../utils/DateFunctions';
import { theme } from '../../EmraxisTheme/Theme';
import SnackBarComponent from "../../components/SnackBarComponent/SnackBarComponent";
import {setRefreshDataAvailable} from '../../Redux/features/uploadDoc/uploadDocSlice';

const AddDoctor = ({ isOpen, onClose, setRefreshData, doctor,docPic,setDocPic,setDocFile, page }) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({
    FirstName: '',
    MiddleName: '',
    LastName: '',
    phone_number: '',
    email_id: '',
    department: '',
    joiningDate: ''
  });
  var loggeduser = useSelector(state => state.login.loginuserData);
  var loggedusertoken = useSelector(state => state.login.userBarerToken);
  const previewTheme = useSelector(state => state.theme.colors) || theme.defaultTheme;
  const [toast, setToast] = useState({ show: false, message: '', type: '' });
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [departments, setDepartments] = useState([]);
  var refreshDataAvailable = useSelector(state => state.document_upload.refreshDataAvailable);

  useEffect(() => {
    if (loggeduser) {
      GetDepartments(loggeduser.facilityID);
    }
  }, [loggeduser])

  const [newDoctor, setNewDoctor] = useState({
    doctor_id: 0,
    title: "",
    FirstName: '',
    MiddleName: '',
    LastName: '',
    department_id: 0,
    gender: '',
    specialization: '',
    experience: '',
    doctor_degree: '',
    phone_number: '',
    email_id: '',
    joiningDate: '',
    profile_pic: '',
    status: 'Added',
    consulting_fees: 0
  });
  const titles = [
    { value: 1, label: "Dr." },
    { value: 2, label: "Mr." },
    { value: 3, label: "Ms." },
    { value: 4, label: "Mrs." }
  ];

  const [submitDisable, setSubmitDisable] = useState(false);

  const showToast = () => {

    setTimeout(() => {
      setToast({ show: false, message: '', type: '' });
    }, 3000);
  };

  useEffect(() => {
    setTimeout(() => {
      setNewDoctor({
        doctor_id: (doctor && doctor?.doctor_id) ? doctor?.doctor_id : 0,
        title: (doctor && doctor?.title) ? doctor?.title : '',
        FirstName: (doctor && doctor?.FirstName) ? doctor?.FirstName : '',
        MiddleName: (doctor && doctor?.MiddleName) ? doctor?.MiddleName : '',
        LastName: (doctor && doctor?.LastName) ? doctor?.LastName : '',
        department_id: (doctor && doctor?.department_id) ? doctor?.department_id : 0,
        gender: (doctor && doctor?.gender) ? doctor?.gender : '',
        specialization: (doctor && doctor?.specialization) ? doctor?.specialization : '',
        experience: (doctor && doctor?.experience) ? doctor?.experience : '',
        doctor_degree: (doctor && doctor?.doctor_degree) ? doctor?.doctor_degree : '',
        phone_number: (doctor && doctor?.phone_number) ? doctor?.phone_number : '',
        email_id: (doctor && doctor?.email_id) ? doctor?.email_id : '',
        joiningDate: doctor?.joiningDate ? new Date(doctor.joiningDate) : '',
        profile_pic: (doctor && doctor?.profile_pic) ? doctor?.profile_pic : '',
        consulting_fees: (doctor && doctor?.consulting_fees) ? doctor?.consulting_fees : 0,
        status: 'Added'
      });
    }, 100);
  }, [doctor]);

  if (!isOpen) return null;
  function chooseImage() {
    document.getElementById('uploadimage').click();
  }

  function createDoctor() {
    if (!validateForm()) {
      dispatch(setNotification({ message: '* Fields are required!', status: 'error', action: true }));
    } else {
      setSubmitDisable(true);
      const formData = new FormData();

      if (file) {
        formData.append("files", file);
      }
      if (newDoctor.title === "") {
        newDoctor.title = "Dr.";
      }
      const encData = JSON.parse(DataDecode.encryptPayload(newDoctor));

      formData.append('iv', encData.iv);
      formData.append('payload', encData.payload);
      if (newDoctor.doctor_id == 0) {
        store.AddDoctor(loggedusertoken, formData)
          .then((result) => {
            setSubmitDisable(false);
            if (result.status == 201) {
              store.CreateActivityLogs(loggeduser, page, `Doctor Created - for ${newDoctor.title} ${newDoctor.FirstName} ${newDoctor.MiddleName?.charAt(0)} ${newDoctor.LastName}`, "Create");
              dispatch(setNotification({ message: 'Doctor Created Successfully!', status: 'success', action: true }));
              setRefreshData(r => !r);
              onClose();
            } else {
              dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
            }
          })
          .catch((err) => {
            setToast({ show: true, message: "Error occurred while saving doctor details", type: "error" });
            showToast();
            setLoading(false);
            onClose();
          });
      } else {
        store.UpdateDoctorDetails(loggedusertoken, formData)
          .then((result) => {
            setSubmitDisable(false);
            if (result.status == 204) {
              dispatch(setRefreshDataAvailable(!refreshDataAvailable))
              if(docPic!==undefined)setDocPic(false);
              store.CreateActivityLogs(loggeduser, page, `Doctor Updated - for ${newDoctor.title} ${newDoctor.FirstName} ${newDoctor.MiddleName?.charAt(0)} ${newDoctor.LastName}`, "Update");
              dispatch(setNotification({ message: 'Doctor Updated Successfully!', status: 'success', action: true }));
              setRefreshData(r => !r);
              onClose();
            } else {
              dispatch(setNotification({ message: 'No Changes to Update!', status: 'info', action: true }));
            }
          })
          .catch((err) => {
            setToast({ show: true, message: "Error occurred while saving doctor details", type: "error" });
            showToast();
            setLoading(false);
            onClose();
          });

      }
    }
  }
  const validateForm = () => {

    let valid = true;
    const newErrors = { FirstName: '',MiddleName:'', LastName: '', phone_number: '', email_id: '', department: "", title: "" };

    if (!newDoctor.FirstName) {
      newErrors.FirstName = 'First Name is required';
      valid = false;
    }
    if (!newDoctor.LastName) {
      newErrors.LastName = 'Last Name is required';
      valid = false;
    }
    if (!/^[a-zA-Z\s]+$/.test(newDoctor.FirstName)) {
      newErrors.FirstName = 'First Name must contain only letters ';
      valid = false;
    }
    if (!/^[a-zA-Z\s]+$/.test(newDoctor.LastName)) {
      newErrors.LastName = 'Last Name must contain only letters ';
      valid = false;
    }
    if (!/^[a-zA-Z\s]+$/.test(newDoctor.MiddleName)&& newDoctor.MiddleName.length > 0) {
      newErrors.MiddleName = 'Middle Name must contain only letters ';
      valid = false;
    }
    if (!newDoctor.email_id) {
      newErrors.email_id = 'Email is required';
      valid = false;
    }
    if (newDoctor.email_id && !/\S+@\S+\.\S+/.test(newDoctor.email_id)) {
      newErrors.email_id = 'Email is invalid';
      valid = false;
    }

    if (newDoctor.department_id == 0) {
      newErrors.department = 'Department is required';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };


  const GetDepartments = (guid) => {
    store.GetDepartmentForDoctor(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': guid }))
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            let data = JSON.parse(response)
            var depRes = data?.map(function (val) {
              // if(val.department_name=="Others")
              //   {
              //     setNewDoctor({
              //       ...newDoctor,
              //       department_id:val.department_id
              //     })
              //   }
              return {
                value: val.department_id,
                label: val.department_name

              }

            });

            setDepartments(depRes);
          })
        } else {
          setDepartments([])
        }
      })
      .catch(err => {
        dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
      })
  }

  const handleSelectTitles = (selectedOption) => {
    setNewDoctor({
      ...newDoctor, title: selectedOption ? selectedOption.label : "Dr.",
      clinic_guid: loggeduser.facilityID
    })
  };

  const handleSelectDepartment = (selectedOption) => {
    setNewDoctor({
      ...newDoctor, department_id: selectedOption ? selectedOption.value : 0,
      clinic_guid: loggeduser.facilityID
    })
  };
  const handleProfilePicChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      if(docPic){
        setDocFile(URL.createObjectURL(selectedFile))
        }
      setPreviewImage(URL.createObjectURL(selectedFile));
      setNewDoctor(prevDoctor => ({
        ...prevDoctor,
        clinic_guid: loggeduser.facilityID
      }));
    }
  };

  const handleChange = (event) => {

    const { name, value } = event.target;


    const adjustedValue = name === 'joiningDate'
      ? new Date(value.getTime() - value.getTimezoneOffset() * 60000)
      : value;

    setNewDoctor(prevDoctor => ({
      ...prevDoctor,
      clinic_guid: loggeduser.facilityID,
      [name]: adjustedValue,
    }));
  };

  return (
    <div>
      <div
        id="AddDoctorDivmainContainer"
        className="fixed inset-0 flex justify-center items-center lg:p-8 z-50"
      >
        <div
          id="AddDoctorDivbackdrop"
          className="absolute inset-0 bg-black opacity-25 "
        ></div>
        <div
          id="AddDoctorDivpopupContainer"
          className="relative bg-white shadow-xl p-2 lg:p-8 max-h-[80vh] lg:max-h-[70vh] 2xl:max-h-[70vh] overflow-y-auto "
          style={{ width: "600px" }}
        >
          <div
            id="AddDoctorDivheader"
            className="flex items-center justify-between "
          >
            <div
              id="AddDoctorDivtitle"
              className={`text-black font-bold text-2xl sm:mb:6 ${previewTheme.headingTitleColor}`}
              style={{ fontFamily: previewTheme.fontFamily }}
            >
              {newDoctor.doctor_id == 0 ? (
                <span>Add New Doctor</span>
              ) : (
                <span>Update Doctor</span>
              )}
            </div>
            <button
              id="AddDoctorButtonclose"
              className={`text-red-700 sm:justify-left`}
              onClick={onClose}
            >
              <svg
                id="AddDoctorSvgcloseIcon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className={`h-10 w-10 ${previewTheme.popupCloseBtnColor} transition-colors duration-300 ease-in-out hover:${previewTheme.popupCloseBtnHoverColor}`}
              >
                <path
                  id="AddDoctorPathcloseIcon"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </button>
          </div>
          <form id="AddDoctorFormdoctorDetails">
            <div
              id="AddDoctorDivformRow1"
              className="flex w-[100%] lg:gap-2 gap-1">
              <div
                id="AddDoctorDivTitleField"
                className=" flex-grow w-[40%] lg:w-[30%] pb-1 lg:pr-2"
              >
                <label
                  id="AddDoctorLabeltitle"
                  htmlFor="AddDoctorInputtitle"
                  className="block text-gray-700 mb-2 sm:w-1 lg:w-auto"
                >
                  Title
                </label>
                <Select
                  id="AddDoctorSelecttitle"
                  options={titles}
                  onChange={handleSelectTitles}
                  isSearchable={false}
                  className="border border-gray-500 rounded-md"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      minHeight: "42px",
                    }),
                  }}
                  value={
                    newDoctor.title
                      ? titles.find(
                        (option) => option.label === newDoctor.title
                      )
                      : titles[0]
                  }
                />
              </div>

              <div
                id="AddDoctorDivFirstnameField"
                className=" flex-grow w-[50%] lg:pr-2 pb-2"
              >
                <label
                  id="AddDoctorLabelFirstname"
                  htmlFor="AddDoctorInputname"
                  className="block text-gray-700 mb-2 sm:w-auto sm:text-left lg:w-auto"
                >
                  First Name<span className='text-red-700'>*</span>
                </label>
                <div id="AddDoctorDivFirstnameInput" className="relative">
                  <input
                    id="AddDoctorFirstName"
                    type="text"
                    name="FirstName"
                    value={newDoctor.FirstName}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                    className="block w-full border rounded-md focus:outline-none focus:border-gray-500 pr-2"
                    style={{ paddingLeft: "10px" }}
                  />
                  {errors.FirstName && (
                    <p id="AddDoctorErrorFirstname" className="text-red-500 text-sm">
                      {errors.FirstName}
                    </p>
                  )}
                </div>
              </div>
              <div
                id="AddDoctorDivLastnameField"
                className=" flex-grow w-[50%] lg:pr-2 pb-2"
              >
                <label
                  id="AddDoctorLabelLastname"
                  htmlFor="AddDoctorInputLastname"
                  className="block text-gray-700 mb-2 sm:w-auto sm:text-left lg:w-auto"
                >
                  Last Name<span className='text-red-700'>*</span>
                </label>
                <div id="AddDoctorDivLastnameInput" className="relative">
                  <input
                    id="AddDoctorLastname"
                    type="text"
                    name="LastName"
                    value={newDoctor.LastName}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                    className="block w-full border rounded-md focus:outline-none focus:border-gray-500 pr-2"
                    style={{ paddingLeft: "10px" }}
                  />
                  {errors.LastName && (
                    <p id="AddDoctorErrorLastName" className="text-red-500 text-sm">
                      {errors.LastName}
                    </p>
                  )}

                </div>
              </div>
            </div>
            <div
              id="AddDoctorMiddleName"
              className="flex w-[100%] lg:gap-2 gap-1">
              <div
                id="AddDoctorDivMiddlenameField"
                className=" flex-grow w-[30%] pb-1 lg:pr-2"
              >
                <label
                  id="AddDoctorMidleLabeltitle"
                  htmlFor="AddDoctorInputtitle"
                  className="block text-gray-700 mb-2 sm:w-1 lg:w-auto"
                >
                  Middle Name
                </label>
                <input
                  id="AddDoctorMiddleInputname"
                  type="text"
                  name="MiddleName"
                  value={newDoctor.MiddleName}
                  onChange={(event) => {
                    handleChange(event);
                  }}
                  className="block w-full border rounded-md focus:outline-none focus:border-gray-500 pr-2"
                  style={{ paddingLeft: "10px" }}
                />
                {errors.MiddleName && (
                    <p id="AddDoctorErrorMiddleName" className="text-red-500 text-sm">
                      {errors.MiddleName}
                    </p>
                  )}
              </div>
              <div
                id="AddDoctorDivmobileField"
                className="flex-grow w-1/2 lg:pr-2 pb-2">
                <label
                  id="AddDoctorLabelmobile"
                  htmlFor="AddDoctorPhoneinputMobile"
                  className="block text-gray-700 mb-2 sm:w-auto sm:text-left lg:w-auto"
                >
                  Mobile{" "}
                </label>
                <div id="AddDoctorDivmobileInputContainer" className="relative">
                  <PhoneInput
                    id="AddDoctorPhoneinputMobile"
                    country={"in"}
                    value={newDoctor.phone_number}
                    countryCodeEditable={false}
                    disableDropdown={true}
                    enableSearch={false}
                    onChange={(value) => {
                      handleChange({ target: { name: "phone_number", value } });
                    }}
                    inputProps={{
                      name: "mobile_number",
                      id: "mobile",
                      className:
                        "block w-full border rounded-md focus:outline-none focus:border-gray-400 pl-14",
                    }}
                    specialLabel=""

                  />
                  <svg
                    id="AddDoctorSvgmobileIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-4 h-4 absolute top-1/2 right-3 -translate-y-1/2 text-gray-500 hidden lg:block"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>

                {errors.phone_number && (
                  <p id="AddDoctorErrormobile" className="text-red-500 text-sm">
                    {errors.phone_number}
                  </p>
                )}
              </div>
            </div>
            <div
              id="AddDoctorDivformRow3"
              className="flex w-[100%] lg:gap-2 gap-1"
            >
              <div
                id="AddDoctorDivemailField"
                className="flex-grow w-[60%] pb-1 lg:pr-2"
              >
                <label
                  id="AddDoctorLabelemail"
                  htmlFor="AddDoctorInputemail"
                  className="block text-gray-700 mb-2 sm:w-1 lg:w-auto"
                >
                  Email <span className='text-red-700'>*</span>
                </label>
                <div id="AddDoctorDivemailInputContainer" className="relative">
                  <input
                    id="AddDoctorInputemail"
                    type="text"
                    onChange={(event) => {
                      handleChange(event);
                    }}
                    name="email_id"
                    value={newDoctor.email_id}
                    className="block w-full border rounded-md focus:outline-none focus:border-gray-500 pr-10"
                    style={{ paddingLeft: "10px" }}
                  />

                  <svg
                    id="AddDoctorSvgemailIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-4 h-4 absolute top-1/2 right-3 -translate-y-1/2 text-gray-500"
                  >
                    <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                    <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                  </svg>
                </div>
                {errors.email_id && (
                  <p id="AddDoctorErrorEmail" className="text-red-500 text-sm">
                    {errors.email_id}
                  </p>
                )}
              </div>

              <div
                id="AddDoctorDivgenderField"
                className="flex-grow w-[40%] pb-2"
              >
                <label
                  id="AddDoctorLabelgender"
                  htmlFor="AddDoctorgender"
                  className="block text-gray-700 mb-2 sm:w-1 lg:w-auto"
                >
                  Gender
                </label>
                <div id="ddDoctorDivgenderInputContainer" className="relative">
                  <select
                    id="AddDoctorSelectgender"
                    name="gender"
                    value={newDoctor.gender}
                    onChange={handleChange}
                    className="block w-full border rounded-md focus:outline-none focus:border-gray-500 pr-2"
                  >
                    <option value="">Select</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Others">Others</option>
                  </select>
                </div>
              </div>
            </div>

            <div
              id="AddDoctorDivformRow4"
              className="flex w-[100%] lg:gap-2 gap-1">
              <div
                id="AddDoctorDivSpecializationField"
                className="flex-grow w-[50%]  lg:pr-2 pb-2"
              >
                <label
                  id="AddDoctorLabelSpecialization"
                  htmlFor="AddDoctorSpecialization"
                  className="block text-gray-700 mb-2 sm:w-1 lg:w-auto "
                >
                  Specialization
                </label>
                <div
                  id="AddDoctorDivSpecializationInputContainer"
                  className="relative"
                >
                  <input
                    id="AddDoctorInputSpecialization"
                    type="text"
                    onChange={(event) => {
                      handleChange(event);
                    }}
                    name="specialization"
                    value={newDoctor.specialization}
                    className="block w-full border rounded-md focus:outline-none focus:border-gray-500 pr-10"
                    style={{ paddingLeft: "10px" }}
                  />
                </div>
              </div>
              <div
                id="AddDoctorDivqualificationField"
                className=" flex-grow w-[50%] pb-2 lg:pr-2"
              >
                <label
                  id="AddDoctorLabelqualification"
                  htmlFor="AddDoctorInputqualification"
                  className="block text-gray-700 mb-2 sm:w-1 lg:w-auto "
                >
                  Degree
                </label>
                <div
                  id="AddDoctorDivqualificationInputContainer"
                  className="relative"
                >
                  <input
                    id="AddDoctorInputqualification"
                    type="text"
                    onChange={(event) => {
                      handleChange(event);
                    }}
                    name="doctor_degree"
                    value={newDoctor.doctor_degree}
                    className="block w-full border rounded-md focus:outline-none focus:border-gray-500 pr-10"
                    style={{ paddingLeft: "10px" }}
                  />
                  <svg
                    id="AddDoctorSvgqualificationIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-4 h-4 absolute top-1/2 right-3 -translate-y-1/2 text-gray-500"
                  >
                    <path d="M11.7 2.805a.75.75 0 0 1 .6 0A60.65 60.65 0 0 1 22.83 8.72a.75.75 0 0 1-.231 1.337 49.948 49.948 0 0 0-9.902 3.912l-.003.002c-.114.06-.227.119-.34.18a.75.75 0 0 1-.707 0A50.88 50.88 0 0 0 7.5 12.173v-.224c0-.131.067-.248.172-.311a54.615 54.615 0 0 1 4.653-2.52.75.75 0 0 0-.65-1.352 56.123 56.123 0 0 0-4.78 2.589 1.858 1.858 0 0 0-.859 1.228 49.803 49.803 0 0 0-4.634-1.527.75.75 0 0 1-.231-1.337A60.653 60.653 0 0 1 11.7 2.805Z" />
                    <path d="M13.06 15.473a48.45 48.45 0 0 1 7.666-3.282c.134 1.414.22 2.843.255 4.284a.75.75 0 0 1-.46.711 47.87 47.87 0 0 0-8.105 4.342.75.75 0 0 1-.832 0 47.87 47.87 0 0 0-8.104-4.342.75.75 0 0 1-.461-.71c.035-1.442.121-2.87.255-4.286.921.304 1.83.634 2.726.99v1.27a1.5 1.5 0 0 0-.14 2.508c-.09.38-.222.753-.397 1.11.452.213.901.434 1.346.66a6.727 6.727 0 0 0 .551-1.607 1.5 1.5 0 0 0 .14-2.67v-.645a48.549 48.549 0 0 1 3.44 1.667 2.25 2.25 0 0 0 2.12 0Z" />
                    <path d="M4.462 19.462c.42-.419.753-.89 1-1.395.453.214.902.435 1.347.662a6.742 6.742 0 0 1-1.286 1.794.75.75 0 0 1-1.06-1.06Z" />
                  </svg>
                </div>
              </div>
            </div>
            <div id="AddDoctorDivformRow5" className="flex w-[100%] lg:gap-2 gap-1">
              <div
                id="AddDoctorDivdobField"
                className="flex-grow w-[50%]  lg:pr-2 pb-2 "
              >
                <label
                  id="AddDoctorLabeldob"
                  htmlFor="AddDoctorConsulting_fees"
                  className="block text-gray-700 mb-2 w-auto "
                >
                  Consulting Fees
                </label>
                <div id="AddDoctorDivdobInputContainer" className="relative">
                  <input
                    id="AddDoctorConsulting_fees"
                    type="number"
                    onChange={(event) => {
                      handleChange(event);
                    }}
                    name="consulting_fees"
                    value={newDoctor.consulting_fees}
                    className="block w-full border rounded-md focus:outline-none focus:border-gray-500 pr-10"
                    style={{ paddingLeft: "10px" }}
                  />
                  <svg
                    id="AddDoctorSvgdobIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-4 h-4 absolute top-1/2 right-3 -translate-y-1/2 text-gray-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
                    />
                  </svg>
                </div>
              </div>
              <div
                id="AddDoctorDivWorkExperience"
                className="flex-grow w-[50%]  lg:pr-2 pb-2 "
              >
                <label
                  id="AddDoctorLabelWorkExperience"
                  htmlFor="AddDoctorWorkExperience"
                  className="block text-gray-700 mb-2 sm:w-1 lg:w-auto "
                >
                  Work Experience
                </label>
                <div id="AddDoctorDivWorkInputContainer" className="relative">
                  <input
                    id="AddDoctorInputExperience"
                    type="text"
                    onChange={(event) => {
                      handleChange(event);
                    }}
                    name="experience"
                    value={newDoctor.experience}
                    className="block w-full border rounded-md focus:outline-none focus:border-gray-500 pr-10"
                    style={{ paddingLeft: "10px" }}
                  />
                </div>
              </div>
            </div>
            <div
              id="AddDoctorDivformRow2"
              className="flex w-[100%] lg:gap-2 gap-1"
            >
              <div
                id="AddDoctorDivdepartmentField"
                className=" flex-grow w-[50%] pb-1 lg:pr-2"
              >
                <label
                  id="AddDoctorLabeldepartment"
                  htmlFor="AddDoctorSelectdepartment"
                  className="block text-gray-700 mb-2 sm:w-1 lg:w-auto"
                >
                  Department <span className='text-red-700'>*</span>
                </label>
                <Select
                  id="AddDoctorSelectdepartment"
                  options={departments}
                  onChange={handleSelectDepartment}
                  isSearchable={false}
                  className="border border-gray-500 rounded-md"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      minHeight: "42px",
                    }),
                  }}
                  value={
                    newDoctor.department_id > 0
                      ? departments.find(
                        (option) => option.value === newDoctor.department_id
                      )
                      : null
                  }
                />
                {errors.department && (
                  <p
                    id="AddDoctorErrordepartment"
                    className="text-red-500 text-sm"
                  >
                    {errors.department}
                  </p>
                )}
              </div>
              <div
                id="AddDoctorDivjoiningField"
                className="flex-grow w-[50%] lg:pr-2 pb-2">
                <label
                  id="AddDoctorLabeljoining"
                  htmlFor="AddDoctorDatePickerfield"
                  className="block text-gray-700 mb-2 sm:w-1 lg:w-auto"
                >
                  Joining Date
                </label>
                <DatePicker
                  id="AddDoctorDatePickerfield"
                  selected={newDoctor.joiningDate}
                  onKeyDown={(e) => e.preventDefault()}
                  onChange={(date) =>
                    handleChange({
                      target: {
                        name: "joiningDate",
                        value: date,
                      },
                    })
                  }
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  showMonthDropdown
                  scrollableMonthYearDropdown
                  className="block w-full border rounded-md focus:outline-none focus:border-gray-500 pr-2"
                  dateFormat="dd-MM-yyyy"
                  calendarClassName="doctor-datepicker"
                />
              </div>
            </div>
          </form>
          <div className="flex mt-5 ">
            <div className="w-[100%] mb-2">
              <div
                id="AddDoctorDivuploadField"
                className="focus:border-gray-500 cursor-pointer w-[100%]  border rounded-md focus:outline-none p-2 pr-2 flex"
              >
                {file ? (
                  <img
                    id="AddDoctorImgfileUrl"
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    className="object-cover w-24 h-24"
                  />
                ) : doctor && doctor.profile_pic ? (
                  <img
                    id="AddDoctorImgfilePic"
                    src={`${process.env.REACT_APP_EMRAXIS_S3_URL}/${doctor.profile_pic}`}
                    alt="Doctor Profile"
                    className="object-cover w-24 h-24"
                  />
                ) : (
                  <svg
                    id="AddDoctorSvgplaceholder"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-24 h-24 bg-gray-300 rounded-sm p-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 15.75L7.409 10.591a2.25 2.25 0 013.182 0l5.159 5.159M15.75 12.75l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909M3 21h16.5a1.5 1.5 0 001.5-1.5V6A1.5 1.5 0 0019.5 4.5H3.75A1.5 1.5 0 002.25 6v12A1.5 1.5 0 003 21z"
                    />
                  </svg>
                )}

                <div>
                  <label
                    id="AddDoctorLabeluploadText"
                    htmlFor="AddDoctorInputuploadimage"
                    className="block text-gray-900 mb-2 italic text-base ml-4"
                  >
                    Choose Profile Photo.
                  </label>
                  <div className="relative">
                    <button
                      id="AddDoctorBtnchoseImage"
                      type="button"
                      onClick={chooseImage}
                      className={`py-1 px-2 ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} transition rounded-md ml-4 hover:${previewTheme.saveBtnHoverColor}`}
                    >
                      Choose Image
                    </button>
                    <input
                      type="file"
                      id="uploadimage"
                      name="profile_pic"
                      onChange={handleProfilePicChange}
                      className="hidden"
                      accept="image/*"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <button
              id="AddDoctorBtnsave"
              disabled={submitDisable}
              className={`hidden lg:flex px-4 py-2 border rounded-md transition-colors duration-300 ease-in-out ${previewTheme.saveBtnColor
                } ${previewTheme.saveBtnBgColor} ${submitDisable
                  ? "opacity-50 cursor-not-allowed"
                  : `hover:${previewTheme.saveBtnHoverColor}`
                }`}
              onClick={createDoctor}
            >
              {newDoctor.doctor_id == 0 ? (
                <span>Add</span>
              ) : (
                <span>Update</span>
              )}
              {submitDisable && (
                <svg
                  id="AddDoctorSvgsubmitIcon"
                  aria-hidden="true"
                  role="status"
                  className="inline w-4 h-4 ml-2 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              )}
            </button>
          </div>
          <div className="justify-center lg:!hidden flex">
            <button
              id="AddDoctorBtnsave"
              disabled={submitDisable}
              className={`px-4 py-2 border rounded-md transition-colors duration-300 ease-in-out ${previewTheme.saveBtnColor
                } ${previewTheme.saveBtnBgColor} ${submitDisable
                  ? "opacity-50 cursor-not-allowed"
                  : `hover:${previewTheme.saveBtnHoverColor}`
                }`}
              onClick={createDoctor}
            >
              {newDoctor.doctor_id == 0 ? (
                <span>Add</span>
              ) : (
                <span>Update</span>
              )}
              {submitDisable && (
                <svg
                  id="AddDoctorSvgsubmitIcon"
                  aria-hidden="true"
                  role="status"
                  className="inline w-4 h-4  text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
      {toast.show && (
        <SnackBarComponent
          message={toast.message}
          type={toast.type}
          onClose={() => setToast({ show: false, message: "", type: "" })}
        />
      )}
    </div>
  );
};
export default AddDoctor;