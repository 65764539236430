import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from 'react-redux';
import { theme } from '../../EmraxisTheme/Theme';
import DataDecode from "../../utils/DataDecode";
import store from "../../API/store";
import { setNotification } from "../../Redux/features/toast/toastSlice";
import DateFunction from "../../utils/DateFunctions";

const ActivityLogs = () => {
    const previewTheme = useSelector(state => state.theme.colors) || theme.filter(a => a.name === "defaultTheme")[0];
    var loggeduser = useSelector(state => state.login.loginuserData);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);

    const dispatch = useDispatch();

    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [selectedOptions, setSelectedOptions] = useState([{ value: "all", label: "All Users" }]);
    const [selectedSortBy, setSelectedSortBy] = useState({ value: "latest_first", label: "Latest First" });
    const [userOptions, setUserOptions] = useState([{ value: "all", label: "All Users" }])
    const [loading, setLoading] = useState(false)

    const today = new Date();
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(today.getMonth() - 6);

    useEffect(() => {
        const GetAllUsersActivityLogs = () => {
            store.GetAllUsersActivityLogs(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID }))
                .then((result) => {
                    if (result.status == 200) {
                        result.json().then((res) => {
                            let response = DataDecode.decryptResponse(res);
                            var resObject = JSON.parse(response);
                            setUserOptions([{ value: "all", label: "All Users" }, ...resObject.map(u => ({ value: u, label: u }))]);
                        })
                    } else {
                        setUserOptions([{ value: "all", label: "All Users" }]);
                    }
                })
                .catch(err => {
                    setUserOptions([{ value: "all", label: "All Users" }]);
                    dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                })
        }

        if (loggeduser.facilityID)
            GetAllUsersActivityLogs();
    }, [loggeduser])



    const handleFromDateChange = (date) => {
        setFromDate(date);
        if (toDate && date > toDate) {
            setToDate(null); // Clear toDate if it's before fromDate
        }
    };

    const handleSelectChange = (selected, selOption) => {
        if (selOption && selOption.option && selOption.option.value && selOption.option.value !== "all") {
            setSelectedOptions(selected.filter((option) => option.value !== "all"));
        }
        else if (selOption && selOption.option && selOption.option.value && selOption.option.value === "all") {
            setSelectedOptions([selOption.option]);
        }
        else {
            setSelectedOptions(selected.filter((option) => option.value !== "all"));
        }
    };

    const handleSelectSortByChange = (selected) => {
        setSelectedSortBy(selected)
    }

    const handleFilter = () => {
        let users = selectedOptions.map(a => a.value);
        if (!fromDate || users.length == 0) {
            dispatch(setNotification({ message: '* Fields are required!', status: 'info', action: true }));
        } else {
            setLoading(true);
            let payload = { 'clinic_guid': loggeduser.facilityID, start_date: fromDate ? DateFunction.SetDbDateOfBirth(fromDate) : null, end_date: toDate ? DateFunction.SetDbDateOfBirth(toDate) : null, user_name: users, sort_by: selectedSortBy.value }
            let fileName = `UserActivity-${DateFunction.GetDateUID()}.xlsx`;
            store.DownloadUsersActivityLogs(loggedusertoken, DataDecode.encryptPayload(payload))
                .then(result => {
                    setLoading(false);
                    if (result.status == 200) {
                        result.arrayBuffer().then(data => {
                            const url = window.URL.createObjectURL(new Blob([data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', fileName);
                            document.body.appendChild(link);
                            link.click();
                        })
                        dispatch(setNotification({ message: 'Logs Downloaded!', status: 'success', action: true }));
                    }
                    else if (result.status == 404) {
                        dispatch(setNotification({ message: 'No logs found!', status: 'error', action: true }));
                    }
                    else {
                        dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                    }
                })
                .catch(err => {
                    setLoading(false);
                    dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                })
        }
    };

    return (
        <div className=" mx-auto px-4 py-2 my-20 w-full">
            <div className='px-4 py-2'>
                <h1 id="H1tagActivityLogs" className={`font-bold mb-2 text-2xl lg:text-2xl sm:text-sm pl-2.5 pt-1 mr-2 ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewTheme.fontFamily }}>User Activity Logs</h1>
                <section id="SectagActivityLogs" className="p-4 bg-white rounded-lg shadow-lg mt-4">
                    <div className="flex flex-col gap-4 md:flex-row md:items-center">
                        <div className="flex flex-col w-full md:mr-4">
                            <label id="lblActivityLogsStartDate" className="text-gray-700 font-medium">Start Date *</label>
                            <DatePicker
                                id="DpActivityLogsStartDate"
                                onKeyDown={(e) => e.preventDefault()}
                                selected={fromDate}
                                onChange={handleFromDateChange}
                                className="mt-1 p-2 w-full border border-gray-300 rounded-md h-10"
                                placeholderText="Select start date"
                                dateFormat="MMM dd, yyyy"
                                maxDate={today}
                                minDate={sixMonthsAgo}
                            />
                        </div>

                        <div className="flex flex-col w-full md:mr-4">
                            <label id="lblActivityLogsEndDate" className="text-gray-700 font-medium">End Date</label>
                            <DatePicker
                                id="DpActivityLogsEndDate"
                                onKeyDown={(e) => {
                                    if (e.key === "Backspace" || e.key === "Delete") {
                                        setToDate(null)
                                    } else {
                                        e.preventDefault();
                                    }
                                }}
                                selected={toDate}
                                onChange={(date) => setToDate(date)}
                                className="mt-1 p-2 w-full border border-gray-300 rounded-md h-10"
                                placeholderText="Select end date"
                                dateFormat="MMM dd, yyyy"
                                maxDate={today}
                                minDate={fromDate || sixMonthsAgo}
                            />
                        </div>
                        <div className="flex flex-col w-full">
                            <label id="lblActivityLogsUsers" className="text-gray-700 font-medium">Users *</label>
                            <Select
                                id="SelectUsersActivityLogs"
                                isMulti
                                options={userOptions}
                                value={selectedOptions}
                                onChange={handleSelectChange}
                                className="mt-1"
                                placeholder="Select options"
                                styles={{
                                    input: (base) => ({
                                        ...base,
                                        "input:focus": {
                                            boxShadow: "none",
                                        },
                                    }),
                                }}
                            />
                        </div>
                        <div className="flex flex-col w-full">
                            <label id="lblActivityLogsSortBy" className="text-gray-700 font-medium">Sort By</label>
                            <Select
                                id="SelectSortbyActivityLogs"
                                options={[{ value: "latest_first", label: "Latest First" }, { value: "oldest_first", label: "Oldest First" }]}
                                value={selectedSortBy}
                                onChange={handleSelectSortByChange}
                                className="mt-1"
                                placeholder="Select options"
                                styles={{
                                    input: (base) => ({
                                        ...base,
                                        "input:focus": {
                                            boxShadow: "none",
                                        },
                                    }),
                                }}
                            />
                        </div>
                        <div className="flex flex-col mt-6 w-full">
                            <button id="BtnActivityLogExport" type="button" onClick={handleFilter}
                                className={`py-2 px-4 rounded-md ${previewTheme.addBtnColor} ${previewTheme.addBtnBgColor} transition-colors duration-300 ease-in-out hover:${previewTheme.addBtnHoverColor}`} style={{ fontFamily: previewTheme.fontFamily }}>
                                Export to Excel
                                {loading ?
                                    <svg id="SvgExportLoadingIcon" aria-hidden="true" role="status" stroke-width="2" className="size-5 inline ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                    </svg>
                                    :
                                    <svg id="SvgExportPermentIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" className="size-6 inline ml-2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9.75v6.75m0 0-3-3m3 3 3-3m-8.25 6a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z" />
                                    </svg>
                                }
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ActivityLogs