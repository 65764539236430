import { createSlice } from '@reduxjs/toolkit'

const initialState = {
 feedback_status:false
 
}

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    addFeedback: (state, action) => {
      state.feedback_status = action.payload
    }
}
})

// Action creators are generated for each case reducer function
export const { addFeedback} = feedbackSlice.actions

export default feedbackSlice.reducer