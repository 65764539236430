import React from 'react';

const LoadingSpinner = ({ text="Loading" }) => {
    return (
        <div className="flex justify-start m-2">
            <p id="LoaderSpinnerPtext" className="loading-text text-red-700">{text}<span className="dot1 text-2xl -mt-1">.</span><span className="dot2 text-2xl -mt-1">.</span><span className="dot3 text-2xl -mt-1">.</span></p>
        </div>
    );
};

export default LoadingSpinner;
