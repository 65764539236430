import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Appointment from './Appointment';
import AppGridView from './GridView/AppGridView';

const AppointmentViewPort = () => {
    const isDefaultGridView = useSelector(state => state.appGridView.isDefaultGridView);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 767px)');
        setIsMobile(mediaQuery.matches);
        const handleMediaChange = (e) => {
            setIsMobile(e.matches);
        };
        mediaQuery.addListener(handleMediaChange);
        return () => {
            mediaQuery.removeListener(handleMediaChange);
        };
    }, []);
    return (
        <>
            {(isMobile || !isDefaultGridView) ? <Appointment /> : <AppGridView />}
        </>
    )
}

export default AppointmentViewPort