import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { theme } from '../../EmraxisTheme/Theme';
import { setOpenSharePopup } from '../../Redux/features/patientIntake/patientIntakeSlice';
import PhoneInput from 'react-phone-input-2';
import { setNotification } from '../../Redux/features/toast/toastSlice';
import store from '../../API/store';
import DataDecode from '../../utils/DataDecode';
import ToastMessage from '../../components/ToastMessage';

const ShareLinkPopup = () => {
    const previewTheme = theme.filter((a) => a.name === "defaultTheme")[0];
    const notification = useSelector((state) => state.toast.notification);
    const dispatch = useDispatch();

    var loggeduser = useSelector(state => state.login.loginuserData);

    const [patientData, setPatientData] = useState({
        clinic_guid: "",
        phone_number: "",
        email: "",
        share_whatsapp: false,
        share_email: true,
        share_sms: false,
        facility_name: ""
    });

    const [errors, setErrors] = useState({
        phone_number: "",
        email: "",
        share_: ""
    });

    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if (loggeduser) {
            setPatientData({ ...patientData, clinic_guid: loggeduser.facilityID, facility_name: loggeduser.facility_name })
        }
    }, [loggeduser])

    const handleChange = (e) => {
        setPatientData(prevPatient => ({
            ...prevPatient,
            [e.target.name]: e.target.value
        }));
    };

    const handleChangeCheckbox = (e) => {
        setPatientData(prevPatient => ({
            ...prevPatient,
            [e.target.name]: e.target.checked
        }));
    };

    const validateFields = () => {
        let valid = true;
        const errors = { phone_number: '', email: '', share_: '' };
        if (!patientData.email && (!patientData.phone_number || patientData.phone_number.length <= 2)) {
            errors.phone_number = 'Please enter Phone number or Email Address';
            valid = false;
        }
        else if (patientData.phone_number && patientData.phone_number.length < 12) {
            errors.phone_number = 'Please enter valid Phone number';
            valid = false;
        }
        else if (patientData.email && patientData.email && !/\S+@\S+\.\S+/.test(patientData.email)) {
            errors.email = 'Please enter valid Email';
            valid = false;
        }
        else if (patientData.share_whatsapp && !patientData.phone_number) {
            errors.phone_number = 'Please enter Phone number to share via whatsapp';
            valid = false;
        }
        else if (patientData.share_email && !patientData.email) {
            errors.email = 'Please enter email to share via email';
            valid = false;
        }
        if (!patientData.share_whatsapp && !patientData.share_email) {
            errors.share_ = 'Please select atleast one to share';
            valid = false;
        }
        setErrors(errors);
        return valid;
    }

    const validateFieldsOnlyEmail = () => {
        let valid = true;
        const errors = { phone_number: '', email: '', share_: '' };
        if (!patientData.email) {
            errors.email = 'Please enter Email Address';
            valid = false;
        }
        else if (patientData.email && patientData.email && !/\S+@\S+\.\S+/.test(patientData.email)) {
            errors.email = 'Please enter valid Email';
            valid = false;
        }
        else if (patientData.share_email && !patientData.email) {
            errors.email = 'Please enter email to share via email';
            valid = false;
        }
        if (!patientData.share_email) {
            errors.share_ = 'Please select email to share';
            valid = false;
        }
        setErrors(errors);
        return valid;
    }

    const shareLink = () => {
        const error = validateFieldsOnlyEmail();
        if (!error) {
            dispatch(setNotification({ message: 'Please fill the mandatory fields!', status: 'info', action: true }));
            return;
        }
        setDisabled(true)
        store.SendPatientShareLink(DataDecode.encryptPayload(patientData))
            .then((result) => {
                setDisabled(false);
                if (result.status == 200) {
                    dispatch(setNotification({ message: "Link Shared Successfully", status: "success", action: true, }));
                    store.CreateActivityLogs(loggeduser, "Patient", `Patient Invited for the email ${patientData.email}`, "Invite");
                    dispatch(setOpenSharePopup(false))
                }
                else if (result.status == 409) {
                    dispatch(setNotification({ message: "User already exists in the system! Please contact front desk!", status: "info", action: true, }));
                    dispatch(setOpenSharePopup(false))
                }
                else{
                    dispatch(setNotification({ message: "Error Occurred!", status: "error", action: true, }));
                }
            })
            .catch((err) => {
                setDisabled(false);
                dispatch(setNotification({ message: "Error Occurred!", status: "error", action: true, }));
            });
    }

    return (
        <>
            <div id="divMainMobileShareLink" className='block md:hidden'>
                <div className="mx-auto p-2 my-20 max-w-full">
                    <header id="headerMainMobileShareLink" className='flex justify-start'>
                        <svg id="svgMainMobileShareLink" onClick={() => { dispatch(setOpenSharePopup(false)) }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="size-6 cursor-pointer">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                        </svg>
                        <h3 id="h3MainMobileShareLink" className='pl-0.5 font-semibold text-[17px]'>Fill the form</h3>
                    </header>
                    <div className='mt-4 mx-2'>
                        {/* <div className='grid col-span-2'>
                            <label id="lblMobileNumber" htmlFor="phoneIpMobileNumber" className='font-sans text-[17px] font-semibold'>Mobile Number</label>
                            <PhoneInput id="phoneIpMobileNumber"
                                country={'in'}
                                onlyCountries={['in']}
                                value={patientData.phone_number}
                                countryCodeEditable={false}
                                disableDropdown={true}
                                enableSearch={false}
                                onChange={(value) => { handleChange({ target: { name: 'phone_number', value } }) }}
                                inputProps={{
                                    name: 'phone_number',
                                    id: 'mobile',
                                    className: "w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400 pl-14"
                                }}
                                specialLabel=""
                            />
                            {errors.phone_number && <p id="ErrorMobileNumberMUI" className="text-red-500 text-sm">{errors.phone_number}</p>}
                        </div>
                        <p className='text-center font-semibold m-2'>or</p> */}
                        <div className='grid col-span-2'>
                            <label id="lblMobEmailAddress" htmlFor='ipMobEmailAddress' className='font-sans text-[17px] font-semibold'>Email Address</label>
                            <input id="ipMobEmailAddress" type='text' name="email" value={patientData.email} onChange={(event) => { handleChange(event) }} className='w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400' />
                            {errors.email && <p id="errMobEmailAddress" className="text-red-500 text-sm">{errors.email}</p>}
                        </div>
                        <p className='font-semibold mt-4 mb-2'>Choose a way to get the link:</p>
                        <div className="flex">
                            {/* <div className="flex items-center me-4">
                                <input id="whatsapp-checkbox" type="checkbox" name="share_whatsapp" checked={patientData.share_whatsapp} onChange={handleChangeCheckbox} className="size-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                <label htmlFor="whatsapp-checkbox" className="ms-2 text-md font-medium text-gray-900 dark:text-gray-300">Whatsapp</label>
                            </div> */}
                            <div className="flex items-center me-4">
                                <input id="email-checkbox" type="checkbox" name="share_email" checked={patientData.share_email} onChange={handleChangeCheckbox} className="size-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                <label htmlFor="email-checkbox" className="ms-2 text-md font-medium text-gray-900 dark:text-gray-300">Email</label>
                            </div>
                        </div>
                        {errors.share_ && <p id="AddPatientPerrorFirstName" className="text-red-500 text-sm">{errors.share_}</p>}
                        <div className='mt-4 text-right'>
                            <button disabled={disabled} onClick={shareLink} id="btnShareLinkSubmit" className={`py-2 px-4 rounded-md flex items-center ${previewTheme.addBtnColor} ${previewTheme.addBtnBgColor} transition-colors duration-300 ease-in-out hover:${previewTheme.addBtnHoverColor} disabled:opacity-50 disabled:cursor-not-allowed`} style={{ fontFamily: previewTheme.fontFamily }} >
                                Share Link
                                {disabled &&
                                    <svg id="svgSaveLinkSubmit" aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                    </svg>
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="divMainDesktopShareLink" className='hidden md:block mt-28 mb-40'>
                <div className="fixed inset-0 flex justify-center items-center lg:p-8 z-50">
                    <div className="absolute inset-0 bg-black opacity-25 "></div>
                    <div className="relative bg-white shadow-xl p-2 lg:p-8 max-h-[80vh] lg:max-h-[70vh] 2xl:max-h-[70vh] overflow-y-auto " style={{ width: "600px" }} >
                        <div className="flex justify-between items-center mb-4">
                            <h4 id="h4DesktopShareLink" className={`text-black font-bold text-2xl ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewTheme.fontFamily }}>Share Link</h4>
                            <button id="ShareLinkCLoseBtn" className={`text-red-700 sm:justify-left`} onClick={() => dispatch(setOpenSharePopup(false))}>
                                <svg id="SvgShareLinkCLoseBtn" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`h-10 w-10 ${previewTheme.popupCloseBtnColor} transition-colors duration-300 ease-in-out hover:${previewTheme.popupCloseBtnHoverColor}`}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                            </button>
                        </div>
                        {/* <div className='grid col-span-2'>
                            <label id="lbldesktopShareLinkPhoneInput" htmlFor='ipdesktopShareLinkPhoneInput' className='font-sans text-[15px] sm:text-[16px] font-semibold'>Mobile Number</label>
                            <PhoneInput id="ipdesktopShareLinkPhoneInput"
                                country={'in'}
                                onlyCountries={['in']}
                                value={patientData.phone_number}
                                countryCodeEditable={false}
                                disableDropdown={true}
                                enableSearch={false}
                                onChange={(value) => { handleChange({ target: { name: 'phone_number', value } }) }}
                                inputProps={{
                                    name: 'phone_number',
                                    id: 'mobile',
                                    className: "w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400 pl-14"
                                }}
                                specialLabel=""
                            />
                            {errors.phone_number && <p id="errdesktopShareLinkPhoneInput" className="text-red-500 text-sm">{errors.phone_number}</p>}
                        </div>
                        <p className='text-center font-semibold m-2'>or</p> */}
                        <div className='grid col-span-2'>
                            <label id="lbldesktopShareLinkEmail" htmlFor='ipdesktopShareLinkEmail' className='font-sans text-[15px] sm:text-[16px] font-semibold'>Email Address</label>
                            <input id="ipdesktopShareLinkEmail" autoFocus type='text' name="email" value={patientData.email} onChange={(event) => { handleChange(event) }} className='w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400' />
                            {errors.email && <p id="errdesktopShareLinkEmail" className="text-red-500 text-sm">{errors.email}</p>}
                        </div>
                        <p className='font-semibold mt-4 mb-2'>Choose a way to get the link:</p>
                        <div className="flex">
                            {/* <div className="flex items-center me-4">
                                <input id="desktop-whatsapp-checkbox" type="checkbox" name="share_whatsapp" checked={patientData.share_whatsapp} onChange={handleChangeCheckbox} className="size-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                <label htmlFor="desktop-whatsapp-checkbox" className="ms-2 text-md font-medium text-gray-900 dark:text-gray-300">Whatsapp</label>
                            </div> */}
                            <div className="flex items-center me-4">
                                <input id="desktop-email-checkbox" type="checkbox" name="share_email" checked={patientData.share_email} onChange={handleChangeCheckbox} className="size-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                <label htmlFor="desktop-email-checkbox" className="ms-2 text-md font-medium text-gray-900 dark:text-gray-300">Email</label>
                            </div>
                        </div>
                        {errors.share_ && <p id="AddPatientPerrorFirstName" className="text-red-500 text-sm">{errors.share_}</p>}
                        <div className='mt-4 text-right'>
                            <button id="desktopShareLinkBtn" disabled={disabled} onClick={shareLink} className={`py-2 px-4 rounded-md flex items-center ${previewTheme.addBtnColor} ${previewTheme.addBtnBgColor} transition-colors duration-300 ease-in-out hover:${previewTheme.addBtnHoverColor}  disabled:opacity-50 disabled:cursor-not-allowed`} style={{ fontFamily: previewTheme.fontFamily }} >
                                Share Link
                                {disabled &&
                                    <svg id="svgDesktopShareLinkBtn" aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                    </svg>
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {notification.action && (
                <ToastMessage
                    message={notification.message}
                    type={notification.status}
                />
            )}
        </>
    );
}

export default ShareLinkPopup;