import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import { styled } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";

dayjs.extend(isBetweenPlugin);

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) => prop !== "isSelected" && prop !== "isHovered",
})
    (({ theme, isSelected, isHovered, day }) => ({
        borderRadius: 0,
        ...(isSelected && {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            "&:hover, &:focus": {
                backgroundColor: theme.palette.primary.main,
            },
        }),
        ...(isHovered && {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
            "&:hover, &:focus": {
                backgroundColor: theme.palette.primary.light,
            },
        }),
    }));

const isInRange = (day, start, end) => {
    if (start == null || end == null) {
        return false;
    }
    return day.isBetween(start, end, null, "[]");
};

function Day(props) {
    const {
        day,
        selectedStart,
        selectedEnd,
        hoveredStart,
        hoveredEnd,
        ...other
    } = props;

    const isHovered = isInRange(day, hoveredStart, hoveredEnd);
    const isSelected = isInRange(day, selectedStart, selectedEnd);

    return (
        <CustomPickersDay
            {...other}
            day={day}
            sx={{ px: 2.5 }}
            disableMargin
            isSelected={isSelected}
            isHovered={isHovered}
        />
    );
}


const DayViewPicker = ({ selectedDate, views = ["day", "month", "year"], setSelectedDate, daysToSelect, setDayMonthSelectRef }) => {

    const [hoveredStart, setHoveredStart] = useState(null);
    const [hoveredEnd, setHoveredEnd] = useState(null);
    const [selectedStart, setSelectedStart] = useState(dayjs(selectedDate));
    const [selectedEnd, setSelectedEnd] = useState(daysToSelect == 1 ? dayjs(selectedDate) : dayjs(selectedDate).add(daysToSelect - 1, "day"));

    const handleDateChange = (newValue) => {
        if (newValue && newValue.date() !== selectedDate?.date()) {
            setSelectedDate(dayjs(newValue));
            setSelectedStart(dayjs(newValue));
            setSelectedEnd(daysToSelect == 1 ? newValue : newValue.add(daysToSelect - 1, "day"));
            setDayMonthSelectRef(null);
        }
    };

    const handleDayHover = (day) => {
        if (day) {
            setHoveredStart(day);
            setHoveredEnd(daysToSelect == 1 ? day : day.add(daysToSelect - 1, "day"));
        } else {
            setHoveredStart(null);
            setHoveredEnd(null);
        }
    };

    useEffect(() => {
        setSelectedStart(dayjs(selectedDate));
        setSelectedEnd(daysToSelect == 1 ? dayjs(selectedDate) : dayjs(selectedDate).add(daysToSelect - 1, "day"));
    }, [selectedDate]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
                id="DayDataCalanderPicker"
                value={selectedStart}
                onChange={handleDateChange}
                showDaysOutsideCurrentMonth
                displayWeekNumber={false}
                views={views}
                slots={{ day: Day }}
                sx={{
                    width: "300px !important",
                    marginTop: "28px !important",
                    backgroundColor: "bg-[#b72424] !important",
                }}
                slotProps={{
                    day: (ownerState) => ({
                        selectedStart,
                        selectedEnd,
                        hoveredStart,
                        hoveredEnd,
                        onPointerEnter: () => handleDayHover(ownerState.day),
                        onPointerLeave: () => handleDayHover(null),
                    }),
                }}
            />
        </LocalizationProvider>
    );
}


export default DayViewPicker;