import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { setNotification } from '../../../Redux/features/toast/toastSlice';
import { useSelector, useDispatch } from 'react-redux';
import store from '../../../API/store';
import DataDecode from '../../../utils/DataDecode';

let image_id = 0;
const UploadPatientFiles = ({ setUploadPatientFile, patient_id, appointment_id, setExpandRow, setRefreshImage }) => {

    var loggeduser = useSelector(state => state.login.loginuserData);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    const dispatch = useDispatch();

    const [files, setFiles] = useState([]);
    const [loadingAddImage, setLoadingAddImage] = useState(false);
    const [fileType, setFileType] = useState("Lab Reports");

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files

        const newImages = acceptedFiles.map(file => ({
            id: image_id++,
            src: file,
        }));
        setFiles(oldImages => [...oldImages, ...newImages]);
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: { "image/*": [] } })

    const onUploadPatientFiles = () => {
        if (files.length > 0) {
            const formData = new FormData();
            for (var i = 0; i < files.length; i++) {
                formData.append("files", files[i].src);
            }
            let otherData = JSON.parse(DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, 'patient_id': patient_id, 'appointment_id': appointment_id, 'file_type': fileType }))
            setTimeout(() => {
                formData.append('iv', otherData.iv);
                formData.append('payload', otherData.payload);
                setLoadingAddImage(true)
                store.SavePatientFile(loggedusertoken, formData)
                    .then((result) => {
                        setLoadingAddImage(false)
                        if (result.status == 201) {
                            setRefreshImage(o => !o);
                            setUploadPatientFile(false)
                            setExpandRow(true)
                            dispatch(setNotification({ message: 'File Uploaded Successfully!', status: 'success', action: true }));
                        }
                        else {
                            dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                        }

                    })
                    .catch((error) => {
                        console.error("Error occurred while uploading file");
                    });
            }, 100)
        } else {
            dispatch(setNotification({ message: 'Please choose a file!', status: 'error', action: true }));
        }
    }

    const deleteImage = id => {
        let file = files.filter(a => a.id != id);
        setFiles(file)
    }

    return (

        <>
            <div className="fixed inset-0 flex justify-center items-center p-8 z-[999999]">
                <div className="absolute inset-0 bg-black opacity-25 "></div>
                <div className="relative bg-white shadow-xl rounded-sm" style={{ width: "600px" }} >
                    <div className="text-right">
                        <button className="text-red-700 sm:justify-left" onClick={() => setUploadPatientFile(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-7 w-7 mr-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                        </button>
                    </div>

                    <div className='min-h-[200px] pl-8 pr-8'>
                        <div {...getRootProps()} className='cursor-pointer'>
                            <input {...getInputProps()} />
                            {
                                isDragActive ?
                                    <p>Drop the files here ...</p> :
                                    <div className='border border-dashed border-green-400 p-2 rounded-md'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-green-600  w-8 h-8 mb-1 mx-auto cursor-pointer">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z" />
                                        </svg>
                                        <p className='text-center font-bold cursor-pointer'>Click to{files ? ' change ' : ' select '}a file</p>
                                    </div>
                            }
                        </div>

                        <div className='text-center mt-4'>
                            <div className='grid grid-cols-3 gap-2 max-h-[340px] overflow-y-scroll'>
                                {files.map((file, index) => (
                                    <div key={'index-' + index} className="relative p-2 border rounded-sm">
                                        <img height={150} width={150} className='object-cover' src={URL.createObjectURL(file.src)} alt={file.src.name} />
                                        <button className="absolute top-2 right-2 bg-gray-100 rounded-full p-1 shadow" onClick={() => deleteImage(file.id)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                            </svg>
                                        </button>
                                    </div>
                                ))}
                            </div>
                            {files.length > 0 &&
                                <div className='flex items-center justify-center'>
                                    <select value={fileType} className='block border text-sm rounded-md focus:outline-none focus:border-gray-500 px-2 py-2 cursor-pointer' onChange={e => setFileType(e.target.value)}>
                                        <option value="Lab Reports">Lab Reports</option>
                                        <option value="X Ray">X Ray</option>
                                        <option value="Others">Others</option>
                                    </select>
                                    <button disabled={loadingAddImage} className='bg-green-600 text-white hover:bg-green-800 rounded-md p-2 m-3 disabled:ursor-not-allowed disabled:opacity-50' onClick={onUploadPatientFiles}>Upload
                                        {loadingAddImage ?
                                            <svg aria-hidden="true" role="status" className="inline w-4 h-4 ml-1 text-green-600 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                            </svg>
                                            :
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-4 ml-1 inline">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                                            </svg>
                                        }
                                    </button>
                                </div>
                            }
                        </div>

                    </div>


                </div>
            </div>

        </>
    )
}

export default UploadPatientFiles