import { createSlice } from '@reduxjs/toolkit';

const recycleBinNavbarSlice = createSlice({
    name: 'recycleBinSideNavbar',
    initialState: {
        sidebar: { patient: false, doctor: false, department: false, appointment: false, patientFiles: false },
        activeNavbar: "patient",
        isPatientDataAvailable: false,
        isDoctorDataAvailable: false,
        isDepartmentDataAvailabale: false,
        isAppointmentDataAvailable: false,
        isPatientFilesDataAvailable: false
    },
    reducers: {
        setRecycleBinSideNavbar: (state, action) => {
            state.sidebar = action.payload;
        },
        setRecycleBinActiveNavbar: (state, action) => {
            state.activeNavbar = action.payload;
        },
        setRecycleBinPatientDataAvailable: (state, action) => {
            state.isPatientDataAvailable = action.payload;
        },
        setRecycleBinDoctorDataAvailable: (state, action) => {
            state.isDoctorDataAvailable = action.payload;
        },
        setRecycleBinDepartmentDataAvailabale: (state, action) => {
            state.isDepartmentDataAvailabale = action.payload;
        },
        setRecycleBinAppointmentDataAvailable: (state, action) => {
            state.isAppointmentDataAvailable = action.payload;
        },
        setRecycleBinPatientFilesDataAvailable: (state, action) => {
            state.isPatientFilesDataAvailable = action.payload;
        }
    },
});

export const { setRecycleBinSideNavbar, setRecycleBinActiveNavbar, setRecycleBinPatientDataAvailable, setRecycleBinDoctorDataAvailable, setRecycleBinAppointmentDataAvailable, setRecycleBinDepartmentDataAvailabale, setRecycleBinPatientFilesDataAvailable} = recycleBinNavbarSlice.actions;
export default recycleBinNavbarSlice.reducer;