import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setRecycleBinActiveNavbar, setRecycleBinSideNavbar } from '../../../Redux/features/recycleBin/recycleBinNavbarSlice';
import { theme } from '../../../EmraxisTheme/Theme';

const RecycleSidebar = () => {
    const dispatch = useDispatch();
    var isActive = useSelector(state => state.recycleBinSideNavbar.activeNavbar);
    let isPatientDataAvailable = useSelector(state => state.recycleBinSideNavbar.isPatientDataAvailable);
    let isDoctorDataAvailable = useSelector(state => state.recycleBinSideNavbar.isDoctorDataAvailable);
    let isDepartmentDataAvailabale = useSelector(state => state.recycleBinSideNavbar.isDepartmentDataAvailabale);
    let isAppointmentDataAvailable = useSelector(state => state.recycleBinSideNavbar.isAppointmentDataAvailable);
    let isPatientFilesDataAvailable = useSelector(state => state.recycleBinSideNavbar.isPatientFilesDataAvailable);
    const previewTheme = useSelector(state => state.theme.colors) || theme.filter(a => a.name === "defaultTheme")[0];


    const handleNavbarSelect = (selectedMenu) => {
        let initialState = { patient: false, doctor: false, department: false, appointment: false, patientFiles: false };
        dispatch(setRecycleBinActiveNavbar(selectedMenu));
        if (selectedMenu === "patient") {
            dispatch(setRecycleBinSideNavbar({ ...initialState, patient: true }))
        }
        else if (selectedMenu === "appointment") {
            dispatch(setRecycleBinSideNavbar({ ...initialState, appointment: true }))
        }
        else if (selectedMenu === "doctor") {
            dispatch(setRecycleBinSideNavbar({ ...initialState, doctor: true }))
        }
        else if (selectedMenu === "department") {
            dispatch(setRecycleBinSideNavbar({ ...initialState, department: true }))
        }
        else if (selectedMenu === "patient-files") {
            dispatch(setRecycleBinSideNavbar({ ...initialState, patientFiles: true }))
        }
    }

    return (
        <div className='bg-[#f0f5ff] h-screen text-black flex flex-col' id="binSideNavBarMainDiv">
            <nav className="flex flex-col">
                {isPatientDataAvailable &&
                    <button id="btnRecycleBinSideNavbarPatient" onClick={() => handleNavbarSelect('patient')} className={`flex items-center p-3 px-4 space-x-3 hover:${previewTheme?.navbarBgHoverColor} hover:${previewTheme?.navbarTextColor} ${isActive === 'patient' ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold` : ''}`}>
                        <img
                            id="imgRecycleBinSideNavbarPatient"
                            src="./recyclebin-navbar/Patient.png"
                            alt="Patient"
                            className="h-4 w-4"
                        />
                        <span>Patient</span>
                    </button>}
                {isAppointmentDataAvailable &&
                    <button id="btnRecycleBinSideNavbarAppointment" onClick={() => handleNavbarSelect('appointment')} className={`flex items-center p-3 px-4 space-x-3 hover:${previewTheme?.navbarBgHoverColor} hover:${previewTheme?.navbarTextColor} ${isActive === 'appointment' ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold` : ''}`}>
                        <img
                            id="imgRecycleBinSideNavbarAppointment"
                            src="./recyclebin-navbar/Appointment.png"
                            alt="Appointment"
                            className="h-4 w-4"
                        />
                        <span>Appointment</span>
                    </button>}
                {isDoctorDataAvailable &&
                    <button id="btnRecycleBinDoctorSideNavbar" onClick={() => handleNavbarSelect('doctor')} className={`flex items-center p-3 px-4 space-x-3 hover:${previewTheme?.navbarBgHoverColor} hover:${previewTheme?.navbarTextColor} ${isActive === 'doctor' ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold` : ''}`}>
                        <img
                            id="imgRecycleBinSideNavbarDoctor"
                            src="./recyclebin-navbar/Doctor.png"
                            alt="Doctor"
                            className="h-4 w-4"
                        />
                        <span>Doctor</span>
                    </button>}
                {isDepartmentDataAvailabale &&
                    <button id="btnRecycleBinDepartmentSideNavbar" onClick={() => handleNavbarSelect('department')} className={`flex items-center p-3 px-4 space-x-3 hover:${previewTheme?.navbarBgHoverColor} hover:${previewTheme?.navbarTextColor} ${isActive === 'department' ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold` : ''}`}>
                        <img
                            id="imgRecycleBinSideNavbarDepartment"
                            src="./recyclebin-navbar/mdi_notes.png"
                            alt="Department"
                            className="h-4 w-4"
                        />
                        <span>Department</span>
                    </button>}
                {isPatientFilesDataAvailable &&
                    <button id="btnRecycleBinPatientFilesSideNavbar" onClick={() => handleNavbarSelect('patient-files')} className={`flex items-center p-3 px-4 space-x-3 hover:${previewTheme?.navbarBgHoverColor} hover:${previewTheme?.navbarTextColor} ${isActive === 'patient-files' ? `${previewTheme?.navbarActiveColor} ${previewTheme?.navbarBgColor} font-bold` : ''}`}>
                        <img
                            id="imgRecycleBinSideNavbarPatientFilest"
                            src="./recyclebin-navbar/typcn_upload.png"
                            alt="Patient Files"
                            className="h-4 w-4"
                        />
                        <span>Patient Files</span>
                    </button>}
            </nav>
        </div>
    )
}

export default RecycleSidebar