import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import store from '../../../API/store';
import DataDecode from '../../../utils/DataDecode';
import { setNotification } from '../../../Redux/features/toast/toastSlice';
import DateFunction from '../../../utils/DateFunctions';
import BinFilePreview from './BinFilePreview';
import { theme } from '../../../EmraxisTheme/Theme';

const PatientFileBin = ({ data, setRefreshDate, dataLoading }) => {
  var loggeduser = useSelector(state => state.login.loginuserData);
  var loggedusertoken = useSelector(state => state.login.userBarerToken);
  const previewTheme = useSelector(state => state.theme.colors) || theme.filter(a => a.name === "defaultTheme")[0];

  const dispatch = useDispatch();

  const [cfmRestorePopup, setCfmRestorePopup] = useState(false);
  const [restoreLoadingIcon, setRestoreLoadingIcon] = useState(false);
  const [restoreID, setRestoreID] = useState(0);


  const restoreConfirm = (patientFilesID) => {
    setRestoreID(patientFilesID);
    setCfmRestorePopup(true);
  }

  const handleRestorePopup = () => {
    setRestoreLoadingIcon(true);
    store.RestorePatientFilesData(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, 'patient_files_id': restoreID.patient_files_id }))
      .then((result) => {
        setRestoreLoadingIcon(false);
        setCfmRestorePopup(false);
        if (result.status == 200) {
          store.CreateActivityLogs(loggeduser, "Recyclebin", `Patient ${restoreID.file_type} File Restored - (${restoreID.file_path})`, "Restore");
          setRefreshDate(r => !r);
          dispatch(setNotification({ message: 'Patient Files Restored!', status: 'success', action: true }));
        }
        else {
          dispatch(setNotification({ message: 'Error Occurred', status: 'error', action: true }));
        }
      })
      .catch(err => {
        setRestoreLoadingIcon(false);
        dispatch(setNotification({ message: 'Error Occurred', status: 'error', action: true }));
      })
  }

  return (
    <>
      {cfmRestorePopup &&
        <div id="restorePatientFilesCfmPopup" tabIndex="-1" aria-hidden="true" className="fixed z-10 lg:left-[40%] lg:w-80 top-[10rem] lg:top-[3rem] lg:translate-x-[50%] translate-y-[50%]">
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5">
              <svg id="binPatientFilePopIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-gray-600 dark:text-gray-500 size-7 mb-3.5 mx-auto">
                <path d="M9.97.97a.75.75 0 0 1 1.06 0l3 3a.75.75 0 0 1-1.06 1.06l-1.72-1.72v3.44h-1.5V3.31L8.03 5.03a.75.75 0 0 1-1.06-1.06l3-3ZM9.75 6.75v6a.75.75 0 0 0 1.5 0v-6h3a3 3 0 0 1 3 3v7.5a3 3 0 0 1-3 3h-7.5a3 3 0 0 1-3-3v-7.5a3 3 0 0 1 3-3h3Z" />
                <path d="M7.151 21.75a2.999 2.999 0 0 0 2.599 1.5h7.5a3 3 0 0 0 3-3v-7.5c0-1.11-.603-2.08-1.5-2.599v7.099a4.5 4.5 0 0 1-4.5 4.5H7.151Z" />
              </svg>
              <p className="mb-4 text-gray-500 dark:text-gray-300">Are you sure you want to restore this patientFiles?</p>
              <div className=" flex justify-center items-center">
                <button id="btnCancelCfmRestorePatientFilesPopup"
                  disabled={restoreLoadingIcon}
                  className={`mr-2 py-2 px-3 text-sm font-medium  rounded-lg border border-gray-200  focus:z-10 ${previewTheme.cancelBtnColor} ${previewTheme.cancelBtnBgColor} hover:${previewTheme.cancelBtnHoverColor}`}
                  onClick={() => {
                    setCfmRestorePopup(false);
                  }}>No, cancel</button>
                <button id="btnYesCfmRestorePatientFilesPopup" type="button" onClick={handleRestorePopup} disabled={restoreLoadingIcon} className={`py-2 px-3 text-sm font-medium text-center rounded-lg focus:ring-4 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} hover:${previewTheme.saveBtnHoverColor}`}>
                  Yes, I'm sure {restoreLoadingIcon &&
                    <svg aria-hidden="true" role="status" className="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                    </svg>
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      }


      <div className='shadow-lg rounded-md' id="binPatientFilesMainDiv">
        <div className='flex justify-between bg-[#e2e8f0] text-[#a31b1b] rounded-t-lg text-lg font-semibold py-1 px-2'>
          <div>
            <p id="binPatientFilesText" className='font-semibold text-lg'>Patient Files</p>
          </div>
          <div>

          </div>
        </div>
        <div className="w-full rounded-lg bg-gray-50">
          {data.length > 0 ?
            <>
              <div className="px-2 py-2 bg-white rounded-t-lg dark:bg-gray-800 min-h-36">
                <table id="binPatientFilesTable" className='table-auto w-full border rounded-md focus:outline-none text-sm'>
                  <thead>
                    <tr className='odd:bg-white even:bg-gray-100 border-b-2 border-gray-200'>
                      <th className='text-left py-2 text-sm font-semibold pl-2'>File</th>
                      <th className='text-left py-2 text-sm font-semibold'>File Type</th>
                      <th className='text-left py-2 text-sm font-semibold'>File Format</th>
                      <th className='text-left py-2 text-sm font-semibold'>Created On</th>
                      <th className='text-left py-2 text-sm font-semibold'>Deleted On</th>
                      <th className='text-left py-2 text-sm font-semibold'>Hard Delete in</th>
                      <th className='text-center py-2 text-sm font-semibold'>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((row, index) => (
                      <tr key={'restore-patientFiles-' + index} className='odd:bg-white even:bg-gray-100'>
                        <td className='text-left py-1 pl-2 w-[25%]'>
                          <BinFilePreview file={row} key={"bin-file-" + index} index={index}/>
                        </td>
                        <td className='text-left py-1'>
                          {row.file_type}
                        </td>
                        <td className='text-left py-1'>
                          {row.file_path?.split('.')?.pop()?.toLowerCase()}
                        </td>
                        <td className='text-left py-1'>
                          {row.created_on && DateFunction.GetDate(row.created_on)}
                        </td>
                        <td className='text-left py-1'>
                          {DateFunction.GetDate(row.deleted_on)}
                        </td>
                        <td className='text-left py-1'>
                          {DateFunction.GetSubtractedDays(row.deleted_on, 30)}
                        </td>
                        <td className='text-center py-1'>
                          <button id={"binPatientFilesRestoreButton" + index} onClick={() => { restoreConfirm(row); }} className={`${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} hover:${previewTheme.saveBtnHoverColor} p-1 rounded-md`}>Restore</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
            :
            <>
              {dataLoading &&
                <p className='text-center p-5'>
                  <svg id="imgBinPatientFilesDataLoading" aria-hidden="true" role="status" className="size-6 ml-2 text-red-600 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                  </svg>
                </p>}
            </>
          }
        </div>
      </div>
    </>
  )
}

export default PatientFileBin