import React from 'react'
import { useSelector } from 'react-redux';

import { theme } from '../EmraxisTheme/Theme'; 


const AccountOwner = () => {
    const previewTheme = useSelector(state => state.theme.colors) || theme.defaultTheme;

  return (
    <div className=" mx-auto px-4 py-2 my-20 w-[100%]">
    
    <header id="AccountOwnerHeaderAccountOwner" className={`origin-top-left flex font-bold mb-2 text-2xl lg:text-xl sm:text-lg pl-2.5 pt-1 ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewTheme.fontFamily }}>
  Account Owner
</header></div>
  )
}

export default AccountOwner