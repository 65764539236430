import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    openSharePopup: false
}

export const patientIntakeSlice = createSlice({
    name: 'patientIntake',
    initialState,
    reducers: {
        setOpenSharePopup: (state, action) => {
            state.openSharePopup = action.payload
        },

    },
})


export const { setOpenSharePopup } = patientIntakeSlice.actions

export default patientIntakeSlice.reducer