import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { theme } from '../../EmraxisTheme/Theme';
import GenerateInvoice from './GenerateInvoice';
import store from '../../API/store';
import DataDecode from '../../utils/DataDecode';
import DateFunction from '../../utils/DateFunctions';
import { setAppointmentInvoicePrint } from '../../Redux/features/popup/popupSlice';
import { setAppointmentInvoice } from '../../Redux/features/invoice/invoiceSlice';
import { setNotification } from '../../Redux/features/toast/toastSlice';

const AdminInvoices = () => {
    const [sortType, setSortType] = useState("all");
    const [sortedAlInvoices, setSortedAllInvoices] = useState([]);
    const [allInvoices, setAllInvoices] = useState([]);
    const [search, setSearch] = useState("");

    var loggeduser = useSelector(state => state.login.loginuserData);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    var showAppointmentInvoicePrint = useSelector(state => state.popup.showAppointmentInvoicePrint);
    const previewTheme = useSelector(state => state.theme.colors) || theme.defaultTheme;
    const dispatch = useDispatch()

    const GetAllInvoice = () => {
        store.GetAllInvoice(loggedusertoken, DataDecode.encryptPayload({ clinic_guid: loggeduser.facilityID, sort_by: sortType }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        var data = JSON.parse(response);
                        setAllInvoices(data);
                        setSortedAllInvoices(data);
                    })
                } else {
                    setAllInvoices([])
                }
            })
            .catch(err => {
                dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
            })
    }

    useEffect(() => {
        if (loggeduser) {
            GetAllInvoice();
        }
    }, [loggeduser, sortType])

    useEffect(() => {
        if (allInvoices.length > 0 && search.trim() !== "") {
            let invoice = allInvoices.filter(a => a.patients.patient_name?.toLowerCase()?.includes(search.toLowerCase())
                || a.patients.mobile_number?.toLowerCase()?.includes(search.toLowerCase())
                || a.doctorName?.toLowerCase()?.includes(search.toLowerCase())
                || a.invoice_code?.toLowerCase()?.includes(search.toLowerCase())
            )
            setSortedAllInvoices(invoice);
        }
    }, [search, allInvoices, sortType])

    const printInvoice = (inv) => {
        dispatch(setAppointmentInvoicePrint(true))
        dispatch(setAppointmentInvoice({ patient: inv.patients, doctor: inv.appointments?.doctor_id, appointment: inv.appointments, targetFrom: inv.targetFrom }))
    }

    return (
        <div className=" mx-auto px-4 py-2 my-20 w-100% ">

            <div className=' flex flex-row gap-8 justify-between mb-5'>
                <div className="flex items-center  ">
                    <header id="AdminInvoicesHeadertitle" className={`origin-top-left flex font-bold mb-2 text-2xl lg:text-2xl sm:text-lg pl-2.5 pt-1 ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewTheme.fontFamily }}>Invoices</header>
                </div>
                <div className='flex'>
                    <select id="AdminInvoicesSelectdropDown" value={sortType} className='block border text-sm rounded-md focus:outline-none focus:border-gray-500 px-2 py-2 cursor-pointer mx-4' onChange={e => setSortType(e.target.value)}>
                        <option id="AdminInvoicesOptionall" value="all">All</option>
                        <option id="AdminInvoicesOptiontoday" value="today">Today</option>
                        <option id="AdminInvoicesOptionweek" value="week">This Week</option>
                        <option id="AdminInvoicesOptionmonth" value="month">This Month</option>
                    </select>
                    <div className="hidden relative items-center gap-2 mx-4 md:block">
                        <input type="text" value={search} id="AdminInvoicesInputsearch" className="block pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none" placeholder="Id, Patient, Doctor Name"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <svg id="AdminInvoicesInputsearchIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 absolute left-3 top-1/2 transform -translate-y-1/2 text-red-700">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                        </svg>
                    </div>

                    <button id="AdminInvoicesBtnreload" className="items-center lg:ml-6 mx-4" onClick={() => window.location.reload()}>
                        <svg id="AdminInvoicesBtnreloadIcon" xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-reload  h-8 w-8 mr-2 text-red-700 hover:text-red-800" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1 7.935 1.007 9.425 4.747" />
                            <path d="M20 4v5h-5" />
                        </svg>
                    </button>
                </div>
            </div>
            <div className='cursor-default'>
                <div className='block lg:hidden'>
                    <table className="table-auto w-full border focus:outline-none min-w-full bg-white shadow-lg rounded-lg">
                        <thead id="AdminInvoicesTableheader" className={`text-sm`}>
                            <tr className='px-2 py-4 text-left border-b'>                                
                                <th id="AdminInvoicesThinvoicedate" className="px-2 py-2 text-left">
                                    Invoice Date
                                </th>
                                <th id="AdminInvoicesThpatientname" className="px-2 py-2 text-left">
                                    Patient Name
                                </th>                                
                                <th id="AdminInvoicesThtotal" className="px-2 py-2 text-right ">
                                    Total
                                </th>
                                <th className="px-2 py-2 text-center ">Print</th>
                            </tr>
                        </thead>
                        <tbody id="AdminInvoicesTbodyinvoice" className='text-sm'>
                            {
                                sortedAlInvoices.map((inv, ind) => (
                                    <tr key={'app-table-' + ind} className='odd:bg-white even:bg-red-100 transition duration-200'>
                                        
                                        <td id="AdminInvoicesTdinvoiceDate" className="px-2 py-2 text-left">
                                            {DateFunction.GetDate(inv.invoice_on)}
                                        </td>

                                        <td id="AdminInvoicesTdpatientName" className="px-2 py-2 text-left">
                                            {inv.patients?.FirstName + " " + inv.patients?.MiddleName?.charAt(0) + " " + inv.patients?.LastName}
                                        </td>
                                        
                                        <td id="AdminInvoicesTdtotalAmount" className="px-2 py-2 text-right">
                                            {inv.totalAmount}
                                        </td>
                                        <td id="AdminInvoicesTdbtnContainer" className="text-center">
                                            <button id="AdminInvoicesBtninvoicePrint"
                                                onClick={() => { printInvoice(inv) }}
                                                className={`disabled:cursor-not-allowed text-blue-600 disabled:text-blue-300`}>
                                                <svg id="AdminInvoicesSvginvoicePrintIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5">
                                                    <path fillRule="evenodd" d="M7.875 1.5C6.839 1.5 6 2.34 6 3.375v2.99c-.426.053-.851.11-1.274.174-1.454.218-2.476 1.483-2.476 2.917v6.294a3 3 0 0 0 3 3h.27l-.155 1.705A1.875 1.875 0 0 0 7.232 22.5h9.536a1.875 1.875 0 0 0 1.867-2.045l-.155-1.705h.27a3 3 0 0 0 3-3V9.456c0-1.434-1.022-2.7-2.476-2.917A48.716 48.716 0 0 0 18 6.366V3.375c0-1.036-.84-1.875-1.875-1.875h-8.25ZM16.5 6.205v-2.83A.375.375 0 0 0 16.125 3h-8.25a.375.375 0 0 0-.375.375v2.83a49.353 49.353 0 0 1 9 0Zm-.217 8.265c.178.018.317.16.333.337l.526 5.784a.375.375 0 0 1-.374.409H7.232a.375.375 0 0 1-.374-.409l.526-5.784a.373.373 0 0 1 .333-.337 41.741 41.741 0 0 1 8.566 0Zm.967-3.97a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H18a.75.75 0 0 1-.75-.75V10.5ZM15 9.75a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75V10.5a.75.75 0 0 0-.75-.75H15Z" clipRule="evenodd" />
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                            {
                                sortedAlInvoices.length > 0 &&
                                <tr className='border-t border-gray-300'>
                                    <td id="AdminInvoicesTdtotalAmount" colSpan={4} className='text-right p-2 text-green-600 font-semibold text-lg'><span className='text-black'>Total Amount:</span> {sortedAlInvoices.reduce((sum, item) => sum + (item.totalAmount), 0)}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div className='hidden lg:block'>
                    <table className="table-auto w-full border focus:outline-none min-w-full bg-white shadow-lg rounded-lg">
                        <thead id="AdminInvoicesTableheader" className={`text-sm`}>
                            <tr className='px-2 py-4 text-left border-b'>
                                <th id="AdminInvoicesThinvoiceId" className="px-2 py-2 text-left">
                                    Invoice ID
                                </th>
                                <th id="AdminInvoicesThinvoicedate" className="px-2 py-2 text-left">
                                    Invoice Date
                                </th>
                                <th id="AdminInvoicesThpatientname" className="px-2 py-2 text-left">
                                    Patient Name
                                </th>
                                <th id="AdminInvoicesThdoctorname" className="px-2 py-2 text-left">
                                    Doctor Name
                                </th>
                                <th id="AdminInvoicesThtotal" className="px-2 py-2 text-right ">
                                    Total
                                </th>
                                <th className="px-2 py-2 text-center ">Print</th>
                            </tr>
                        </thead>
                        <tbody id="AdminInvoicesTbodyinvoice" className='text-sm'>
                            {
                                sortedAlInvoices.map((inv, ind) => (
                                    <tr key={'app-table-' + ind} className='odd:bg-white even:bg-red-100 transition duration-200'>
                                        <td id="AdminInvoicesTdinvoiceId" className="px-2 py-2 text-left">
                                            {inv.invoice_code}
                                        </td>
                                        <td id="AdminInvoicesTdinvoiceDate" className="px-2 py-2 text-left">
                                            {DateFunction.GetDate(inv.invoice_on)}
                                        </td>

                                        <td id="AdminInvoicesTdpatientName" className="px-2 py-2 text-left">
                                            {inv.patients?.FirstName + " " + inv.patients?.MiddleName?.charAt(0) + " " + inv.patients?.LastName}
                                        </td>
                                        <td id="AdminInvoicesTddoctorName" className="px-2 py-2 text-left">
                                            {inv.doctorName}
                                        </td>
                                        <td id="AdminInvoicesTdtotalAmount" className="px-2 py-2 text-right">
                                            {inv.totalAmount}
                                        </td>
                                        <td id="AdminInvoicesTdbtnContainer" className="text-center">
                                            <button id="AdminInvoicesBtninvoicePrint"
                                                onClick={() => { printInvoice(inv) }}
                                                className={`disabled:cursor-not-allowed text-blue-600 disabled:text-blue-300`}>
                                                <svg id="AdminInvoicesSvginvoicePrintIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5">
                                                    <path fillRule="evenodd" d="M7.875 1.5C6.839 1.5 6 2.34 6 3.375v2.99c-.426.053-.851.11-1.274.174-1.454.218-2.476 1.483-2.476 2.917v6.294a3 3 0 0 0 3 3h.27l-.155 1.705A1.875 1.875 0 0 0 7.232 22.5h9.536a1.875 1.875 0 0 0 1.867-2.045l-.155-1.705h.27a3 3 0 0 0 3-3V9.456c0-1.434-1.022-2.7-2.476-2.917A48.716 48.716 0 0 0 18 6.366V3.375c0-1.036-.84-1.875-1.875-1.875h-8.25ZM16.5 6.205v-2.83A.375.375 0 0 0 16.125 3h-8.25a.375.375 0 0 0-.375.375v2.83a49.353 49.353 0 0 1 9 0Zm-.217 8.265c.178.018.317.16.333.337l.526 5.784a.375.375 0 0 1-.374.409H7.232a.375.375 0 0 1-.374-.409l.526-5.784a.373.373 0 0 1 .333-.337 41.741 41.741 0 0 1 8.566 0Zm.967-3.97a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H18a.75.75 0 0 1-.75-.75V10.5ZM15 9.75a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75V10.5a.75.75 0 0 0-.75-.75H15Z" clipRule="evenodd" />
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                            {
                                sortedAlInvoices.length > 0 &&
                                <tr className='border-t border-gray-300'>
                                    <td id="AdminInvoicesTdtotalAmount" colSpan={6} className='text-right p-2 text-green-600 font-semibold text-lg'><span className='text-black'>Total Amount:</span> {sortedAlInvoices.reduce((sum, item) => sum + (item.totalAmount), 0)}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>

            </div>

            {showAppointmentInvoicePrint && <GenerateInvoice page="Admin Invoice"/>}
        </div>
    )
}

export default AdminInvoices