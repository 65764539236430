import React, { useState, useEffect } from "react";
import { FaHouseMedical } from "react-icons/fa6";
import SnackBarComponent from "../../components/SnackBarComponent/SnackBarComponent"
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import AddFacility from "../Facility/AddFacility";
import DataDecode from "../../utils/DataDecode";
import store from "../../API/store";
import { Spinner } from "../../components/Spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  addFacilityName,
  addFacilityID,
  addFacilityEmail,
  addFacilityColor,
  addFacilityLogo,
} from "../../Redux/features/facility/facilitySlice";
import {
  setIsDesktopUserScreenOpen,
  setIsSuperAdminDropdownOpen,
  setIsAddOnFeatureData,
  setIsClinicGuid,
  setIsActiveData,
} from "../../Redux/features/drawer/drawerSlice";
import AdminFeature from "../AdminFeature/AdminFeature";

function SuperAdminDashboard() {
  var isActiveData = useSelector(
    (state) => state.drawer.isDesktopUserScreenOpen
  );
  var loggedusertoken = useSelector((state) => state.login.userBarerToken);
  var loggeduser = useSelector((state) => state.login.loginuserData);

  const dispatch = useDispatch();
  var isDesktopUserScreenOpen = useSelector(
    (state) => state.drawer.isDesktopUserScreenOpen
  );
  var isAddOnFeatureData = useSelector(
    (state) => state.drawer.isAddOnFeatureData
  );
  const [buttonStates, setButtonStates] = useState([
    false,
    false,
    false,
    false,
  ]);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [totalpages, settotalpages] = useState(0);
  const [selectedpage, setselectedpage] = useState(1);
  const [searchfacility, setsearchfacility] = useState([]);
  const [toast, setToast] = useState({ show: false, message: '', type: '' });
  const [suggestedProducts, setSuggestedProducts] = useState([]);
  const [view, setView] = useState(false);
  const [list, setList] = useState([]);
  const [query, setQuery] = useState("");
  const [facilityguid, setfacilityguid] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [refreshData, setRefreshData] = useState("");
  const [showSpinner, setShowSpinner] = useState(false)

  const dispath = useDispatch();
  const showToast = () => {
	
    setTimeout(() => {
      setToast({ show: false, message: '', type: '' });
    }, 3000);
  };

  useEffect(() => {
    if (showPopup) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showPopup]);

  const facilityDataForSuperAdmin = async (card) => {
    setShowSpinner(true)
    const payload = { facility_guid: card.facility_guid };

    await store
      .GetFacilityDataForSuperAdmin(
        loggedusertoken,
        DataDecode.encryptPayload(payload)
      )
      .then((result) => {
        if (result.status === 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            let data = JSON.parse(response);
            dispatch(setIsActiveData(data));

            list.push(data?.addon_features);
            dispatch(setIsClinicGuid(data?.clinic_guid));
            dispatch(setIsAddOnFeatureData(list[0]));
            setShowSpinner(false)
          });
        }
      })
      .catch((err) => {
        setToast({ show: true, message: "Error occured while saving UserRoles", type: "error" });
        showToast();
        setLoading(false);
        
      });
  };

  const fetchFacilitydata = async () => {
    await store
      .GetFacilityDetails(loggedusertoken, selectedpage)
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            var myObject = JSON.parse(response);
            if (selectedpage == 1) {
              setCardData(myObject.facilities);

              settotalpages(Math.ceil(myObject.facilityGuid.length / 16));
              setsearchfacility(myObject.facilityGuid);
            } else {
              setCardData((prevItems) => [
                ...prevItems,
                ...myObject.facilities,
              ]);
            }
          });

          /*    totalpages >= selectedpage && setselectedpage(prevPage => prevPage + 1)
           */
        } else {
        }
      })
      .catch((err) => {
        setToast({ show: true, message: "Error occured", type: "error" });
        showToast();
        setLoading(false);
       
      });
  };

  const searchFacility = async (search, page) => {
    await store
      .SearchFacility(
        loggedusertoken,
        DataDecode.encryptPayload({ searchterm: search, page: page })
      )
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            var myObject = JSON.parse(response);
            setCardData(myObject);
          });
        } else {
        }
      })
      .catch((err) => {
        setToast({ show: true, message: "Error occured", type: "error" });
        showToast();
        setLoading(false);
        
      });
  };
  //load the facility details from the database
  useEffect(() => {
    if (loggeduser != null && loggeduser.length != 0) {
      if (query == "") fetchFacilitydata();
      else searchFacility(query, selectedpage);
    }
  }, [showPopup, loggedusertoken, selectedpage, query, refreshData, hasMore]);

  useEffect(() => {
    if (query == "") {
      settotalpages(0);
      setselectedpage(1);
      setHasMore(true);
    }
  }, [query]);

  

  useEffect(() => {
    if (facilityguid != "") setShowPopup(true);
  }, [facilityguid]);

  /*  const spinnerelements=document.getElementById('spinner')
  useEffect(()=>{
    if(spinnerelements!=null)
      {
     
      totalpages >= (selectedpage + 1) ? setHasMore(true) : setHasMore(false)
      totalpages > selectedpage && setselectedpage(prevPage => prevPage + 1) 
      }
  },[spinnerelements]) */

  const pageselected = () => {
    totalpages >= selectedpage + 1 ? setHasMore(true) : setHasMore(false);
    totalpages > selectedpage && setselectedpage((prevPage) => prevPage + 1);
  };

  /*  const pageselecteds = () => {
    totalpages >= (selectedpage + 1) ? setHasMore(true) : setHasMore(false)
    totalpages > selectedpage && setselectedpage(prevPage => prevPage + 1) 
  } */

  const toggleHandler = () => {
    setView(false);
    /*  setselectedpage(1)
     settotalpages(0) */
  };

  const toggleHandler1 = () => {
    setView(true);
    /*  setselectedpage(1) */
  };

  const handleChangeQuery = (event) => {
    const querys = event.target.value;
    setQuery(querys.trim());
    if (querys.trim() === "") {
      //setCardData(suggestedProducts);
      settotalpages(Math.ceil(searchfacility.length / 16));
    } else {
      var demo = [];
      searchFacility(event.target.value.trim(), pageselected);
      searchfacility.map(
        (product, index) =>
          product.facility_name.toLowerCase().includes(querys.toLowerCase()) &&
          demo.push(product)
      );
      settotalpages(Math.ceil(demo.length / 16));
    }
  };

  const editfacility = (card) => {
    setShowPopup(true);
    setfacilityguid(card.facility_guid);
  };

  const setUserManagementData = (card) => {
    dispath(addFacilityName(card.facility_name));
    dispath(addFacilityID(card.facility_guid));
    dispath(addFacilityEmail(card.facility_email));
    dispath(addFacilityColor(card.facility_color));
    dispath(addFacilityLogo(card.facility_logo));
  };

  useEffect(() => {
    dispatch(setIsActiveData(false));
  });

  return (
    <>
    <div>
      {loggeduser.user_role === "SuperAdmin" && (
        <div id="SuperAdminDashbardDivmainContainer" className=" mx-auto px-4 py-2 my-[6rem] w-100% ">
          <div id="SuperAdminDashbardDivheader" className="block lg:flex justify-between items-center mb-5 ">
            <div id="SuperAdminDashbardDivheaderLeft" className="flex justify-between items-center lg:ml-4 mb-5 lg:mb-0 ">
              <div id="SuperAdminDashbardDivheaderTitle" className="flex font-bold text-[24px] text-black-800">
                {" "}
                <svg  id="SuperAdminDashbardSvgdashboardLogo"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  className="w-8 h-8 lg:w-10 lg:h-10"
                >
                  <path
                    d="M6.6665 22.6667H5.33317C4.62593 22.6667 3.94765 22.3857 3.44755 21.8856C2.94746 21.3855 2.6665 20.7072 2.6665 20V6.66667C2.6665 5.95942 2.94746 5.28115 3.44755 4.78105C3.94765 4.28095 4.62593 4 5.33317 4H26.6665C27.3737 4 28.052 4.28095 28.5521 4.78105C29.0522 5.28115 29.3332 5.95942 29.3332 6.66667V20C29.3332 20.7072 29.0522 21.3855 28.5521 21.8856C28.052 22.3857 27.3737 22.6667 26.6665 22.6667H25.3332"
                    stroke="#333333"
                    strokeWidth="1.42"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.0002 20L22.6668 28H9.3335L16.0002 20Z"
                    stroke="#333333"
                    strokeWidth="2.42"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <h1 id="SuperAdminDashbardH1title" className="mt-[-4px] lg:mt-0 ml-1 ">Dashboard</h1>
              </div>
              <div id="SuperAdminDashbardDivmobileButton" className="block lg:hidden items-center">
                <button   id="SuperAdminDashbardButtonaddFacilityMobile"
                  className="bg-red-700 hover:bg-red-800 text-white py-2 px-4 rounded-md flex items-center"
                  onClick={() => {
                    setShowPopup(true);
                    setfacilityguid("");
                  }}
                >
                  <svg id="SuperAdminDashbardSvgaddFacilityMobile"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6 mr-2"
                  >
                    <path d="M5.25 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM2.25 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM18.75 7.5a.75.75 0 0 0-1.5 0v2.25H15a.75.75 0 0 0 0 1.5h2.25v2.25a.75.75 0 0 0 1.5 0v-2.25H21a.75.75 0 0 0 0-1.5h-2.25V7.5Z" />
                  </svg>
                  Add New Facility
                </button>
                {showPopup && (
                  <AddFacility
                    isOpen={showPopup}
                    onClose={() => setShowPopup(false)}
                    facilityguid={facilityguid}
                    setRefreshData={setRefreshData}
                  />
                )}
              </div>
            </div>
            <div id="SuperAdminDashbardDivviewToggle" className="relative flex items-center justify-between gap-6">
              <div id="SuperAdminDashbardDivviewButtons" className="flex">
                <button id="SuperAdminDashbardButtongridView"
                  size="100%"
                  style={{ backgroundColor: "#DCE5EC" }}
                  className={
                    view == false
                      ? "border-solid border-2 border-gray-500"
                      : "border-none"
                  }
                  onClick={toggleHandler}
                >
                  <svg   id="SuperAdminDashbardSvggridViewIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 p-1 cursor-pointer"
                    style={
                      view === "grid" ? { backgroundColor: "#facc15" } : {}
                    }
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5"
                    />
                  </svg>
                </button>
                <button id="SuperAdminDashbardBtnlistView"
                  size="100%"
                  style={{ backgroundColor: "#DCE5EC" }}
                  className={
                    view == true
                      ? "border-solid border-2 border-gray-500"
                      : "border-none"
                  }
                  onClick={toggleHandler1}
                >
                  <svg id="SuperAdminDashbardBtnlistViewIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 p-1 cursor-pointer"
                    style={
                      view === "list" ? { backgroundColor: "#facc15" } : {}
                    }
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z"
                    />
                  </svg>
                </button>
              </div>
              <form className="lg:max-w-md lg:mx-auto">
                <label id="SuperAdminDashbardLabelsearchText"
                  htmlFor="default-search"
                  className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                >
                  Search
                </label>
                <div className="relative">
                  <input
                    className={
                      false
                        ? "w-full bg-white border-b-[1px] rounded-t-xl  py-2 pl-3 border-b-[] focus:outline-none"
                        : "w-full bg-white border border-slate-400 drop-shadow-xl rounded-md py-1 pl-3  focus:outline-none sm:py-2 "
                    }
                    placeholder={"Search Facility"}
                    type="text"
                    onChange={handleChangeQuery}
                    /*  onKeyDown={handleKeyDown} */
                    autoComplete="off"
                    id="SuperAdminDashbardInputsearchText"
                  />
                  <button 
                    aria-label="searchIcon"
                    id="SuperAdminDashbardBtnsearchIcon1"
                    className="absolute inset-y-0 right-0 flex items-center pr-[0.2rem] pt-[0.23rem] px-4 lg:px-0"
                  >
                    <div 
                      role="status"
                      id="SuperAdminDashbardsearchLoader"
                      style={{ display: "none" }}
                    >
                      <>
                        <svg id="SuperAdminDashbardSvgsearchLoader"
                          aria-hidden="true"
                          className="mr-3 w-6 h-6 text-yellow-400 animate-spin dark:text-yellow-400 fill-black"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />{" "}
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>{" "}
                        <span id="SuperAdminDashbardSpanloading" className="sr-only">Loading...</span>
                      </>
                    </div>

                    {/* <div className=' bg-yellow-400 w-2 h-2 mr-2 md:w-[1rem] md:h-[1rem] rounded-full'> */}
                    <svg id="SuperAdminDashbardSvgloadingIcon"
                      className="h-9 w-9 fill-blackrounded-full block m-auto relative xs:top-[0.2rem] md:top-[0.3rem]"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="60"
                      height="60"
                      viewBox="0 0 30 40"
                    >
                      <path d="M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z"></path>
                    </svg>
                  </button>
                </div>
              </form>
              <div className="hidden lg:flex items-center">
                <button id="SuperAdminDashbardBtnnewFacility"
                  className="bg-red-700 hover:bg-red-800 text-white py-2 px-4 rounded-md flex items-center"
                  onClick={() => {
                    setShowPopup(true);
                    setfacilityguid("");
                  }}
                >
                  <svg id="SuperAdminDashbardSvgnewFacilityIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6 mr-2"
                  >
                    <path d="M5.25 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM2.25 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM18.75 7.5a.75.75 0 0 0-1.5 0v2.25H15a.75.75 0 0 0 0 1.5h2.25v2.25a.75.75 0 0 0 1.5 0v-2.25H21a.75.75 0 0 0 0-1.5h-2.25V7.5Z" />
                  </svg>
                  Add New Facility
                </button>
              </div>
            </div>
          </div>
          {showPopup && (
            <AddFacility
              isOpen={showPopup}
              onClose={() => setShowPopup(false)}
              facilityguid={facilityguid}
              setRefreshData={setRefreshData}
            />
          )}

          <InfiniteScroll className='lg:!h-[100vh] !overflow-hidden xl:!h-max 2xl:!h-max'
            dataLength={cardData.length}
            next={pageselected}
            hasMore={hasMore} // Replace with a condition based on your data source
            loader={showSpinner ?  <Spinner /> : ""}
            style={{ overflow: "auto" }}
            scrollableTarget="scroll-container"
            scrollThreshold={0.9}
            /* endMessage={<p>No more data to load.</p>} */
          >
            {view == true ? (
              <>
                <div id="SuperAdminDashbardDivtableContainer" className="w-[90vw] overflow-auto xl:h-[100vh]">
                  <table  id="SuperAdminDashbardTablefacilities" className="table-auto w-full border rounded-md focus:outline-none">
                    <thead id="SuperAdminDashbardTableHead">
                      <tr id="SuperAdminDashbardTableheaderRow" className="px-5 py-2 text-left">
                        <th id="SuperAdminDashbardTableheaderFacilityName" className="px-5 py-2 text-left">Facility Name</th>
                        <th id="SuperAdminDashbardTableheaderFacilityType" className="px-2 py-2 text-left ">Facility Type</th>
                        <th id="SuperAdminDashbardTableemail" className="px-2 py-2 text-left ">Email</th>
                        <th id="SuperAdminDashbardTablephone" className="px-2 py-2 text-left ">Phone</th>
                        <th id="SuperAdminDashbardTableaddress" className="px-2 py-2 text-left ">Address</th>
                        <th id="SuperAdminDashbardTableedit" className="px-2 py-2 text-left ">Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cardData.map((card, index) => (
                        <tr
                          key={"app-table-" + index}
                          className="odd:bg-white even:bg-gray-100 cursor-pointer"
                        >
                          <td id="SuperAdminDashbardTdtableFacilityName" className="px-5 py-2 text-left">
                            <Link id="SuperAdminDashbardLinkuserManagement"
                              to="/user-management"
                              onClick={() => {
                                setUserManagementData(card);
                              }}
                            >
                              {card.facility_name}
                            </Link>
                          </td>
                          <td id="SuperAdminDashbardTdtableFacilityAbout" className="px-2 py-2 text-left">
                            <Link id="SuperAdminDashbardLinkaboutUserManagement"
                              to="/user-management"
                              onClick={() => {
                                setUserManagementData(card);
                              }}
                            >
                              {card.facility_about}
                            </Link>
                          </td>
                          <td id="SuperAdminDashbardTdtableFacilityEmail" className="px-2 py-2 text-left">
                            <Link id="SuperAdminDashbardLinkemailUserManagement"
                              to="/user-management"
                              onClick={() => {
                                setUserManagementData(card);
                              }}
                            >
                              {card.facility_email}
                            </Link>
                          </td>
                          <td id="SuperAdminDashbardTdtableFacilityPhone" className="px-2 py-2 text-left">
                            <Link id="SuperAdminDashbardLinkphoneUserManagement"
                              to="/user-management"
                              onClick={() => {
                                setUserManagementData(card);
                              }}
                            >
                              {card.facility_phone}
                            </Link>
                          </td>
                          <td id="SuperAdminDashbardTdtableFacilityAddress" className="px-2 py-2 text-left">
                            <Link id="SuperAdminDashbardLinkaddressUserManagement"
                              to="/user-management"
                              onClick={() => {
                                setUserManagementData(card);
                              }}
                            >
                              {card.facility_address}
                            </Link>
                          </td>
                          <td id="SuperAdminDashbardTdtableEditFacility" className="px-2 py-2 text-center">
                            <button id="SuperAdminDashbardBtneditFacility" onClick={() => editfacility(card)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="w-5 h-5 text-blue-600"
                              >
                                <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z" />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <div id="SuperAdminDashbardDivcontainer" className="container mx-auto py-4 lg:py-8">
                <div id="SuperAdminDashbardDivgridContainer" className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                  {cardData.map((card, index) => (
                    <div
                      key={index}
                      className={`bg-white rounded-lg p-6 shadow-md flex h-auto w-[100%]`}
                    >
                      <div id="SuperAdminDashbardDivcardContent" className="w-[90%] flex">
                        <div
                          className="flex md:pr-2"
                          style={{ paddingRight: "0.5rem" }}
                        >
                          {card.facility_logo ? (
                            <div id="SuperAdminDashbardDivcardLogo"
                              style={{
                                backgroundColor: "#d3d3d3",
                                width: "40px",
                                height: "40px",
                                borderRadius: "8px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "relative",
                              }}
                            >
                              <img id="SuperAdminDashbardImgfacilityLogo"
                                height={"24"}
                                width={"24"}
                                src={
                                  process.env.REACT_APP_EMRAXIS_S3_URL +
                                  "/" +
                                  card.facility_logo
                                }
                                alt={card.facility_name}
                              />
                            </div>
                          ) : (
                            <div   id="SuperAdminDashbardDivcardFallbackLogo"
                              style={{
                                backgroundColor:
                                  card.facility_color !== null
                                    ? card.facility_color
                                    : "orange",
                                width: "40px",
                                height: "40px",
                                borderRadius: "8px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "relative",
                              }}
                            >
                              <FaHouseMedical id="SuperAdminDashbardFaHouseIcon"
                                style={{ color: "white", fontSize: "24px" }}
                              />
                              <div id="SuperAdminDashbardDivplusIcon"
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  color:
                                    card.facility_color !== null
                                      ? card.facility_color
                                      : "orange",
                                  fontSize: "10px",
                                }}
                              >
                                +
                              </div>
                            </div>
                          )}
                        </div>
                        <Link id="SuperAdminDashbardLinkUser-Management"
                          to="/user-management"
                          onClick={() => {
                            setUserManagementData(card);
                            dispatch(setIsDesktopUserScreenOpen(true));
                            dispatch(setIsSuperAdminDropdownOpen(true));
                            facilityDataForSuperAdmin(card);
                          }}
                        >
                          <div id="SuperAdminDashbardDivcardFacilityName"
                            className="flex flex-col justify-between font-bold"
                            style={{ lineHeight: "1" }}
                          >
                            {card.facility_name}

                            <p id="SuperAdminDashbardPcardFacilityAbout" className="text-gray-500 mt-1">
                              {card.facility_about}
                            </p>
                          </div>
                          <div className="mt-2" style={{ lineHeight: "1" }}>
                            <div className={`flex text-blue-500 `}>
                              {card.facility_address !== null && (
                                <img id="SuperAdminDashbardImgcardFacilityAddress"
                                  src="../../images/mapPin.png"
                                  alt="location"
                                  className="inline-block mr-2"
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    objectFit: "cover",
                                  }}
                                />
                              )}
                              <p id="SuperAdminDashbardPcardFacilityAddress" className="break-all">
                                {card.facility_address}
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
 
                      <div id="SuperAdminDashbardDiveditCardFacility"
                        className="w-[10%] cursor-pointer"
                        onClick={() => editfacility(card)}
                      >
                        <svg id="SuperAdminDashbardSvgeditCardFacilityIcon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-4 h-4 text-blue-600"
                        >
                          <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z" />
                        </svg>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </InfiniteScroll>
          {isActiveData && (
            <AdminFeature
              facilityDataForSuperAdmin={facilityDataForSuperAdmin}
            />
          )}
        </div>
      )}
      {toast.show && (
				<SnackBarComponent
				message={toast.message}
				type={toast.type}
				onClose={() => setToast({ show: false, message: '', type: '' })}
				/>
			)}
      </div>
    </>
  );
}
export default SuperAdminDashboard;
