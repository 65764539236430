import React, { useState, useEffect } from "react";
import PhoneInput from "../PhoneInput";
import Select from "react-select";
import store from "../../API/store";
import { useSelector, useDispatch } from "react-redux";
import DataDecode from "../../utils/DataDecode";
import "react-datepicker/dist/react-datepicker.css";
import { setNotification } from "../../Redux/features/toast/toastSlice";
import "react-phone-input-2/lib/style.css";
import { theme } from "../../EmraxisTheme/Theme";
import SnackBarComponent from "../../components/SnackBarComponent/SnackBarComponent"

const AddMembers = ({ isOpen, onClose, facility, formstatus, userdata, page }) => {
  var loggedusertoken = useSelector((state) => state.login.userBarerToken);
  var loggeduser = useSelector((state) => state.login.loginuserData);
  var facilityName = useSelector((state) => state.facility.facility_name);
  var facilityEmail = useSelector((state) => state.facility.facility_email);
  const previewTheme =
    useSelector((state) => state.theme.colors) || theme.defaultTheme;

  var facilityGuid = useSelector((state) => state.facility.facility_id);
  const { facility_user, user_email, user_role, user_phone } = useSelector(
    (state) => state.users
  );
  const [phone, setPhone] = useState("");
  const [countryCodeDial, setCountryCodeDial] = useState("");
  const [phNumberLength, setPhNumberLength] = useState(0);
  const [phoneErr, setPhoneErr] = useState({
    pageEmpty: false,
    phoneValidation: false,
  });
  const [errFlag, setErrFlag] = useState(false);
  const [emailErr, setEmailErr] = useState({
    emailEmpty: false,
    emailValidation: false,
  });
  const [errEmailFlag, setEmailErrFlag] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alerType, setAlertType] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [userRole, setUserRole] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [userDetails, setUserDetails] = useState({
    Username: formstatus == "edit" ? facility_user : "",
    UserEmailId:facilityEmail,
      // formstatus == "edit"
      //   ? user_email
      //   : facility != null
      //   ? facility.email_id
      //   : facilityEmail != ""
      //   ? facilityEmail
      //   : loggeduser.email_id,
      Facilityname:facilityName,
      // facility != null
      //   ? facility.facility_name
      //   : facilityName != ""
      //   ? facilityName
      //   : loggeduser.facility_name,
    UserMobilenumber: formstatus == "edit" ? user_phone : "",
    Userrole:
      formstatus == "edit" ? user_role : facility != null ? "Admin" : "",
    Usersby:
      loggeduser.user_role == "SuperAdmin"
        ? "SuperAdmin"
        : loggeduser.user_name,
  });
  const [facilitydata, setfacilitydata] = useState({
    clinic_guid: facilityGuid != "" ? facilityGuid : loggeduser.facilityID,
  });
  const [loading, setLoading] = useState(false);
  const [roleErr, setroleErr] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [usernames, setusernames] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [toast, setToast] = useState({ show: false, message: '', type: '' });
  const dispatch = useDispatch();

  const showToast = () => {
	
    setTimeout(() => {
      setToast({ show: false, message: '', type: '' });
    }, 3000);
  };
 useEffect(() => {
    const fetchUserRole = async () => {
      await store
        .GetUserRole(loggedusertoken)
        .then((result) => {
          result.json().then((res) => {
            setUserRole(res);
          });
        })
        .catch((err) => {
            setToast({ show: true, message: "Error occured while Getting the User Roles", type: "error" });
            showToast();
            setLoading(false);
           
          });
    };

    if (loggedusertoken != null) {
      fetchUserRole();
    }
  }, [loggedusertoken]);

  useEffect(() => {
    const fetchUser = async () => {
      await store
        .FetchUsers(loggedusertoken)
        .then((result) => {
          if (result.status == 200) {
            result.json().then((res) => {
              let response = DataDecode.decryptResponse(res);
              var myObject = JSON.parse(response);
              setusernames(myObject);
            });
          }
        })
        .catch((err) => {
            setToast({ show: true, message: "Error occured while Getting the Users", type: "error" });
            showToast();
            setLoading(false);
           
          }); 
    };
    if (loggedusertoken != null) {
      fetchUser();
    }
  }, [loggedusertoken]);

  if (!isOpen) onClose();

  var jsonProduct = userRole?.map(function (val) {
    return {
      value: val.user_role_id,
      label: val.user_role_name,
    };
  });
  const customTheme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: "#1976d2", // Background color of the selected option
    },
  });
  const onBlurEmail = () => {
    EmailValidation();
  };

  const EmailValidation = () => {
    let err = false;
    var emails = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    if (userDetails.UserEmailId === "") {
      if (
        userDetails.UserEmailId === "" ||
        userDetails.UserEmailId === undefined ||
        userDetails.UserEmailId === null
      ) {
        setEmailErr({ ...emailErr, emailEmpty: true, emailValidation: false });
        err = true;
      } else if (userDetails.UserEmailId.match(emails)) {
        setEmailErr({ ...emailErr, emailValidation: false, emailEmpty: false });
        err = false;
      } else {
        setEmailErr({ ...emailErr, emailValidation: true, emailEmpty: false });
        err = true;
      }
    } else {
      if (userDetails.UserEmailId.match(emails)) {
        setEmailErr({ ...emailErr, emailValidation: false, emailEmpty: false });
        err = false;
      } else {
        setEmailErr({ ...emailErr, emailValidation: true, emailEmpty: false });
        err = true;
      }
    }
    setEmailErrFlag(err);
  };
  let Phone;

  const onBlurPhone = () => {
    PhoneValidation();
  };
  const onBlurRole = () => {
    if (
      userDetails.Userrole === "" ||
      userDetails.Userrole === undefined ||
      userDetails.Userrole === null
    ) {
      setroleErr(true);
    } else {
      setroleErr(false);
    }
  };

  const onPhonechange = (value, data) => {
    let dots = data.format.split("");
    let filtred = dots.filter((val, index) => val === ".");
    if (data.countryCode === "ae") {
      setPhNumberLength(13);
    } else if (filtred.length >= 15) {
      setPhNumberLength(15);
    } else {
      setPhNumberLength(filtred.length);
    }
    setPhoneErr({ ...phoneErr, pageEmpty: false, phoneValidation: false });
    setPhone(value);
    setCountryCodeDial(data.dialCode);
    setUserDetails({
      ...userDetails,
      UserMobilenumber: value,
    });
  };
  const PhoneValidation = () => {
    let err = false;
    if (phone === "" || phone === undefined) {
      setPhoneErr({ ...phoneErr, pageEmpty: true, phoneValidation: false });
      err = true;
    }
    // setErrFlag(true);
  };

  Phone = (
    <PhoneInput
      phone={userDetails.UserMobilenumber}
      setPhone={setPhone}
      onPhonechange={onPhonechange}
      onBlurPhone={onBlurPhone}
    />
  );
  const onBlurName = () => {
    if (
      userDetails.Username === "" ||
      userDetails.Username === undefined ||
      userDetails.Username === null
    ) {
      setNameErr(true);
    } else {
      setNameErr(false);
    }
  };

  const clicksave = async () => {
    userDetails.phone = phone;
    userDetails.Userrole === "" ||
    userDetails.Userrole === undefined ||
    userDetails.Userrole === null
      ? setroleErr(true)
      : setroleErr(false);
    userDetails.Username === "" ? setNameErr(true) : setNameErr(false);
    const found = usernames.find(
      (x) => x.toLowerCase() === userDetails.Username.toLowerCase()
    );
    EmailValidation();
    if (formstatus == "edit") {
      //adding save logic here
      if (userDetails.Userrole != "" && roleErr == false) {
        setDisableButton(true);
        await store
          .SaveUserDetails(
            loggedusertoken,
            DataDecode.encryptPayload({
              userDetails: userDetails,
              facility: facility == null ? facilitydata : facility,
            })
          )
          .then((result) => {
            if (result.status == 200) {
              setDisableButton(false);
              if(page)
                store.CreateActivityLogs(loggeduser, page, `User Updated - for ${userDetails.Username} with role ${userDetails.Userrole}`, "Update");
              dispatch(
                setNotification({
                  message: "User Updated Successfully!",
                  status: "success",
                  action: true,
                })
              );
              onClose();
            } else {
              dispatch(
                setNotification({
                  message: "Error Occured!",
                  status: "error",
                  action: true,
                })
              );
            }
          })
          .catch((err) => {
            setToast({ show: true, message: "Error occured while saving UserRoles", type: "error" });
            showToast();
            setLoading(false);
           
          }); 
         
      }
    } else {
      if (
        nameErr == false &&
        errFlag == false &&
        errEmailFlag == false &&
        found == undefined &&
        userDetails.Userrole != ""
      ) {
        setDisableButton(true);
        await store
          .InviteFacilitybySuperAdmin(
            loggedusertoken,
            DataDecode.encryptPayload({
              userDetails: userDetails,
              facility: facility == null ? facilitydata : facility,
            })
          )
          .then((result) => {
            if (result.status == 201) {
              setDisableButton(false);
              if(page)
                store.CreateActivityLogs(loggeduser, page, `User Created - for ${userDetails.Username} with role ${userDetails.Userrole}`, "Create");
              dispatch(
                setNotification({
                  message: "User Created Successfully!",
                  status: "success",
                  action: true,
                })
              );
              onClose();
            } else {
              dispatch(
                setNotification({
                  message: "Error Occured!",
                  status: "error",
                  action: true,
                })
              );
            }
          })
          .catch((err) => {
            setToast({ show: true, message: "Error occured while saving UserRoles", type: "error" });
            showToast();
            setLoading(false);
            
          });
      } else {
        /*  setShowAlert(true);
                 setAlertType('error');
                 setAlertMessage(f); */
        dispatch(
          setNotification({
            message:
              found != undefined && userDetails.Username !== ""
                ? "UserName Exist"
                : "Please fill mandatory fields!",
            status: "error",
            action: true,
          })
        );
      }
    }
  };

  function clickcancel(event) {
    event.preventDefault();
    onClose();
  }

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Tailwind's `md` breakpoint is 768px
    };

    handleResize(); // Set initial value
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
    <div>
    
      {/*  <ToastMessage type={alerType} message={alertMessage} show={showAlert} setShow={setShowAlert} />  */}
      <div className="fixed inset-0 z-50 flex justify-center items-center p-8">
        <div className="absolute inset-0 bg-black opacity-25"></div>
        <div
          className="relative bg-white shadow-xl p-8 "
          style={{ width: "700px" }}
        >
          <div className="flex justify-between items-center mb-6">
            <div id="AddMembersDivinvteUser"
              className={`text-black font-bold text-2xl  ${previewTheme.headingTitleColor}`}
              style={{ fontFamily: previewTheme.fontFamily }}
            >
              Invite User
            </div>
            <button id="AddMembersBtnclose"
              className={`text-red-700 sm:justify-left`}
              onClick={onClose}
            >
              <svg id="AddMemberssvgcloseIcon"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className={`h-10 w-10 ${previewTheme.popupCloseBtnColor} transition-colors duration-300 ease-in-out hover:${previewTheme.popupCloseBtnHoverColor}`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </button>
          </div>
          <form>
            <div className="grid lg:grid-cols-2 xs:grid-cols-1 lg:gap-4 xs:gap-0.5">
              <div className="sm:mb-4 sm:p-2 xxs:mb-2 xxs:p-1">
                <label id="AddMembersLabelname" className="font-sans text-[15px] sm:text-[16px] font-semibold">
                  Name
                </label>{" "}
                <span className="text-red-500 text-xl font-semibold">*</span>
                <div className="relative">
                  <input
                    type="text"
                    id="AddMembersUsername"
                    name="InputUsername"
                    value={userDetails.Username}
                    onChange={(e) => {
                      formstatus != "edit" &&
                        setUserDetails({
                          ...userDetails,
                          Username: e.target.value,
                        });
                    }}
                    className="mt-1 sm:mb-0 block w-full px-[14px] py-[7px] bg-white border border-[#CACACA] 
                                        rounded  shadow-sm placeholder-[#B7B7B7] focus:outline-none focus:border-[#1976d2] focus:ring-1 focus:ring-[#1976d2] hover:border-black"
                  />
                </div>
              </div>
              <div className="sm:mb-4 sm:p-2 xxs:mb-2 xxs:p-0.5">
                <label id="AddMembersLabelfacility" className="font-sans text-[15px] sm:text-[16px] font-semibold">
                  Facility
                </label>{" "}
                <span className="text-red-500 text-xl font-semibold">*</span>
                <input
                  type="text"
                  id="AddMembersFacilityname"
                  name="InputFacilityname"
                  disabled
                  value={userDetails.Facilityname}
                  onChange={(e) => {
                    formstatus != "edit" &&
                      setUserDetails({
                        ...userDetails,
                        Facilityname: e.target.value,
                      });
                  }}
                  className="mt-1 sm:mb-0 block w-full px-[14px] py-[7px] bg-white border border-[#CACACA] 
                                        rounded  shadow-sm placeholder-[#B7B7B7] focus:outline-none focus:border-[#1976d2] focus:ring-1 focus:ring-[#1976d2] hover:border-black"
                />
              </div>
            </div>

            <div className="grid lg:grid-cols-2 xs:grid-cols-1 lg:gap-4 xs:gap-1">
              <div className="sm:mb-4 sm:p-2 xxs:mb-2 xxs:p-0.5">
                <label id="AddMembersLabelmobile" className="font-sans text-[15px] sm:text-[16px] font-semibold">
                  Mobile
                </label>{" "}
                <span className="text-red-500 text-xl font-semibold"></span>
                {Phone}
                {/*  {phoneErr.pageEmpty && (<p className='text-red-500 text-sm'>Mobile Number is Required</p>)} */}
                {phoneErr.phoneValidation && (
                  <p id="AddMembersPerrorMobNumber" className="text-red-500 text-sm">
                    Mobile Number is not Valid
                  </p>
                )}
              </div>
              <div className="sm:mb-4 sm:p-2 xxs:mb-2 xxs:p-0.5">
                <label id="AddMembersLabelemail" className="font-sans text-[15px] sm:text-[16px] font-semibold">
                  Email
                </label>{" "}
                <span id="AddMembersSpanemailRequired" className="text-red-500 text-xl font-semibold">*</span>
                <div className="relative">
                <input
                  type="email"
                  name="UserEmailId"
                  id="AddMembersUserEmailId"
                  value={userDetails.UserEmailId}
                  placeholder="Enter your Email Address"
                  onBlur={onBlurEmail}
                  onChange={(e) => {
                    formstatus != "edit" &&
                      setUserDetails({
                        ...userDetails,
                        UserEmailId: e.target.value,
                      });
                    setEmailErr({
                      ...emailErr,
                      emailValidation: false,
                      emailEmpty: false,
                    });
                  }}
                  className="block w-full border rounded-md focus:outline-none focus:border-gray-500 pr-10" />
                 <svg id="AddMembersSvgemailIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 absolute top-1/2 right-3 -translate-y-1/2 text-gray-500">
                                    <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                                    <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                                </svg>
                {emailErr.emailEmpty && (
                  <p id="AddMembersPerrorEmail" className="text-red-500 text-sm" >
                    Email ID is Required
                  </p>
                )}
                {emailErr.emailValidation && (
                  <p className="text-red-500 text-sm" id="AddMembersPinvalidemailerror">
                    Please enter Eg:(abc@xyzcorp.com)
                  </p>
                )}
              </div>
            </div>
            </div>
            {facility == null && (
              <div className="grid lg:grid-cols-2 xs:grid-cols-1 lg:gap-4 xs:gap-1">
                <div className="sm:mb-4 sm:p-2 xxs:mb-2 xxs:p-1">
                  <label id="AddMembersLabeluserName" className="font-sans text-[15px] sm:text-[16px] font-semibold">
                    User Role
                  </label>{" "}
                  <span id="AddMembersSpanemailrequired" className="text-red-500 text-xl font-semibold">*</span>
                  <Select id="AddMembersSelectrole"
                    isClearable={false}
                    isSearchable={false}
                    
                    options={jsonProduct.filter(
                      (val) => val.label !== "SuperAdmin"
                    )}
                    onChange={(e) => {
                      setroleErr(false);
                      if (formstatus == "edit") {
                        //check for admin is present
                        if (e.label != "Admin") {
                          let demo = [];
                          for (let i = 0; i < userdata.length; i++) {
                            if (userdata[i].user_role == "Admin") {
                              demo.push(userdata[i]);
                            }
                          }
                          if (demo.length > 1) {
                            setUserDetails({
                              ...userDetails,
                              Userrole: e.label,
                            });
                            setroleErr(false);
                          } else {
                            if (demo.length == 1) {
                              if (demo[0].user_name == userDetails.Username) {
                                setroleErr(true);
                                dispatch(
                                  setNotification({
                                    message: "Admin Role not able to edit!",
                                    status: "error",
                                    action: true,
                                  })
                                );
                              } else {
                                setUserDetails({
                                  ...userDetails,
                                  Userrole: e.label,
                                });
                                setroleErr(false);
                              }
                            }
                          }
                        }
                      } else
                        setUserDetails({ ...userDetails, Userrole: e.label });
                      setroleErr(false);
                    }}
                    placeholder={userDetails.Userrole}
                    theme={customTheme}
                    menuPlacement={isSmallScreen ? "top" : "auto"}
                  />
                  {roleErr && (
                    <p  className="text-red-500 text-sm" id="AddMembersProleerror">
                      Role is Required
                    </p>
                  )}
                </div>
              </div>
            )}
          </form>
          <div className="flex justify-between space-x-4">
            <button id="AddMembersBtncancel"
              disabled={disableButton}
              className={`px-4 disabled:cursor-not-allowed disabled:opacity-50 py-2 border rounded-md text-white transition-colors duration-300 ease-in-out ${previewTheme.cancelBtnColor} ${previewTheme.cancelBtnBgColor} hover:${previewTheme.cancelBtnHoverColor}`}
              onClick={(event) => clickcancel(event)}
            >
              Cancel
            </button>

            <button id="AddMembersBtnsave"
              disabled={disableButton}
              className={`px-4 disabled:cursor-not-allowed disabled:opacity-50 py-2 border rounded-md text-white transition-colors duration-300 ease-in-out ${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} hover:${previewTheme.saveBtnHoverColor}`}
              onClick={(event) => clicksave(event)}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      {toast.show && (
				<SnackBarComponent
				message={toast.message}
				type={toast.type}
				onClose={() => setToast({ show: false, message: '', type: '' })}
				/>
			)}
    </div>
    </>
  );
};

export default AddMembers;
