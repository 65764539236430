import React from "react";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

function PhoneInput(props) {
  const { phone, onPhonechange, onBlurPhone } = props;
  return (
    <div style={{ zIndex: "10" }}>
      <ReactPhoneInput
        country="in"
        onlyCountries={["in"]}
        countryCodeEditable={false}
        disableCountryCode={false}
            disableDropdown={true}
        placeholder="Enter Phone Number"
        fullWidth={true}
        specialLabel={""}
        inputStyle={{
          width: "100%",
          height: "40px",
          borderRadius: "5px",
        }}
        onChange={onPhonechange}
        value={phone ? phone : ""}
        onBlur={onBlurPhone}
        enableSearch={false}
      />
    </div>
  );
}
export default PhoneInput;
