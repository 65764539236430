import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const InvoiceItemDatePicker = ({ handleDateChange, rowData }) => {
    const [selectedDate, setSelectedDate] = useState();

    useEffect(() => {
        if (rowData.date) {
            setSelectedDate(new Date(rowData.date));
        }
    }, [rowData])

    return (
        <div className="relative">
            <DatePicker id='DatePicker'
                selected={selectedDate}
                onKeyDown={(e) => e.preventDefault()}
                onChange={(date) => { handleDateChange(date, rowData.order_id) }}
                maxDate={new Date()}
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={100}
                showMonthDropdown
                scrollableMonthYearDropdown
                className='h-8 rounded-md pl-1 w-full'
                dateFormat="dd-MM-yyyy"
            />
        </div>
    )
}

export default InvoiceItemDatePicker