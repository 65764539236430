import { createSlice } from '@reduxjs/toolkit';
import { theme } from '../../../EmraxisTheme/Theme';
 
const initialState = {
  colors: theme.defaultTheme,
  fontFamily: '',

};
 
const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state.colors = action.payload;
    },
    setFont: (state, action) => {
      state.fontFamily = action.payload;
    },
    // saveTheme: (state, action) => {
    //   state.colors = action.payload;
    // },
  },
});
 
export const { setTheme, setFont } = themeSlice.actions;
export default themeSlice.reducer;