import React, { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import DataDecode from '../../../../utils/DataDecode';
import store from '../../../../API/store';
import { setNotification } from '../../../../Redux/features/toast/toastSlice';
import { setDocumentType, setIsCameraUpload, setRefreshDataAvailable, setRefreshLabNotes, setRefreshNoAppointmentUploadedData, setRefreshNotes, setRefreshPrescription, setRefreshRadiologyNotes, setRefreshUploadedFiles, setShowDocumentUploadPopup, setShowUploadPopup } from '../../../../Redux/features/uploadDoc/uploadDocSlice';
import { Camera } from "react-camera-pro";
import DateFunction from '../../../../utils/DateFunctions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import FileIcon from '../FileIcon';
import { theme } from '../../../../EmraxisTheme/Theme';

const UploadDocument = ({ patient_id, appointment_id, appointment, patient, page }) => {

    var loggeduser = useSelector(state => state.login.loginuserData);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    var documentType = useSelector(state => state.document_upload.documentType);
    var refreshNotes = useSelector(state => state.document_upload.refreshNotes);
    var refreshLabNotes = useSelector(state => state.document_upload.refreshLabNotes);
    var refreshRadiologyNotes = useSelector(state => state.document_upload.refreshRadiologyNotes);
    var refreshPrescription = useSelector(state => state.document_upload.refreshPrescription);
    var refreshUploadedFiles = useSelector(state => state.document_upload.refreshUploadedFiles);
    var isCameraUpload = useSelector(state => state.document_upload.isCameraUpload);
    var refreshNoAppointmentUploadedData = useSelector(state => state.document_upload.refreshNoAppointmentUploadedData);
    var refreshDataAvailable = useSelector(state => state.document_upload.refreshDataAvailable);
    var isWithoutAppointment = useSelector(state => state.document_upload.isWithoutAppointment);
    const previewTheme = useSelector(state => state.theme.colors) || theme.filter(a => a.name === "defaultTheme")[0];
    const dispatch = useDispatch();

    const cameraRef = useRef(null);
    const [numberOfCameras, setNumberOfCameras] = useState(0);

    const [file, setFile] = useState(null);
    const [loadingAddImage, setLoadingAddImage] = useState(false);

    const [loading, setLoading] = useState(false);
    const [confirmBox, setConfirmBox] = useState(false);
    const [selectedDocs, setSelectedDocs] = useState(Object.fromEntries(Object.entries(documentType).filter(([key, value]) => value)));

    const handleCameraReady = () => {
        setLoading(false);
    };

    const handleClose = () => {
        dispatch(setShowDocumentUploadPopup(false))
        dispatch(setShowUploadPopup(false))
        dispatch(setIsCameraUpload(false));
        setFile(null);
        dispatch(setDocumentType({
            doctor_note: false,
            prescription: false,
            lab_reports: false,
            x_ray: false,
            others: false
        }))
        if (isCameraUpload) {
            cameraRef.current = null;
        }
    }
    const handleCleanup = () => {
        setFile(null);
        if (isCameraUpload) {
            cameraRef.current = null;
        }
    }

    const onUploadFiles = async (type) => {
       if (file) {
            const formData = new FormData();
            if (type === "capture") {
                const blob = await fetch(file).then((res) => res.blob());
                formData.append('files', blob, DateFunction.GetDateUID() + '.jpg');
            } else {
                formData.append("files", file);
            }
           
            const topDoc = Object.entries(selectedDocs).slice(0, 1);
            const topDocObj = Object.fromEntries(topDoc);
            
            let otherData = JSON.parse(DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, 'patient_id': patient_id, 'appointment_id': appointment_id,  'document_type': topDocObj, created_on: DateFunction.SetDbCurrentTimeStamp() }))
            setTimeout(() => {
                formData.append('iv', otherData.iv);
                formData.append('payload', otherData.payload);
                setLoadingAddImage(true)
                store.UploadPatientDocument(loggedusertoken, formData)
                    .then((result) => {
                        if (result.status == 201) {
                            dispatch(setRefreshDataAvailable(!refreshDataAvailable))
                            if (documentType.doctor_note) {
                                store.CreateActivityLogs(loggeduser, page, `Doctor Notes ${(isCameraUpload ? 'Capture Image': "File")} Uploaded - ${isWithoutAppointment ? `Without Appointment for patient ${patient.FirstName} ${patient.MiddleName?.charAt(0)} ${patient.LastName}(${patient?.patient_op_number})` : `${(appointment?.doctor_name ? "for " + appointment?.doctor_name?.trim():"")} with patient ${patient.FirstName} ${patient.MiddleName?.charAt(0)} ${patient.LastName}(${patient?.patient_op_number}) ${(appointment?.appointment_time ? " on " + DateFunction.GetDate(appointment?.appointment_time) : "")} ${(appointment?.app_time ? " at " + DateFunction.Convert12Hour(appointment?.app_time) : "")}`}`,(isCameraUpload ? 'Capture': "Upload"));
                                dispatch(setRefreshNotes(!refreshNotes))
                            }
                            if (documentType.prescription) {
                                store.CreateActivityLogs(loggeduser, page, `Prescription ${(isCameraUpload ? 'Capture Image': "File")} Uploaded - ${isWithoutAppointment ? `Without Appointment for patient ${patient.FirstName} ${patient.MiddleName?.charAt(0)} ${patient.LastName}(${patient?.patient_op_number})` : `${(appointment?.doctor_name ? "for " + appointment?.doctor_name?.trim():"")} with patient ${patient.FirstName} ${patient.MiddleName?.charAt(0)} ${patient.LastName}(${patient?.patient_op_number}) ${(appointment?.appointment_time ? " on " + DateFunction.GetDate(appointment?.appointment_time) : "")} ${(appointment?.app_time ? " at " + DateFunction.Convert12Hour(appointment?.app_time) : "")}`}`,(isCameraUpload ? 'Capture': "Upload"));
                                dispatch(setRefreshPrescription(!refreshPrescription))
                            }
                            if (documentType.lab_reports) {
                                store.CreateActivityLogs(loggeduser, page, `Lab Reports ${(isCameraUpload ? 'Capture Image': "File")} Uploaded - ${isWithoutAppointment ? `Without Appointment for patient ${patient.FirstName} ${patient.MiddleName?.charAt(0)} ${patient.LastName}(${patient?.patient_op_number})` : `${(appointment?.doctor_name ? "for " + appointment?.doctor_name?.trim():"")} with patient ${patient.FirstName} ${patient.MiddleName?.charAt(0)} ${patient.LastName}(${patient?.patient_op_number}) ${(appointment?.appointment_time ? " on " + DateFunction.GetDate(appointment?.appointment_time) : "")} ${(appointment?.app_time ? " at " + DateFunction.Convert12Hour(appointment?.app_time) : "")}`}`,(isCameraUpload ? 'Capture': "Upload"));
                                dispatch(setRefreshUploadedFiles(!refreshUploadedFiles))
                                dispatch(setRefreshLabNotes(!refreshLabNotes))
                            }
                            if (documentType.x_ray) {
                                store.CreateActivityLogs(loggeduser, page, `Radiology ${(isCameraUpload ? 'Capture Image': "File")} Uploaded - ${isWithoutAppointment ? `Without Appointment for patient ${patient.FirstName} ${patient.MiddleName?.charAt(0)} ${patient.LastName}(${patient?.patient_op_number})` : `${(appointment?.doctor_name ? "for " + appointment?.doctor_name?.trim():"")} with patient ${patient.FirstName} ${patient.MiddleName?.charAt(0)} ${patient.LastName}(${patient?.patient_op_number}) ${(appointment?.appointment_time ? " on " + DateFunction.GetDate(appointment?.appointment_time) : "")} ${(appointment?.app_time ? " at " + DateFunction.Convert12Hour(appointment?.app_time) : "")}`}`,(isCameraUpload ? 'Capture': "Upload"));
                                dispatch(setRefreshUploadedFiles(!refreshUploadedFiles))
                                dispatch(setRefreshRadiologyNotes(!refreshRadiologyNotes))
                            }
                            if (documentType.others) {
                                store.CreateActivityLogs(loggeduser, page, `Uploaded Data ${(isCameraUpload ? 'Capture Image': "File")} Uploaded - ${isWithoutAppointment ? `Without Appointment for patient ${patient.FirstName} ${patient.MiddleName?.charAt(0)} ${patient.LastName}(${patient?.patient_op_number})` : `${(appointment?.doctor_name ? "for " + appointment?.doctor_name?.trim():"")} with patient ${patient.FirstName} ${patient.MiddleName?.charAt(0)} ${patient.LastName}(${patient?.patient_op_number}) ${(appointment?.appointment_time ? " on " + DateFunction.GetDate(appointment?.appointment_time) : "")} ${(appointment?.app_time ? " at " + DateFunction.Convert12Hour(appointment?.app_time) : "")}`}`,(isCameraUpload ? 'Capture': "Upload"));
                                dispatch(setRefreshUploadedFiles(!refreshUploadedFiles))
                            }
                            setTimeout(() => {
                                if (Object.keys(selectedDocs).length > 1) {
                                    setSelectedDocs(Object.fromEntries(Object.entries(selectedDocs).slice(1)));
                                    handleCleanup();
                                    dispatch(setNotification({ message: 'Document Uploaded Successfully!', status: 'success', action: true }));
                                    setLoadingAddImage(false);
                                } else {
                                    handleClose();
                                    dispatch(setNotification({ message: 'Document Uploaded Successfully!', status: 'success', action: true }));
                                    setLoadingAddImage(false);
                                    dispatch(setRefreshNoAppointmentUploadedData(!refreshNoAppointmentUploadedData));
                                }
                            }, 200)
                        }
                        else {
                            dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                        }
                    })
                    .catch((error) => {
                        console.error("Error occurred while uploading file");
                    });
            }, 100)
        } else {
            dispatch(setNotification({ message: 'Please choose a file!', status: 'error', action: true }));
        }
    }

    const handleCaptureButton = (e) => {
        e.preventDefault();
        let imageBinary = cameraRef.current.takePhoto();
        setFile(imageBinary);
    };

    const handleRetake = (e) => {
        e.preventDefault();
        setFile(null);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFile(file);
        }
    };

    const handleClick = () => {
        document.getElementById('file-input').click();
    };

    return (
        <>
            {confirmBox &&
                <div className="fixed inset-0 flex justify-center items-center p-8 z-[999999]">
                    <div className="absolute inset-0 bg-black opacity-25 "></div>
                    <div className="relative bg-white shadow-xl rounded-sm" style={{ width: "400px" }} >
                        <div className='pl-8 pr-8'>
                            <p className="pt-2 text-lg font-semibold text-center">Your image will be deleted, are you sure you want to close?</p>
                            <div className="my-10 flex justify-center items-center">
                                <button
                                    className="mr-2 bg-red-600 py-1 px-3 text-white font-semibold rounded-sm shadow-md ring-red-800 ring-1 hover:bg-red-700"
                                    onClick={() => {
                                        setConfirmBox(false)
                                    }}>Cancel</button>
                                <button
                                    className="mr-2 bg-red-600 py-1 px-3 text-white font-semibold rounded-sm shadow-md ring-red-800 ring-1 hover:bg-red-700"
                                    onClick={() => {
                                        handleClose()
                                    }}>Proceed</button>
                            </div>
                        </div>

                    </div>
                </div>
            }
            {
                isCameraUpload ?
                    <>
                        <div className="fixed inset-0 flex justify-center items-center p-8 z-[999998]">
                            <div className="absolute inset-0 bg-black opacity-25 "></div>
                            <div className="relative max-h-[90vh] lg:max-h-[90vh] 2xl:max-h-[90vh] overflow-y-scroll bg-white shadow-xl rounded-sm " style={{ width: "500px" }} >
                                <div className="flex justify-between items-center mb-2">
                                    <div className="text-[#a31b1b] font-semibold m-2">Capture Image</div>
                                    <button className={`${previewTheme.popupCloseBtnColor} hover:${previewTheme.popupCloseBtnHoverColor} sm:justify-left m-2 z-[999999]`} onClick={() => file ? setConfirmBox(true) : handleClose()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                        </svg>
                                    </button>
                                </div>
                                <div className={"px-2 mx-2 flex justify-center items-center !rounded-lg " + (file ? "h-auto" : "h-[72vh]")}>
                                    {loading
                                        ?
                                        <LoadingSpinner text='Initializing Camera' />
                                        :
                                        <>
                                            {file ?
                                                <div className='text-center'>
                                                    <div className='flex items-center justify-center'>
                                                        <img src={file} alt="Captured" className='w-full h-auto' />
                                                    </div>
                                                </div>
                                                :
                                                <Camera
                                                    ref={cameraRef}
                                                    numberOfCamerasCallback={setNumberOfCameras}
                                                    facingMode="environment"
                                                />
                                            }
                                        </>}
                                </div>
                                {!loading &&
                                    <div className={`bottom-4 w-full`}>
                                        {!!file ? (
                                            <div className="flex justify-evenly items-center gap-4 my-2">
                                                <button
                                                    onClick={handleRetake}
                                                    disabled={loadingAddImage}
                                                    className="bg-red-600 hover:bg-red-700 text-white px-2 py-1 text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                                                >
                                                    Retake
                                                </button>
                                                <button
                                                    onClick={(e) => {
                                                        onUploadFiles('capture')
                                                    }}
                                                    type='button'
                                                    className={`bg-green-600 hover:bg-green-700 text-white px-2 py-1 text-sm disabled:opacity-50 disabled:cursor-not-allowed`}
                                                    disabled={loadingAddImage}
                                                >
                                                    Save
                                                    {loadingAddImage &&
                                                        <svg aria-hidden="true" role="status" className="inline w-4 h-4 ml-1 text-green-600 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                        </svg>
                                                    }
                                                </button>
                                            </div>
                                        ) : (
                                            <div className="flex justify-center items-center gap-4 w-full">
                                                <button
                                                    id="capture-camera-image-icon-button"
                                                    aria-label="capture-image"
                                                    className={`justify-self-center align-center p-2 !mr-2 !mt-2 z-[999] text-white rounded-full border-2 border-white bg-gray-700 opacity-50`}
                                                    onClick={handleCaptureButton}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-8">
                                                        <path d="M6 3a3 3 0 0 0-3 3v1.5a.75.75 0 0 0 1.5 0V6A1.5 1.5 0 0 1 6 4.5h1.5a.75.75 0 0 0 0-1.5H6ZM16.5 3a.75.75 0 0 0 0 1.5H18A1.5 1.5 0 0 1 19.5 6v1.5a.75.75 0 0 0 1.5 0V6a3 3 0 0 0-3-3h-1.5ZM12 8.25a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5ZM4.5 16.5a.75.75 0 0 0-1.5 0V18a3 3 0 0 0 3 3h1.5a.75.75 0 0 0 0-1.5H6A1.5 1.5 0 0 1 4.5 18v-1.5ZM21 16.5a.75.75 0 0 0-1.5 0V18a1.5 1.5 0 0 1-1.5 1.5h-1.5a.75.75 0 0 0 0 1.5H18a3 3 0 0 0 3-3v-1.5Z" />
                                                    </svg>

                                                </button>
                                                {numberOfCameras > 1 && (
                                                    <button
                                                        id="switch-camera-icon-button"
                                                        aria-label="switch-camera"
                                                        className={`justify-self-end align-center !right-[2%] !absolute z-[999]`}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            cameraRef.current.switchCamera();
                                                        }}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-8">
                                                            <path d="M12 9a3.75 3.75 0 1 0 0 7.5A3.75 3.75 0 0 0 12 9Z" />
                                                            <path fillRule="evenodd" d="M9.344 3.071a49.52 49.52 0 0 1 5.312 0c.967.052 1.83.585 2.332 1.39l.821 1.317c.24.383.645.643 1.11.71.386.054.77.113 1.152.177 1.432.239 2.429 1.493 2.429 2.909V18a3 3 0 0 1-3 3h-15a3 3 0 0 1-3-3V9.574c0-1.416.997-2.67 2.429-2.909.382-.064.766-.123 1.151-.178a1.56 1.56 0 0 0 1.11-.71l.822-1.315a2.942 2.942 0 0 1 2.332-1.39ZM6.75 12.75a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0Zm12-1.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clipRule="evenodd" />
                                                        </svg>

                                                    </button>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="fixed inset-0 flex justify-center items-center p-8 z-[999999]">
                            <div className="absolute inset-0 bg-black opacity-25 "></div>
                            <div className="relative bg-white shadow-xl rounded-sm" style={{ width: "600px" }} >
                                <div className="text-right">
                                    <button className={`${previewTheme.popupCloseBtnColor} hover:${previewTheme.popupCloseBtnHoverColor} sm:justify-left`} onClick={() => { handleClose(); }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-7 w-7 mr-2 mt-2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                        </svg>
                                    </button>
                                </div>

                                <div className='min-h-[200px] pl-8 pr-8 '>
                                    <span className='text-lg font-medium text-gray-700 mb-2'>Please upload {Object.keys(selectedDocs)[0]?.replace("_"," ")}</span>
                                    <div className="flex flex-col items-center border border-dashed border-green-400 p-2  rounded-md">
                                        <input
                                            id="file-input"
                                            type="file"

                                            onChange={handleFileChange}
                                            className="hidden"
                                        />

                                        <div
                                            onClick={handleClick}
                                            className=" flex justify-center items-center cursor-pointer my-2">
                                            <div>
                                                <img src="./images/upload-icon.png" className="w-16 h-auto" />
                                                <p className='text-center font-bold cursor-pointer'><span className='text-red-800'>Browse</span></p>
                                            </div>
                                        </div>
                                    </div>


                                    {file &&
                                        <div className='text-center'>
                                            <div className='flex items-center justify-center'>
                                                <a rel="noopener" target="_blank" href={URL.createObjectURL(file)} className='m-2 p-2'>
                                                    {file.type.startsWith('image/') ? (
                                                        <img className="max-h-[250px] w-auto h-auto object-contain" src={URL.createObjectURL(file)} alt={file.name} />
                                                    ) : file.type.startsWith('audio/') ? (
                                                        <audio className="max-h-[250px] w-auto" controls>
                                                            <source src={URL.createObjectURL(file)} type={file.type} />
                                                            Your browser does not support the audio element.
                                                        </audio>
                                                    ) : file.type.startsWith('video/') ? (
                                                        <video className="max-h-[250px] w-auto h-auto object-contain" controls>
                                                            <source src={URL.createObjectURL(file)} type={file.type} />
                                                            Your browser does not support the video element.
                                                        </video>
                                                    ) : (
                                                        <FileIcon ext={file.type} type="local" />
                                                    )}
                                                </a>
                                            </div>
                                            <button disabled={loadingAddImage} className={`${previewTheme.saveBtnColor} ${previewTheme.saveBtnBgColor} hover:${previewTheme.saveBtnHoverColor} transition-colors rounded-md p-2 m-3 disabled:cursor-not-allowed disabled:opacity-50`}
                                                onClick={(e) => {
                                                    onUploadFiles('upload')
                                                }}>Upload
                                                {loadingAddImage ?
                                                    <svg aria-hidden="true" role="status" className="inline w-4 h-4 ml-1 text-green-600 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                                    </svg>
                                                    :
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-4 ml-1 inline">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                                                    </svg>
                                                }
                                            </button>
                                        </div>
                                    }
                                </div>


                            </div>
                        </div>

                    </>
            }
        </>
    )
}

export default UploadDocument