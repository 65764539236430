import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs';
import Popover from "@mui/material/Popover";
import { Container, Grid } from "@mui/material";
import { setAppGridViewAppointmentData, setAppGridViewSelection } from '../../../Redux/features/appointmentGridView/gridviewSlice';
import { useDispatch } from 'react-redux';
import AppDetailsPopover from './AppDetailsPopover';
import { Tooltip } from "@mui/material";
import MonthPicker from './MonthPicker';
import MonthViewAppointment from './MonthViewAppointment';
import { setSideDateToShow } from '../../../Redux/features/appointmentGridView/gridViewTodaySlice';

const MonthView = ({ appointments, doctors, doctorsTimeslot, setRefreshData, deleteAppointment }) => {


  const [selectedDate, setSelectedDate] = useState(dayjs()); // Initial date
  const [calendarDays, setCalendarDays] = useState(getCalendarDays(dayjs()));
  const [currentMonthLbl, setCurrentMonthLbl] = useState("");
  const [dayMonthSelectRef, setDayMonthSelectRef] = useState(null);
  const [appointmentDetailRef, setAppointmentDetailRef] = useState(null);
  const [events, setEvents] = useState([]);

  const popoverTop = appointmentDetailRef?.getBoundingClientRect().top || 0;
  const popoverRight = appointmentDetailRef?.getBoundingClientRect().right || 0;

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const today = dayjs(); // Get today's day name (e.g., "Mon")

  // Function to get the days of the selected month
  function getCalendarDays(date) {
    const startOfMonth = date.startOf("month");
    const endOfMonth = date.endOf("month");
    const days = [];

    // Start from the Sunday of the first week of the selected month
    const startDay = startOfMonth.startOf("week");
    const endDay = endOfMonth.endOf("week");

    for (let day = startDay; day.isBefore(endDay) || day.isSame(endDay); day = day.add(1, "day")) {
      days.push(day);
    }

    return days;
  }

  // Helper function to group days into weeks
  const getWeeks = (days) => {
    const weeks = [];
    for (let i = 0; i < days.length; i += 7) {
      weeks.push(days.slice(i, i + 7));
    }
    return weeks;
  };

  const weeks = getWeeks(calendarDays); // Get days grouped into weeks


  useEffect(() => {
    if (appointments.length > 0) {
      let event = [];
      appointments.map((app, ind) => {
        let startTime = new Date(`1970-01-01T${app.app_time ? app.app_time : "00:00:00"}`);
        event.push({
          appointment: app,
          day: dayjs(new Date(app.appointment_time)).format('ddd'),
          currentDate: dayjs(new Date(app.appointment_time)).format('DDMMYYYY'),
          hour: dayjs(startTime).format('HH'),
          minute: dayjs(startTime).format('mm') >= 30 ? "30" : "00",
          duration: 1
        })
      })
      setEvents(event);
    } else {
      setEvents([])
    }
  }, [appointments])

  useEffect(() => {
    const startOfWeek = selectedDate.startOf('day');// Sunday - 0
    setCurrentMonthLbl(`${startOfWeek.add(0, 'day').format('MMMM')}, ${startOfWeek.add(0, 'day').format('YYYY')}`)
    setCalendarDays(getCalendarDays(selectedDate));
  }, [selectedDate]);

  const handleAppointmentDetailPopup = (evt, appointment) => {
    dispatch(setAppGridViewAppointmentData(appointment))
    setAppointmentDetailRef(evt.currentTarget);
  }

  const nextMonth = () => {
    setSelectedDate((prev) => prev.add(1, 'month'));
  }

  const previousMonth = () => {
    setSelectedDate((prev) => prev.add(-1, 'month'));
  }

  const handleClick = (event) => {
    setDayMonthSelectRef(event.currentTarget);
  };

  const handleAppointmentDetailPopupClose = () => {
    setAppointmentDetailRef(null);
  }

  return (
    <div id="GridMonthViewMainDiv">
      {Boolean(appointmentDetailRef) && (
        <Popover
          id={
            Boolean(appointmentDetailRef)
              ? "appointment-details-view-popup"
              : undefined
          }
          open={Boolean(appointmentDetailRef)}
          anchorEl={appointmentDetailRef}
          anchorReference="anchorPosition"
          anchorPosition={{ top: popoverTop, left: popoverRight }}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          onClose={() => handleAppointmentDetailPopupClose()}
          PaperProps={{
            sx: {
              width: "20rem",
              borderRadius: "8px",
              backgroundColor: "#fff",
              marginBottom: "50px",
              zIndex: "999999",
              position: "sticky",
              bottom: "50px"
            },
          }}
          disablePortal // To avoid incorrect positioning
          getContentAnchorEl={null} // Prevent content anchor issues
          modifiers={[
            {
              name: 'preventOverflow',
              options: {
                boundary: 'viewport', // Ensure it stays within the viewport
              },
            },
            {
              name: 'flip',
              options: {
                altBoundary: true,
                fallbackPlacements: ['top', 'right', 'left'], // Allow it to flip positions
              },
            },
          ]}
        >
          <AppDetailsPopover doctors={doctors} doctorsTimeslot={doctorsTimeslot} setRefreshData={setRefreshData} setAppointmentDetailRef={setAppointmentDetailRef} deleteAppointment={deleteAppointment} />
        </Popover>
      )}

      <div className='sticky top-[9.5rem] bg-white z-10'>
        <header className='bg-[#282358F5] px-2 py-2 flex justify-start' id="GridMonthViewMainHeader">
          <button id="BtnGridMonthViewToday" onClick={() => { dispatch(setAppGridViewSelection("1day")); dispatch(setSideDateToShow(new Date().toISOString())); }} className='bg-white text-[#A31B1B] px-2 py-1 rounded-md font-semibold ml-3'>Today</button>
          <button id="BtnGridMonthViewPrevious" className='text-white font-semibold ml-3' onClick={previousMonth}>
            <svg id="ImgGridMonthViewPrevious" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
            </svg>
          </button>
          <button id="BtnGridMonthViewNext" className='text-white font-semibold ml-3' onClick={nextMonth}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>
          </button>

          <button id="BtnGridMonthViewDatePicker" onClick={handleClick} aria-describedby={Boolean(dayMonthSelectRef)} type="button" className="text-center inline-flex items-center bg-white text-[#A31B1B] px-2 py-1 rounded-md font-semibold ml-4">
            {currentMonthLbl}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="size-6 ms-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>
          </button>
          <Popover
            id={
              Boolean(dayMonthSelectRef)
                ? "date-month-select-calendar"
                : undefined
            }
            open={Boolean(dayMonthSelectRef)}
            anchorEl={dayMonthSelectRef}
            onClose={() => setDayMonthSelectRef(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Container>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <MonthPicker
                    selectedDate={selectedDate}
                    views={['year', 'month']}
                    setSelectedDate={setSelectedDate}
                    setDayMonthSelectRef={setDayMonthSelectRef}
                  />
                </Grid>
              </Grid>
            </Container>
          </Popover>
        </header>
      </div>

      <div className="">
        <table id="TableGridMonthView" className="table-fixed w-full border-collapse border border-gray-300 mt-[4.3rem]">
          <thead id="TheadGridMonthView" className='sticky top-[12.47rem] bg-white z-10'>
            <tr id="TrGridMonthView">
              {/* Weekday headers */}
              {["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].map((day, ind) => (
                <th id={"ThGridMonthView" + ind} key={day} className="border border-gray-300 p-2 py-3 text-center bg-gray-100 text-sm font-semibold">
                  {day}
                </th>
              ))}
            </tr>
          </thead>
          <tbody id="TbodyGridMonthView">
            {/* Render each week */}
            {weeks.map((week, weekIndex) => (
              <tr key={weekIndex} id={"TrGridMonthView" + weekIndex}>
                {/* Render each day in the week */}
                {week.map((day, dayIndex) => (
                  <td
                    id={"TrtdGridMonthTimeView" + weekIndex}
                    key={dayIndex}
                    className={`border border-gray-300 p-2 align-top ${day.month() === selectedDate.month() ? "" : "text-gray-400"} 
                    ${day.format("DMYY").toString() === today.format("DMYY").toString() ? "bg-gray-100" : ""}`}
                  >
                    <p id={"TrtdGridDataMonthView" + weekIndex} onClick={() => dispatch(setSideDateToShow(day.format("YYYY-MM-DD")))} className={`${day.format("DMYY").toString() === today.format("DMYY").toString() ? "rounded-full text-white bg-[#A31B1B] p-1 w-8 h-8 text-center mb-2 " : ""} font-semibold cursor-pointer hover:text-blue-500`}>{day.format("D")}</p>
                    <div className='min-h-20'>
                      <MonthViewAppointment day={day.format('DDMMYYYY')} handleAppointmentDetailPopup={handleAppointmentDetailPopup} events={events} />
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className='pb-[130px]'></div>
      </div>
    </div>
  )
}

export default MonthView