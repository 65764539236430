import { createSlice } from '@reduxjs/toolkit'

const initialState = {
 loginuserData:[],
 userBarerToken:""
}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    addLoginDetails: (state, action) => {
      state.loginuserData = action.payload
    },
    addLoginToken:(state,action)=>{
      state.userBarerToken=action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { addLoginDetails, addLoginToken } = loginSlice.actions

export default loginSlice.reducer