import { createSlice } from '@reduxjs/toolkit';

const visitSideNavbarSlice = createSlice({
    name: 'visitSideNavbar',
    initialState: {
        sidebar: { vitals: false, doctorNotes: false, visitHistory: false, prescription: false, uploadedData: false, labNotes: false, radiology: false },
        autoSaveAll: false,
        activeNavvisitbar: "Visit-details",
        selectedAppointmentId: 0,
        pastSelected: 0,
        isdoctorNotes: false,
        isprescriptions: false,
        isuploadedData: false,
        islabNotes:false,
        isradiologyNotes: false,
        refreshNavBarScroll:false,
        
        
    },
    reducers: {
        setVisitSideNavbar: (state, action) => {
            state.sidebar = action.payload;
        },
        setAutoSaveAll: (state, action) => {
            state.autoSaveAll = action.payload;
        },
        setActiveNavvisitbar: (state, action) => {
            state.activeNavvisitbar = action.payload;
        },
        setSelectedAppointmentId: (state, action) => {
            state.selectedAppointmentId = action.payload;
        },
        setpastSelected: (state, action) => {
            state.pastSelected = action.payload;
        },
        setDoctorNotes: (state, action) => {
            state.isdoctorNotes = action.payload;
        },
        setPrescriptions: (state, action) => {
            state.isprescriptions = action.payload;
        },
        setUploadedData: (state, action) => {
            state.isuploadedData = action.payload;
        },
        setLabNotes: (state, action) => {
            state.islabNotes = action.payload;
        },
        setRadiologyNotes: (state, action) => {
            state.isradiologyNotes = action.payload;
        },
        setRefreshNavBarScroll: (state, action) => {
            state.refreshNavBarScroll = action.payload;
        },
        
    },
});

export const {
    setVisitSideNavbar,
    setAutoSaveAll,
    setActiveNavvisitbar,
    setSelectedAppointmentId,
    setpastSelected,
    setDoctorNotes,
    setPrescriptions,
    setUploadedData,
    setLabNotes,
    setRadiologyNotes,
    setRefreshNavBarScroll
} = visitSideNavbarSlice.actions;

export default visitSideNavbarSlice.reducer;
