
import { useState, useEffect } from 'react';
import DashboardIcon from '../../components/Assets/Dashboard.png';
import DialerIcon from '../../components/Assets/dialer_icon.png';
import { NavLink } from 'react-router-dom';
import AddAppointment from '../Appointments/AddAppointment';
import store from '../../API/store';
import { useSelector, useDispatch } from 'react-redux';
import DataDecode from '../../utils/DataDecode';
import DateFunction from '../../utils/DateFunctions';
import { format } from 'date-fns';
import { Tooltip } from 'react-tooltip';
import { setAddAppointmentPopup } from '../../Redux/features/popup/popupSlice';
import SnackBarComponent from "../../components/SnackBarComponent/SnackBarComponent"
import AddPatient from '../Patients/AddPatient';
import { theme } from '../../EmraxisTheme/Theme';

const Dashboard = () => {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [selectedDate, setSelectedDate] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [refreshData, setRefreshData] = useState(false);
    const [showPhoneNumbers, setShowPhoneNumbers] = useState({});
    const [showMobileNumbers, setShowMobileNumbers] = useState({});
    const [patients, setPatients] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [doctorsTimeslot, setDoctorsTimeSlot] = useState([]);
    const [doctorsList, setdoctorsList] = useState([]);
    const [department, setDepartment] = useState([]);
    const [appointments, setAppointments] = useState([]);
    const [selectedEditAppointment, setSelectedEditAppointment] = useState(null);
    var loggeduser = useSelector(state => state.login.loginuserData);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    const [clinicGuid, setClinicGuid] = useState(false);
    const [showPatientPopup, setShowPatientPopup] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState({ show: false, message: '', type: '' });
    var showAddPatientpopup = useSelector(state => state.popup.showAddPatient);
    var showAddAppointmentpopup = useSelector(state => state.popup.showAddAppointment);
    var refreshAppointment = useSelector(state => state.popup.refreshAppointment);
    var patientAddAppointment = useSelector(state => state.popup.setPatientAddAppointment);
    const previewTheme = useSelector(state => state.theme.colors) || theme.defaultTheme;
    const showToast = () => {
	
        setTimeout(() => {
          setToast({ show: false, message: '', type: '' });
        }, 3000);
      };
    useEffect(() => {
        if (patientAddAppointment?.isOpenAppointment) {
            dispatch(setAddAppointmentPopup(true));
        }
    }, [patientAddAppointment])

    const generateCalendar = (year, month) => {
        const calendarElement = document.getElementById('calendar');
        const currentMonthElement = document.getElementById('currentMonth');

        const firstDayOfMonth = new Date(year, month, 1);
        const daysInMonth = new Date(year, month + 1, 0).getDate();

        const formatDate = (date, formatString) => {
            return format(date, formatString);
        };

        calendarElement.innerHTML = '';

        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        currentMonthElement.innerText = `${monthNames[month]} ${year}`;

        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        daysOfWeek.forEach(day => {
            const dayElement = document.createElement('div');
            dayElement.className = 'text-center font-semibold';
            dayElement.innerText = day;
            calendarElement.appendChild(dayElement);
        });

        const firstDayOfWeek = firstDayOfMonth.getDay();
        for (let i = 0; i < firstDayOfWeek; i++) {
            const emptyDayElement = document.createElement('div');
            calendarElement.appendChild(emptyDayElement);
        }

        const currentDate = new Date();
        const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

        for (let day = 1; day <= daysInMonth; day++) {
            const dayElement = document.createElement('div');
            dayElement.className = 'text-center py-2 border cursor-pointer';
            dayElement.innerText = day;

            const dateToCheck = new Date(year, month, day);
            if (dateToCheck < today) {
                dayElement.classList.add('text-gray-400', 'cursor-not-allowed');
            } else {
                if (year === currentDate.getFullYear() && month === currentDate.getMonth() && day === currentDate.getDate()) {
                    dayElement.classList.add('bg-blue-500', 'text-white');
                }

                dayElement.addEventListener('click', () => {
                    openPopup();
                    
                    // Create the Date object
                    const selectedDate = new Date(year, month, day);  // month is zero-based in JavaScript Date object
                    // Format the date to "YYYY-MM-DDTHH:mm:ss"
                    const yearStr = selectedDate.getFullYear();
                    const monthStr = String(selectedDate.getMonth() + 1).padStart(2, '0');  // zero-based month to one-based
                    const dayStr = String(selectedDate.getDate()).padStart(2, '0');
                    const hoursStr = '00';
                    const minutesStr = '00';
                    const secondsStr = '00';
                    const isoFormattedDate = `${yearStr}-${monthStr}-${dayStr}T${hoursStr}:${minutesStr}:${secondsStr}`;
                    const formattedDate = formatDate(new Date(isoFormattedDate), 'MMM dd, yyyy hh:mm');
                    setSelectedDate(formattedDate);
                    setSelectedEditAppointment({appointment_id:0,appointment_time:formattedDate,appointment_type:"First Time",app_time:null});
                });
            }

            calendarElement.appendChild(dayElement);
        }
    };

    const showPrevMonth = () => {
        setCurrentMonth(prevMonth => {
            let newMonth = prevMonth - 1;
            let newYear = currentYear;
            if (newMonth < 0) {
                newMonth = 11;
                newYear -= 1;
            }
            setCurrentYear(newYear);
            return newMonth;
        });
        generateCalendar(currentYear, currentMonth);
    };

    const showNextMonth = () => {
        setCurrentMonth(prevMonth => {
            let newMonth = prevMonth + 1;
            let newYear = currentYear;
            if (newMonth > 11) {
                newMonth = 0;
                newYear += 1;
            }
            setCurrentYear(newYear);
            return newMonth;
        });
        generateCalendar(currentYear, currentMonth);
    };

    const openPopup = () => {
        dispatch(setAddAppointmentPopup(true));
    }

    const closePopup = () => {
        setShowPopup(false);
    };

    const handleDilerClickForDoctor = (index) => {
        if (window.innerWidth <= 768) {
            window.location.href = `tel:${doctorsList[index].phone_number}`;
        } else {
            setShowPhoneNumbers((prevState) => ({
                ...prevState,
                [index]: !prevState[index],
            }));
        }
    };

    const handleDilerClickForPatient = (index) => {
        if (window.innerWidth <= 768) {
            window.location.href = `tel:${patients[index].mobile_number}`;
        } else {
            setShowMobileNumbers((prevState) => ({
                ...prevState,
                [index]: !prevState[index],
            }));
        }
    };

    const getInitials = (name) => {
        const names = name.split(' ');
        const initials = names.map(namePart => namePart.charAt(0).toUpperCase());
        return initials.join('');
    };

    const stringToHSLColor = (string) => {
        let hash = 0;
        for (let i = 0; i < string.length; i++) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
        const h = hash % 360;
        const s = 60 + (hash % 20); // Saturation between 70-100%
        const l = 40 + (hash % 20); // Lightness between 50-70%
        return `hsl(${h}, ${s}%, ${l}%)`;
    };


    const handleRowClick = (app) => {
        let data = btoa(JSON.stringify({ appointment_id: app.appointment_id, patient_id: app.patient_id, doctor_id: app.doctor_id || 0, clinic_guid: loggeduser.facilityID, page_src: "appointment" }))
        window.open(`/patient-profile?profile=${data}`, '_blank')
    };

    const GetAppointments = (guid) => {
        store.getAppointments(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': guid, 'sort_by': "today" }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        var myObject = JSON.parse(response);
                        setAppointments(myObject.appointmentData);
                        setSelectedEditAppointment(myObject.appointmentData)
                    })
                } else {

                }
            })
            .catch((err) => {
                setToast({ show: true, message: "Error occured", type: "error" });
                showToast();
                setLoading(false);
            });
          
    }

    const fetchPatientData = () => {
        try {
            store.GetAllPatients(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID })).then((result) => {
                if (result.status === 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        var myObject = JSON.parse(response);
                        setPatients(myObject.patients);
                        /*  setPatients(JSON.parse(response)); */
                    });
                } else {
                    setPatients([]);
                }
            });
        } catch (err) {
            setToast({ show: true, message: "Error occured", type: "error" });
                showToast();
                setLoading(false);
        }
    };



    const fetchDoctorData = () => {
        try {
            store.GetAllDoctors(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID }))
                .then((result) => {
                    if (result.status === 200) {
                        result.json().then((res) => {
                            let response = DataDecode.decryptResponse(res);
                            let data = JSON.parse(response)
                            setdoctorsList(data.doctors);
                        });
                    } else {
                        setdoctorsList([]);
                    }
                });
        } catch (err) {
            setToast({ show: true, message: "Error occured", type: "error" });
            showToast();
            setLoading(false);
        }
    };

    const fetchDoctorDepartment = () => {
        try {
            store.GetDepartmentForDoctor(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID }))
                .then((result) => {
                    if (result.status === 200) {
                        result.json().then((res) => {
                            let response = DataDecode.decryptResponse(res);
                            setDepartment(JSON.parse(response));
                        });
                    } else {

                    }
                });
        } catch (err) {
                setToast({ show: true, message: "Error occured", type: "error" });
                showToast();
                setLoading(false);
            }
    };


    const getDepartmentname = (department_id) => {
        let department1 = department.filter(dep => dep.department_id == department_id)
        return department1.length > 0 ? department1[0].department_name : ""

    }

    const GetDoctors = (guid) => {
        store.getDoctorForAppointment(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': guid }))
            .then((result) => {
                if (result.status == 200) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        let data = JSON.parse(response);
                        setDoctors(data.drDetails);
                        setDoctorsTimeSlot(data.drDetailstimeSlots);
                    })
                } else {
                    setDoctors([])
                    setDoctorsTimeSlot([])
                }
            })
            .catch((err) => {
                setToast({ show: true, message: "Error occured", type: "error" });
                showToast();
                setLoading(false);
                
              });
    }

    useEffect(() => {
        if (showAddAppointmentpopup) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'unset';
        }
      }, [showAddAppointmentpopup]);
    


    useEffect(() => {
        fetchDoctorData();
        fetchPatientData();
        GetAppointments(loggeduser.facilityID);
        generateCalendar(currentYear, currentMonth);
        fetchDoctorDepartment()
        // FormatDate(selectedDate)
    }, [currentYear, currentMonth, refreshAppointment]);

    useEffect(() => {
        if (loggeduser) {
            setClinicGuid(loggeduser.facilityID);
            GetDoctors(loggeduser.facilityID);
        }
    }, [loggeduser])


    return (
        <div>
        <div className=" mx-auto px-4 py-2 my-20 w-100% ">
            {/* <img src={DashboardIcon} alt="Dashboard" className="inline-block w-6 h-6" /> */}
            <header id="DivdashboardHeader"  className={`origin-top-left flex font-bold mb-2 text-2xl lg:text-xl sm:text-lg pl-2.5 pt-1 ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewTheme.fontFamily }}>Dashboard</header>
            <div id="DivdashboardContent"  className=" py-4">
                <div id="DivdashboardGrid" className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div id="DivcalendarCard"  className="bg-white rounded-xx p-6 shadow-md flex flex-col justify-between" style={{ flex: '1 1 0', minHeight: '350px', width: '100%', border: "1px solid #dadada" }}>
                        <div id="DivcalendarInner"  className="bg-white rounded-lg overflow-hidden" style={{ height: '334px', width: '100%' }}>
                            <div id="DivcalendarHeader" className={`flex items-center justify-between px-6 py-3 bg-white ${previewTheme.headingTitleColor}`}>
                                <button id="prevMonth" className="text-lg font-semibold" onClick={showPrevMonth}>&lt;&lt;</button>
                                <h2 id="currentMonth" className="text-lg font-semibold">Current Month</h2>
                                <button id="nextMonth" className="text-lg font-semibold" onClick={showNextMonth}>&gt;&gt;</button>
                            </div>
                            <div className="grid grid-cols-7 gap-2 p-0.5" id="calendar">
                                {/* Calendar Days Go Here */}
                            </div>
                        </div>
                    </div>
                    {showAddAppointmentpopup && <AddAppointment setRefreshData={setRefreshData} appointment={selectedEditAppointment} doctors={doctors} selectedDate={selectedDate} doctorsTimeslot={doctorsTimeslot} page="Dashboard"/>}
                    {showAddPatientpopup && <AddPatient doctors={doctors} setRefreshData={setRefreshData} patient={{}} page="Dashboard Book Appointment Popup"/>}
                    <div  id="DivupcomingAppointmentsCard" className="bg-white rounded-lg p-6 shadow-md flex flex-col justify-between" style={{ flex: '1 1 0', minHeight: '350px', width: '100%', border: "1px solid #dadada" }}>
                        <div id="DivupcomingAppointmentsContent" className="flex flex-col h-full">
                            <div id="DivupcomingAppointmentsHeader" className="pb-2 mb-2 border-b border-gray-300 flex justify-between">
                                <p id="PupcomingAppointmentsTitle" className={`font-semibold text-lg  ${previewTheme.headingTitleColor}`}>Upcoming Appointments</p>
                                <NavLink to="/appointments">
                                    <p id="PseeAll"  className="text-base text-gray-400 lg:mr-[55px] hover:text-blue-500">See all</p>
                                </NavLink>
                            </div>
                            <div id="DivupcomingAppointmentsList" className="grid grid-cols-3 gap-3 py-2 border-b border-gray-300">
                                <div id="DivpatientNameHeader" className="font-semibold text-sm text-gray-700 pl-px">Patient Name</div>
                                <div id="DivappointmentHeader" className="font-semibold text-sm text-gray-700 pl-px">Appointment</div>
                                <div id="DivdoctorHeader" className="font-semibold text-sm text-gray-700 pl-4">Doctor</div>
                                {/* <div className="font-semibold text-sm text-gray-700 pl-8 lg:ml-[10px]">Status</div> */}
                            </div>
                            {appointments.slice(0, 4).map((appointment, index) => (
                                <div id="DivgetAppointement"
                                    key={index}
                                    className="grid grid-cols-3 gap-3 py-2 border-b border-dotted border-gray-300 cursor-pointer"
                                    onClick={() => handleRowClick(appointment)}
                                >
                                    <div id="DivupcomingAppointmentPatient" className="flex items-center text-sm">
                                        <div id="DivappointmentAvatar" 
                                            className="avatar bg-blue-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-sm"
                                            style={{ backgroundColor: stringToHSLColor(getInitials(`${appointment.FirstName+" "+appointment.MiddleName?.charAt(0)+" "+appointment.LastName}`)) }}
                                            data-tooltip-id="tooltip"
                                            data-tooltip-content={`${appointment.FirstName+" "+appointment.MiddleName?.charAt(0)+" "+appointment.LastName}`}
                                        >
                                            {getInitials(`${appointment.FirstName+" "+appointment.MiddleName?.charAt(0)+" "+appointment.LastName}`)}
                                        </div>
                                        <div id="DivappointmentName" className="overflow-hidden whitespace-nowrap text-ellipsis">
                                            <p  id="PappointmentPatientName"
                                                className="font-semibold pl-1 text-sm overflow-hidden text-ellipsis whitespace-nowrap"
                                                onClick={(e) => e.stopPropagation()}
                                                data-tooltip-id="tooltip"
                                                data-tooltip-content={`${appointment.FirstName+" "+appointment.MiddleName?.charAt(0)+" "+appointment.LastName}`}
                                            >
                                                {`${appointment.FirstName+" "+appointment.MiddleName?.charAt(0)+" "+appointment.LastName}`}
                                            </p>
                                        </div>
                                        <Tooltip id="tooltip" place="top" />
                                    </div>
                                    <div id="DivappTime" className="flex justify-center items-center  font-semibold text-sm mr-4 lg:mr-[100px]">
                                        {DateFunction.GetDate(appointment.appointment_time)} {appointment.app_time && DateFunction.Convert12Hour(appointment.app_time)}
                                    </div>
                                    <div className="flex items-center">
                                        <img id="ImgappImage" src={appointment.image || "./image/user.png"} alt="Appointment" className="w-10 h-10 mr-2 flex-shrink-0" />
                                        <div className="overflow-hidden whitespace-nowrap text-ellipsis">
                                            <p id="PappdoctorName"
                                                className="font-semibold text-sm overflow-hidden text-ellipsis whitespace-nowrap"
                                                onClick={(e) => e.stopPropagation()}
                                                data-tooltip-id="tooltip"
                                                data-tooltip-content={appointment.doctor_name}
                                            >
                                                {appointment.doctor_name}
                                            </p>
                                        </div>
                                        <Tooltip id="tooltip" place="top" type="dark" effect="solid" />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div  id="DivdoctorListContainer" className="bg-white rounded-lg p-6 shadow-md flex flex-col justify-between" style={{ flex: '1 1 0', minHeight: '350px', width: '100%', border: "1px solid #dadada" }}>
                        <div id="DivdoctorListHeader" className="flex flex-col h-full">
                            <div id="DivdoctorListHeaderContent" className="pb-2 mb-2 border-b border-gray-300 flex justify-between"> {/* Reduced bottom padding */}
                                <p id="PdoctorListTitle" className={`font-semibold text-lg  ${previewTheme.headingTitleColor}`}>Active Doctor List</p>
                                <NavLink to="/doctors" id="LinktoDoctors">
                                    <p id="PseeAllLink" className="text-base text-gray-400 mr-4 lg:mr-[50px] hover:text-blue-500">See all</p>
                                </NavLink>
                            </div>

                            {doctorsList.length > 0 && doctorsList?.slice(0, 5).map((doctor, index) => (
                                <div id="DivdoctorItem" key={index} className="grid grid-cols-3 gap-4 py-2">
                                    <div id="DivdoctorImage" className="flex items-center">
                                        <img id="ImgdoctorImage" src={doctor.image || "./image/user.png"} alt="Doctor" className="w-10 h-10 rounded-md mr-2" />
                                        <div id="DivdoctorInfo" className="whitespace-nowrap">
                                            <p id="PdoctorName" className="font-semibold text-sm">{doctor.title}{" "} {`${doctor.FirstName+" "+doctor.MiddleName?.charAt(0)+" "+doctor.LastName}`} </p>
                                            <p id="PdoctorDepartment" className="text-gray-500 text-sm">{getDepartmentname(doctor.department_id)}</p>
                                        </div>
                                    </div>
                                    <div id="DivdoctorAdditionalInfo" className="flex items-center text-gray-500 text-xs font-semibold pl-12">
                                        {/* Placeholder for any additional info */}
                                    </div>
                                    <div id="DivdoctorDialer" className="flex justify-center items-center pl-8" onClick={() => handleDilerClickForDoctor(index)}>
                                        {showPhoneNumbers[index] ? (
                                            <div id="DivdoctorPhoneNumber" className="border border-orange-500 text-xs font-medium leading-[24px] rounded-md px-3 py-1 flex justify-center">
                                                {doctor.phone_number}
                                            </div>
                                        ) : (
                                            <img id="ImgdailerIcon" src={DialerIcon} className="inline-block w-6 h-6" alt="Dialer Icon" />
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div id="DivpatientListContainer" className="bg-white rounded-lg p-6 shadow-md flex flex-col justify-between" style={{ flex: '1 1 0', minHeight: '350px', width: '100%', border: "1px solid #dadada" }}>
                        <div id="DivpatientListHeader" className="flex flex-col h-full">
                            <div id="DivpatientListHeaderContent" className="pb-2 mb-2 border-b border-gray-300 flex justify-between"> {/* Reduced bottom padding */}
                                <p id="PpatientListTitle" className={`font-semibold text-lg  ${previewTheme.headingTitleColor}`}>Active Patients List</p>
                                <NavLink id="LinktoPatients" to="/patients">
                                    <p id="PseeAllPatientsLink" className="text-base text-gray-400 mr-4 lg:mr-[50px] hover:text-blue-500">See all</p>
                                </NavLink>
                            </div>

                            {patients.slice(0, 5).map((patient, index) => (
                                <div id="DivpatientItem" key={index} className="grid grid-cols-3 gap-4 py-2 pb-6">
                                    <div id="DivpatientInfo" className="flex items-center">
                                        {/* <img src={patient.image || "./image/user.png"} alt="Doctor" className="w-10 h-10 rounded-md mr-2" /> */}
                                        <div id="DivpatientImage" className="whitespace-nowrap">
                                            <p id="PpatientOpNumber" className="font-semibold text-sm">{patient.patient_op_number}</p>

                                        </div>
                                    </div>
                                    <div id="DivpatientName" className="flex items-center">
                                        <div id="DivpatientNameText" className="overflow-hidden whitespace-nowrap text-ellipsis pl-4 lg:pl-12">
                                            <p id="PpatientName"
                                                className="font-semibold text-sm overflow-hidden text-ellipsis whitespace-nowrap"
                                                onClick={(e) => e.stopPropagation()}
                                                data-tooltip-id="tooltip"
                                                data-tooltip-content= {`${patient.FirstName+" "+patient.MiddleName?.charAt(0)+" "+patient.LastName}`}

                                            >
                                                {`${patient.FirstName+" "+patient.MiddleName?.charAt(0)+" "+patient.LastName}`}
                                            </p>
                                        </div>
                                        <Tooltip id="tooltip" place="top" type="dark" effect="solid" />
                                    </div>
                                    <div id="DivpatientDialer" className="flex justify-center items-center pl-6 lg:pl-8" onClick={() => handleDilerClickForPatient(index)}>
                                        {showMobileNumbers[index] ? (
                                            <div id="DivpatientMobileNumber" className="border border-orange-500 text-xs font-medium leading-[24px] rounded-md px-3 py-1 flex justify-center">
                                                {patient.mobile_number}
                                            </div>
                                        ) : (
                                            <img id="ImgpatientDialerIcon" src={DialerIcon} className="inline-block w-6 h-6" alt="Dialer Icon" />
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </div>
        </div>
        {toast.show && (
				<SnackBarComponent
				message={toast.message}
				type={toast.type}
				onClose={() => setToast({ show: false, message: '', type: '' })}
				/>
			)}
        </div>

    );
};
export default Dashboard;
