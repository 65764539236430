import React, { useState, useEffect } from 'react';
import DesktopPatientProfile from './DesktopPatientProfile';
import MobilePatientProfile from './MobilePatientProfile';
import PatientCardDetails from './PatientCardDetails';



const ProfileViewPort = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [showPatient, setShowPatient] = useState(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 767px)');
        setIsMobile(mediaQuery.matches);

        const handleMediaChange = (e) => {
            setIsMobile(e.matches);

            if (!e.matches && showPatient) {
                setShowPatient(false);
            }
        };

        mediaQuery.addListener(handleMediaChange);
        return () => {
            mediaQuery.removeListener(handleMediaChange);
        };
    }, [showPatient]);

   

    return (
        <div>
            {!showPatient && isMobile && <MobilePatientProfile setShowPatient={setShowPatient} />}
            {!isMobile && <DesktopPatientProfile />}
            {showPatient && isMobile && <PatientCardDetails setShowPatient={setShowPatient} />}
        </div>
    );
};

export default ProfileViewPort;