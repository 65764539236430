import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isInvoiceActive: false,
    isVoice2TextActive: false,
    isStandardAiActive: false
}

export const popupSlice = createSlice({
    name: 'feature',
    initialState,
    reducers: {
        setActivateInvoiceStatus: (state, action) => {
            state.isInvoiceActive = action.payload
        },
        setActivateVoice2TextStatus: (state, action) => {
            state.isVoice2TextActive = action.payload
        },
        setActivateIsStandardAiStatus: (state, action) => {
            state.isStandardAiActive = action.payload
        }
    }
})

export const { setActivateInvoiceStatus, setActivateVoice2TextStatus, setActivateIsStandardAiStatus } = popupSlice.actions;

export default popupSlice.reducer;