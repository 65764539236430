import React, { useState } from 'react'
import { setNotification } from '../../../Redux/features/toast/toastSlice';
import { useSelector, useDispatch } from 'react-redux';
import store from '../../../API/store';
import DataDecode from '../../../utils/DataDecode';
import FileIcon from './FileIcon';

const UploadPrescription = ({ setUploadPrescription, patient_id, appointment_id, setExpandRow, setRefreshImage }) => {

    var loggeduser = useSelector(state => state.login.loginuserData);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    const dispatch = useDispatch();

    const [file, setFile] = useState(null);
    const [loadingAddImage, setLoadingAddImage] = useState(false);

    const onUploadPresciption = () => {
        if (file) {
            const formData = new FormData();
            formData.append("files", file);
            let otherData = JSON.parse(DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID, 'patient_id': patient_id, 'appointment_id': appointment_id, 'file_type': "Prescription" }))
            setTimeout(() => {
                formData.append('iv', otherData.iv);
                formData.append('payload', otherData.payload);
                setLoadingAddImage(true)
                store.SavePatientFile(loggedusertoken, formData)
                    .then((result) => {
                        setLoadingAddImage(false)
                        if (result.status == 201) {
                            setRefreshImage(o => !o);
                            setUploadPrescription(false)
                            setExpandRow(true)
                            dispatch(setNotification({ message: 'File Uploaded Successfully!', status: 'success', action: true }));
                        }
                        else {
                            dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                        }

                    })
                    .catch((error) => {
                        console.error("Error occurred while uploading file");
                    });
            }, 100)
        } else {
            dispatch(setNotification({ message: 'Please choose a file!', status: 'error', action: true }));
        }
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFile(file);
        }
    };

    const handleClick = () => {
        document.getElementById('file-input').click();
    };

    return (

        <>
            <div className="fixed inset-0 flex justify-center items-center p-8 z-[999999]">
                <div className="absolute inset-0 bg-black opacity-25 "></div>
                <div className="relative bg-white shadow-xl rounded-sm" style={{ width: "600px" }} >
                    <div className="text-right">
                        <button className="text-red-700 sm:justify-left" onClick={() => setUploadPrescription(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-7 w-7 mr-2 mt-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                        </button>
                    </div>

                    <div className='min-h-[200px] pl-8 pr-8 '>
                        <div className="flex flex-col items-center border border-dashed border-green-400 p-2 rounded-md">
                            <input
                                id="file-input"
                                type="file"

                                onChange={handleFileChange}
                                className="hidden"
                            />
                            <div
                                onClick={handleClick}
                                className=" flex justify-center items-center cursor-pointer my-2">
                                <div>
                                    <img src="./images/upload-icon.png" className="w-16 h-auto" />
                                    <p className='text-center font-bold cursor-pointer'><span className='text-red-800'>Browse</span></p>
                                </div>
                            </div>
                        </div>

                        {file &&
                            <div className='text-center'>
                                <div className='flex items-center justify-center'>
                                    <a rel="noopener" target="_blank" href={URL.createObjectURL(file)} className='m-2 p-2'>
                                        {file.type.startsWith('image/') ? (
                                            <img className="max-h-[250px] w-auto h-auto object-contain" src={URL.createObjectURL(file)} alt={file.name} />
                                        ) : file.type.startsWith('audio/') ? (
                                            <audio className="max-h-[250px] w-auto" controls>
                                                <source src={URL.createObjectURL(file)} type={file.type} />
                                                Your browser does not support the audio element.
                                            </audio>
                                        ) : file.type.startsWith('video/') ? (
                                            <video className="max-h-[250px] w-auto h-auto object-contain" controls>
                                                <source src={URL.createObjectURL(file)} type={file.type} />
                                                Your browser does not support the video element.
                                            </video>
                                        ) : (
                                            <FileIcon ext={file.type} type={"local"} />
                                        )}
                                    </a>
                                </div>
                                <button disabled={loadingAddImage} className='bg-green-600 text-white hover:bg-green-800 rounded-md p-2 m-3 disabled:cursor-not-allowed disabled:opacity-50'
                                    onClick={onUploadPresciption}>Upload
                                    {loadingAddImage ?
                                        <svg aria-hidden="true" role="status" className="inline w-4 h-4 ml-1 text-green-600 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                        </svg>
                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-4 ml-1 inline">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                                        </svg>
                                    }
                                </button>
                            </div>
                        }

                    </div>


                </div>
            </div>

        </>
    )
}

export default UploadPrescription