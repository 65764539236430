import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import store from '../../API/store';
import AddFeatures from './AddFeatures';
import { setNotification } from '../../Redux/features/toast/toastSlice';


const Features = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [refresh, setrefresh] = useState(0);
    const [features, setfeatures] = useState([]);
    const [editdata, seteditdata] = useState([]);
    var loggeduser = useSelector(state => state.login.loginuserData);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    const dispatch = useDispatch();

    const openPopup = (status) => {

        seteditdata(status);
        setShowPopup(true);

    };

    const closePopup = () => {
        setShowPopup(false);
        setrefresh(oldKey => oldKey + 1)
    };

    //load the user Role from the database
    useEffect(() => {
         const fetchFeature = async () => {

            await store.GetFeatures(loggedusertoken)
                .then((result) => {
                    result.json().then((res) => {
                        setfeatures(res);
                    })

                })
                .catch(err => {
                    dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
                })
        };

        if (loggeduser != null && loggeduser.length != 0 && loggeduser.user_role == "SuperAdmin") {
            fetchFeature();
        } 
    }, [refresh])


    const handleuserdelete = async (data) => {

      /*   await store.DeleteUserRole(data.user_role_id, loggedusertoken)
            .then((result) => {
                result.json().then((res) => {
                    if (res == true) {
                        setrefresh(oldKey => oldKey + 1)
                        alert("added")
                    }
                    else {
                        alert("failed")
                    }
                })
            })
            .catch(err => {
                console.log("Error occured while Deleting UserRoles " + err)
            }) */


    }

    return (
        <div id="FeatureDivfeaturesContainer" className=" mx-auto px-4 py-2 my-20 w-100% ">
            <div id="FeatureDivfeaturesHeader" className="flex items-center">
                <header id="FeatureHeaderfeatures" className="ml-4 font-semibold text-lg">Features</header>
            </div>
            <header id="FeatureHeadercontainer" className="flex justify-between items-center my-2 mb-4 px-2">
                <div id="FeatureDivaddFeatureHeader" className="flex items-center">
                    <button id="FeatureBtnaddNewFeature" className="bg-red-700 hover:bg-red-800 text-white py-2 px-4 rounded-md flex items-center" onClick={() => { openPopup([]) }}>
                        <svg id="FeatureSvgaddNewFeatureIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 mr-2">
                            <path d="M5.25 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM2.25 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM18.75 7.5a.75.75 0 0 0-1.5 0v2.25H15a.75.75 0 0 0 0 1.5h2.25v2.25a.75.75 0 0 0 1.5 0v-2.25H21a.75.75 0 0 0 0-1.5h-2.25V7.5Z" />
                        </svg>
                        Add New Feature
                    </button>
                </div>
            </header>
            {showPopup && <AddFeatures id="FeatureAddfeaturePopup" isOpen={showPopup} onClose={closePopup} features={features}/>} 
            <table id="FeatureTablefeaturesTable" className="table-auto block w-full border rounded-md focus:outline-none focus:border-gray-200">
                <thead>
                    <tr id="FeatureTableheader">
                        
                        <th id="FeatureThname" className="px-14 py-2 text-left">Name</th>
                        <th id="FeatureThmonthly" className="px-14 py-2 text-left">Amount/Month</th>
                        <th id="FeatureThyearly" className="px-14 py-2 text-left">Amount/Year</th>
                        <th id="FeatureThisperuser" className="px-14 py-2 text-left">Feature/User</th>
                        <th id="FeatureThactions" className="px-14 py-2 text-left">Actions</th>

                    </tr>
                </thead>
                <tbody id="FeatureTbodyfeatures">

                     {features.map((userdata, index) => (
                        <tr key={index}>
         
                            <td id="FeatureTdname" className="px-14 py-2">{userdata.feature_name || '-'}</td>
                            <td id="FeatureTdmonthly" className="px-14 py-2">{userdata.feature_monthly || '-'}</td>
                            <td id="FeatureTdyearly" className="px-14 py-2">{userdata.feature_yearly || '-'}</td>
                            <td id="FeatureTdisperuser" className="px-14 py-2">{userdata.isperuser?"True":"False" || '-'}</td>
                            <td id="FeatureTdactions" className="px-14">
                                <button id="FeatureBtndelete" className='w-6 h-6' onClick={() => { handleuserdelete(userdata) }}>
                                    <svg id="FeatureSvgdeleteIcon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M9.808 17H10.808V7.99998H9.808V17ZM13.192 17H14.192V7.99998H13.192V17ZM6 20V5.99998H5V4.99998H9V4.22998H15V4.99998H19V5.99998H18V20H6Z" fill="#EE1D52" />
                                    </svg>
                                </button>
                                <button id="FeatureBtnedit" className=' mx-2 w-6 h-6' onClick={() => { openPopup(userdata) }}>
                                    <svg id="FeatureFeatureSvgeditIcon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M2.25 12.9375V15.75H5.0625L13.3575 7.455L10.545 4.6425L2.25 12.9375ZM15.5325 5.28C15.602 5.21062 15.6572 5.1282 15.6948 5.03747C15.7325 4.94674 15.7518 4.84948 15.7518 4.75125C15.7518 4.65303 15.7325 4.55576 15.6948 4.46503C15.6572 4.3743 15.602 4.29189 15.5325 4.2225L13.7775 2.4675C13.7081 2.39797 13.6257 2.34281 13.535 2.30518C13.4442 2.26754 13.347 2.24817 13.2488 2.24817C13.1505 2.24817 13.0533 2.26754 12.9625 2.30518C12.8718 2.34281 12.7894 2.39797 12.72 2.4675L11.3475 3.84L14.16 6.6525L15.5325 5.28Z" fill="black" />
                                    </svg>

                                </button>
                            </td>
                        </tr>
                    ))} 
                </tbody>
            </table>
        </div>
    );
};

export default Features;