import { createSlice } from '@reduxjs/toolkit'

const initialState = {
facility_user:"",
user_phone:"",
user_role:"",
user_email:""
}

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    addFacilityUser: (state, action) => {
      state.facility_user = action.payload
    },
    addFacilityPhone:(state,action)=>{
      state.user_phone=action.payload
    },
    addUserEmail:(state,action)=>{
      state.user_email=action.payload
    },
    addUserRole:(state,action)=>{
        state.user_role=action.payload
      }
  },
})

// Action creators are generated for each case reducer function
export const { addFacilityUser, addFacilityPhone,addUserEmail,addUserRole} = userSlice.actions

export default userSlice.reducer