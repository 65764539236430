import { createSlice } from '@reduxjs/toolkit';

const VisitDrawerSlice = createSlice({
  name: 'visitDrawer',
  initialState: {
    isVisitDrawerOpen: false,
  },
  reducers: {
    setIsVisitDrawerOpen: (state,action) => {
      state.isVisitDrawerOpen = action.payload;
    },
    
  },
});

export const {setIsVisitDrawerOpen} = VisitDrawerSlice.actions;
export default VisitDrawerSlice.reducer;