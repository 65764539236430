import React, { useRef, useState } from "react";
import homeBanner from './Assets/homeImages/homeBanner.png';
import mobilehomeBanner from './Assets/homeImages/mobilehomeBanner.png';
import submitBanner from './Assets/homeImages/requestBanner.jpg'
import appointmentIllus from './Assets/homeImages/book appointments.png'
import voiceIllus from './Assets/homeImages/Voice to text.png'
import prescriptionIllus from './Assets/homeImages/Prescriptions.png'
import clinicalNotesIllus from './Assets/homeImages/Automated Clinical Notes.png'
import documentsIllus from './Assets/homeImages/Scan Documents.png'
import electronicMedialIllus from './Assets/homeImages/Electronic Medical Records.png'
import SnackBarComponent from "./SnackBarComponent/SnackBarComponent";
import RequestFloatingButton from "./staticFloatingButton/requestButton";
import Loader from "./staticFloatingButton/loader";
import store from '../API/store';
import PhoneInput from "../Pages/PhoneInput";
 
const HomePage = () => {
	const targetDivRef = useRef(null);
	const [contactData, setContactData] = useState({
		name: "",
		phoneNumber: "",
		email: "",
		clinicName: "",
	});
	const [loading, setLoading] = useState(false);
	const [toast, setToast] = useState({ show: false, message: '', type: '' });
	const [nameError, setNameError] = useState(false);
	const [phoneError, setPhoneError] = useState(false);
	const [clinicNameError, setClinicNameError] = useState(false);
	const [emailErr, setEmailErr] = useState(false);
	const [emailValidErr, setEmailValidErr] = useState(false);
	const [snackStatus, setSnackStatus] = useState({});
	const [openSnack, setOpenSnack] = useState(false);
	const contactRef = useRef(null);

	const scrollToDiv = () => {
		if (targetDivRef.current) {
			targetDivRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	const showToast = () => {
	
		setTimeout(() => {
		  setToast({ show: false, message: '', type: '' });
		}, 3000);
	  };
	const handleNameChange = (e) => {
		if (e.target.value === "" || e.target.value === undefined) {
			setNameError(true);
		}

		setContactData({
			...contactData,
			name: e.target.value,
		});
	};
	const handlePhoneChange = (event) => {
 		const { name, value } = event.target;
 
		if (value === "" || value=== undefined) {

			setPhoneError(true);
		}
else{
	setPhoneError(false);
	setContactData({
		...contactData,
		phoneNumber :  value ,
	});
}};
		
	const handleClinicChange = (e) => {
		if (e.target.value === "" || e.target.value === undefined) {
			setClinicNameError(true);
		}

		setContactData({
			...contactData,
			clinicName: e.target.value,
		});
	};
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	const handleChange = (e) => {
		if (e.target.value === "" || e.target.value === undefined) {
			setEmailErr(true);
			setEmailValidErr(false);
		} else if (!emailRegex.test(e.target.value)) {
			setEmailErr(false);
			setEmailValidErr(true);
		} else if (emailRegex.test(e.target.value)) {
			setEmailValidErr(false);
		}

		setContactData({
			...contactData,
			email: e.target.value,
		});
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			if (contactData.name == '' || contactData.phoneNumber == '' || contactData.email == '' || contactData.clinicName == '') {
				setNameError(true);
				setPhoneError(true);
				setEmailErr(true);
				setClinicNameError(true);
				setLoading(false);
			} else if (contactData.phoneNumber.length !== 10) {
				setPhoneError(true)
				setLoading(false);
			} else {
				let req = {
					name: contactData.name,
					reciever_email: contactData.email,
					organization: contactData.clinicName,
					message: "",
					mobile: "91"+contactData.phoneNumber
				};
 				store.postContactData(req).then(res => {
					setContactData({
						name: "",
						phoneNumber: "",
						email: "",
						clinicName: "",
					});
					setNameError(false);
					setPhoneError(false);
					setEmailErr(false);
					setClinicNameError(false);
 					setToast({ show: true, message:"Email sent successfully", type:"success" })
					showToast()
					setLoading(false);
				})
			}
		} catch (err) {
			setToast({ show: true, message:"Something went wrong", type:"error" })
			showToast()
			setLoading(false);
		}
	};



	return (
		<div className="  mt-[70px] bg-[#f8f8f8]">			
			<div className="w-[100%] hidden lg:flex p-8 py-12 lg:px-8 xl:px-9 min-[1400px]:px-16 2xl:px-16 3xl:px-24 4xl:px-36 5xl:px-56 min-[3500px]:px-56 min-[4000px]:px-[50rem] bg-[#fff] ">
				{/* <img src={mobilehomeBanner} className="block sm:hidden h-[60vh] min-[400px]:h-[40vh] min-[510px]:h-[50vh]" width="100%" /> */}
				<div className="w-[50%] my-auto">
					<div id="HomePageDivhealthCareText" className="text-[26px] min-[400px]:text-[31px] lg:text-[40px] xl:text-[64px] min-[1400px]:text-[66px] 3xl:text-[74px] 4xl:text-[84px] 6xl:text-[100px] font-[800] text-[#878787] mb-[-25px] min-[1400px]:mb-[-30px] font-poppins">Healthcare</div>
					<div id="HomePageDivsoftwareText" className="text-[26px] min-[400px]:text-[31px] lg:text-[40px] xl:text-[64px] min-[1400px]:text-[66px] 3xl:text-[74px] 4xl:text-[84px] 6xl:text-[100px] font-[800] text-[#878787] font-poppins">Software</div>
					<div id="HomePageDivvoiceCaptureText" className="block text-[18px] lg:text-[19px]  2xl:text-[22px] 3xl:text-[24px] 5xl:text-[26px] 6xl:h-[30px]  text-[#000] font-[500] lg:text-[#475659] font-inter">"Voice Capture for Clinical Notes, Patient Engagement</div>
					<div  id="HomePageDivmedicalRecordsText" className="block text-[18px] lg:text-[19px]  2xl:text-[22px] 3xl:text-[24px] 5xl:text-[26px] 6xl:h-[30px]  text-[#000] font-[500] lg:text-[#475659] font-inter">and Medical Records all in one solution"</div>
				</div>
				<div id="HomePagehomeBanner" className="w-[50%]">
					<img id="HomePageImghomeBannerLogo" src={homeBanner} alt="Home Page Banner"
						className="lg:h-[330px] xl:h-[400px] 2xl:h-[450px] 3xl:h-[500px] 4xl:h-[550px] 5xl:h-[650px] 6xl:h-[800px] hidden sm:block object-contain"
						width="100%" />
				</div>
			</div>
			<div className="block lg:hidden my-8 p-4 bg-[#fff]">
				<div id="HomePageDivhealthCareMobile" className="text-[26px] font-[800] text-[#878787] text-center font-poppins">Healthcare</div>
				<div id="HomePageDivsoftwareMobile" className="text-[26px]  font-[800] text-[#878787] mb-8 text-center font-poppins">Software</div>
				<img id="HomePageImgmobileHomeBannerLogo" src={mobilehomeBanner} alt="Home Page Banner"
					className=" block sm:hidden object-contain"
					width="100%" />
				<div id="HomePageDivmobileVoiceCaptureText" className="block text-[18px]  font-[500] text-[#475659] mt-8 text-center font-inter">"Voice Capture for Clinical Notes, Patient Engagement and Medical Records all in one solution"</div>
			</div>



			<div className="p-2 lg:p-20 lg:pt-2 lg:px-0 xl:px-0 min-[1400px]:px-8 2xl:px-16 3xl:px-24 4xl:px-36 5xl:px-56 min-[3500px]:px-56 min-[4000px]:px-[50rem] bg-[#f8f8f8]">
				<div className="hidden lg:grid grid-cols-1 gap-4 m-4 mt-12 bg-[#f8f8f8]">
					<div className="p-6 flex justify-center items-center  w-[100%]">
						<div className=" w-[75%] flex">
							<div id="HomePageDivvoiceIllus" className="w-[40%] flex justify-center items-center">
								<img id="HomePageImgvoiceIllus" src={voiceIllus} className="h-[140px]  2xl:h-[180px]  3xl:h-[170px] w-[350px] object-contain" width="100%"  alt="Home Page Banner"/>
							</div>

							<div className="w-[60%] ml-4">
								<div id="HomePageDivvoiceToText1" className="text-[22px] 2xl:text-[33px] pb-6 font-semibold text-[#d50032] text-left font-poppins">Voice to Text</div>
								<div id="HomePageDivtypingNotesText" className="text-[18px] 2xl:text-[24px] text-[#062126] font-[500] text-left font-inter">Typing notes is a thing of the past with our advanced Voice to text feature. Just dictate your note into our app on your phone and the software
									does the rest.</div>
							</div>

						</div>
					</div>
					<div className="p-6 flex justify-center items-center w-[100%]">
						<div className=" w-[75%] flex">
							<div className="w-[60%] mr-4">
								<div id="HomePageDivautomatedClincalNotes" className="text-[22px] 2xl:text-[33px] pb-6 font-semibold text-[#d50032] text-right font-poppins">Automated Clinical Notes</div>
								<div id="HomePageDivourSoftwareUsesAdvanceAIText" className="text-[18px] 2xl:text-[24px] text-[#062126] font-[500] text-right font-inter">Our software uses advanced Al tools to automatically convert a voice note to summarized clinical note with all the relevant information organized in easy-to-read sections</div>
							</div>
							<div id="HomePageDivclinicalNotesIllus" className="w-[40%] flex justify-center items-center">
								<img id="HomePageImgclinicalNotesIllus" src={clinicalNotesIllus} className="h-[140px]  2xl:h-[200px] 3xl:h-[180px] 5xl:h-[140px] w-[350px] object-contain" width="100%" alt="Home Page Banner"/>
							</div>
						</div>
					</div>
					<div className="p-6  flex justify-center items-center w-[100%]">
						<div className=" w-[75%] flex">
							<div className="w-[40%] flex justify-center items-center">
								<img id="HomePagemobImgappointmentIllus2"  src={appointmentIllus} className="h-[140px]  2xl:h-[200px] 3xl:h-[180px] 5xl:h-[140px] w-[350px] object-contain ml-[80px]" width="100%"  alt="Home Page Banner"/>
							</div>
							<div className="w-[60%] ml-4">
								<div id="HomePageDivbookAppointmentsText" className="text-[22px] 2xl:text-[33px] pb-6 font-semibold text-[#d50032] font-poppins">Book Appointments</div>
								<div id="HomePageDivaddAppointementsRightText" className="text-[18px] 2xl:text-[24px] text-[#062126] font-[500] font-inter">Add appointments right from the app with our easy-to-use appointment scheduling system. No more missed appointments or needing to have patients call the front desk.</div>
							</div>
						</div>
					</div>

					<div className="p-6 flex justify-center items-center w-[100%]">
						<div className=" w-[75%] flex">
							<div className="w-[60%] mr-4">
								<div id="HomePageDivprescriptionsText" className="text-[22px] 2xl:text-[33px] pb-6 font-semibold text-[#d50032] font-poppins text-right">Prescriptions</div>
								<div id="HomePageDivaddPrescriptionsDirectlyIntoSoftwareText" className="text-[18px] 2xl:text-[24px] text-[#062126] font-[500] font-inter text-right">Add prescriptions directly into our software or snap a picture of a handwritten prescription.
									Both can be automatically sent the pharmacy
									of your choice.</div>
							</div>
							<div id="HomePageDivprescriptionIllus" className="w-[40%] flex justify-center items-center">
								<img id="HomePageImgprescriptionIllus" src={prescriptionIllus} className="h-[140px] 2xl:h-[177px] 3xl:h-[177px] 5xl:h-[140px] w-[350px] object-contain mr-[120px]" width="100%"  alt="Home Page Banner"/>
							</div>

						</div>
					</div>

					<div className="p-6 flex justify-center items-center w-[100%]">
						<div className=" w-[75%] flex">
							<div id="HomePageDivdocumentsIllus" className="w-[40%] flex justify-center items-center">
								<img id="HomePageImgdocumentsIllus" src={documentsIllus} className="h-[140px] 2xl:h-[200px] 3xl:h-[180px] 5xl:h-[140px]  w-[250px] object-contain ml-[155px]" width="100%"  alt="Home Page Banner"/>
							</div>
							<div className="w-[60%] ml-4">
								<div id="HomePageDivscanUploadDocumentsText" className="text-[22px] 2xl:text-[33px] pb-6 font-semibold text-[#d50032] font-poppins">Scan & Upload Documents</div>
								<div id="HomePageDivSnapPictureLabReportsText" className="text-[18px] 2xl:text-[24px] text-[#062126] font-[500] font-inter">Snap a picture of lab reports, prescriptions or any other medical records. Images are stored directly under the patient file and can be transcribed to text so they can be searched.</div>
							</div>
						</div>
					</div>
					<div className="p-6 flex justify-center items-center w-[100%]">
						<div className=" w-[75%] flex">
							<div className="w-[60%] mr-4">
								<div id="HomePageDivelectronicMedicalRecordsText" className="text-[22px] 2xl:text-[33px] pb-6 font-semibold text-[#d50032] text-right font-poppins">Electronic Medical Records</div>
								<div id="HomePageDivmakingSoftwareExtremelyEasyText" className="text-[18px] 2xl:text-[24px] text-[#062126] font-[500] text-right font-inter">We have put in a lot of thought into making our software extremely easy to use. In addition, our state-of-the-art security features ensure all patient records are stored
									securely.</div>
							</div>
							<div id="HomePageDivelectronicMedialIllus" className="w-[40%] flex justify-center items-center">
								<img id="HomePageImgelectronicMedialIllus" src={electronicMedialIllus} className="h-[140px] 2xl:h-[200px] 3xl:h-[180px] 5xl:h-[140px] w-[350px] object-contain mr-[120px]" width="100%"  alt="Home Page Banner"/>
							</div>
						</div>
					</div>
				</div>
				<div className=" grid lg:hidden grid-cols-1 gap-4 m-4 mt-12 bg-[#f8f8f8]">
					<div className=" flex justify-center items-center  w-[100%]">
						<div className=" ">
							<div id="HomePageDivvoicetoText2" className="text-[22px] 2xl:text-[24px] pb-4 font-semibold text-[#d50032] text-center mb-[-24px] font-poppins">Voice to Text</div>
							<div className=" flex justify-center items-center">
								<img id="HomePageImgvoiceIllus2" src={voiceIllus} className="h-[150px] w-[250px] object-contain" width="100%"  alt="Home Page Banner"/>
							</div>
							<div id="HomePageDivadvancedVoicetoTextFeature" className="text-[18px] 2xl:text-[24px] 3xl:text-[30px] font-[500]  my-4 text-center text-[#062126] mb-8 font-inter">Typing notes is a thing of the past with our advanced Voice to text feature. Just dictate your note into our app on your phone and the software
								does the rest.</div>
						</div>
					</div>
					<div className=" flex justify-center items-center w-[100%]">
						<div className="">
							<div id="HomePageDivautomatedClinicalNotes2" className="text-[22px] 2xl:text-[24px] pb-4 font-semibold text-[#d50032] text-center mb-[-16px] font-poppins">Automated Clinical Notes</div>
							<div id="HomePageDivmobclinicalNotesIllus2" className=" flex justify-center items-center">
								<img id="HomePageImgclinicalNotesIllus2" src={clinicalNotesIllus} className="h-[150px] w-[250px] object-contain" width="100%"  alt="Home Page Banner"/>
							</div>
							<div id="HomePageDivconvertVoiceNotetoSummarizedClinicalNoteText"  className="text-[18px] 2xl:text-[24px] 3xl:text-[30px] font-[500] my-4 text-center text-[#062126] mb-8 font-inter">Our software uses advanced Al tools to automatically convert a voice note to summarized clinical note with all the relevant information organized in easy-to-read sections</div>
						</div>
					</div>

					<div className=" flex justify-center items-center w-[100%]">

						<div className="">
							<div id="HomePageDivBookAppointementText2" className="text-[22px] 2xl:text-[24px] pb-4 font-semibold text-[#d50032] text-center font-poppins">Book Appointments</div>
							<div id="HomePageDivappointmentIllus2" className=" flex justify-center items-center">
								<img id="HomePageImgappointmentIllus2" src={appointmentIllus} className="h-[150px] w-[250px] object-contain" width="100%"  alt="Home Page Banner"/>
							</div>
							<div className="text-[18px] 2xl:text-[24px] 3xl:text-[30px] font-[500] my-4 text-center text-[#062126] mb-8 font-inter">Add appointments right from the app with our easy-to-use appointment scheduling system. No more missed appointments or needing to have patients call the front desk.</div>
						</div>
					</div>


					<div className=" flex justify-center items-center w-[100%]">
						<div className="">
							<div id="HomePageDivprescription2" className="text-[22px] 2xl:text-[24px] pb-4 font-semibold text-[#d50032] text-center font-poppins">Prescriptions</div>
							<div id="HomePageDivprescriptionIllus2" className=" flex justify-center items-center">
								<img src={prescriptionIllus} className="h-[150px] w-[250px] object-contain" width="100%"  alt="Home Page Banner"/>
							</div>
							<div id="HomePageDivaddPrescriptionsDirectlyIntoSoftwareText2" className="text-[18px] 2xl:text-[24px] 3xl:text-[30px] font-[500] my-4 text-center text-[#062126] mb-8 font-inter">Add prescriptions directly into our software or snap a picture of a handwritten prescription.
								Both can be automatically sent the pharmacy
								of your choice.</div>
						</div>
					</div>

					<div className="flex justify-center items-center w-[100%]">
						<div className="">
							<div id="HomePageDivscanUploadDocumentsText2" className="text-[22px] 2xl:text-[24px] pb-4 font-semibold text-[#d50032] text-center font-poppins">Scan & Upload Documents</div>
							<div className=" flex justify-center items-center">
								<img id="HomePageImgdocumentsIllus2"  src={documentsIllus} className="h-[150px] w-[180px] object-contain" width="100%"  alt="Home Page Banner"/>
							</div>
							<div id="HomePageDivprescriptionsOrRecordsText" className="text-[18px] 2xl:text-[24px] 3xl:text-[30px] font-[500] my-4 text-center text-[#062126] mb-8 font-inter">Snap a picture of lab reports, prescriptions or any other medical records. Images are stored directly under the patient file and can be transcribed to text so they can be searched.</div>
						</div>
					</div>
					<div className=" flex justify-center items-center w-[100%]">
						<div className="">
							<div id="HomePageDivelectronicMedicalRecordsText2" className="text-[22px] 2xl:text-[24px] pb-4 font-semibold text-[#d50032] text-center font-poppins">Electronic Medical Records</div>
							<div className="flex justify-center items-center">
								<img id="HomePageImgelectronicMedialIllus2"  src={electronicMedialIllus} className="h-[150px] w-[250px] object-contain" width="100%"  alt="Home Page Banner"/>
							</div>
							<div id="HomePageDivmakingSoftwareExtremelyEasyText2" className="text-[18px] 2xl:text-[24px] 3xl:text-[30px] font-[500] my-4 text-center text-[#062126] mb-8 font-inter">We have put in a lot of thought into making our software extremely easy to use. In addition, our state-of-the-art security features ensure all patient records are stored
								securely.</div>
						</div>

					</div>
				</div>
			</div>
			<div className="p-2 py-20 lg:py-20 lg:p-20 bg-[#fff] lg:px-0 xl:px-0 min-[1400px]:px-8 2xl:px-16 3xl:px-24 4xl:px-36 5xl:px-56 min-[3500px]:px-56 min-[4000px]:px-[50rem]">
				<div id="HomePageDivpatientManagementText" className=" lg:flex justify-center lg:items-center text-center text-[30px] lg:text-[40px] 2xl:text-[50px] 3xl:text-[60px]  font-[600] text-[#d10031] font-poppins">Patient Management Software with</div>
				<div id="HomePageDivautomatedClinicalNotes3" className="lg:flex justify-center lg:items-center text-center text-[30px] lg:text-[40px] 2xl:text-[50px] 3xl:text-[60px]  font-[600] text-[#282358] font-poppins">Automated Clinical Notes</div>
				<div id="HomePageDivemrAxisComprehensivePatientManagementText" className="text-center mt-[20px] text-[18px] 2xl:text-[24px] lg:leading-6 xl:leading-8 2xl:leading-10  text-[#474747] font-semibold font-inter">EMR Axis is the most comprehensive Patient Management system that helps streamline the entire patient management process. Right from the
					setting of the appointment to the generation of the clinical notes and the patient invoice. Our state-of-the-art Voice and Al system allows doctors to use dictation or ambient listening to automatically generate the clinical note. Doctors focus on providing patient care while we take
					care of the rest.
				</div>
			</div>
			<div id="contact-section" ref={contactRef}>
			<div className=" bg-[#f8f8f8]" ref={targetDivRef}>
				<div className="relative ">
					<img id="HomePageImgBannerSubmit" src={submitBanner} width={"100%"} className="h-[70vh] min-[400px]:h-[56vh] min-[510px]:h-[70vh] sm:h-[50vh]  lg:h-[450px] 4xl:h-[500px] 5xl:h-[600px]" alt="Home Page Banner" />
					<div className="absolute inset-0 flex items-center justify-center">
						<div className="bg-white p-4 rounded shadow-lg">
							<h2 className="text-xl 2xl:text-2xl my-2 md:my-4 5xl:my-6 font-bold text-center text-[#d50032]">Get In Touch</h2>
							<div className="w-[67vw] lg:w-[80vw] 3xl:w-[70vw] xl:px-20">
								<div>
									<input id="HomePageInputcontactName"
										type="text"
										className="border border-gray-300 p-2 2xl:p-4 5xl:p-6 bg-[#f4f4f4] m-2 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 w-[90%] lg:w-[99%]"
										placeholder="Your First Name"
										value={
											contactData.name
												? contactData.name
												: ""
										}
										onChange={(e) => {
											setNameError(false);
											handleNameChange(e);
											setContactData({
												...contactData,
												name: e.target.value,
											});
										}}
									/>
									{nameError && (
										<p id="HomePagePenterNameText" className="text-red-400 text-left text-xs ml-2">
											Please Enter Name
										</p>
									)}
								</div>
								<div className="w-[100%] block lg:flex">
									<div className="block w-[100%] lg:w-[50%]">
										<input id="HomePageInputcontactEmail"
											type="text"
											className="border border-gray-300 p-2 2xl:p-4 5xl:p-6 bg-[#f4f4f4] m-2 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 w-[90%] lg:w-[98%] 4xl:w-[99%]  lg:mr-1"
											placeholder="Email"
											value={
												contactData.email
													? contactData.email
													: ""
											}
											onChange={(e) => {
												setEmailErr(false);
												setEmailValidErr(false);
												handleChange(e);
												setContactData({
													...contactData,
													email: e.target.value,
												});
											}}
										/>
										{emailErr && (
											<>
												<p id="HomePagePenterEmailText" className="text-red-400 text-left text-xs ml-2">
													Please enter the email
												</p>
											</>
										)}
										{emailValidErr && (
											<>
												<p id="HomePagePenterValidEmailText" className="text-red-400 text-left text-xs ml-2">
													Please enter a valid email
												</p>
											</>
										)}
									</div>
									<div className="block w-[100%] lg:w-[50%]">
									<input
                                	 id="HomePageInputphoneNumber"
  									 type="tel"
  									 className="border border-gray-300 p-2 2xl:p-4 5xl:p-6 bg-[#f4f4f4] m-2 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 w-[90%] lg:w-[98%] 4xl:w-[99%] lg:mr-1"
  									 maxLength={14} 
 									 value={`+91 ${contactData.phoneNumber.replace(/(\d{5})(\d{5})/, '$1-$2')}`}  
 									 onChange={(e) => {
    								 const input = e.target.value.replace(/\D/g, '');  
    								 const phoneNumber = input.slice(2);  
   									 if (phoneNumber.length <= 10) {  
     								 setContactData({
       								 ...contactData,
       								 phoneNumber,
     								 });
   									 }
 									 }}
  									style={{
    								backgroundImage: 'url(https://flagcdn.com/w80/in.png)',
    								backgroundPosition: 'left 10px center',
   								    backgroundRepeat: 'no-repeat',
   								    backgroundSize: '35px 25px',
    								paddingLeft: '55px', 
   									}}
									/>
										{phoneError && (
											<p id="HomePagePphoneNmberValidation" className="text-red-400 text-left text-xs ml-4">
												Phone number is not valid
											</p>
										)}
									</div>
								</div>
								<div>
									<input id="HomePageInputclinicName"
										type="text"
										className="border border-gray-300 p-2 2xl:p-4 5xl:p-6 bg-[#f4f4f4] m-2 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 w-[90%] lg:w-[99%]"
										placeholder="Hospital/Clinic Name"
										value={
											contactData.clinicName
												? contactData.clinicName
												: ""
										}
										onChange={(e) => {
											setClinicNameError(false);
											handleClinicChange(e);
											setContactData({
												...contactData,
												clinicName: e.target.value,
											});
										}}
									/>
									{clinicNameError && (
										<p id="HomePagePenterClinicNameText" className="text-red-400 text-left text-xs ml-2">
											Please Enter Clinic Name
										</p>
									)}
								</div>
								<div id="HomePageDivsubmit" className="flex justify-center items-center my-4" onClick={handleSubmit}>
									<div className="text-white text-[13px] xl:text-[16px] bg-red-800 p-2  rounded-lg hover:cursor-pointer px-6 w-[88px] lg:w-[100px]">{loading ? <Loader /> : 'Submit'}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			</div>
			{toast.show && (
				<SnackBarComponent
				message={toast.message}
				type={toast.type}
				onClose={() => setToast({ show: false, message: '', type: '' })}
				/>
			)}
			<RequestFloatingButton scrollToDiv={scrollToDiv} />
		</div>
	);
};

export default HomePage;
