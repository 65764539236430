import React, { useState } from 'react'
import DateFunction from '../../../utils/DateFunctions';
import { Paper, Tooltip } from "@mui/material";
import AppDetailsPopover from './AppDetailsPopover';
import Popover from "@mui/material/Popover";
import { setAppGridViewAppointmentData } from '../../../Redux/features/appointmentGridView/gridviewSlice';
import { useDispatch, useSelector } from 'react-redux';

const TodayView = ({ todaysAppointments, doctors, doctorsTimeslot, setRefreshData, deleteAppointment }) => {

    const [appointmentDetailRef, setAppointmentDetailRef] = useState(null);
    var sideDateToShow = useSelector((state) => state.appGridViewToday.sideDateToShow);

    const dispatch = useDispatch();

    const editVisitHistory = (appointment_id, patient_id, doctor_id, clinicGuid) => {
        const data = btoa(
            JSON.stringify({
                appointment_id: appointment_id,
                patient_id: patient_id,
                doctor_id: doctor_id,
                clinic_guid: clinicGuid,
            })
        );
        window.location.href = `/past-history-details?history=${data}`;
    };

    const openPatientProfile = (app) => {
        if (app.status === "Completed") {
            editVisitHistory(app.appointment_id, app.patient_id, app.doctor_id || 0, app.clinic_guid)
        } else {
            localStorage.setItem("NavigationScreen", "appointments");
            let data = btoa(
                JSON.stringify({
                    appointment_id: app.appointment_id,
                    patient_id: app.patient_id,
                    doctor_id: app.doctor_id || 0,
                    clinic_guid: app.clinic_guid,
                    page_src: "appointment",
                })
            );
            window.location.href = `/patient-profile?profile=${data}`;
        }
    };

    const handleAppointmentDetailPopup = (evt, appointment) => {
        dispatch(setAppGridViewAppointmentData(appointment))
        setAppointmentDetailRef(evt.currentTarget);
    }

    const handleAppointmentDetailPopupClose = () => {
        setAppointmentDetailRef(null);
    }

    return (
        <div id="divAppointmentDetailToday">
            {Boolean(appointmentDetailRef) && (
                <Popover
                    id={
                        Boolean(appointmentDetailRef)
                            ? "appointment-details-view-popup"
                            : undefined
                    }
                    open={Boolean(appointmentDetailRef)}
                    anchorEl={appointmentDetailRef}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left', // Anchor popover to the left side of the row
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right', // Adjusts popover's alignment relative to the anchor
                    }}
                    onClose={() => handleAppointmentDetailPopupClose()}
                    PaperProps={{
                        sx: {
                            width: "20rem",
                            borderRadius: "8px",
                            backgroundColor: "#fff",
                            marginBottom: "50px",
                            zIndex: "999999",
                            position: "sticky",
                            bottom: "50px"
                        },
                    }}
                >
                    <Paper>
                        <AppDetailsPopover doctors={doctors} doctorsTimeslot={doctorsTimeslot} setRefreshData={setRefreshData} setAppointmentDetailRef={setAppointmentDetailRef} deleteAppointment={deleteAppointment} />
                    </Paper>
                </Popover>
            )}
            <div className='border border-[#b0b0b0] mx-1 bg-white min-h-72 rounded-md shadow-md mb-10' id="todaySection">
                <div className='border-b p-2'>
                    {
                        sideDateToShow && DateFunction.TodayDateCompare(sideDateToShow) ?
                            <p>
                                <span id="spanTodayDate" className='text-[#A31B1B] font-semibold'>TODAY</span><span className='pl-2'>{DateFunction.GetDate(new Date())}</span>
                            </p>
                            :
                            <p>
                                <span className='pl-2'>{DateFunction.GetDate(sideDateToShow)}</span>
                            </p>
                    }

                </div>
                {todaysAppointments.length > 0 ?
                    <div className='p-1'>
                        <table id="tableTodayDate" className='table-fixed w-full overflow-y-auto'>
                            <tbody id="tbodyTodayDate">
                                {
                                    todaysAppointments.map((tod, ind) => (
                                        <tr id={"trtodayDate" + ind} key={'today-appointment-table' + ind} className='border-b cursor-pointer w-full' onClick={(evt) => handleAppointmentDetailPopup(evt, tod)}>
                                            <td id={"tdTodayDate" + ind} className='whitespace-nowrap p-1 text-sm w-16'>
                                                {DateFunction.Convert12Hour(tod.app_time) || "N/A"}
                                            </td>
                                            <td id={"tdTodayName" + ind} className='py-3  min-w-[20%]'>
                                                <p className='border-l-2 border-[#282358] ml-2 pl-1 truncate text-left text-sm'>
                                                    <Tooltip
                                                        title={tod.FirstName + " " + tod.MiddleName?.charAt(0) + " " + tod.LastName}
                                                        placement="top-start"
                                                    >
                                                        {tod.FirstName + " " + tod.MiddleName?.charAt(0) + " " + tod.LastName}
                                                    </Tooltip>
                                                </p>
                                            </td>
                                            <td id={"tdTodayStatus" + ind} className='py-2 text-sm w-20'>
                                                {
                                                    tod.status === "New" && <p>
                                                        <span className='bg-blue-200 truncate text-blue-800 p-1'>
                                                            <Tooltip
                                                                title={tod.status}
                                                                placement="top-start">
                                                                {tod.status}
                                                            </Tooltip>
                                                        </span>
                                                    </p>
                                                }
                                                {
                                                    tod.status === "Pending" && <p>
                                                        <span className='bg-yellow-200 truncate  p-1 text-yellow-800 '>
                                                            <Tooltip
                                                                title={tod.status}
                                                                placement="top-start">
                                                                {tod.status}
                                                            </Tooltip>
                                                        </span>
                                                    </p>
                                                }
                                                {
                                                    tod.status === "Completed" && <p>
                                                        <span className='bg-green-200 truncate text-green-800 p-1'>
                                                            <Tooltip
                                                                title={tod.status}
                                                                placement="top-start">
                                                                {tod.status}
                                                            </Tooltip>
                                                        </span>
                                                    </p>
                                                }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    :
                    <p className='text-[#A31B1B] p-2'>No appointments found!</p>}
            </div>
        </div>
    )
}

export default TodayView