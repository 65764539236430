import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "../../API/store";
import AddMembers from "./AddMembers";
import { useNavigate } from "react-router-dom";
import SnackBarComponent from "../../components/SnackBarComponent/SnackBarComponent"
import DataDecode from "../../utils/DataDecode";
import {
  addFacilityUser,
  addFacilityPhone,
  addUserEmail,
  addUserRole,
} from "../../Redux/features/users/userSlice";
import Toggle from "./Toggle";
import { setNotification } from "../../Redux/features/toast/toastSlice";
import { theme } from "../../EmraxisTheme/Theme";

import SearchIcon from "@mui/icons-material/Search";

import { OutlinedInput, InputAdornment } from "@mui/material";

const Users = () => {
  const navigate = useNavigate();

  var isDesktopUserScreenOpen = useSelector(
    (state) => state.drawer.isDesktopUserScreenOpen
  );
  const [edit, setedit] = useState("create");
  const [showPopup, setShowPopup] = useState(false);
  const [userdata, setuserdata] = useState([]);

  const [searchText, setSearchText] = useState("");
  var loggeduser = useSelector((state) => state.login.loginuserData);
  var loggedusertoken = useSelector((state) => state.login.userBarerToken);
  var facilityName = useSelector((state) => state.facility.facility_name);
  var facilityLogo = useSelector((state) => state.facility.facility_logo);
  var facilityID = useSelector((state) => state.facility.facility_id);
  var facilityColor = useSelector((state) => state.facility.facility_color);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeRole, setActiveRole] = useState(null);
  const [isAdminDropdownOpen, setIsAdminDropdownOpen] = useState(false);
  const [isTestDropdownOpen, setIsTestDropdownOpen] = useState(false);
  const [isStandarduserDropdownOpen, setIsStandarduserDropdownOpen] =
    useState(false);
  const [isManagerDropdownOpen, setIsManagerDropdownOpen] = useState(false);
  const [isTextVisible, setIsTextVisible] = useState(false);
  const [editableText, setEditableText] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [toast, setToast] = useState({ show: false, message: '', type: '' });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("UserManagement");
  const [userDetails, setUserDetails] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
 
  const UserRoles = [{ name: "Admin" }, { name: "Doctor" }, { name: "Users" }];
  const previewTheme =
  useSelector((state) => state.theme.colors) || theme.defaultTheme;
  const showToast = () => {

    setTimeout(() => {
      setToast({ show: false, message: '', type: '' });
    }, 3000);
  };
  const handleSuperAdminDeleteUser = async () => {
     
    const adminUsers = userdata.filter(user => user.user_role === 'Admin');
    if(adminUsers.length <=1 && userDetails.user_role ==="Admin"){
      dispatch(
        setNotification({
          message: "We cant Delete Admin user If one Admin left in facility",
          status: "info",
          action: true,
        })
      );
      setDeletePopup(false);
      return;
    };
      if (loggeduser && loggeduser.user_role === "SuperAdmin" && adminUsers.length >= 1) {
      setDisableButton(true);
       const DeleteUser = {
        UserName: userDetails.user_name,
        EmailId: userDetails.email_id,
      };
      await store
        .DeleteUserFromDBAzureAdb2c(DataDecode.encryptPayload(DeleteUser))
        .then((response) => {
            if (response.status === 200) {

            dispatch(
              setNotification({
                message: "User deleted Successfully",
                status: "success",
                action: true,
              })
            );
             fetchUserData();
            setDeletePopup(false);


          } else {
            dispatch(
              setNotification({
                message: "Failed While deleting user",
                status: "error",
                action: true,
              })
            );
          }
          setDisableButton(false);
          setDeletePopup(false);
        });
    } else {
      dispatch(
        setNotification({
          message: "Super Admins can only delete the users",
          status: "error",
          action: true,
        })
      );
      setDeletePopup(false);

    }
  };
  // Load the user details from the database corresponding to that facility
  const fetchUserData = async () => {
     await store
      .GetFacilityUserDetails(
        loggedusertoken,
        DataDecode.encryptPayload(facilityID)
      )
      .then((result) => {
        if (result.status == 200) {
          result.json().then((res) => {
             //let response = DataDecode.decryptResponse(res)
            //var myObject = JSON.parse(response);
            setUserDetails(res);
            setuserdata(res);
          });
        } else {
        }
      })
      .catch((err) => {
        setToast({ show: true, message: "Error occured", type: "error" });
        showToast();
        setLoading(false);
       
      
      });
  };
  useEffect(() => {
    if (loggeduser && loggeduser.user_role === "SuperAdmin") {
      fetchUserData();
    }
  }, [
    loggeduser,
    loggedusertoken,
    facilityID,
    facilityName,
    showPopup,
    refreshData,
  ]);

  const handleFacilityEdit = (userdata) => {
    //allow only the Phone number and role to edit
    //add all the credentials in the redux store
    if (userdata.user_status == true && userdata.user_verified == true) {
      dispatch(addFacilityUser(userdata.user_name));
      dispatch(addFacilityPhone(userdata.phonenumber));
      dispatch(addUserEmail(userdata.email_id));
      dispatch(addUserRole(userdata.user_role));
      setedit("edit");
      setShowPopup(true);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDelete = (role) => {
    // Add delete logic here
    
  };

  const handleEdit = (role) => {
    // Add edit logic here
    
  };
  const toggleDropdown = (role) => {
    if (activeRole === role) {
      setIsDropdownOpen(!isDropdownOpen);
    } else {
      setActiveRole(role);
      setIsDropdownOpen(true);
    }
  };
  const handleAdminSvgClick = () => {
    setIsAdminDropdownOpen(!isAdminDropdownOpen);
    setIsTestDropdownOpen(false);
    setActiveRole("Admin");
  };

  const handleTestSvgClick = () => {
    setIsTestDropdownOpen(!isTestDropdownOpen);
    setIsAdminDropdownOpen(false);
    setActiveRole("Test");
  };
  const handleStandarduserSvgClick = () => {
    setIsStandarduserDropdownOpen(!isStandarduserDropdownOpen);
    setActiveRole("Standarduser");
  };
  const handleManagerSvgClick = () => {
    setIsManagerDropdownOpen(!isManagerDropdownOpen);
    setIsStandarduserDropdownOpen(false);
    setActiveRole("Manager");
  };
  const handleToggleAdminText = () => {
    setShowOptions(!showOptions);
  };

  const handleOptionSelect = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(
        selectedOptions.filter((selected) => selected !== option)
      );
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };
  
  const FullRoles = [
    "Invite User",
    "Create Doctor",
    "Create Patient",
    "Schedule Appointment",
    "CheckIn & CheckOut",
    "Signoff Appointment",
    "View Doctor",
    "Vieww Patient",
    "View Appointment",
  ];
  const Admin = [
    "Invite User",
    "Create Doctor",
    "Create Patient",
    "Schedule Appointment",
    "CheckIn & CheckOut",
    "Signoff Appointment",
    "View Doctor",
    "Vieww Patient",
    "View Appointment",
  ];
  const Doctor = [
    "Schedule Appointment",
    "CheckIn & CheckOut",
    "Signoff Appointment",
    "View Doctor",
    "Vieww Patient",
    "View Appointment",
  ];
  const User = [
    "Create Patient",
    "Schedule Appointment",
    "CheckIn & CheckOut",
    "View Doctor",
    "Vieww Patient",
    "View Appointment",
  ];

  const handleToggleText = () => {
    setIsTextVisible((prevState) => !prevState);
  };
 
  return (
    <div className="bg-white mx-auto px-4  my-20 w-100%">
      <div className="flex  items-center lg:ml-1 ">
        <div className=" flex items-center "></div>
      </div>
      <div className="flex justify-between items-center lg:ml-1 ">
        <div className="text-[#A31B1B] font-bold text-2xl ml-2">Users</div>
        <div className="flex">
          <span className="flex justify-between items-center px-6 py-4 transition delay-700 duration-1000 ease-in">
            <OutlinedInput
              id="UsersOutlinedtableDataSearchField"
              type="text"
              size="small"
              fullWidth
              placeholder="Search"
              value={searchText}
              onChange={(e) => {
                e.stopPropagation();
                setSearchText(e?.target?.value);
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon id="UsersSearchIcon" fontSize="small" />
                </InputAdornment>
              }
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused": {
                    border: "2px solid #1a8aa8",
                    background: "#F5F5F5",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  },
                  "& fieldset": {
                    borderColor: "transparent", // Hide default border
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent", // Hide hover border
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent", // Hide focus border
                  },
                },
                "& .MuiOutlinedInput-input": {
                  "&:focus": {
                    outline: "none",
                    boxShadow: "none",
                  },
                },
              }}
            />
          </span>

          <div className="flex justify-between items-center mb-4 px-2 mt-4">
            <svg id="UsersSvgsearchIcon"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.5333 8.46671C22.5465 7.47317 21.3728 6.68479 20.0799 6.14702C18.7869 5.60924 17.4003 5.33271 16 5.33338C10.1067 5.33338 5.34668 10.1067 5.34668 16C5.34668 21.8934 10.1067 26.6667 16 26.6667C20.9733 26.6667 25.12 23.2667 26.3067 18.6667H23.5333C22.9841 20.2261 21.9645 21.5765 20.6151 22.5318C19.2658 23.4871 17.6533 24.0001 16 24C11.5867 24 8.00001 20.4134 8.00001 16C8.00001 11.5867 11.5867 8.00004 16 8.00004C18.2133 8.00004 20.1867 8.92004 21.6267 10.3734L17.3333 14.6667H26.6667V5.33338L23.5333 8.46671Z"
                fill="#A31B1B"
              />
            </svg>
          </div>
          <h1 className="flex justify-between items-center mb-4 px-2 mt-4">
            <div className=" items-center flex flex-row">
              <button id="UsersBtnnewUser"
                className="bg-red-700 hover:bg-red-800 text-white py-2 px-4 rounded-md flex items-center"
                onClick={() => {
                  setShowPopup(true);
                  setedit("create");
                }}
              >
                <svg id="UsersSvgnewUsericon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6 mr-2"
                >
                  <path d="M5.25 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM2.25 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM18.75 7.5a.75.75 0 0 0-1.5 0v2.25H15a.75.75 0 0 0 0 1.5h2.25v2.25a.75.75 0 0 0 1.5 0v-2.25H21a.75.75 0 0 0 0-1.5h-2.25V7.5Z" />
                </svg>
                Add New User
              </button>
              {showPopup && (
                <AddMembers
                  isOpen={showPopup}
                  onClose={() => setShowPopup(false)}
                  formstatus={edit}
                  userdata={userdata}
                />
              )}
            </div>
          </h1>
        </div>
      </div>
      <div className="flex justify-between mx-auto mt-5 gap-4">
        <div className="flex justify-center w-full"></div>
      </div>
      {/* //UserManagement */}
      {activeTab === "UserManagement" && (
        <div className="text-black">
          <div className="overflow-x-scroll lg:overflow-x-auto hidden lg:block">
            <table className="table-auto w-full border rounded-md focus:outline-none">
              <thead>
                <tr className="px-5 py-2 text-left">
                  <th id="UsersThname" className="px-5 py-2 text-left">Name</th>
                  <th id="UsersThemailId" className="px-2 py-2 text-left">EMail ID</th>
                  <th id="UsersThphoneNumber" className="px-2 py-2 text-left">PhoneNumber</th>
                  <th id="UsersThrole" className="px-2 py-2 text-left">Role</th>
                  <th id="UsersThstatus" className="px-2 py-2 text-left">Status</th>
                  <th id="UsersThactive" className="px-2 py-2 text-left ">InActive/Active</th>
                  <th id="UsersThaction" className="px-2 py-2 text-left">Action</th>
                  <th id="UsersThDelete" className="px-2 py-2 text-left">  Delete </th>
                </tr>
              </thead>
              <tbody>
                {userdata.map((userdata, index) => (
                  <tr
                    key={"user-table-" + index}
                    className="odd:bg-white even:bg-gray-100"
                  >
                    <td id="UsersTduserName" className="px-2 py-2 text-left">
                      {userdata.user_name || "-"}
                    </td>
                    <td id="UsersTdemailId" className="px-2 py-2 text-left">
                      {userdata.email_id || "-"}
                    </td>
                    <td id="UsersTdphoneNumber" className="px-2 py-2 text-left">
                      {userdata.phonenumber || "-"}
                    </td>
                    <td id="UsersTduserRole" className="px-2 py-2 text-left">
                      {userdata.user_role || "-"}
                    </td>
                    <td id="UsersTduserData" className="px-2 py-2 text-left">
                      {userdata.user_verified == true ? "Verified" : "Pending"}
                    </td>
                    <td className="px-2 py-2 text-left">
                      <Toggle
                        key={"usersactive-toggle-" + index}
                        user={userdata}
                        setRefreshData={setRefreshData}
                      />
                    </td>
                    <td className="px-2">
                      <button id="UsersBtnfacilityEdit"
                        className=" w-6 h-6"
                        onClick={() => handleFacilityEdit(userdata)}
                      >
                        <svg id="UsersSvgfacilityEditIcon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-5 h-5"
                        >
                          <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z" />
                        </svg>
                      </button>
                    </td>
                    <td className="px-2">
                  <button
                    id="DeleteUsersfacilityBtn"
                    className="w-6 h-6 text-red-800 disabled:cursor-not-allowed text-[#9B1C1C] disabled:text-[#D88C8C]"
                    disabled={userdata.user_name === loggeduser.user_name}
                    onClick={(e) => {
                      setUserDetails(userdata);
                      setDeletePopup(true);
                    }}
                  >
                    <svg
                      id="userDeleteActionIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-5 h-5 "
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="lg:hidden">
            {userdata.map((userdata, index) => (
              <div className="grid grid-cols-1 gap-5" key={index}>
                <div className="bg-white rounded-lg p-2 shadow-md  h-auto">
                  <div className="px-2 py-[4px] text-left flex text-[17px] font-[600]">
                    <div id="UsersDivname">Name:</div>{" "}
                    <div id="UsersDivuserName" className="ml-2 text-[#616565]">
                      {userdata.user_name || "-"}
                    </div>
                  </div>
                  <div className="px-2 py-[4px] text-left flex  text-[17px] font-[600]">
                    <div id="UsersDivemailId">EMail ID:</div>
                    <div id="UsersDivuserEmailId" className="ml-2 text-[#616565]">
                      {userdata.email_id || "-"}
                    </div>
                  </div>
                  <div className="px-2 py-[4px] text-left flex  text-[17px] font-[600]">
                    <div id="UsersDivphoneNumber">PhoneNumber:</div>
                    <div id="UsersDivuserPhoneNumber" className="ml-2 text-[#616565]">
                      {userdata.phonenumber || "-"}
                    </div>
                  </div>
                  <div className="px-2 py-[4px] text-left flex  text-[17px] font-[600]">
                    <div id="UsersDivrole">Role:</div>
                    <div id="UsersDivuserRole" className="ml-2 text-[#616565]">
                      {userdata.user_role || "-"}
                    </div>
                  </div>
                  <div className="px-2 py-[4px] text-left flex  text-[17px] font-[600]">
                    <div id="UsersDivstatus">Status:</div>
                    <div id="UsersDivuserData" className="ml-2 text-[#616565]">
                      {userdata.user_verified == true ? "Verified" : "Pending"}
                    </div>
                  </div>
                  <div className="flex justify-between items-center px-2 py-[4px]">
                    {" "}
                    <Toggle
                      key={"usersactive-toggle-" + index}
                      user={userdata}
                      setRefreshData={setRefreshData}
                    />{" "}
                    <button id="UsersBtnfacilityEdit"
                      className=" w-6 h-6"
                      onClick={() => handleFacilityEdit(userdata)}
                    >
                      <svg id="UsersSvgfacilityEditIcon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5 text-blue-600"
                      >
                        <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z" />
                      </svg>
                    </button>
                  <div className="flex justify-between items-center px-2 py-[4px]">
                   <button
                    id="MObDeleteUsersBtnfacility"
                    className="w-6 h-6 text-red-800 disabled:cursor-not-allowed text-[#9B1C1C] disabled:text-[#D88C8C]"
                    disabled={userdata.user_name === loggeduser.user_name}
                    onClick={(e) => {
                      setUserDetails(userdata);
                      setDeletePopup(true);
                    }}
                  >
                    <svg
                      id="MObuserDeleteActionIcon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-5 h-5 "
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
        {deletePopup && (
        <div
          id="UserDivconfirmBoxAppointment"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed left-1/2 top-1/2 w-full max-w-sm transform  -translate-x-1/2 -translate-y-1/2 sm:w-80 sm:left-[40%]  sm:top-[3rem] sm:translate-x-[50%] sm:translate-y-[50%] "
        >
          <div
            id="UserssDivconfirmBoxAppointmentContentDialog"
            className="relative p-4 w-full max-w-md h-full md:h-auto"
          >
            <div
              id="UsersDivconfirmBoxContentDialogBox"
              className="relative p-4 text-center bg-white border-gray-400 border-[1px] rounded-lg shadow-lg dark:bg-gray-800 sm:p-5"
            >
              <svg
                id="UsersSvgconfirmBoxIcon"
                className="text-red-800 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <p
                id="UsersPconfirmBoxMessage"
                className={`mb-4 `}
                style={{ fontFamily: previewTheme.fontFamily }}
              >
                Are you sure you want to delete this Account Permanently?
              </p>
              <div
                id="UsersDivconfirmBoxButtons"
                className="flex justify-center items-center space-x-4"
              >
                <button
                  id="UsersButtonconfirmBoxcancel"
                  onClick={() => setDeletePopup(false)}
                  disabled={disableButton}
                  type="button"
                  className={`py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10  ease-in-out
                  ${previewTheme.cancelBtnColor} 
                  ${previewTheme.cancelBtnBgColor} 
                  ${
                    disableButton
                      ? "opacity-50 cursor-not-allowed"
                      : `hover:${previewTheme.cancelBtnHoverColor}`
                  }
                  `}
                >
                  No, cancel
                </button>

                <button
                  id="UsersButtonconfirmBoxconfirm"
                  type="button"
                  onClick={handleSuperAdminDeleteUser}
                  disabled={disableButton}
                  className={`py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10  ease-in-out
                      ${previewTheme.saveBtnColor} 
                      ${previewTheme.saveBtnBgColor} 
                      ${
                        disableButton
                          ? "opacity-50 cursor-not-allowed"
                          : `hover:${previewTheme.saveBtnHoverColor}`
                      }`}
                >
                  Yes, I'm sure{" "}
                  {disableButton && (
                    <svg
                      id="UsersSvgconfirmBoxIcon"
                      aria-hidden="true"
                      role="status"
                      className="inline w-4 h-4 ml-2 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {toast.show && (
				<SnackBarComponent
				message={toast.message}
				type={toast.type}
				onClose={() => setToast({ show: false, message: '', type: '' })}
				/>
			)}
    </div>
  );
};

export default Users;
