import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest, b2cPolicies, protectedResources } from '../authConfig';
import { setNotification } from "../Redux/features/toast/toastSlice";
import MainPage from './MainPage';
import Footer from './Footer';
import { addLoginDetails, addLoginToken } from '../Redux/features/login/loginSlice';
import MobileFooter from './MobileFooter';
import { theme } from '../EmraxisTheme/Theme';
import themeSlice, { setFont, setTheme } from '../Redux/features/colors/themeSlice';
import { jwtDecode } from "jwt-decode";

import { setIsDesktopUserScreenOpen } from "../Redux/features/drawer/drawerSlice";
import HomePage from './HomePage';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import store from '../API/store';
import MobileSideNavBar from './MobileSideNavBar';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import React, { useRef } from 'react';
import { setAppointmentInvoice, setIsInvoiceEnabled, setShowAppEditInvoice } from '../Redux/features/invoice/invoiceSlice';
import { setActivateIsStandardAiStatus, setActivateVoice2TextStatus } from '../Redux/features/feature/featureSlice';
import { clearSessionStorage } from '../utils/Storage';
import TermsOfService from '../Pages/static/TermsOfService';
import PrivacyPolicy from '../Pages/static/PrivacyPolicy';
import { setIsProfileDrawerOpen } from '../Redux/features/profileDrawer/profileDrawerSlice';
import CustomLogin from './CustomLogin';
import DataDecode from '../utils/DataDecode';
import { setAppGridViewSelection, setAppShowTodaySection, setIsDefaultGridView } from '../Redux/features/appointmentGridView/gridviewSlice';
export const PageLayouts = (props) => {
    const dropdownRef = useRef(null);
    const navigate = useNavigate();
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    // const { instance, inProgress } = useMsal();
    var loggeduser = useSelector(state => state.login.loginuserData);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    const previewTheme = useSelector(state => state.theme.colors) || theme.defaultTheme;
    var isProfileDrawerOpen = useSelector(state => state.profileDrawer.isProfileDrawerOpen);
    const previewFont = useSelector(state => state.theme.fontFamily);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [credPassword, setcredPassword] = useState("");
    const [credUserName, setcredUserName] = useState("");
    const [isCredsOk, setisCredsOk] = useState(false);

    const dispatch = useDispatch();
    let activeAccount;
    const location = useLocation();
    const pathName = location?.pathname?.substring(1);

    const handleClearStorage = () => {
        clearSessionStorage(); // Broadcast clear event
    };

    const handleTimeout = () => {
        if (loggedusertoken != null || loggedusertoken != "") {
            // instance.logout()
            dispatch(addLoginToken(""));
            dispatch(addLoginDetails([]));
            dispatch(setIsInvoiceEnabled(false));
            dispatch(setActivateVoice2TextStatus(false))
            dispatch(setActivateIsStandardAiStatus(false))
            dispatch(setAppointmentInvoice({ patient: {}, doctor: {}, appointment: {}, targetFrom: "" }));
            dispatch(setShowAppEditInvoice(false));
            navigate('/');
        }
    }

    const handleLogoutRedirect = () => {
        // instance.logout()
        store.CreateActivityLogs(loggeduser, pathName?.replace(/-/g, ' ')?.replace(/\b\w/g, char => char.toUpperCase()), `User Logout Successfully`, "Logout");
        handleClearStorage();
        //   instance.logoutRedirect(loginRequest);
        dispatch(addLoginToken(""));
        dispatch(addLoginDetails([]));
        dispatch(setIsInvoiceEnabled(false));
        dispatch(setActivateVoice2TextStatus(false))
        dispatch(setActivateIsStandardAiStatus(false))
        dispatch(setAppointmentInvoice({ patient: {}, doctor: {}, appointment: {}, targetFrom: "" }));
        dispatch(setShowAppEditInvoice(false));
        dispatch(setIsDesktopUserScreenOpen(false))
        dispatch(setIsDesktopUserScreenOpen(false))
        dispatch(setIsProfileDrawerOpen(false));
        navigate('/');
    };


    const toggleDropdown = () => {
        dispatch(setIsProfileDrawerOpen(!isProfileDrawerOpen));
    };
    const handleClick = () => {
        navigate('/UserProfile');
    };
    const handleClickGenerateQR = () => {
        navigate('/GenerateQRCode');
    }
    const handleSettingsClick = () => {
        navigate('/settings');
    };
    const getcreds = async () => {
        const logindetails = {
            Username: credUserName,
            Password: credPassword,
        };
        await store.GetUserCreds(DataDecode.encryptPayload({
            Username: credUserName,
            Password: credPassword,
        }))
            .then(result => result.json())
            .then(res => {
                let keys = JSON.parse(DataDecode.decryptResponse(res)).id_token;
                //console.info(JSON.parse(DataDecode.decryptResponse(res)).access_token);
                if (JSON.parse(DataDecode.decryptResponse(res)).access_token) {
                    setisCredsOk(true);
                    fetchuser(keys, JSON.parse(DataDecode.decryptResponse(res)).access_token);
                } else {
                    setisCredsOk(false);
                    dispatch(
                        setNotification({
                            message: "Incorrect password",
                            status: "error",
                            action: true,
                        })
                    );
                }
            })
            .catch(e => {
                setisCredsOk(false);
            });
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                dispatch(setIsProfileDrawerOpen(false));
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [dropdownRef]);

    const fetchuser = async (bearer, accessToken) => {
        try {
            const decodedToken = jwtDecode(bearer);

            // const bearer = `eyJhbGciOiJSUzI1NiIsImtpZCI6Ilg1ZVhrNHh5b2pORnVtMWtsMll0djhkbE5QNC1jNTdkTzZRR1RWQndhTmsiLCJ0eXAiOiJKV1QifQ.eyJhdWQiOiI5MjBiMTUwNy02MmJjLTRiMTctYTMzYy1hNjdlMmFhM2QzNmQiLCJpc3MiOiJodHRwczovL3dhdmVheGlzaG1zLmIyY2xvZ2luLmNvbS85OTQwMDBhMS0zYWVmLTQ2ZjktYTU1Mi03ODYzMWI4MmQzNzcvdjIuMC8iLCJleHAiOjE3MjM1NDQ0MTYsIm5iZiI6MTcyMzU0MDgxNiwiaWRwIjoiTG9jYWxBY2NvdW50Iiwib2lkIjoiY2E0ZDgxYmMtOWFlMy00MTI3LThiMTYtOWM2MWNjZjQ3YjAwIiwic3ViIjoiY2E0ZDgxYmMtOWFlMy00MTI3LThiMTYtOWM2MWNjZjQ3YjAwIiwibmFtZSI6Ik1NYWF6IiwibmV3VXNlciI6ZmFsc2UsImVtYWlscyI6WyJtYWF6c29mdHRlY2hAZ21haWwuY29tIl0sInRmcCI6IkIyQ18xX0VNUl9ST1BDX0FVVEgiLCJzY3AiOiJGaWxlcy5SZWFkIiwiYXpwIjoiOTIwYjE1MDctNjJiYy00YjE3LWEzM2MtYTY3ZTJhYTNkMzZkIiwidmVyIjoiMS4wIiwiaWF0IjoxNzIzNTQwODE2fQ.msGu20zxNe4ErDhmeqQeHJ6J8xG4cvsgA-l60szSJ8bpWl3VU3TcxmEjOGOVxm33XAZGJ_JsgcI7vCeHMtYdGkqEQhF7eenK0HqUldXMJeWYPomi4XI8MiHr-fTZ8eM2pTokzwJN_XJa3lgKNM799w79eVgamtOACUghxY0ctv1AMFx2XtiEVvowynpUyxHbw2f8sbUO0mZc5PZTaR-n88fr6hJtUOmxbm0FUSuqES0TWbZtG68fh3JxnU081JR1SypZtXa5m3GHZq3eYkQOUhO4Dy5T_3ixpp__QpPKi7mR45ds18D-N0ExHnXAwYaDlkJ1cpti_J5ZLjBGReYeKg`;//activeAccount.idToken;
            // const bearer = `eyJhbGciOiJSUzI1NiIsImtpZCI6Ilg1ZVhrNHh5b2pORnVtMWtsMll0djhkbE5QNC1jNTdkTzZRR1RWQndhTmsiLCJ0eXAiOiJKV1QifQ.eyJhdWQiOiJlZGJkNjE3Ny1hN2IyLTRiMTctODM0ZS02YWZhNTk0ZWZjNTgiLCJpc3MiOiJodHRwczovL3dhdmVheGlzaG1zLmIyY2xvZ2luLmNvbS85OTQwMDBhMS0zYWVmLTQ2ZjktYTU1Mi03ODYzMWI4MmQzNzcvdjIuMC8iLCJleHAiOjE3MjM2MDg0MTIsIm5iZiI6MTcyMzYwNDgxMiwic3ViIjoiY2E0ZDgxYmMtOWFlMy00MTI3LThiMTYtOWM2MWNjZjQ3YjAwIiwibmFtZSI6Ik1NYWF6IiwiZW1haWxzIjpbIm1hYXpzb2Z0dGVjaEBnbWFpbC5jb20iXSwidGZwIjoiQjJDXzFfaG1zX3VzZXJfc2lzdSIsIm5vbmNlIjoiMD…lLTg5OGQtN2JhYjM3NzgzM2FmIiwic2NwIjoiQWRtaW4uV3JpdGUgQWRtaW4uUmVhZCIsImF6cCI6IjY4YzgzZmIyLTBlMmYtNDM2MS05NGQ5LWQ1MTNjZDgzOGE4NyIsInZlciI6IjEuMCIsImlhdCI6MTcyMzYwNDgxMn0.YsxvZYWe2kNKQiDbDIF-8tpvL0n7NfSiZdesZAnMO7BHfp1UEcgTxn2cfW7pDXeIIRTKOTpz4vgH3A9v59y4Y8fwITVRmH90vEspqSMnSDmn17RsOtk3yu5a_qtb_7I8DBRqlgeiPM14NGxAp7rvw5jMHrR8HZ05Df2AepoM38xYXIDAxfW-7WCTo4WSUdzZnumvbmSSfRtcjvrmGZa8vbfvQ6NQPkknY18tJysobtpwyBRjaKY0DBwOsUUMNwxpBhM1_DN8MDIXHVLTeBAKiA7rCl9DeOR29Vsl0hSzY9bB52HAqsvZBuN_EmlITLlth48gX3i4tG26n_Csy8PB3A`;
            //const bearer = `eyJhbGciOiJSUzI1NiIsImtpZCI6Ilg1ZVhrNHh5b2pORnVtMWtsMll0djhkbE5QNC1jNTdkTzZRR1RWQndhTmsiLCJ0eXAiOiJKV1QifQ.eyJleHAiOjE3MjM2MDkyMzAsIm5iZiI6MTcyMzYwNTYzMCwidmVyIjoiMS4wIiwiaXNzIjoiaHR0cHM6Ly93YXZlYXhpc2htcy5iMmNsb2dpbi5jb20vOTk0MDAwYTEtM2FlZi00NmY5LWE1NTItNzg2MzFiODJkMzc3L3YyLjAvIiwic3ViIjoiY2E0ZDgxYmMtOWFlMy00MTI3LThiMTYtOWM2MWNjZjQ3YjAwIiwiYXVkIjoiOTIwYjE1MDctNjJiYy00YjE3LWEzM2MtYTY3ZTJhYTNkMzZkIiwiaWF0IjoxNzIzNjA1NjMwLCJhdXRoX3RpbWUiOjE3MjM2MDU2MzAsImlkcCI6IkxvY2FsQWNjb3VudCIsIm9pZCI6ImNhNGQ4MWJjLTlhZTMtNDEyNy04YjE2LTljNjFjY2Y0N2IwMCIsIm5hbWUiOiJNTWFheiIsIm5ld1VzZXIiOmZhbHNlLCJlbWFpbHMiOlsibWFhenNvZnR0ZWNoQGdtYWlsLmNvbSJdLCJ0ZnAiOiJCMkNfMV9FTVJfUk9QQ19BVVRIIiwiYXRfaGFzaCI6Im9SM0NIbjJhMTRVMTlHTjFYUjhtbEEifQ.fIyBFimoEX81_ouTv_NAkpMkfUGp7gqKSiXPkMDjrQdEZbKgX8JgGZ2Adv4uNCOKMztH3aWv-osaByCFylUZuqcGv7lc6Un-mqhaoEnEjHTJIYN6rGty74rHPvPUqYhvdRuLTnpV287yzmHim4Y9r4on38lZIqtY4QZshSPXRECjI3YzYtLGVH05-ikGZPntbooFS-WsMBXxKjhfZ7sZuu4wVBlTnSfjq--Z0aBT3NtX9Epijnchr9OPRIWu5PUwOAZ97qtjJov9pyHBqNA1hUJ7nl751dlktP3YZF4eRgnfzQYNZx3qaoPKvO3j7-vRMoWADsNR7duC_ihL98M0HA`;
            /*  if (activeAccount.idTokenClaims.extension_signInName == process.env.REACT_APP_EMRAXIS_SuperAdmin &&
                    activeAccount.idTokenClaims.extension_userrole == "Super Admin") */
            if (decodedToken.extension_signInName && decodedToken.extension_signInName == process.env.REACT_APP_EMRAXIS_SuperAdmin) {
                //Entered user is Admin
                //store all the values in redux store

                const logindetails = {
                    email_id: decodedToken.emails[0],
                    facility_name: decodedToken.extension_FacilityName,
                    user_name: decodedToken.extension_signInName,
                    phonenumber: decodedToken.extension_phonenumber,
                    user_role: "SuperAdmin"
                };
                const themeToPreview = theme.filter(t => t.name == "defaultTheme")[0] || {};
                const themeToFont = theme.filter(t => t.name == "Arial, sans-serif") || {};


                dispatch(setTheme(themeToPreview))
                dispatch(setFont("Arial, sans-serif"));

                /* const request = {
                     account: activeAccount,
                     scopes: [
                         ...protectedResources.apiSuperAdmin.scopes.read,
                         ...protectedResources.apiSuperAdmin.scopes.write
                     ],
                 };
                 instance.acquireTokenSilent(request)
                     .then(response => {
                         if (response.accessToken) {
                             dispatch(addLoginToken(response.accessToken));
                         }
 
                     })
                     .catch(error => console.log('token error', error));
                     */
                dispatch(addLoginToken(accessToken));
                dispatch(addLoginDetails(logindetails));
                setIsModalOpen(false);
            }
            else {
                const logindetails = {
                    email_id: decodedToken.emails[0],//"maazsofttech@gmai.com",//activeAccount.idTokenClaims.emails[0],
                    user_name: decodedToken.name,//"MMaaz"//activeAccount.idTokenClaims.name,
                };
                await store.GetUserdetais(logindetails, bearer)
                    .then((result) => {
                        result.json().then((res) => {


                            if (res.email_id != null && res.email_id != "") {
                                let status = res.userSubscriptionstatus;
                                dispatch(addLoginDetails(res));
                                setIsModalOpen(false);
                                //Setting up Invoice Module
                                // console.log('Res-user', res)

                                dispatch(setIsInvoiceEnabled(res?.addon_features?.filter(a => a.feature === "Invoice" && a.is_active).length > 0))
                                dispatch(setActivateVoice2TextStatus(res?.addon_features?.filter(a => a.feature === "Voice_To_Text" && a.is_active).length > 0))
                                dispatch(setActivateIsStandardAiStatus(res?.addon_features?.filter(a => a.feature === "Standard_AI" && a.is_active).length > 0))
                                const themeToPreview = theme.filter(t => t.name == res?.theme_management?.theme)[0] || {};
                                const themeToFont = theme.filter(t => t.name == res?.theme_management?.theme) || {};

                                dispatch(setTheme(themeToPreview))
                                dispatch(setFont(res?.theme_management?.font_family))

                                if (res && res.appointment_grid_view) {
                                    dispatch(setIsDefaultGridView(res.appointment_grid_view?.isDefaultGridView))
                                    dispatch(setAppGridViewSelection(res.appointment_grid_view?.appGridViewSelection))
                                    dispatch(setAppShowTodaySection(res.appointment_grid_view?.appShowTodaySection))
                                }

                                //getting the scope for the users
                                if (res.user_role == "Admin") {
                                    const request = {
                                        account: activeAccount,
                                        scopes: [
                                            ...protectedResources.apiAdmin.scopes.read,
                                            ...protectedResources.apiAdmin.scopes.write
                                        ],
                                    };
                                    dispatch(addLoginToken(accessToken));
                                    // Retrieve an access token
                                    /* instance.acquireTokenSilent(request)
                                         .then(response => {
                                             if (response.accessToken) {
                                                 dispatch(addLoginToken(response.accessToken));
                                             }

                                         })
                                         .catch(error => console.log('token error', error));*/

                                }


                            }
                        })

                    })
                    .catch(err => {
                        dispatch(setNotification({ message: "Error Occurred", status: "error", action: true }));
                    })
            }
        }
        catch (err) {
            dispatch(setNotification({ message: "Error Occurred", status: "error", action: true }));
        }
    };

    return (
        <div style={{ fontFamily: previewFont }}>
            <h1 className="text-xs text-transparent hidden">Healthcare Software</h1>

            {/* {showing} */}
            {/* <IdleTimer timeoutHandler={handleTimeout}/> */}
            <section>

                <header className="bg-white border-b-4 border-gray-200 fixed top-0 w-full z-30">
                    <div className=" mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex justify-between items-center my-3">
                            <div className={loggeduser.length != 0 && loggeduser.user_isactive ? ' lg:hidden xs:block w-10' : "hidden"}>
                                <MobileSideNavBar />
                            </div>
                            <div className="flex-shrink-0">
                                <a href={process.env.REACT_APP_EMRAXIS_MS_AUTH_SPA_REDIRECT_URL}>
                                    <img id="PageLayoutImglogo" className="h-6 lg:h-9 w-auto" src={`${process.env.REACT_APP_EMRAXIS_S3_URL}/logo.png`} alt="Logo" />
                                </a>
                            </div>
                            {/*!loggeduser.user_name && <div >
                                <button id="PageLayouthmssignin" className="float-right bg-[#d50032] hover:bg-[#d50032] text-[#fff] font-semibold py-2 px-4  rounded shadow" onClick={handleLoginRedirect}>
                                    Login
                                </button>
                            </div>*/}
                            {!loggeduser.user_name && <div >
                                <button id="PageLayouthmssignin" className="float-right bg-[#d50032] hover:bg-[#d50032] text-[#fff] font-semibold py-2 px-4  rounded shadow" onClick={() => { setIsModalOpen(true); setcredUserName(""); setcredPassword("") }}>
                                    Login
                                </button>
                            </div>}

                            {loggeduser.user_name && <div className="AuthenticatedTemplate">
                                <div className='flex justify-end'>
                                    {
                                        loggeduser != null && loggeduser.length != 0 && loggeduser.user_role != "SuperAdmin" &&
                                        <label id="PageLayoutLabelfacilityName" className='mt-[10px] mr-3 hidden lg:block'>
                                            <strong className="font-bold">{loggeduser.facility_name}</strong>
                                        </label>


                                    }
                                    <div className=''>
                                        <div className="relative inline-block text-left lg:items-center" ref={dropdownRef}>
                                            <button
                                                id="PageLayoutdropdownAvatarNameButton"
                                                onClick={toggleDropdown}
                                                className="flex items-center bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 md:px-0 lg:px-4 border border-gray-400 rounded-lg shadow"
                                                type="button">
                                                <span id="PageLayoutSpanopenMenuText" className="sr-only">Open user menu</span>
                                                <img
                                                    id="PageLayoutImguserPhoto"
                                                    className="w-8 h-8 me-2 hidden lg:block rounded-full"
                                                    src={
                                                        loggeduser && loggeduser.user_pic
                                                            ? `${process.env.REACT_APP_EMRAXIS_S3_URL}/${loggeduser.user_pic}`
                                                            : "./image/user.png"
                                                    }
                                                    alt="user"
                                                />
                                                <img
                                                    id="PageLayoutImguserPhoto2"
                                                    className="w-8 h-8 mx-2 block lg:hidden rounded-full"
                                                    src={
                                                        loggeduser && loggeduser.user_pic
                                                            ? `${process.env.REACT_APP_EMRAXIS_S3_URL}/${loggeduser.user_pic}`
                                                            : "./image/user.png"
                                                    }
                                                    alt="user"
                                                />

                                                <div id="PageLayoutDivuserName" className='hidden lg:block'>{loggeduser.user_name}</div>
                                            </button>

                                        </div>
                                    </div>
                                    {/* user */}
                                    {isProfileDrawerOpen && (

                                        <div
                                            className="z-30 absolute right-0 mt-[50px] w-80 bg-white divide-y divide-gray-200 rounded-sm shadow dark:bg-gray-700 dark:divide-gray-600"
                                            role="menu"
                                            aria-orientation="vertical"
                                            aria-labelledby="dropdownAvatarNameButton">

                                            <div className="px-4 py-2 text-sm text-gray-900 dark:text-white">
                                                <div className="flex items-center">
                                                    <img
                                                        className="w-20 h-20 mr-2 rounded-full"
                                                        src={
                                                            loggeduser && loggeduser.user_pic
                                                                ? `${process.env.REACT_APP_EMRAXIS_S3_URL}/${loggeduser.user_pic}`
                                                                : "./image/user.png"
                                                        }
                                                        alt="user"
                                                    />
                                                    <div className="p-2 flex flex-col">
                                                        <div className="text-lg">
                                                            <span id="PageLayoutSpanname" className='sr-only'>user name</span>
                                                            {loggeduser.user_name}
                                                        </div>
                                                        <div id="PageLayoutDivuserRoe" className="truncate text-gray-400 capitalize">{loggeduser.user_role}</div>
                                                    </div>
                                                </div>
                                                {loggeduser.user_role !== "SuperAdmin" &&
                                                    <button id="PageLayoutButtonstandardPlan" className="flex items center w-full text-sm text-left mt-2">
                                                        <svg id="PageLayoutSvgstandardPlanIcon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="w-6 h-6 mr-2">
                                                            <path d="M5 16L3 5L8.5 10L12 4L15.5 10L21 5L19 16H5ZM19 19C19 19.6 18.6 20 18 20H6C5.4 20 5 19.6 5 19V18H19V19Z" fill="#FFB800" />
                                                        </svg>
                                                        Standard Plan
                                                    </button>
                                                }
                                            </div>
                                            {loggeduser.user_role !== "SuperAdmin" && <>
                                                <button id="PageLayoutButtonviewProfile" className="flex items center w-full px-4 py-2 text-sm text-left hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" onClick={handleClick}>
                                                    <svg id="PageLayoutSvgviewProfileIcon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className="w-6 h-6 mr-2">
                                                        <path d="M7.50002 10.0001C9.10835 10.0001 10.4167 8.69175 10.4167 7.08342C10.4167 5.47508 9.10835 4.16675 7.50002 4.16675C5.89169 4.16675 4.58335 5.47508 4.58335 7.08342C4.58335 8.69175 5.89169 10.0001 7.50002 10.0001ZM7.50002 5.83341C8.19169 5.83341 8.75002 6.39175 8.75002 7.08342C8.75002 7.77508 8.19169 8.33342 7.50002 8.33342C6.80835 8.33342 6.25002 7.77508 6.25002 7.08342C6.25002 6.39175 6.80835 5.83341 7.50002 5.83341ZM7.50002 11.4584C5.55002 11.4584 1.66669 12.4334 1.66669 14.3751V15.8334H13.3334V14.3751C13.3334 12.4334 9.45002 11.4584 7.50002 11.4584ZM3.61669 14.1667C4.31669 13.6834 6.00835 13.1251 7.50002 13.1251C8.99169 13.1251 10.6834 13.6834 11.3834 14.1667H3.61669ZM13.3667 11.5084C14.3334 12.2084 15 13.1417 15 14.3751V15.8334H18.3334V14.3751C18.3334 12.6917 15.4167 11.7334 13.3667 11.5084ZM12.5 10.0001C14.1084 10.0001 15.4167 8.69175 15.4167 7.08342C15.4167 5.47508 14.1084 4.16675 12.5 4.16675C12.05 4.16675 11.6334 4.27508 11.25 4.45841C11.775 5.20008 12.0834 6.10841 12.0834 7.08342C12.0834 8.05842 11.775 8.96675 11.25 9.70842C11.6334 9.89175 12.05 10.0001 12.5 10.0001Z" fill="#544C4C" />
                                                    </svg>
                                                    View Profile
                                                </button>
                                                {loggeduser.user_role === "Admin" &&
                                                    <button id="PageLayoutButtonGenerateQR" className="flex items center w-full px-4 py-2 text-sm text-left hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" onClick={handleClickGenerateQR}>
                                                        <svg id="PageLayoutSvgGenerateQR" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 mr-2">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 3.75 9.375v-4.5ZM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 0 1-1.125-1.125v-4.5ZM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 13.5 9.375v-4.5Z" />
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 6.75h.75v.75h-.75v-.75ZM6.75 16.5h.75v.75h-.75v-.75ZM16.5 6.75h.75v.75h-.75v-.75ZM13.5 13.5h.75v.75h-.75v-.75ZM13.5 19.5h.75v.75h-.75v-.75ZM19.5 13.5h.75v.75h-.75v-.75ZM19.5 19.5h.75v.75h-.75v-.75ZM16.5 16.5h.75v.75h-.75v-.75Z" />
                                                        </svg>
                                                        <span className='pt-0.5'>Generate QR Code</span>
                                                    </button>}
                                                <button id="PageLayoutButtonsettings" className="flex items center w-full px-4 py-2 text-sm text-left hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" onClick={handleSettingsClick}>
                                                    <svg id="PageLayoutSvgsettingsIcon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 mr-2">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                                    </svg>
                                                    Settings
                                                </button>
                                            </>
                                            }
                                            <button id="PageLayoutButtonlogOut" className="flex items center w-full px-4 py-2 text-sm text-left hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white" onClick={handleLogoutRedirect}>
                                                <svg id="PageLayoutSvgloOutIcon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className="w-6 h-6 mr-2">
                                                    <path d="M14.1667 6.66667L13 7.83333L14.3333 9.16667H7.5V10.8333H14.3333L13 12.1667L14.1667 13.3333L17.5 10L14.1667 6.66667ZM4.16667 4.16667H10V2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H10V15.8333H4.16667V4.16667Z" fill="#544C4C" />
                                                </svg>
                                                Log out
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>}

                        </div>
                    </div>
                </header>
            </section>
            <section>
                {loggeduser.user_name && <div >
                    {pathName === "terms-of-service" &&
                        <TermsOfService />
                    }
                    {pathName === "privacy" &&
                        <PrivacyPolicy />
                    }
                    {pathName !== "terms-of-service" && pathName !== "privacy" &&
                        <div className='flex'>
                            {loggeduser.length != 0 && loggeduser.user_isactive == false ?
                                <>
                                    <div className="h-screen flex items-center justify-center  bg-red-100 border border-red-400 text-red-800 px-20 py-3 mt-20 w-full rounded relative" role="alert">
                                        <svg className="fill-current w-4 h-4.5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" /></svg>
                                        <span className="sm:inline">Account Blocked , Contact Admin For Furthur Details......</span>
                                        {/*  <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                        <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                                    </span> */}
                                    </div>
                                </>
                                : <>

                                    <div className={isSidebarOpen ? "w-[15%] 2xl:w-[13%] relative hidden lg:block border-r border-gray-200 z-10" : ' w-[7%] min-[2200px]:w-[3%] relative  hidden lg:block border-r border-gray-200 z-10'} >
                                        <MainPage ishomepage={false} isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} toggleSidebar={toggleSidebar} />
                                    </div>
                                    <div className={isSidebarOpen ? "w-[100vw] lg:w-[85%] 2xl:w-[87%]" : 'w-[100vw] lg:w-[93%] min-[2200px]:w-[97%] ml-6'}>
                                        {/* {
                               location.pathname=="/" && loggeduser!=null && loggeduser.length!=0 && loggeduser.user_role=="SuperAdmin" && <SuperAdmin />
                            } */}
                                        {props.children}

                                    </div>
                                </>
                            }
                        </div>
                    }
                    <div>
                        <MobileFooter id="pagelayoutMobileFooter" />
                    </div>
                </div>}
                {!loggeduser.user_name && <div>
                    {/* <div>
                        <section className=' min-h-screen' style={{
                            backgroundImage: (isMobile ? `url("${process.env.REACT_APP_EMRAXIS_S3_URL}/emr-mobile.jpg")` : `url("${process.env.REACT_APP_EMRAXIS_S3_URL}/emr-desktop.jpg")`),
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover'
                        }}></section>
                    </div> */}
                    {pathName === "terms-of-service" &&
                        <TermsOfService />
                    }
                    {pathName === "privacy" &&
                        <PrivacyPolicy />
                    }
                    {pathName !== "terms-of-service" && pathName !== "privacy" &&
                        <HomePage />
                    }
                    <Footer />
                </div>}
            </section>
            {!loggeduser.user_name && <CustomLogin setisCredsOk={setisCredsOk} isCredsOk={isCredsOk} getcreds={getcreds} credPassword={credPassword} credUserName={credUserName} setcredUserName={setcredUserName} setcredPassword={setcredPassword} setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />}

        </div>
    );
}