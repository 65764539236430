import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNotification } from "../Redux/features/toast/toastSlice";
import store from "../API/store";
import DataDecode from "../utils/DataDecode";
import ForgotPassword from "./ForgotPassword";
import Login from "./Assets/homeImages/Login.png";
import ToastMessage from "./ToastMessage";
import PhoneInput from "../Pages/PhoneInput";

function LoginCustom(props) {
  const [loginStage, setLoginStage] = useState("username");
  const [errorMessage, setErrorMessage] = useState("");
  const [loginResponse, setloginResponse] = useState("");
  const [visloginResponse, setVisloginResponse] = useState(false);
  const [errorVerificationName, setErrorVerificationName] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [emailId, setEmailId] = useState("");
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.toast.notification);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [erMessage, setErMessage] = useState(false);
  const [showContactAdmin, setShowContactAdmin] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(0);
  const [erMobileNumber, setErMobileNumber] = useState("");
  const [showadError, setAdError] = useState(false);
  useEffect(() => {
    setLoginStage("username");
  }, []);

  const handleKeyDown = (event, stage) => {
    if (event.key === "Enter") {
      if (stage === "userNameverify") {
        if (props.credUserName !== "" && props.credUserName.length >= 3) {
          handleUserNameVerification();
        }
      } else if (stage === "VerifyPass") {
        handleLoginSubmit();
      } else if (stage === "verifyForgotUN") {
        apiCallForgotUser();
      } else if (stage === "verifyMobile") {
        handleAdminForgotEmail();
      }
    }
  };
  const handleVerifyUserName = () => {
    if (props.credUserName !== "" && props.credUserName.length >= 3) {
      handleUserNameVerification();
    } else {
      setErrorVerificationName(true);
      setErrorMessage("Enter username.");
    }
  };
  const handleUserNameVerification = async () => {
    if (props.credUserName == "" || props.credUserName == " ") {
      setErrorVerificationName(true);
      setErrorMessage("Enter username.");
    } else {
      const userNameVerification = {
        userName: props.credUserName,
      };

      await store
        .VerifyUsername(DataDecode.encryptPayload(userNameVerification))
        .then((response) => {
          setIsDisabled(false);
          if (response.status === 200) {
            setloginResponse("");
            setErrorVerificationName(false);
            setLoginStage("password");
          } else {
            setErrorVerificationName(true);
            setErrorMessage("Enter a valid username");

            dispatch(
              setNotification({
                message: "Invalid Username",
                status: "error",
                action: true,
              })
            );
          }
        })
        .catch((error) => {
          dispatch(setNotification({ message: "Error Occurred", status: "error", action: true }));
          setIsDisabled(false);
        });
    }
  };
  const handleAdminForgotEmail = async () => {
    setAdError(false);

    const AdminForgotEmail = {
      MobileNumber: mobileNumber.toString(),
    };

    await store
      .AdminForgotUserPassword(DataDecode.encryptPayload(AdminForgotEmail))
      .then((response) => {
        if (response.status === 200) {
          setAdError(false);
          setLoginStage("username");
          setShowContactAdmin(false);
          dispatch(
            setNotification({
              message:
                "Details sent to admin successfully Admin will contact with you soon",
              status: "success",
              action: true,
            })
          );
        } else {
          setErMobileNumber("Enter Registered Mobile Number");
          setAdError(true);
          dispatch(
            setNotification({
              message: "Invalid mobilenumber",
              status: "error",
              action: true,
            })
          );
        }
      })
      .catch((error) => {
        setErMobileNumber("Error encountered while sending details gto admin");
        setAdError(true);
        dispatch(
          setNotification({
            message: "Error encountered while sending details gto admin",
            status: "error",
            action: true,
          })
        );
      });
  };

  const handleLoginSubmit = () => {
      setVisloginResponse(false);
    setloginResponse("");  
    setErrorMessage("");  
  
     if (!loginStage) {
      console.error("Login stage is undefined");
      return;
    }
  
    else if (loginStage === "password") {
     props.getcreds().then((resp)=>{
      setTimeout(() => {}, 2000);
       if(props.isCredsOk){
        setloginResponse("");
        setVisloginResponse(false);
        return;
      }
      else if( !props.isCredsOk){
        setloginResponse("Enter a valid password");
        setVisloginResponse(true);
      }
     });  
    } 
    
     else if (loginStage === "username") {
       if (!props.isCredsOk) {
        setErrorMessage("Enter a valid username");
         return;
      }
    } else {
      console.error("Unknown login stage");
    }
  };
  const handleContactAdmin = () => {
     setLoginStage("username");
    props.setIsModalOpen(false);
    const contactSection = document.getElementById('contact-section');
    if (contactSection) {
       contactSection.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };
   const apiCallForgotUser = async () => {
    setErMessage(false);

    if (emailId.trim() !== "") {
      setIsDisabled(true);
      const userNameForgotWithEmail = { Email: emailId };

      await store
        .ForgotUsernameApiCall(
          DataDecode.encryptPayload(userNameForgotWithEmail)
        )
        .then((response) => {
          setIsDisabled(false);
          if (response.status === 200 || response.status === 201) {
            setLoginStage("username");
            setEmailId("");
            dispatch(
              setNotification({
                message: "User details sent to your email",
                status: "success",
                action: true,
              })
            );
          } else {
            setErMessage(true);
            setErrorMessage("Invalid user email");
            dispatch(
              setNotification({
                message: "Invalid user email",
                status: "error",
                action: true,
              })
            );
          }
        })
        .catch((error) => {
          console.error("Error occurred while retrieving username:", error);
          setIsDisabled(false);
        });
    } else {
      setErrorMessage("Enter an email");
      dispatch(
        setNotification({
          message: "Enter email",
          status: "error",
          action: true,
        })
      );
    }
  };
  return (
    <>
      {props.isModalOpen && (
        <>
          <div className="hidden lg:block">
            <div className="p-6 fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
              <div className="flex bg-white relative h-auto border rounded-lg overflow-hidden">
                <button
                  id={"btnCross"}
                  onClick={() => {
                    props.setIsModalOpen(false);
                    setLoginStage("username");
                  }}
                  className="absolute top-2 right-2 font-semibold text-gray-500"
                >
                  &#x2715;
                </button>

                {loginStage !== "password" &&
                  loginStage !== "forgotUsername" &&
                  loginStage !== "forgotPassword" &&
                 (
                    <div className="flex p-14 w-[50%] relative bg-gray-100">
                      <img
                        src={Login}
                        alt="Left Image"
                        className="w-full h-auto relative z-10"
                      />
                    </div>
                  )}

                <div className={loginStage !== "forgotUsername" && loginStage !== "forgotPassword" ? "w-[50%] " : "" }>
                  {loginStage === "username" && (
                    <div className="p-14 my-14">
                      <h2 className="text-2xl font-bold text-red-800 mb-6 text-center  lg:text-left">
                        Login
                      </h2>

                      <div className="relative mb-4">
                        <button
                          id={"buttonRemoveLoginTab"}
                          type="button"
                          className="text-gray-600"
                          onClick={() => {
                            window.location.reload();
                          }}
                        >
                          <svg
                            id={"Username"}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            className="absolute left-3 top-1/2 transform -translate-y-1/2 pointer-events-none"
                            style={{ top: "50%" }}
                          >
                            <path
                              d="M18.0413 17.1875C17.9864 17.2825 17.9075 17.3614 17.8124 17.4163C17.7174 17.4711 17.6096 17.5 17.4998 17.5H2.49985C2.39019 17.4999 2.28249 17.4709 2.18757 17.416C2.09265 17.3611 2.01384 17.2822 1.95906 17.1872C1.90428 17.0922 1.87546 16.9845 1.87549 16.8748C1.87552 16.7652 1.90439 16.6575 1.95922 16.5625C3.14907 14.5055 4.98266 13.0305 7.1225 12.3312C6.06404 11.7011 5.24168 10.741 4.78171 9.59827C4.32174 8.45554 4.2496 7.19343 4.57636 6.00574C4.90313 4.81805 5.61072 3.77045 6.59049 3.02384C7.57026 2.27722 8.76803 1.87286 9.99985 1.87286C11.2317 1.87286 12.4294 2.27722 13.4092 3.02384C14.389 3.77045 15.0966 4.81805 15.4233 6.00574C15.7501 7.19343 15.678 8.45554 15.218 9.59827C14.758 10.741 13.9357 11.7011 12.8772 12.3312C15.017 13.0305 16.8506 14.5055 18.0405 16.5625C18.0954 16.6574 18.1245 16.7652 18.1246 16.8749C18.1247 16.9846 18.096 17.0924 18.0413 17.1875Z"
                              fill="#BABABA"
                            />
                          </svg>
                        </button>
                        <input
                          id="txtUserName"
                          type="text"
                          placeholder="Username"
                          className="w-full border border-gray-300 p-3 rounded-lg pl-10"
                          value={props.credUserName}
                          onKeyDown={(e) => handleKeyDown(e, "userNameverify")}
                          onChange={(e) =>{
                            props.setcredUserName(e.target.value)
                           }
                          }
                        />
                      </div>
                      {errorVerificationName && (
                        <p id={"ErrorIdForUsername"}
                         className="text-red-800 mb-4">{errorMessage}</p>
                      )}
                      <div className="flex justify-end items-center mb-6">
                        <button
                          id="btnForgotUser"
                          className="text-sm text-blue-600 hover:text-blue-800 bg-transparent border-none cursor-pointer"
                          onClick={() => {
                            setEmailId("");
                            setLoginStage("forgotUsername");
                          }}
                        >
                          Forgot Username?
                        </button>
                      </div>
                      <button
                        id="btnSubmitUser"
                        onClick={handleVerifyUserName}
                        disabled={isDisabled}
                        className="w-full bg-red-700 text-white py-3 rounded-lg hover:bg-red-800"
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </div>
                {loginStage === "password" && (
                  <div className="p-6 max-w-sm mx-auto">
                    <div className="flex justify-between items-center mb-6">
                      <h2 className="text-xl font-bold text-red-800 whitespace-nowrap">
                        Enter your Password
                      </h2>
                    </div>
                    <div className="relative mb-6">
                      <svg
                        id={"Password"}
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        className="absolute left-3 top-1/2 transform -translate-y-1/2 pointer-events-none"
                      >
                        <path
                          d="M16 0L7.74067 8.25867C6.84419 7.9869 5.89653 7.92882 4.97358 8.08907C4.05062 8.24932 3.17801 8.62346 2.42562 9.18152C1.67322 9.73958 1.06195 10.4661 0.640742 11.3028C0.219535 12.1395 9.74972e-05 13.0632 0 14C0 15.5913 0.632141 17.1174 1.75736 18.2426C2.88258 19.3679 4.4087 20 6 20C6.93678 19.9998 7.86051 19.7803 8.69722 19.359C9.53393 18.9377 10.2604 18.3264 10.8184 17.5739C11.3764 16.8215 11.7504 15.9488 11.9106 15.0258C12.0707 14.1028 12.0125 13.1551 11.7407 12.2587L13.3333 10.6667V9.33333H14.6667L20 4V0H16ZM18.6667 3.44733L14.1147 8H12V10.114L10.798 11.3153L10.2327 11.8807L10.4647 12.6453C10.5987 13.0867 10.6667 13.542 10.6667 14C10.6667 16.5733 8.57267 18.6667 6 18.6667C3.42733 18.6667 1.33333 16.5733 1.33333 14C1.33333 11.4267 3.42733 9.33333 6 9.33333C6.45733 9.33333 6.91333 9.40133 7.354 9.53467L8.118 9.76667L8.68333 9.20133L16.552 1.33333H18.6667V3.44733Z"
                          fill="#C3C3C3"
                        />
                        <path
                          d="M4.72238 16.6667C5.48945 16.6667 6.11127 16.0449 6.11127 15.2778C6.11127 14.5107 5.48945 13.8889 4.72238 13.8889C3.95532 13.8889 3.3335 14.5107 3.3335 15.2778C3.3335 16.0449 3.95532 16.6667 4.72238 16.6667Z"
                          fill="#C3C3C3"
                        />
                        <path
                          d="M13.3335 6.19817L17.3094 2.22223L17.7779 2.69073L13.802 6.66667L13.3335 6.19817Z"
                          fill="#C3C3C3"
                        />
                      </svg>

                      <input
                        id={"txtPassword"}
                        type={passwordVisible ? "text" : "password"}
                        placeholder="Password"
                        className="w-full border border-gray-300 p-3 rounded-lg pl-10"
                        value={props.credPassword}
                        onKeyDown={(e) => {
                          handleKeyDown(e, "VerifyPass");
                        }}
                        onChange={(e) => props.setcredPassword(e.target.value)}
                      />
                      <button
                        id={"btnPasswordVisible"}
                        type="button"
                        className="absolute right-3 top-1/2 transform -translate-y-1/2"
                        onClick={() => setPasswordVisible(!passwordVisible)}
                      >
                        {passwordVisible ? (
                          <svg
                            id={"PasswordVisible"}
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M11.8125 9C11.8125 9.74592 11.5162 10.4613 10.9887 10.9887C10.4613 11.5162 9.74592 11.8125 9 11.8125C8.25408 11.8125 7.53871 11.5162 7.01126 10.9887C6.48382 10.4613 6.1875 9.74592 6.1875 9C6.1875 8.25408 6.48382 7.53871 7.01126 7.01126C7.53871 6.48382 8.25408 6.1875 9 6.1875C9.74592 6.1875 10.4613 6.48382 10.9887 7.01126C11.5162 7.53871 11.8125 8.25408 11.8125 9Z"
                              fill="#444444"
                            />
                            <path
                              d="M0 9C0 9 3.375 2.8125 9 2.8125C14.625 2.8125 18 9 18 9C18 9 14.625 15.1875 9 15.1875C3.375 15.1875 0 9 0 9ZM9 12.9375C10.0443 12.9375 11.0458 12.5227 11.7842 11.7842C12.5227 11.0458 12.9375 10.0443 12.9375 9C12.9375 7.95571 12.5227 6.95419 11.7842 6.21577C11.0458 5.47734 10.0443 5.0625 9 5.0625C7.95571 5.0625 6.95419 5.47734 6.21577 6.21577C5.47734 6.95419 5.0625 7.95571 5.0625 9C5.0625 10.0443 5.47734 11.0458 6.21577 11.7842C6.95419 12.5227 7.95571 12.9375 9 12.9375Z"
                              fill="#444444"
                            />
                          </svg>
                        ) : (
                          <svg
                            id={"PasswordHidden"}
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M8.8725 6.75L11.25 9.12V9C11.25 8.40326 11.0129 7.83097 10.591 7.40901C10.169 6.98705 9.59674 6.75 9 6.75H8.8725ZM5.6475 7.35L6.81 8.5125C6.7725 8.67 6.75 8.8275 6.75 9C6.75 9.59674 6.98705 10.169 7.40901 10.591C7.83097 11.0129 8.40326 11.25 9 11.25C9.165 11.25 9.33 11.2275 9.4875 11.19L10.65 12.3525C10.1475 12.6 9.5925 12.75 9 12.75C8.00544 12.75 7.05161 12.3549 6.34835 11.6517C5.64509 10.9484 5.25 9.99456 5.25 9C5.25 8.4075 5.4 7.8525 5.6475 7.35ZM1.5 3.2025L3.21 4.9125L3.5475 5.25C2.31 6.225 1.335 7.5 0.75 9C2.0475 12.2925 5.25 14.625 9 14.625C10.1625 14.625 11.2725 14.4 12.285 13.995L12.6075 14.31L14.7975 16.5L15.75 15.5475L2.4525 2.25M9 5.25C9.99456 5.25 10.9484 5.64509 11.6517 6.34835C12.3549 7.05161 12.75 8.00544 12.75 9C12.75 9.48 12.6525 9.945 12.48 10.365L14.6775 12.5625C15.8025 11.625 16.7025 10.395 17.25 9C15.9525 5.7075 12.75 3.375 9 3.375C7.95 3.375 6.945 3.5625 6 3.9L7.6275 5.5125C8.055 5.3475 8.5125 5.25 9 5.25Z"
                              fill="#444444"
                            />
                          </svg>
                        )}
                      </button>
                    </div>
                    {/* {errorVerificationName ? (
                      <p id={"ErrorVerfiyuserNameId"}
                      className="text-red-800 mb-4">{errorMessage}</p>
                    ) : (
                      ""
                    )} */}
                    {visloginResponse &&
                      !props.isCredsOk &&
                      loginStage === "password" && (
                        <div className="text-red-500 mb-4">
                          {loginResponse}
                        </div>
                      )}
                    <div className="flex justify-end items-center mb-6">
                      <button
                        id={"btnForgotPassWord"}
                        className="text-sm text-blue-600 hover:text-blue-800 bg-transparent border-none cursor-pointer"
                        onClick={() => setLoginStage("forgotPassword")}
                      >
                        Forgot Password?
                      </button>
                    </div>

                    <button
                      id={"buttonLogIn"}
                      className="w-80 bg-red-700 text-white px-4 py-2 rounded-md hover:bg-red-800"
                      onClick={handleLoginSubmit}
                    >
                      Log In
                    </button>
                  </div>
                )}

                {loginStage === "forgotUsername" && (
                  <div className="p-8 w-[20rem] mx-auto">
                    <h2 className="text-xl font-bold text-red-800 mb-4">
                      Forgot Username
                    </h2>
                    <div className="text-center mt-4">
                      <div className="text-center mt-4">
                        <p className="text-lg font-bold text-black py-2 bg-transparent border-none cursor-pointer mb-2">Please contact your facility Admin</p>
                        <div className="flex justify-center items-center">
                          <button
                            id="btnBack"
                            className="text-sm text-black hover:text-gray-800 bg-transparent border-none cursor-pointer"
                            onClick={() => setLoginStage("username")}
                          >
                            Back to
                          </button>
                          <span className="mx-1"></span>
                          <button
                            id="btnIn"
                            className="text-sm text-red-600 hover:text-red-800 bg-transparent border-none cursor-pointer"
                            onClick={() => setLoginStage("username")}
                          >
                            Log In
                          </button>
                        </div>
                      </div>                      
                    </div>
                  </div>
                )}
                {loginStage === "forgotPassword" && (
                  <ForgotPassword UserName={props.credUserName} />
                )}
              </div>
            </div>
          </div>
          {/* MOBILE VIEW */}
          <div className="lg:hidden block">
            <div className="p-4 fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
              <div className="">
                <div
                  className={
                    loginStage !== "forgotUsername" &&
                    loginStage !== "forgotPassword" 
                      ? "w-full"
                      : "w-[90%]"
                  }
                >
                  {loginStage === "username" && (
                    <div className="p-8 my-8 bg-white rounded-lg relative mt-4">
                      <button
                        id={"btnClose"}
                        onClick={() =>{ props.setIsModalOpen(false);window.location.reload()}}
                        className="absolute top-2 right-2 text-gray-500"
                      >
                        &#x2715;
                      </button>
                      <h2 className="text-2xl font-bold text-red-800 mb-6 text-center  lg:text-left">
                        Login
                      </h2>

                      <div className="relative mb-4">
                        <button
                          id={"buttonRemoveLoginTab"}
                          type="button"
                          className="text-gray-600"
                          onClick={() => {
                            window.location.reload();
                          }}
                        >
                          <svg
                            id={"btnName"}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            className="absolute left-3 top-1/2 transform -translate-y-1/2 pointer-events-none"
                            style={{ top: "50%" }}
                          >
                            <path
                              d="M18.0413 17.1875C17.9864 17.2825 17.9075 17.3614 17.8124 17.4163C17.7174 17.4711 17.6096 17.5 17.4998 17.5H2.49985C2.39019 17.4999 2.28249 17.4709 2.18757 17.416C2.09265 17.3611 2.01384 17.2822 1.95906 17.1872C1.90428 17.0922 1.87546 16.9845 1.87549 16.8748C1.87552 16.7652 1.90439 16.6575 1.95922 16.5625C3.14907 14.5055 4.98266 13.0305 7.1225 12.3312C6.06404 11.7011 5.24168 10.741 4.78171 9.59827C4.32174 8.45554 4.2496 7.19343 4.57636 6.00574C4.90313 4.81805 5.61072 3.77045 6.59049 3.02384C7.57026 2.27722 8.76803 1.87286 9.99985 1.87286C11.2317 1.87286 12.4294 2.27722 13.4092 3.02384C14.389 3.77045 15.0966 4.81805 15.4233 6.00574C15.7501 7.19343 15.678 8.45554 15.218 9.59827C14.758 10.741 13.9357 11.7011 12.8772 12.3312C15.017 13.0305 16.8506 14.5055 18.0405 16.5625C18.0954 16.6574 18.1245 16.7652 18.1246 16.8749C18.1247 16.9846 18.096 17.0924 18.0413 17.1875Z"
                              fill="#BABABA"
                            />
                          </svg>
                        </button>

                        <input
                          id={"textName"}
                          type="text"
                          placeholder="Username"
                          className="w-full border border-gray-300 p-3 rounded-lg pl-10"
                          value={props.credUserName}
                          onKeyDown={(e) => {
                            handleKeyDown(e, "userNameverify");
                          }}
                          onChange={(e) => {
                            props.setcredUserName(e.target.value);
                          }}
                        />
                      </div>
                    
                      {errorMessage &&
                        !props.isCredsOk &&
                        loginStage === "username" && (
                          <div id={"UserNameErrorId"} 
                          className="text-red-500 mb-4">
                            {errorMessage}
                          </div>
                        )}
                      <div className="flex justify-end items-center mb-6">
                        <button
                          id={"btnForgotName"}
                          className="text-sm text-blue-600 hover:text-blue-800 bg-transparent border-none cursor-pointer"
                          onClick={() => {
                            props.setcredUserName("");
                            setEmailId("");
                            setLoginStage("forgotUsername");
                          }}
                        >
                          Forgot Username?
                        </button>
                      </div>
                      <button
                        id={"btnMobSubmitUser"}
                        onClick={handleVerifyUserName}
                        className="w-full bg-red-700 text-white py-3 rounded-lg hover:bg-red-800"
                      >
                        Submit
                      </button>
                    </div>
                  )}
                  {loginStage === "password" && (
                    <div className="p-8 my-8 bg-white rounded-lg relative mt-4">
                      <button
                        id={"btnClose"}
                        onClick={() =>{ props.setIsModalOpen(false);window.location.reload()}}
                        className="absolute top-2 right-2 text-gray-500"
                      >
                        &#x2715;
                      </button>{" "}
                      <h2 className="text-2xl font-bold text-red-800 mb-6">
                        Enter your Password
                      </h2>
                      <div className="relative">
                        <svg
                          id={"btnKey"}
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          className="absolute left-3 top-1/2 transform -translate-y-1/2 pointer-events-none"
                        >
                          <path
                            d="M16 0L7.74067 8.25867C6.84419 7.9869 5.89653 7.92882 4.97358 8.08907C4.05062 8.24932 3.17801 8.62346 2.42562 9.18152C1.67322 9.73958 1.06195 10.4661 0.640742 11.3028C0.219535 12.1395 9.74972e-05 13.0632 0 14C0 15.5913 0.632141 17.1174 1.75736 18.2426C2.88258 19.3679 4.4087 20 6 20C6.93678 19.9998 7.86051 19.7803 8.69722 19.359C9.53393 18.9377 10.2604 18.3264 10.8184 17.5739C11.3764 16.8215 11.7504 15.9488 11.9106 15.0258C12.0707 14.1028 12.0125 13.1551 11.7407 12.2587L13.3333 10.6667V9.33333H14.6667L20 4V0H16ZM18.6667 3.44733L14.1147 8H12V10.114L10.798 11.3153L10.2327 11.8807L10.4647 12.6453C10.5987 13.0867 10.6667 13.542 10.6667 14C10.6667 16.5733 8.57267 18.6667 6 18.6667C3.42733 18.6667 1.33333 16.5733 1.33333 14C1.33333 11.4267 3.42733 9.33333 6 9.33333C6.45733 9.33333 6.91333 9.40133 7.354 9.53467L8.118 9.76667L8.68333 9.20133L16.552 1.33333H18.6667V3.44733Z"
                            fill="#C3C3C3"
                          />
                          <path
                            d="M4.72238 16.6667C5.48945 16.6667 6.11127 16.0449 6.11127 15.2778C6.11127 14.5107 5.48945 13.8889 4.72238 13.8889C3.95532 13.8889 3.3335 14.5107 3.3335 15.2778C3.3335 16.0449 3.95532 16.6667 4.72238 16.6667Z"
                            fill="#C3C3C3"
                          />
                          <path
                            d="M13.3335 6.19817L17.3094 2.22223L17.7779 2.69073L13.802 6.66667L13.3335 6.19817Z"
                            fill="#C3C3C3"
                          />
                        </svg>
                        <input
                          id={"txtCredPassword"}
                          type={passwordVisible ? "text" : "password"}
                          placeholder="Password"
                          className="w-full border border-gray-300 p-3 rounded-lg pl-10"
                          value={props.credPassword}
                          onKeyDown={(e) => {
                            handleKeyDown(e, "VerifyPass");
                          }}
                          onChange={(e) =>{
                            props.setcredPassword(e.target.value)
                           }
                          }
                        />
                        <button
                          id={"btnVisible"}
                          type="button"
                          className="absolute right-3 top-1/2 transform -translate-y-1/2"
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        >
                          {passwordVisible ? (
                            <svg
                              id={"btnShow"}
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M11.8125 9C11.8125 9.74592 11.5162 10.4613 10.9887 10.9887C10.4613 11.5162 9.74592 11.8125 9 11.8125C8.25408 11.8125 7.53871 11.5162 7.01126 10.9887C6.48382 10.4613 6.1875 9.74592 6.1875 9C6.1875 8.25408 6.48382 7.53871 7.01126 7.01126C7.53871 6.48382 8.25408 6.1875 9 6.1875C9.74592 6.1875 10.4613 6.48382 10.9887 7.01126C11.5162 7.53871 11.8125 8.25408 11.8125 9Z"
                                fill="#444444"
                              />
                              <path
                                d="M0 9C0 9 3.375 2.8125 9 2.8125C14.625 2.8125 18 9 18 9C18 9 14.625 15.1875 9 15.1875C3.375 15.1875 0 9 0 9ZM9 12.9375C10.0443 12.9375 11.0458 12.5227 11.7842 11.7842C12.5227 11.0458 12.9375 10.0443 12.9375 9C12.9375 7.95571 12.5227 6.95419 11.7842 6.21577C11.0458 5.47734 10.0443 5.0625 9 5.0625C7.95571 5.0625 6.95419 5.47734 6.21577 6.21577C5.47734 6.95419 5.0625 7.95571 5.0625 9C5.0625 10.0443 5.47734 11.0458 6.21577 11.7842C6.95419 12.5227 7.95571 12.9375 9 12.9375Z"
                                fill="#444444"
                              />
                            </svg>
                          ) : (
                            <svg
                              id={"btnHide"}
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M8.8725 6.75L11.25 9.12V9C11.25 8.40326 11.0129 7.83097 10.591 7.40901C10.169 6.98705 9.59674 6.75 9 6.75H8.8725ZM5.6475 7.35L6.81 8.5125C6.7725 8.67 6.75 8.8275 6.75 9C6.75 9.59674 6.98705 10.169 7.40901 10.591C7.83097 11.0129 8.40326 11.25 9 11.25C9.165 11.25 9.33 11.2275 9.4875 11.19L10.65 12.3525C10.1475 12.6 9.5925 12.75 9 12.75C8.00544 12.75 7.05161 12.3549 6.34835 11.6517C5.64509 10.9484 5.25 9.99456 5.25 9C5.25 8.4075 5.4 7.8525 5.6475 7.35ZM1.5 3.2025L3.21 4.9125L3.5475 5.25C2.31 6.225 1.335 7.5 0.75 9C2.0475 12.2925 5.25 14.625 9 14.625C10.1625 14.625 11.2725 14.4 12.285 13.995L12.6075 14.31L14.7975 16.5L15.75 15.5475L2.4525 2.25M9 5.25C9.99456 5.25 10.9484 5.64509 11.6517 6.34835C12.3549 7.05161 12.75 8.00544 12.75 9C12.75 9.48 12.6525 9.945 12.48 10.365L14.6775 12.5625C15.8025 11.625 16.7025 10.395 17.25 9C15.9525 5.7075 12.75 3.375 9 3.375C7.95 3.375 6.945 3.5625 6 3.9L7.6275 5.5125C8.055 5.3475 8.5125 5.25 9 5.25Z"
                                fill="#444444"
                              />
                            </svg>
                          )}
                        </button>
                      </div>
                      {visloginResponse &&
                        !props.isCredsOk &&
                        loginStage === "password" && (
                          <div className="text-red-500 mb-4">
                            {loginResponse}
                          </div>
                        )}
                      <div className="flex justify-end mt-2 mb-6">
                        <button
                          id={"btnForgotPassWord"}
                          className="text-sm text-blue-600 hover:text-blue-800 bg-transparent border-none cursor-pointer"
                          onClick={() => setLoginStage("forgotPassword")}
                        >
                          Forgot Password?
                        </button>
                      </div>
                      <button
                        id={"MobileLoginSubmitbtn"}
                        onClick={handleLoginSubmit}
                        className="w-full bg-red-700 text-white py-3 rounded-lg hover:bg-red-800 "
                      >
                        Log In
                      </button>
                    </div>
                  )}
                  {loginStage === "forgotUsername" && (
                    <div className="p-8 my-8 bg-white rounded-lg relative mt-4">
                      <button
                        id={"btnClose"}
                        onClick={() => {
                          props.setIsModalOpen(false);
                          setEmailId("");
                          window.location.reload();
                        }}
                        className="absolute top-2 right-2 text-gray-500"
                      >
                        &#x2715;
                      </button>
                      <h2 className="text-xl font-bold text-red-800 mb-6">
                        Forgot Username
                      </h2>
                      <div className="text-center mt-4">
                        <p className="text-lg font-bold text-black bg-transparent border-none cursor-pointer mb-2">Please contact your facility Admin</p>
                        <div className="flex justify-center items-center">
                          <button
                            id={"LoginBackbutton"}
                            className="text-sm text-black hover:text-gray-800 bg-transparent border-none cursor-pointer"
                            onClick={() => setLoginStage("username")}
                            //disabled={isDisabled}
                          >
                            Back to
                          </button>
                          <span className="mx-1"></span>
                          <button
                            id={"btnLogin"}
                            className="text-sm text-red-600 hover:text-red-800 bg-transparent border-none cursor-pointer"
                            onClick={() => setLoginStage("username")}
                          >
                            Log In
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {loginStage === "forgotPassword" && (
            <ForgotPassword UserName={props.credUserName} />
          )}
          </div>
         
          {notification.action && (
            <ToastMessage
              message={notification.message}
              type={notification.status}
            />
          )}
        </>
      )}

    </>
  );
}

export default LoginCustom;
