import React, { useState } from 'react'
import { Tooltip } from "@mui/material";

const MonthViewAppointment = ({ day, handleAppointmentDetailPopup, events }) => {
    const [showAll, setShowAll] = useState(false);
    const renderEvent = (date) => {
        const event = events.filter(
            (event) => event.currentDate === date
        );
        return event.length > 0 ? (
            <>
                {event.slice(0, showAll ? event.length : 3).map((ev, i) => (
                    <div key={'app-event-' + i}
                        id={"DivAgantaAppointmentMonthView" + i}
                        onClick={(evt) => handleAppointmentDetailPopup(evt, ev.appointment)}
                        className="bg-[#2823584A] text-[#454242] truncate p-1 mb-2 rounded test-sm cursor-pointer">
                        <Tooltip
                            id={"TooltipAgantaAppointmentMonthView" + i}
                            title={ev.appointment.FirstName + " " + ev.appointment.MiddleName?.charAt(0) + " " + ev.appointment.LastName}
                            placement="top-start"
                        >
                            {ev.appointment.FirstName + " " + ev.appointment.MiddleName?.charAt(0) + " " + ev.appointment.LastName}
                        </Tooltip>
                    </div>
                ))
                }
                {event.length > 3 &&
                    <div className='text-center bg-gray-200'>
                        <button id={"BtnAgantaAppointmentMonthViewHideShow"} className='text-blue-500 text-sm hover:underline' onClick={() => { setShowAll(o => !o) }}>{showAll ? "Show Less" : "+" + (event.length - 3) + " more"}</button>
                    </div>
                }
            </>
        ) : null;
    };
    return (
        <>{renderEvent(day)}</>
    )
}

export default MonthViewAppointment