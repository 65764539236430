import React from 'react'
import logo from '../components/Assets/waveaxis-logo.png'
import { HiPrinter } from "react-icons/hi";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { PiPrinterFill } from "react-icons/pi";


function Invoice() {
    return (
        <>
            <div className=' mx-auto px-4 py-2 my-20 w-100% '>
                <div className='flex justify-between'>
                    <div id="InvoiceDivinvoiceTitle" className='text-3xl font-semibold text-[#143b75]'>
                        Invoice
                    </div>
                    <div>
                        <img id="InvoiceImglogo" className="h-10 w-auto" src={logo} alt="Logo" />
                    </div>
                </div>
                <div className='my-3 lg:w-[30%] xs:w-[100%]'>
                    <table className="table-auto">
                        <tbody>
                            <tr>
                                <td id="InvoiceTdinvoiceNo" className='pr-[20px] py-[5px] text-[#989ea4]'>Invoice No.</td>
                                <td id="InvoiceTd#INV0001">#INV0001</td>
                            </tr>
                            <tr>
                                <td id="InvoiceTdinvoiceDate" className='pr-[20px] py-[5px] text-[#989ea4]'>Invoice Date</td>
                                <td id="InvoiceTdSep24">Sep 24, 2023</td>
                            </tr>
                            <tr>
                                <td  id="InvoiceTddueDate" className='pr-[20px] py-[5px] text-[#989ea4]'>Due Date</td>
                                <td id="InvoiceTdSep30">Sep 30, 2023</td>
                            </tr>
                            <tr>
                                <td id="InvoiceTdpatientId" className='pr-[20px] py-[5px] text-[#989ea4]'>Patient ID</td>
                                <td id="InvoiceTd#P-00012">#P-00012</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div className="grid lg:grid-cols-2 xs:grid-cols-1 gap-4">
                    <div className='border-slate-200 border rounded px-4 py-6 lg:mr-6 drop-shadow-2xl'>
                        <p id="InvoicePbilledBy" className='text-[#af3d3c] font-semibold pb-2 '>Billed By</p>
                        <p id="InvoiceParjunSingh" className='text-[#353c44] font-bold pb-2 '>Arjun Singh</p>
                        <p id="InvoicePNH-17Address" className='font-normal '>NH-17 Bye Pass Road, XYZ City, ABC Street-673014</p>
                        <p id="InvoicePNearHiliteMall" className='font-normal '>(Near Hilite Mall)</p>
                        <div className=''>
                            <table className="table-auto">
                                <tbody>
                                    <tr>
                                        <td id="InvoiceTdEmail" className='pr-[20px] py-2 text-[#989ea4]'>Email</td>
                                        <td id="InvoiceTdArjunSingh">arjunsinghmedstaff@gmail.com</td>
                                    </tr>
                                    <tr>
                                        <td id="InvoiceTdPhone" className='pr-[20px] text-[#989ea4]'>Phone</td>
                                        <td id="InvoiceTdphoneNumber">+919633333333</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='border-slate-200 border rounded px-4 py-6 drop-shadow-2xl'>
                        <p id="InvoicePbilledTo"className='text-[#af3d3c] font-semibold pb-2 '>Billed TO</p>
                        <p id="InvoicePAnnetteBlack" className='text-[#353c44] font-bold pb-2 '>Annette Black</p>
                        <p id="InvoicePNH-17" className='font-normal '>NH-17 Bye Pass Road, XYZ City, ABC Street-673014</p>
                        <p id="InvoicePnearMallText" className='font-normal '>(Near Hilite Mall)</p>
                        <div className=''>
                            <table className="table-auto">
                                <tbody>
                                    <tr>
                                        <td id="InvoiceTdemailText" className='pr-[20px] py-2 text-[#989ea4]'>Email</td>
                                        <td id="InvoiceTdEmailId">arjunsinghmedstaff@gmail.com</td>
                                    </tr>
                                    <tr>
                                        <td id="InvoiceTdphoneText" className='pr-[20px] text-[#989ea4]'>Phone</td>
                                        <td id="InvoiceTdphoneNumberText">+919633333333</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='mt-4'>

                    <table className="table-auto block w-[100%] border rounded-md focus:outline-none focus:border-gray-200">
                        <thead className='bg-[#a31b1b] px-3 text-white rounded-xl'>
                            <tr>

                                <th id="InvoiceThItem" className="rounded-tl-md lg:px-14 lg:py-2 text-left lg:w-[50%] xs:px-0 xs:py-0 text-left xs:w-[50%]">Item</th>
                                <th id="InvoiceThdescription" className="lg:px-14 lg:py-2 text-left lg:w-[25%] xs:px-0 xs:py-0 text-left xs:w-[20%]">Description</th>
                                <th id="InvoiceThamunt" className="lg:px-14 lg:py-2 text-left lg:w-[20%] xs:px-0 xs:py-0 text-left xs:w-[20%]">Amount</th>
                                <th id="InvoiceThTotal" className="rounded-tr-md lg:px-14 lg:py-2 text-left lg:w-[15%] xs:px-0 xs:py-0 text-left xs:w-[20%]">Total</th>

                            </tr>
                        </thead>
                        <tbody>

                            {/* {features.map((userdata, index) => ( */}
                            <tr>

                                <td id="InvoiceTdFullCheckUp" className="lg:px-14 lg:py-2 xs:px-0 xs:py-0">Full check up</td>
                                <td id="InvoiceTdFullBodyCheckUp" className="lg:px-14 lg:py-2 xs:px-0 xs:py-0">Full Body Check up</td>
                                <td id="InvoiceTd745Text" className="lg:px-14 lg:py-2 xs:px-0 xs:py-0"><p className='flex'><LiaRupeeSignSolid className='mt-[5px]' /><span>745</span></p></td>
                                <td id="InvoiceTd745Text2" className="lg:px-14 lg:py-2 xs:px-0 xs:py-0"><p className='flex'><LiaRupeeSignSolid className='mt-[5px]' /><span>745</span></p></td>

                            </tr>
                            <tr>

                                <td td="TdcheckUp" className="lg:px-14 lg:py-2 xs:px-0 xs:py-0">Full check up</td>
                                <td td="TdbodyCheckUp" className="lg:px-14 lg:py-2 xs:px-0 xs:py-0">Full Body Check up</td>
                                <td td="TdnumText" className="lg:px-14 lg:py-2 xs:px-0 xs:py-0"><p className='flex'><LiaRupeeSignSolid className='mt-[5px]' /><span>745</span></p></td>
                                <td td="Tdnum2Text" className="lg:px-14 lg:py-2 xs:px-0 xs:py-0"><p className='flex'><LiaRupeeSignSolid className='mt-[5px]' /><span>745</span></p></td>

                            </tr>

                            {/* ))}  */}
                        </tbody>
                    </table>
                </div>
                <div className='mt-4 flex justify-between'>
                    <div className='w-[100%'>
                        <div className='hidden lg:block border-slate-200 border rounded px-4 py-6 lg:mr-6 drop-shadow-2xl lg:w-[60%] xs:w-[40%] justify'>
                            <p id="InvoicePtermsCondition" className='text-[#af3d3c] font-semibold pb-2 '>Terms and Conditions</p>
                            <p id="InvoicePpayWithin15Days" className=' pb-2 font-normal text-justify'>Please pay within 15 days from date of invoice, overdue interest @14% will be charged on delayed payments.</p>
                            <p id="InvoicePquoteNumbr" className='font-normal text-justify'>Please quote invoice number when remitting funds.</p>
                        </div>
                    </div>
                    <div className='lg:w-[28%] xs:w-[40%]'>
                        <table className="table-auto">
                            <thead>
                                <tr>
                                    <th className='lg:w-[22%] xs:w-[20%] text-center'></th>
                                    <th className='lg:w-[6%] xs:w-[20%] text-center'></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className=''>
                                    <td id="InvoiceTdsubTotal" className='py-[5px] text-[#989ea4] text-left'>Sub Total</td>
                                    <td id="InvoiceTd1490" className='flex'><LiaRupeeSignSolid className='mt-[5px]' />1490</td>
                                </tr>
                                <tr className=''>
                                    <td td="TdDiscount" className='py-[5px] text-[#989ea4]'>Discount(0%)</td>
                                    <td td="Td0" className='flex'><LiaRupeeSignSolid className='mt-[5px]' />0</td>
                                </tr>
                                <tr className=''>
                                    <td id="InvoiceTdVAT" className='py-[5px] text-[#989ea4]'>VAT(5%)</td>
                                    <td id="InvoiceTd275" className='flex'><LiaRupeeSignSolid className='mt-[5px]' />275</td>
                                </tr>
                                <tr className=' border-y-2 border-y-neutral-400 my-2 text-[black] font-semibold'>
                                    <td id="InvoiceTdAmountDue" className='py-[5px]'>Amount Due</td>
                                    <td id="InvoiceTd1765" className='flex'><LiaRupeeSignSolid className='mt-[5px]' />1765</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='mt-4 lg:hidden xs:block'>
                    <div className='border-slate-200 border rounded px-4 py-6 lg:mr-6 drop-shadow-2xl lg:w-[60%] xs:w-[40%]'>
                        <p id="InvoicePtermsConditionText" className='text-[#af3d3c] font-semibold pb-2 '>Terms and Conditions</p>
                        <p id="InvoicePmobPayableDays" className=' pb-2 font-normal text-justify'>Please pay within 15 days from date of invoice, overdue interest @14% will be charged on delayed payments.</p>
                        <p id="InvoicePmobinvoiceNum" className='font-normal text-justify'>Please quote invoice number when remitting funds.</p>
                    </div>
                </div>
                <div className="mt-4 mb-12 flex items-center float-right">
                    <button id="InvoiceBtnPrinter" className='text-xl	py-[10px] bg-[green] px-[20px] mr-[10px] rounded'>
                        <PiPrinterFill id="InvoicePrinterIcon" className='text-white' />
                    </button>
                    <button id="InvoiceBtnSubmit" className=" bg-red-700 hover:bg-red-800 text-white py-2 px-4 rounded-md items-center" onClick={() => { }}>
                        Submit
                    </button>
                </div>
            </div>
        </>
    )
}

export default Invoice