import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setRecycleBinAppointmentDataAvailable, setRecycleBinDepartmentDataAvailabale, setRecycleBinDoctorDataAvailable, setRecycleBinPatientDataAvailable, setRecycleBinPatientFilesDataAvailable } from '../../Redux/features/recycleBin/recycleBinNavbarSlice';
import store from '../../API/store';
import DataDecode from '../../utils/DataDecode';
import { setNotification } from '../../Redux/features/toast/toastSlice';
import { theme } from '../../EmraxisTheme/Theme';
import MobPatientBin from './mobile/MobPatientBin';
import MobAppointmentBin from './mobile/MobAppointmentBin';
import MobDoctorBin from './mobile/MobDoctorBin';
import MobDepartmentBin from './mobile/MobDepartmentBin';
import MobPatientFileBin from './mobile/MobPatientFileBin';

const MobileRecycleBin = () => {
  var loggeduser = useSelector(state => state.login.loginuserData);
  var loggedusertoken = useSelector(state => state.login.userBarerToken);
  const previewTheme = useSelector(state => state.theme.colors) || theme.filter(a => a.name === "defaultTheme")[0];
  let isPatientDataAvailable = useSelector(state => state.recycleBinSideNavbar.isPatientDataAvailable);
  let isDoctorDataAvailable = useSelector(state => state.recycleBinSideNavbar.isDoctorDataAvailable);
  let isDepartmentDataAvailabale = useSelector(state => state.recycleBinSideNavbar.isDepartmentDataAvailabale);
  let isAppointmentDataAvailable = useSelector(state => state.recycleBinSideNavbar.isAppointmentDataAvailable);
  let isPatientFilesDataAvailable = useSelector(state => state.recycleBinSideNavbar.isPatientFilesDataAvailable);
  var isMobileSidebarOpen = useSelector(state => state.drawer.isMobileSidebarOpen);
  var isProfileDrawerOpen = useSelector(state => state.profileDrawer.isProfileDrawerOpen);

  const dispatch = useDispatch();
  const sectionRef = useRef(null);
  const tabsContainerRef = useRef(null);

  const [patientData, setPatientData] = useState([]);
  const [patientRefreshData, setPatientRefreshData] = useState(false);
  const [patientDataLoading, setPatientDataLoading] = useState(false);

  const [appointmentData, setAppointmentData] = useState([]);
  const [appointmentRefreshData, setAppointmentRefreshData] = useState(false);
  const [appointmentDataLoading, setAppointmentDataLoading] = useState(false);

  const [doctorData, setDoctorData] = useState([]);
  const [doctorRefreshData, setDoctorRefreshData] = useState(false);
  const [doctorDataLoading, setDoctorDataLoading] = useState(false);

  const [departmentData, setDepartmentData] = useState([]);
  const [departmentRefreshData, setDepartmentRefreshData] = useState(false);
  const [departmentDataLoading, setDepartmentDataLoading] = useState(false);

  const [patientFilesData, setPatientFilesData] = useState([]);
  const [patientFilesRefreshData, setPatientFilesRefreshData] = useState(false);
  const [patientFilesDataLoading, setPatientFilesDataLoading] = useState(false);

  const [activeScrollTab, setActiveScrollTab] = useState('Patient');
  const scrollTabs = ['Patient', 'Appointment', 'Doctor', 'Department', 'Patient Files'];
  const [scrolltabs, setscrolltabs] = useState(scrollTabs);
  const [visibleTabs, setVisibleTabs] = useState(scrollTabs.slice(0, 3));
  const [startIndex, setStartIndex] = useState(0);

  useEffect(() => {
    let scroll = [];
    if (isPatientDataAvailable) {
      scroll.push('Patient')
    }
    if (isAppointmentDataAvailable) {
      scroll.push('Appointment')
    }
    if (isDoctorDataAvailable) {
      scroll.push('Doctor')
    }
    if (isDepartmentDataAvailabale) {
      scroll.push('Department')
    }
    if (isPatientFilesDataAvailable) {
      scroll.push('Patient Files')
    }
    setTimeout(() => {
      setscrolltabs(scroll);
      setVisibleTabs(scroll.slice(0, 3))
    }, 100)
  }, [isPatientDataAvailable, isAppointmentDataAvailable, isDoctorDataAvailable, isDepartmentDataAvailabale, isPatientFilesDataAvailable])

  useEffect(() => {
    setVisibleTabs(prevVisibleTabs => {
      const newVisibleTabs = scrolltabs.slice(startIndex, startIndex + 3);
      return JSON.stringify(prevVisibleTabs) === JSON.stringify(newVisibleTabs)
        ? prevVisibleTabs
        : newVisibleTabs;
    });
  }, [startIndex, scrolltabs]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + 75 + tabsContainerRef.current.offsetHeight;

      for (const tab of scrolltabs) {
        if (tab) {
          if(tab==="Patient")
            setStartIndex(0);
          const element = sectionRefs.current[tab].current;
          if (element && element.offsetTop <= scrollPosition && element.offsetTop + element.offsetHeight > scrollPosition) {
            updateTabsForSection(tab);
            break;
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrolltabs]);

  const sectionRefs = useRef(scrolltabs.reduce((acc, tab) => {
    acc[tab] = React.createRef();
    return acc;
  }, {}));


  const scrollOneTab = (direction) => {
    if (direction === 'right' && startIndex < scrolltabs.length - 3) {
      setStartIndex(prevIndex => {
        const newIndex = prevIndex + 1;
        return newIndex;
      });
    } else if (direction === 'left' && startIndex > 0) {
      setStartIndex(prevIndex => {
        const newIndex = prevIndex - 1;
        return newIndex;
      });
    }
  };
  const updateTabsForSection = (section) => {
    const sectionIndex = scrolltabs.indexOf(section);
    if (sectionIndex >= 0) {
      if (sectionIndex < startIndex) {
        setStartIndex(Math.max(0, sectionIndex));
      } else if (sectionIndex >= startIndex + 3) {
        setStartIndex(Math.min(scrolltabs.length - 3, sectionIndex - 2));
      }
      setActiveScrollTab(section);
    }
  };

  const scrollToSection = (tab) => {
    const sectionRef = sectionRefs.current[tab];
    const tabsContainer = tabsContainerRef.current;
    if (sectionRef && sectionRef.current && tabsContainer) {
      const element = sectionRef.current;
      const tabsContainerRect = tabsContainer.getBoundingClientRect();

      const scrollToPosition = element.offsetTop - tabsContainerRect.height - 80;
      window.scrollTo({
        top: scrollToPosition,
        behavior: 'smooth'
      });

      updateTabsForSection(tab);
    }
  };

  // Start Get Patient Data
  const GetPatientRecycleBinData = () => {
    setPatientDataLoading(true)
    store.GetPatientRecycleBinData(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID }))
      .then((result) => {
        setPatientDataLoading(false);
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            let deletedData = JSON.parse(response);
            setPatientData(deletedData);
            dispatch(setRecycleBinPatientDataAvailable(true));
          })
        }
        else {
          dispatch(setRecycleBinPatientDataAvailable(false));
          setPatientData([]);
        }
      })
      .catch(err => {
        setPatientData([]);
        setPatientDataLoading(false);
        dispatch(setRecycleBinPatientDataAvailable(false));
        dispatch(setNotification({ message: 'Error Occurred', status: 'error', action: true }));
      })
  }


  useEffect(() => {
    if (loggeduser) {
      GetPatientRecycleBinData();
    }
  }, [loggeduser, patientRefreshData])
  // End Get Patient Data

  // Start Get Appointment Data
  const GetAppointmentRecycleBinData = () => {
    setAppointmentDataLoading(true)
    store.GetAppointmentRecycleBinData(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID }))
      .then((result) => {
        setAppointmentDataLoading(false);
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            let deletedData = JSON.parse(response);
            setAppointmentData(deletedData);
            dispatch(setRecycleBinAppointmentDataAvailable(true));
          })
        }
        else {
          dispatch(setRecycleBinAppointmentDataAvailable(false));
          setAppointmentData([]);
        }
      })
      .catch(err => {
        setAppointmentData([]);
        setAppointmentDataLoading(false);
        dispatch(setRecycleBinAppointmentDataAvailable(false));
        dispatch(setNotification({ message: 'Error Occurred', status: 'error', action: true }));
      })
  }


  useEffect(() => {
    if (loggeduser) {
      GetAppointmentRecycleBinData();
    }
  }, [loggeduser, appointmentRefreshData])

  // End Get Appointment Data

  // Start Get Doctor Data
  const GetDoctorRecycleBinData = () => {
    setDoctorDataLoading(true)
    store.GetDoctorRecycleBinData(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID }))
      .then((result) => {
        setDoctorDataLoading(false);
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            let deletedData = JSON.parse(response);
            setDoctorData(deletedData);
            dispatch(setRecycleBinDoctorDataAvailable(true));
          })
        }
        else {
          dispatch(setRecycleBinDoctorDataAvailable(false));
          setDoctorData([]);
        }
      })
      .catch(err => {
        setDoctorData([]);
        setDoctorDataLoading(false);
        dispatch(setRecycleBinDoctorDataAvailable(false));
        dispatch(setNotification({ message: 'Error Occurred', status: 'error', action: true }));
      })
  }


  useEffect(() => {
    if (loggeduser) {
      GetDoctorRecycleBinData();
    }
  }, [loggeduser, doctorRefreshData])

  // End Get Doctor Data

  // Start Get Department Data
  const GetDepartmentRecycleBinData = () => {
    setDepartmentDataLoading(true)
    store.GetDepartmentRecycleBinData(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID }))
      .then((result) => {
        setDepartmentDataLoading(false);
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            let deletedData = JSON.parse(response);
            setDepartmentData(deletedData);
            dispatch(setRecycleBinDepartmentDataAvailabale(true));
          })
        }
        else {
          dispatch(setRecycleBinDepartmentDataAvailabale(false));
          setDepartmentData([]);
        }
      })
      .catch(err => {
        setDepartmentData([]);
        setDepartmentDataLoading(false);
        dispatch(setRecycleBinDepartmentDataAvailabale(false));
        dispatch(setNotification({ message: 'Error Occurred', status: 'error', action: true }));
      })
  }


  useEffect(() => {
    if (loggeduser) {
      GetDepartmentRecycleBinData();
    }
  }, [loggeduser, departmentRefreshData])

  // End Get Department Data

  // Start Get Files Data
  const GetPatientFilesRecycleBinData = () => {
    setPatientDataLoading(true)
    store.GetPatientFilesRecycleBinData(loggedusertoken, DataDecode.encryptPayload({ 'clinic_guid': loggeduser.facilityID }))
      .then((result) => {
        setPatientDataLoading(false);
        if (result.status == 200) {
          result.json().then((res) => {
            let response = DataDecode.decryptResponse(res);
            let deletedData = JSON.parse(response);
            setPatientFilesData(deletedData);
            dispatch(setRecycleBinPatientFilesDataAvailable(true));
          })
        }
        else {
          dispatch(setRecycleBinPatientFilesDataAvailable(false));
          setPatientFilesData([]);
        }
      })
      .catch(err => {
        setPatientFilesData([]);
        setPatientDataLoading(false);
        dispatch(setRecycleBinPatientFilesDataAvailable(false));
        dispatch(setNotification({ message: 'Error Occurred', status: 'error', action: true }));
      })
  }


  useEffect(() => {
    if (loggeduser) {
      GetPatientFilesRecycleBinData();
    }
  }, [loggeduser, patientFilesRefreshData])

  // End Get Files Data

  return (
    <>
      {loggeduser ?
        <div className="mx-auto px-4 lg:py-8 my-20 ">
          <div className='lg:hidden xs:block'>
            <div className='flex flex-col'>
              <div className="mt-2 origin-top-left flex font-bold mb-2 lg:text-black text-red-700 lg:text-xl sm:text-xl justify-between items-center">
                <svg id="MobPatientProfileSvgmobReloadIcon" onClick={() => { window.history.back() }} xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 8 12" fill="none">
                  <path d="M7.70492 1.41L6.29492 0L0.294922 6L6.29492 12L7.70492 10.59L3.12492 6L7.70492 1.41Z" className="fill-red-800" />
                </svg>

                <div className='flex justify-between w-full'>
                  <div>
                    <span id="MobPatientProfileSpanpatientDetails" className={`ml-3 ${previewTheme.headingTitleColor}`} style={{ fontFamily: previewTheme.fontFamily }}>Recycle Bin</span>
                  </div>
                  <div className='flex lg:mr-10 gap-2'>

                  </div>
                </div>

              </div>
              {(isPatientDataAvailable || isAppointmentDataAvailable || isDoctorDataAvailable || isDepartmentDataAvailabale || isPatientDataAvailable || isPatientFilesDataAvailable) &&
                <div ref={tabsContainerRef} className={`sticky top-[77px] mb-2 bg-white ${(!isMobileSidebarOpen && !isProfileDrawerOpen) ? 'z-30' : ''}`}>
                  <div className="relative flex items-center bg-white shadow" id='Tabs'>
                    {startIndex > 0 && (
                      <button id='BtnMobtableftButton'
                        onClick={() => scrollOneTab('left')}
                        className="focus:outline-none"
                      >
                        <svg id='SvgMobtableftIcon' xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                      </button>
                    )}
                    <div id="MobPatientProfileDivtabsContainer" className="flex-grow overflow-hidden">
                      <nav id="MobPatientProfileNavtabs" className="flex">
                        {visibleTabs.map((tab, index) => (
                          <div key={'mobRecycleBinTabBtn'+index}>
                            {isPatientDataAvailable && tab === "Patient" &&
                              <button id='BtnMobtablistButton'
                                key={tab + "navTab" + index}
                                className={`py-2 px-4 text-sm font-bold h-14 whitespace-nowrap flex-grow ${activeScrollTab === tab
                                  ? 'text-blue-500 border-b-2 border-red-800'
                                  : 'text-gray-400'
                                  }`}
                                onClick={() => scrollToSection(tab)}
                              >
                                {tab}
                              </button>}
                            {isAppointmentDataAvailable && tab === "Appointment" &&
                              <button id='MobBtntablistButton'
                                key={tab + "navTab" + index}
                                className={`py-2 px-4 text-sm font-bold h-14 whitespace-nowrap flex-grow ${activeScrollTab === tab
                                  ? 'text-blue-500 border-b-2 border-red-800'
                                  : 'text-gray-400'
                                  }`}
                                onClick={() => scrollToSection(tab)}
                              >
                                {tab}
                              </button>}
                            {isDoctorDataAvailable && tab === "Doctor" &&
                              <button id='MobBtntablistButton'
                                key={tab + "navTab" + index}
                                className={`py-2 px-4 text-sm font-bold h-14 whitespace-nowrap flex-grow ${activeScrollTab === tab
                                  ? 'text-blue-500 border-b-2 border-red-800'
                                  : 'text-gray-400'
                                  }`}
                                onClick={() => scrollToSection(tab)}
                              >
                                {tab}
                              </button>}
                            {isDepartmentDataAvailabale && tab === "Department" &&
                              <button id='MobBtntablistButton'
                                key={tab + "navTab" + index}
                                className={`py-2 px-4 text-sm font-bold h-14 whitespace-nowrap flex-grow ${activeScrollTab === tab
                                  ? 'text-blue-500 border-b-2 border-red-800'
                                  : 'text-gray-400'
                                  }`}
                                onClick={() => scrollToSection(tab)}
                              >
                                {tab}
                              </button>}
                            {isPatientFilesDataAvailable && tab === "Patient Files" &&
                              <button id='MobBtntablistButton'
                                key={tab + "navTab" + index}
                                className={`py-2 px-4 text-sm font-bold h-14 whitespace-nowrap flex-grow ${activeScrollTab === tab
                                  ? 'text-blue-500 border-b-2 border-red-800'
                                  : 'text-gray-400'
                                  }`}
                                onClick={() => scrollToSection(tab)}
                              >
                                {tab}
                              </button>}
                          </div>
                        ))}
                      </nav>
                    </div>
                    {startIndex < scrolltabs.length - 3 && (
                      <button
                        onClick={() => scrollOneTab('right')}
                        className="focus:outline-none" id='MobBtnTableft2Button'
                      >
                        <svg id='MobSvgtab2leftIcon' xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                        </svg>
                      </button>
                    )}
                  </div>

                </div>}


              <div ref={sectionRef} className='flex-grow overflow-y-auto px-auto mt-2' style={{ scrollbarWidth: 'none', 'msOverflowStyle': 'none' }}>
                {(isPatientDataAvailable || isAppointmentDataAvailable || isDoctorDataAvailable || isDepartmentDataAvailabale || isPatientDataAvailable || isPatientFilesDataAvailable) ?
                  <div className="grid gap-2 xs:grid-cols-1 lg:grid-cols-2">
                    {isPatientDataAvailable &&
                      <section ref={sectionRefs.current['Patient']} id="secMobilePatientBin">
                        <MobPatientBin data={patientData} setRefreshDate={setPatientRefreshData} dataLoading={patientDataLoading} />
                      </section>}
                    {isAppointmentDataAvailable &&
                      <section ref={sectionRefs.current['Appointment']} id='secMobileAppointmentBin'>
                        <MobAppointmentBin data={appointmentData} setRefreshDate={setAppointmentRefreshData} dataLoading={appointmentDataLoading} />
                      </section>}
                    {isDoctorDataAvailable &&
                      <section ref={sectionRefs.current['Doctor']} id='secMobileDoctorBin'>
                        <MobDoctorBin data={doctorData} setRefreshDate={setDoctorRefreshData} dataLoading={doctorDataLoading} />
                      </section>}
                    {isDepartmentDataAvailabale &&
                      <section ref={sectionRefs.current['Department']} id="secMobileDepartmentBin">
                        <MobDepartmentBin data={departmentData} setRefreshDate={setDepartmentRefreshData} dataLoading={departmentDataLoading} />
                      </section>}
                    {isPatientFilesDataAvailable &&
                      <section ref={sectionRefs.current['Patient Files']} id="secMobilePatientFilesBin">
                        <MobPatientFileBin data={patientFilesData} setRefreshDate={setPatientFilesRefreshData} dataLoading={patientFilesDataLoading} />
                      </section>}
                    <div className='h-screen'></div>
                  </div>
                  :
                  <p id="binMobEmptyText" className='text-red-600 font-semibold text-lg p-10 text-center my-20'>Bin is empty!</p>}
              </div>
            </div>
          </div>
          <div className='mb-10'></div>
        </div>
        :
        <p id="MobPatientProfilePnotAuthorized" className='text-red-600 font-semibold text-lg p-10 text-center my-20'>You are not authorized to perform this action!</p>
      }


    </>
  )
}

export default MobileRecycleBin