import React, { useState, useEffect, useRef } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { OutlinedInput, InputAdornment } from "@mui/material";

const Userroles = () => {
  const navigate = useNavigate();
  var isDesktopUserScreenOpen = useSelector(
    (state) => state.drawer.isDesktopUserScreenOpen
  );

  const [searchText, setSearchText] = useState("");
  const [isAdminDropdownOpen, setIsAdminDropdownOpen] = useState(true);
  const [activeRole, setActiveRole] = useState(null);
  const [isTextVisible, setIsTextVisible] = useState(true);
  const [isTestDropdownOpen, setIsTestDropdownOpen] = useState(true);
  const [isManagerDropdownOpen, setIsManagerDropdownOpen] = useState(true);
  const inputRef = useRef(null);
  const FullRoles = [
    "Invite User",
    "Create Doctor",
    "Create Patient",
    "Schedule Appointment",
    "CheckIn & CheckOut",
    "Signoff Appointment",
    "View Doctor",
    "Vieww Patient",
    "View Appointment",
  ];
  const Admin = [
    "Invite User",
    "Create Doctor",
    "Create Patient",
    "Schedule Appointment",
    "CheckIn & CheckOut",
    "Signoff Appointment",
    "View Doctor",
    "Vieww Patient",
    "View Appointment",
  ];
  const Doctor = [
    "Schedule Appointment",
    "CheckIn & CheckOut",
    "Signoff Appointment",
    "View Doctor",
    "Vieww Patient",
    "View Appointment",
  ];
  const User = [
    "Create Patient",
    "Schedule Appointment",
    "CheckIn & CheckOut",
    "View Doctor",
    "Vieww Patient",
    "View Appointment",
  ];
  const [isStandarduserDropdownOpen, setIsStandarduserDropdownOpen] =
    useState(true);
  const UserRoles = [{ name: "Admin" }, { name: "Doctor" }, { name: "Users" }];
  const handleToggleText = () => {
    setIsTextVisible((prevState) => !prevState);
  };

  const handleAdminSvgClick = () => {
    setIsAdminDropdownOpen(!isAdminDropdownOpen);
    setIsTestDropdownOpen(false);
    setActiveRole("Admin");
  };
  const handleStandarduserSvgClick = () => {
    setIsStandarduserDropdownOpen(!isStandarduserDropdownOpen);
    setActiveRole("Standarduser");
  };
  const handleManagerSvgClick = () => {
    setIsManagerDropdownOpen(!isManagerDropdownOpen);
    setIsStandarduserDropdownOpen(false);
    setActiveRole("Manager");
  };
  

  return (
    <>
      <div className="bg-white mx-auto px-4  my-20 w-100%">
        <div className="flex  items-center lg:ml-1 "></div>
        <div className="flex justify-between items-center lg:ml-1 ">
          <div id="UserRolesDivuserRole" className="text-[#A31B1B] font-bold text-2xl ml-4">
            User Role
          </div>
          <div className="flex">
            <span className="flex justify-between items-center px-6 py-4 transition delay-700 duration-1000 ease-in">
              <OutlinedInput
                id="UserRolesOutlinedtableDataSearchField"
                type="text"
                size="small"
                fullWidth
                placeholder="Search"
                value={searchText}
                onChange={(e) => {
                  e.stopPropagation();
                  setSearchText(e?.target?.value);
                }}
                inputRef={inputRef}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                    {/* </IconButton> */}
                  </InputAdornment>
                }
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      border: "2px solid #1a8aa8",
                      background: "#F5F5F5",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    },
                    "& fieldset": {
                      borderColor: "transparent", // Hide default border
                    },
                    "&:hover fieldset": {
                      borderColor: "transparent", // Hide hover border
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "transparent", // Hide focus border
                    },
                  },
                  "& .MuiOutlinedInput-input": {
                    "&:focus": {
                      outline: "none",
                      boxShadow: "none",
                    },
                  },
                }}
              />
            </span>
            <div className="flex justify-between items-center mb-4 px-2 mt-4">
              <svg id="UserRolesSvgsearchIcon"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.5333 8.46671C22.5465 7.47317 21.3728 6.68479 20.0799 6.14702C18.7869 5.60924 17.4003 5.33271 16 5.33338C10.1067 5.33338 5.34668 10.1067 5.34668 16C5.34668 21.8934 10.1067 26.6667 16 26.6667C20.9733 26.6667 25.12 23.2667 26.3067 18.6667H23.5333C22.9841 20.2261 21.9645 21.5765 20.6151 22.5318C19.2658 23.4871 17.6533 24.0001 16 24C11.5867 24 8.00001 20.4134 8.00001 16C8.00001 11.5867 11.5867 8.00004 16 8.00004C18.2133 8.00004 20.1867 8.92004 21.6267 10.3734L17.3333 14.6667H26.6667V5.33338L23.5333 8.46671Z"
                  fill="#A31B1B"
                />
              </svg>
            </div>
            <h1 className="flex justify-between items-center mb-4 px-2 mt-4">
              <div className=" items-center flex flex-row"></div>
            </h1>
          </div>
        </div>
        <div>
          {" "}
          <div className=" mx-auto px-1 lg:px-4 py-6 overflow-x-scroll lg:overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200 rounded-md shadow-sm">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-left text-sm font-medium text-black">
                    <div id="UserRolesDivuserRole"
                      className="flex text-[18px] items-center"
                      onClick={handleToggleText}
                    >
                      User Role
                    </div>
                  </th>
                </tr>
              </thead>
              <thead>
                <tr className="bg-red-100">
                  <th className="px-6 py-3 text-left text-sm font-medium text-black">
                    <div id="UserRolesDivadmin" className="flex items-center">
                      Admin
                      <svg id="UserRolesSvgadminIcon"
                        xmlns="http:www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        onClick={handleAdminSvgClick}
                      >
                        <path
                          d="M5.8335 8.33289L10.0002 12.4996L14.1668 8.33289H5.8335Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </th>
                  <td className="px-6 py-4 flex justify-end"></td>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white">
                  <td colSpan="1">
                    {isAdminDropdownOpen && (
                      <div className="bg-white  w-full">
                        <div id="UserRolesDivadminRole" className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500">
                          Admin Role listing
                        </div>
                        <div className="overflow-x-auto max-w-full">
                          <table className="min-w-full bg-white">
                            <thead>
                              <tr>
                                {Admin.map((Admin, index) => (
                                  <th id="UserRolesThadmin"
                                    key={index}
                                    className="px-3 py-2 text-center text-xs font-medium text-gray-500"
                                  >
                                    {Admin}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="bg-white">
                                {Admin.map((Admin, index) => (
                                  <td id="UserRolesTdindex"
                                    key={index}
                                    align="center"
                                    className="px-6 py-4"
                                  >
                                    <svg id="UserRolesSvgadminIcon"
                                      xmlns="http:www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                    >
                                      <path
                                        d="M9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5ZM7.5 12.75L3.75 9L4.8075 7.9425L7.5 10.6275L13.1925 4.935L14.25 6L7.5 12.75Z"
                                        fill="#008767"
                                      />
                                    </svg>
                                  </td>
                                ))}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
              <thead>
                <tr className="bg-red-100">
                  <th className="px-6 py-3 text-left text-sm font-medium text-black">
                    <div id="UserRolesDivdoctor" className="flex items-center">
                      Doctor
                      <svg id="UserRolesSvgdoctorIcon"
                        xmlns="http:www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        onClick={handleStandarduserSvgClick}
                      >
                        <path
                          d="M5.8335 8.33289L10.0002 12.4996L14.1668 8.33289H5.8335Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </th>
                  <td className="px-6 py-4 flex justify-end"></td>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white">
                  <td colSpan="1">
                    {isStandarduserDropdownOpen && (
                      <div className="bg-white  w-full">
                        <div id="UserRolesDivdoctorRole" className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500">
                          Doctor Role listing
                        </div>
                        <div className="overflow-x-auto max-w-full">
                          <table className="min-w-full bg-white">
                            <thead>
                              <tr>
                                {FullRoles.map((Standarduser, index) => (
                                  <th id="UserRolesThstandardUser"
                                    key={index}
                                    className="px-3 py-2 text-center text-xs font-medium text-gray-500"
                                  >
                                    {Standarduser}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="bg-white">
                                {FullRoles.map((roles, index) => (
                                  <td id="UserRolesTdfullRolesIndex"
                                    key={index}
                                    align="center"
                                    className="px-6 py-4"
                                  >
                                    {Doctor.includes(roles) ? (
                                      <svg id="UserRolesSvgdoctorIcon"
                                        xmlns="http:www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        className="h-4 w-4"
                                      >
                                        <path
                                          d="M9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5ZM7.5 12.75L3.75 9L4.8075 7.9425L7.5 10.6275L13.1925 4.935L14.25 6L7.5 12.75Z"
                                          fill="#008767"
                                        />
                                      </svg>
                                    ) : (
                                      <svg id="UserRolesSvgdoctorRolesIcon"
                                        xmlns="http:www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="h-4 w-4"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                        />
                                      </svg>
                                    )}
                                  </td>
                                ))}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>

              <thead>
                <tr className="bg-red-100">
                  <th className="px-6 py-3 text-left text-sm font-medium text-black">
                    <div id="UserRolesDivUser" className="flex items-center">
                      User
                      <svg
                        xmlns="http:www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        onClick={handleManagerSvgClick}
                      >
                        <path
                          d="M5.8335 8.33289L10.0002 12.4996L14.1668 8.33289H5.8335Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </th>
                  <td className="px-6 py-4 flex justify-end"></td>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white">
                  <td colSpan="1">
                    {isManagerDropdownOpen && (
                      <div className="bg-white w-full">
                        <div id="UserRolesDivUserRoleLiisting" className="bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500">
                          User Role listing
                        </div>
                        <div className="overflow-x-auto max-w-full">
                          <table className="min-w-full bg-white">
                            <thead>
                              <tr>
                                {FullRoles.map((Manager, index) => (
                                  <th id="UserRolesThManager"
                                    key={index}
                                    className="px-3 py-2 text-center text-xs font-medium text-gray-500"
                                  >
                                    {Manager}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="bg-white">
                                {FullRoles.map((roles, index) => (
                                  <td
                                    key={index}
                                    align="center"
                                    className="px-6 py-4"
                                  >
                                    {User.includes(roles) ? (
                                      <svg id="UserRolesSvgroles"
                                        xmlns="http:www.w3.org/2000/svg"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        className="h-4 w-4 text-center"
                                      >
                                        <path
                                          d="M9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5ZM7.5 12.75L3.75 9L4.8075 7.9425L7.5 10.6275L13.1925 4.935L14.25 6L7.5 12.75Z"
                                          fill="#008767"
                                        />
                                      </svg>
                                    ) : (
                                      <svg id="UserRolesSvguserRoles"
                                        xmlns="http:www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="h-4 w-4"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                        />
                                      </svg>
                                    )}
                                  </td>
                                ))}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Userroles;
