const DateFunction = {
    GetDate: (date) => {
        const d = new Date(date);
        // const year = d.getFullYear();
        // const month = String(d.getMonth() + 1).padStart(2, '0');
        // const day = String(d.getDate()).padStart(2, '0');
        // return `${day}-${month}-${year}`;
        const options = { year: 'numeric', month: 'short', day: '2-digit' };
        return d.toLocaleDateString('en-US', options);
    },
    GetTime: (date) => {
        // const d = new Date(date);
        // const hours = String(d.getHours()).padStart(2, '0');
        // const minutes = String(d.getMinutes()).padStart(2, '0');
        // return `${hours}:${minutes}`;
        const d = new Date(date);

        let hours = String(d.getHours()).padStart(2, '0');
        const minutes = String(d.getMinutes()).padStart(2, '0');
        const amPm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours to 12-hour format
        hours = hours % 12;
        hours = hours ? hours : 12; // The hour '0' should be '12'
        const paddedHours = hours < 10 ? '0' + hours : hours;
        return `${paddedHours}:${minutes} ${amPm}`;
    },
    SetDbDateTime: (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        const hours = String(d.getHours()).padStart(2, '0');
        const minutes = String(d.getMinutes()).padStart(2, '0');
        const seconds = String(d.getSeconds()).padStart(2, '0');
        // Example format: DD-MM-yyyyy HH:mm:ss
        // 2024-05-16T18:30:00.000Z
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    },
    SetDbDateOfBirth: (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        const hours = String(d.getHours()).padStart(2, '0');
        const minutes = String(d.getMinutes()).padStart(2, '0');
        const seconds = String(d.getSeconds()).padStart(2, '0');
        // Example format: DD-MM-yyyyy HH:mm:ss
        // 2024-05-16T18:30:00.000Z
        return `${year}-${month}-${day}T00:00:00`;
    },
    SetDbCurrentTimeStamp: () => {
        const d = new Date();
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        const hours = String(d.getHours()).padStart(2, '0');
        const minutes = String(d.getMinutes()).padStart(2, '0');
        const seconds = String(d.getSeconds()).padStart(2, '0');
        // Example format: DD-MM-yyyyy HH:mm:ss
        // 2024-05-16T18:30:00.000Z
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    },
    GetAge: (dob) => {
        const now = new Date();
        const birth = new Date(dob);

        let years = now.getFullYear() - birth.getFullYear();
        let months = now.getMonth() - birth.getMonth();
        let days = now.getDate() - birth.getDate();

        // Adjust the years, months, and days if needed
        if (days < 0) {
            months--;
            days += new Date(now.getFullYear(), now.getMonth(), 0).getDate();
        }
        if (months < 0) {
            years--;
            months += 12;
        }

        if (years == 0 && months == 0) {
            return `${days} D`;
        }
        else if (months > 0 && years == 0) {
            return `${months} M`;
        }
        else {
            return `${years} Y`;
        }
    },
    calculateAge(dob) {
        const today = new Date();
        const birthDate = new Date(dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    },
    AppointmentDateTimePicker: (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        const hours = String(d.getHours()).padStart(2, '0');
        const minutes = String(d.getMinutes()).padStart(2, '0');
        const seconds = String(d.getSeconds()).padStart(2, '0');
        // Example format: DD-MM-yyyyy HH:mm:ss
        // 2024-05-16T18:30:00.000Z
        return `${year}-${month}-${day}T${hours}:${minutes}:00`;
    },
    getDateTimeAppointment10Minutes: () => {
        const currentDate = new Date();
        const ms = 1000 * 60 * 10; // 10 minutes in milliseconds
        const roundedDate = new Date(Math.ceil(currentDate.getTime() / ms) * ms);
        return roundedDate;
    },
    AddDaysToDate: (date, days) => {
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    },
    CompareDateWithToday: (date) => {
        // Get today's date
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set the time to midnight for an accurate date comparison

        // Example existing date (e.g., 2024-05-28)
        const existingDate = new Date(date);
        existingDate.setHours(0, 0, 0, 0);
        return today.getTime() === existingDate.getTime();
    },
    RecordingTimer: (seconds) => {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hrs < 10 ? '0' : ''}${hrs}:${mins < 10 ? '0' : ''}${mins}:${secs < 10 ? '0' : ''}${secs}`;
    },
    GetDateUID: () => {
        const d = new Date();
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        const hours = String(d.getHours()).padStart(2, '0');
        const minutes = String(d.getMinutes()).padStart(2, '0');
        const seconds = String(d.getSeconds()).padStart(2, '0');
        // Example format: DD-MM-yyyyy HH:mm:ss
        // 2024-05-16T18:30:00.000Z
        return `${year}${month}${day}${hours}${minutes}${seconds}`;
    },
    isToday: (todayDate) => {
        const date = new Date(todayDate);
        const today = new Date();

        return date.getFullYear() === today.getFullYear() &&
            date.getMonth() === today.getMonth() &&
            date.getDate() === today.getDate();
    },
    GetYear: () => {
        const d = new Date();
        const year = d.getFullYear();
        return `${year}`;
    },
    GetSubtractedDays: (date, days) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const previousDate = new Date(date);
        previousDate.setHours(0, 0, 0, 0);
        const differenceInMillis = today - previousDate;
        const remainingDays = (days - Math.ceil(differenceInMillis / (1000 * 60 * 60 * 24)));
        return remainingDays <= 1 ? `${remainingDays} day` : `${remainingDays} days`;
    },
    Convert12Hour: (time) => {
        if (!time) return "";
        let [hours, minutes] = time.split(":").map(Number);
        const period = hours >= 12 ? "PM" : "AM";
        hours = hours % 12 || 12;
        return `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes} ${period}`;
    },
    getFullDayName(dateString) {
        const date = new Date(dateString);
        const options = { weekday: 'long' };
        return date.toLocaleDateString('en-US', options);
    },
    TodayDateCompare: (dateToCompare) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const inputDate = new Date(dateToCompare);
        inputDate.setHours(0, 0, 0, 0);
        return inputDate.toDateString() === today.toDateString() ? true : false;
    },
    formatTo12Hour: (date) => {
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        return `${hours < 10 ? "0" + hours : hours}:${minutes} ${ampm}`;
      }
};

export default DateFunction;