import React, { useEffect, useRef, useState } from "react";
import { ReactMic } from "react-mic";
import { useDispatch, useSelector } from "react-redux";
import {
  setRecDisableButton,
  setRecIsLoading,
  setShowRecoringPopup,
  setStartRecording,
  setStopRecording,
} from "../../../../Redux/features/recording/recordingSlice";
import DateFunction from "../../../../utils/DateFunctions";
import useMicrophonePermission from "./useMicrophonePermission";
import store from "../../../../API/store";
import DataDecode from "../../../../utils/DataDecode";
import { setNotification } from "../../../../Redux/features/toast/toastSlice";
import LoadingSpinner from "../../../../components/LoadingSpinner";

let iniitalState = true;
const EMRAudioRecorder = ({
  patient_id,
  appointment_id,
  setRefreshData,
  setClinicalDocumentation,
}) => {
  const dispatch = useDispatch();

  var loggeduser = useSelector((state) => state.login.loginuserData);
  var loggedusertoken = useSelector((state) => state.login.userBarerToken);
  var isStartRecording = useSelector((state) => state.recording.startRecording);
  var disableButtons = useSelector((state) => state.recording.disableButtons);

  // Initialize state variables for timer and timeInterval
  const [timer, setTimer] = useState(0);
  const [timerRun, setTimerRun] = useState(false);

  const startTimer = () => {
    setTimerRun(true);
  };

  const pauseTimer = () => {
    setTimerRun(false);
  };

  const stopTimer = () => {
    setTimerRun(false);
    setTimer(0);
  };

  useEffect(() => {
    let interval;
    if (timerRun) {
      interval = setInterval(() => {
        setTimer((seconds) => seconds + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timerRun]);

  useEffect(() => {
    if (isStartRecording == false) {
      pauseTimer();
    }
  }, [isStartRecording]);

  // const [mergedAudio, setMergedAudio] = useState(null);
  const [recordedBlobs, setRecordedBlobs] = useState([]);
  const permissionStatus = useMicrophonePermission();
  const requestPermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
    } catch (error) {
      dispatch(
        setNotification({
          message: "Error requesting microphone permission",
          status: "error",
          action: true,
        })
      );
    }
  };

  const readAsArrayBuffer = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsArrayBuffer(blob);
    });
  };

  const mergeAudioFiles = async (blobs) => {
    try {
      const buffers = await Promise.all(blobs.map(readAsArrayBuffer));
      const totalLength = buffers.reduce(
        (sum, buffer) => sum + buffer.byteLength,
        0
      );
      const mergedBuffer = new Uint8Array(totalLength);

      let offset = 0;
      for (const buffer of buffers) {
        mergedBuffer.set(new Uint8Array(buffer), offset);
        offset += buffer.byteLength;
      }

      return new Blob([mergedBuffer], { type: "audio/wav" });
    } catch (error) {
      dispatch(
        setNotification({
          message: "Error occured while recording audio",
          status: "error",
          action: true,
        })
      );
      return;
    }
  };

  useEffect(() => {
    if (iniitalState && permissionStatus === "granted") {
      // iniitalState = false;
      setTimeout(() => {
        dispatch(setStartRecording(true));
        startTimer();
      }, 200);
    } else {
      requestPermission();
    }
  }, [permissionStatus]);

  useEffect(() => {
    if (permissionStatus !== "granted") {
      requestPermission();
      setTimer(0);
      stopTimer();
    }
  }, [permissionStatus]);

  const toggleRecording = () => {
    if (isStartRecording) {
      // Pause recording
      dispatch(setStartRecording(false));
      pauseTimer();
    } else {
      // Start recording
      dispatch(setStartRecording(true));
      dispatch(setStopRecording(false));
      startTimer();
    }
  };
  async function checkForSilence(audioBuffer) {
    let isSilent = true;
    const silenceThreshold = 0.01; // Adjust this threshold based on noise level

    for (let i = 0; i < audioBuffer.length; i++) {
      if (Math.abs(audioBuffer[i]) > silenceThreshold) {
        isSilent = false;
        break;
      }
    }
    return isSilent;
  }
  const blobToBase64 = async (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };
  const getBlobFileFormat = async (bb) => {
    const fileFormat = bb.type;
     return fileFormat;
  };
  const handleMergeAudio = async (blobs) => {
    try {
      const mergedBlob = await mergeAudioFiles(blobs);
      const buffers = await Promise.all(blobs.map(readAsArrayBuffer));
      const totalLength = buffers.reduce(
        (sum, buffer) => sum + buffer.byteLength,
        0
      );

      // If the total length is 0, it's likely the audio is empty
      if (totalLength === 0) {
        dispatch(
          setNotification({
            message: "No audio recorded. Skipping API call.",
            status: "error",
            action: true,
          })
        );
        return;
      }

      const mergedBuffer = new Uint8Array(totalLength);
      let offset = 0;
      for (const buffer of buffers) {
        mergedBuffer.set(new Uint8Array(buffer), offset);
        offset += buffer.byteLength;
      }
      const isSilent = await checkForSilence(mergedBuffer);
      if (isSilent) {
        dispatch(
          setNotification({
            message: "Recording is empty, so transcription isn’t possible.",
            status: "error",
            action: true,
          })
        );
        return;
      }
      let base64string = await blobToBase64(mergedBlob);
      let fileFormat = await getBlobFileFormat(mergedBlob);
      //const mergedBlob = new Blob([mergedBuffer], { type: 'audio/wav' });

      if (mergedBlob && recordedBlobs.length > 0) {
        dispatch(setRecDisableButton(false));
        dispatch(setShowRecoringPopup(false));
        dispatch(setRecIsLoading(true));

        let payload = {
          UserId: loggeduser.userId,
          FacilityId: loggeduser.facilityID,
          PatientId: patient_id,
          AppointmentId: appointment_id,
          transcriptionFilesDto: {
            filebinary: base64string,
            fileformat: fileFormat,
            filename: DateFunction.GetDateUID() + ".wav",
          },
        };

        let encData = DataDecode.encryptPayload(payload);
        setTimeout(async () => {
          await store
            .GetTranscription(encData)
            .then((result) => {
              if (result.status === 200) {
                result.json().then((res) => {
                  setRefreshData((o) => !o);
                  dispatch(setRecDisableButton(false));
                  setRecordedBlobs([]);
                  dispatch(
                    setNotification({
                      message: "Notes Saved!",
                      status: "success",
                      action: true,
                    })
                  );
                  setClinicalDocumentation(true);
                });
              } else if (result.status == 201) {
                dispatch(setRecDisableButton(false));
                setRecordedBlobs([]);
                dispatch(
                  setNotification({
                    message: "Error while Generating Notes! Audio Saved!",
                    status: "error",
                    action: true,
                  })
                );
              } else {
                // dispatch(setRecDisableButton(false));
                setRecordedBlobs([]);
                dispatch(
                  setNotification({
                    message: "Error Occurred! Something went Wrong",
                    status: "error",
                    action: true,
                  })
                );
              }
            })

            .catch((err) => {
              dispatch(
                setNotification({
                  message: "Error occured while saving",
                  status: "error",
                  action: true,
                })
              );
            });
        }, 100);
      } else {
        dispatch(
          setNotification({
            message: "No Audio Recording Found!",
            status: "error",
            action: true,
          })
        );
      }
    } catch (error) {
      dispatch(
        setNotification({
          message: "Error merging audio files",
          status: "error",
          action: true,
        })
      );
    }
  };

  const stopRecording = () => {
    dispatch(setStartRecording(false));
    dispatch(setStopRecording(true));
    stopTimer();
    setTimeout(() => {
      if (recordedBlobs.length > 0) {
        handleMergeAudio(recordedBlobs);
      } else {
        dispatch(
          setNotification({
            message: "No Audio Recording Found!",
            status: "error",
            action: true,
          })
        );
      }
    }, 200);
  };

  const onStop = (recordedData) => {
    let rb = recordedBlobs;
    rb.push(recordedData.blob);
    setRecordedBlobs(rb);
  };

  return (
    <>
      {permissionStatus === "granted" ? (
        <div className="">
          <h2 className="text-center mb-3 -mt-3 text-lg pr-6">
            {DateFunction.RecordingTimer(timer)}
          </h2>
          <ReactMic
            record={isStartRecording}
            onStop={onStop}
            strokeColor="#ff0000"
            backgroundColor="#fff"
            className="w-full my-4"
            mimeType="audio/wav"
          />
          <div className="flex justify-center items-center space-x-4 my-4">
            <button
              type="button"
              onClick={toggleRecording}
              disabled={disableButtons}
              className="border-gray-700 border rounded-full p-1 ml-5 mr-2 disabled:cursor-not-allowed disabled:opacity-50"
            >
              {isStartRecording ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-6 text-yellow-500 hover:text-yellow-700"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.75 5.25a.75.75 0 0 1 .75-.75H9a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H7.5a.75.75 0 0 1-.75-.75V5.25Zm7.5 0A.75.75 0 0 1 15 4.5h1.5a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H15a.75.75 0 0 1-.75-.75V5.25Z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-6 text-green-500 hover:text-green-700"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </button>
            <button
              type="button"
              onClick={stopRecording}
              disabled={disableButtons}
              className="border-gray-700  border rounded-full p-1 mr-5 disabled:cursor-not-allowed disabled:opacity-50"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="size-6 text-red-500 hover:text-red-700"
              >
                <path
                  fillRule="evenodd"
                  d="M4.5 7.5a3 3 0 0 1 3-3h9a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3h-9a3 3 0 0 1-3-3v-9Z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>

          {/* {mergedAudio && isStopRecording && (
            <div className="my-4">
              <audio controls src={URL.createObjectURL(mergedAudio)} className="w-full"></audio>
            </div>
          )} */}
        </div>
      ) : (
        <p className="text-center text-red-600 mt-4 mb-4">
          Please grant Microphone Permission
        </p>
      )}
    </>
  );
};

export default EMRAudioRecorder;
