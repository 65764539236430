import React from "react";

const TermsOfService = () => {
	return (
		<div id="TermsOfServiceDivpolicyContainer" className="max-w-[1536px] xxs:m-4 sm:m-16 !mx-auto xxs:px-8 sm:px-10 md:px-28 xxs:text-[14px] xs:text-[16px] sm:text-[18px] md:text-[20px] xxs:text-justify md:text-start !font-['Inter']">
			<h2 id="TermsOfServiceH2serviceTitle" className="text-[#004B93] md:text-[42px] sm:text-[32px] xxs:text-[20px] font-semibold xxs:mb-8 xxs:mt-16 md:mt-32 md:mb-16 text-center">
				EMR AXIS Terms of Service
			</h2>
			<section>
				<p id="TermsOfServicePprivacyPolicyText">
					Please read these “Terms of Service” and our “Privacy
					Policy” carefully prior to visiting and using this website
					(“Site”), registering to use or using our Service, or
					submitting information through this Site or the Service. By
					doing any of the foregoing, you agree to be bound by these
					Terms of Service (which incorporate the Data Processing
					Addendum) and our Privacy Policy. If you do not agree with
					all of the terms of our Terms of Service (including the Data
					Processing Addendum) and our Privacy Policy, you are not
					authorized to visit or use this Site, register to use or use
					the Service, or submit information through this Site or the
					Service, and your sole remedy is to stop using this Site and
					the Service.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceH2definationText">
					<b>DEFINITIONS</b>
				</h2>
				<p id="TermsOfServicePcapitalized" style={{ overflowWrap: 'break-word', hyphens: 'auto' }}>
					In addition to the capitalized defined within these Terms of
					Service, the following capitalized terms will have the
					following meanings:
				</p>
				<br />
				<p id="TermsOfServicePAggregate" style={{ overflowWrap: 'break-word', hyphens: 'auto' }}>
					<b>“Aggregated Information”</b> means aggregated, anonymous
					data and statistical information about the use of this Site
					and/or our Service.
				</p>
				<br />
				<p id="TermsOfServicePadditionalServices" style={{ overflowWrap: 'break-word', hyphens: 'auto' }}>
					<b>“Additional Service”</b> means any Service provided to a
					Customer by EMR AXIS, such as development,
					configuration, implementation, integration, training,
					support and consulting services, which is not one of our
					standard services.
				</p>
				<br />
				<p id="TermsOfServicePemrAxisText" style={{ overflowWrap: 'break-word', hyphens: 'auto' }}>
					<b>“EMR AXIS”</b>, <b>“our”</b>, <b>“us”</b>, and{" "}
					<b>“we”</b> means EMR AXIS Software, Inc.
				</p>
				<br />
				<p id="TermsOfServicePCustomer" style={{ overflowWrap: 'break-word', hyphens: 'auto' }}>
					<b>“Customer”</b>, <b>“user”</b>, <b>“you”</b> and{" "}
					<b>“your”</b> means any person who visits or uses this Site,
					registers to use or uses the Service or submits information
					through this Site.
				</p>
				<br />
				<p id="TermsOfServicePcustomerData" style={{ overflowWrap: 'break-word', hyphens: 'auto' }}>
					<b>“Customer Data”</b> means all data that is provided
					through this Site for hosting, analytics, report building
					and/or other data processing Services.
				</p>
				<br />
				<p id="TermsOfServicePpersoanlInformation">
					<b>“Personal Information”</b> means all personally
					identifiable information provided through this Site for
					purposes of using this Site or the Service, including,
					without limitation, your name, company’s name, contact
					information, username, password, other log-in information
					and the Service, including, without limitation, your name,
					credit card information.
				</p>
				<br />
				<p id="TermsOfServicePservices">
					<b>“Service”</b> means the service or services, as
					applicable, provided through this Site and all of our
					current and future intellectual property rights and
					proprietary technology relating to the Service, including,
					without limitation, all copyrights, patents, trademarks and
					software, regardless if whether the service is provided as a
					free trial or paid service. The Service will include,
					without limitation, all web-based analytics, web-based
					dashboards, report building and other data processing
					services, as well as all Additional Services, if any,
					provided through this Site.
				</p>
				<br />
				<p id="TermsOfServicePsite">
					<b>“Site”</b> means this website, the Software Agent and all
					of our current and future intellectual property rights and
					proprietary technology relating to this Site, including,
					without limitation, all copyrights, patents, trademarks and
					software.
				</p>
			</section>
			<br />
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceH2useSite" className="text-start">
					<b>YOUR USE OF THIS SITE AND THE SERVICE</b>
				</h2>
				<br />
				<p id="TermsOfServicePentitledSiteService">
					You are only entitled to use this Site and the Service for
					lawful purposes and pursuant to these Terms of Service. By
					using this Site, registering to use or using the Service, or
					submitting information through this site or the Service, you
					represent to EMR AXIS that you (i) are authorized to
					act on behalf of the legal entity contracting with
					EMR AXIS or of legal age to agree to these Terms of
					Service; (ii) agree to comply with all applicable rules,
					regulations, and laws regarding online conduct and
					transmission of information; (iii) agree to be bound by
					these Terms of Service; and (iv) you will comply with our
					Acceptable Use Policy. To determine your compliance with
					these Terms of Service, we reserve the right, but are not
					obligated, to monitor your use of this Site and/or the
					Service.
				</p>
			</section>
			<br />
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceH2addServices">
					<b>ADDITIONAL SERVICES</b>
				</h2>
				<br />
				<p id="TermsOfServicePtermsServices">
					Pursuant to these Terms of Service, you may, from time to
					time, request us to provide you with Additional Services.
					All Additional Services must be set forth in a written
					“Statement of Work” executed by both you and us prior to our
					commencing performance of any Additional Service. Each
					Statement of Work must include a description of the
					applicable deliverables (“Deliverables”) to be provided by
					us and/or Services performed by us, commercially reasonable
					terms for testing and collaboration with us, and pricing, as
					well as estimated time-frames for delivery or performance,
					as applicable. A Statement of Work may also include such
					additional terms and conditions as you and EMR AXIS may
					agree to include. If there is a conflict between these Terms
					of Service and any Statement of Work, then these Terms of
					Service will prevail, unless the Statement of Work
					specifically refers to the conflict and states that the
					Statement of Work will prevail. Each Statement of Work is
					hereby incorporated into these Terms of Service by
					reference.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceH2availability">
					<b>AVAILABILITY</b>
				</h2>
				<br />
				<p id="TermsOfServicePredundantData">
					Your use of this Site and/or the Service may be interrupted
					from time to time for any of several reasons, including,
					without limitation, the malfunction of equipment, periodic
					updating, maintenance or repair of this Site and/or the
					Service, or other actions that we may take in our sole
					discretion and from time to time. We reserve the right to
					modify, suspend, or discontinue the availability of this
					Site and/or the Service, or any portion or feature of
					either, at any time and in our sole discretion and without
					prior notice. You, and not EMR AXIS, are solely
					responsible for maintaining, protecting, backing-up and
					providing redundant access to your Customer Data and
					Personal Information.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceH2jurisdictionalRestrictions">
					<b>JURISDICTIONAL RESTRICTIONS</b>
				</h2>
				<br />
				<p id="TermsOfServicePreserveRights">
					We reserve the right, at any time and in our sole
					discretion, to limit the availability and/or use of this
					Site and/or the Service to any person, geographic area, or
					jurisdiction. You shall, at all times, comply with all
					applicable laws and regulations of the jurisdiction in which
					you access the Site and/or the Service and all other
					applicable governmental entities governing, restricting or
					otherwise pertaining to the use, transmission, display,
					exporting or importing of data, products, services and/or
					technical information.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceuserName">
					<b>USERNAMES AND PASSWORDS</b>
				</h2>
				<br />
				<p id="TermsOfServicePrequiresUserName">
					Use of the Service requires that you register with a
					username and password. Your username and password will allow
					you to log-in, access and use this Site and the Service. YOU
					ARE SOLELY RESPONSIBLE FOR MAINTAINING THE CONFIDENTIALITY,
					MAINTENANCE, AND PROPER USE OF YOUR USERNAME AND PASSWORD
					AND SOLELY RESPONSIBLE FOR ANY AND ALL ACTIVITIES THAT ARE
					CONDUCTED THROUGH YOUR ACCOUNT. You agree not to disclose
					any usernames or passwords to any third party and not to
					permit any third party to make use of any username or
					password. You also agree to use proper termination
					procedures at the end of each session during which you
					access this Site and the Service, including full termination
					of your connection with the Service.
				</p>
				<br />
				<p id="TermsOfServicePimmediatelyNotify">
					You shall immediately notify EMR AXIS by e-mail if you
					become aware of any actual or suspected unauthorized access
					to or use of the Service by any party or any other actual or
					potential security breach involving the Service.
					EMR AXIS may, in our sole discretion and at any time,
					decide to change your username or password upon notice to
					you.
				</p>
				<br />
				<p id="TermsOfServicePdiscoverInformation">
					If you discover at any time that you have been granted
					access to any information or documents contained on this
					Site and/or the Service that you are not authorized to
					access or view, you shall (i) immediately cease any access
					to such information; (ii) take reasonable steps to prevent
					the disclosure of any such unauthorized information; and
					(iii) immediately inform EMR AXIS of this situation by
					e-mail.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceH2pricing">
					<b>PRICING</b>
				</h2>
				<br />
				<p id="TermsOfServicePplansPricing">
					Our plans and pricing information can be found on our Plans
					and Pricing page. All prices are exclusive of any and all
					taxes. You shall be solely responsible for payment of any
					and all such taxes. Pricing for the Service may change from
					time to time. If EMR AXIS changes any pricing, we will
					provide you with at least thirty (30) days’ prior notice. If
					pricing changes, your continued use of the Service after the
					change takes effect indicates your agreement with the new
					pricing.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceH2payment">
					<b>PAYMENT</b>
				</h2>
				<br />
				<p id="TermsOfServicePnonRefundable">
					All fees for the Service are billed and due monthly and in
					advance and are non-refundable. Monthly fees will commence
					immediately upon registration and will continue until your
					right to use this Site and the Service are either canceled
					or terminated. There will be no refunds or credits for
					partial months of using the Service or refunds for months
					unused with an open account.{" "}
					<b>No exceptions will be made</b>. EMR AXIS will
					provide you with a monthly invoice for the Service;
					provided, however, if you do not receive an invoice from
					EMR AXIS, you are still responsible for payment for the
					Service while your account is active. We reserve the right
					to invoice you retroactively for the Service provided to you
					and for which we have not previously invoiced you or
					collected from you. Prices are subject to change upon thirty
					(30) days’ notice from EMR AXIS. We will not be liable
					to you or to any third party for any modification, price
					change, suspension, or discontinuance of the Service.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceH2paymentMethod">
					<b>PAYMENT METHODS</b>
				</h2>
				<br />
				<p id="TermsOfServicePacceptPayment">
					We accept payments by Visa, MasterCard, American Express &
					Paypal. For credit card purchases, we obtain a pre-approval
					from the credit card company for the amount of the order.
					Billing to your credit card occurs at the time of purchase.
					We are unable to accept credit cards issued by banks outside
					of the United States. Debit cards and check cards have daily
					spending limits that may prevent the processing of your
					order. If a purchase has been declined online due to credit
					card issues, please ensure all data is correct and resubmit.
					If the transaction is not accepted online, you will be
					unable to use that card for your purchase and should use
					another credit card.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceH2cancellation">
					<b>CANCELLATION</b>
				</h2>
				<br />
				<p id="TermsOfServicePcancelService">
					You may cancel the Service at anytime by contacting
					EMR AXIS at the e-mail address provided below. You are
					solely responsible for properly canceling your account with
					EMR AXIS. Once you cancel your account, your
					information may no longer be available or accessible. If you
					cancel the Service during a month which is not pre-paid in
					full and in advance, your cancellation will take effect
					immediately
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceH2nonPayment">
					<b>NON-PAYMENT AND LATE FEES</b>
				</h2>
				<br />
				<p id="TermsOfServicePnonPayment">
					In the event of non-payment, EMR AXIS will have the
					right, with or without notice, to suspend or immediately
					terminate your access to the Service. We may determine
					whether or not to reinstate your access to the Service upon
					receipt of payment in full of all sums owed. Any payment not
					received by EMR AXIS on or before thirty (30) days
					following the due date shall automatically be assessed a
					late payment fee equal to the lesser of (i) the maximum
					allowed by applicable law; or (ii) the greater of ten (10%)
					percent or $50. Any payment not received by EMR AXIS on
					or before thirty (30) days following the due date shall also
					bear interest from the due date until paid in full at the
					lesser of one and a half percent (1.5%) per month or the
					maximum rate allowed by applicable law. If EMR AXIS is
					required to use a collection agency or attorney to collect
					money owed by you, you agree to pay the reasonable cost of
					collection. These collection costs include, but are not
					limited to, any collection agency’s fees, reasonable
					attorneys’ fees and court costs.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceH2disputedCharges">
					<b>DISPUTED CHARGES</b>
				</h2>
				<br />
				<p i="PapplicableLaw">
					Subject to applicable law, if you intend to dispute a charge
					or request a credit, you must contact EMR AXIS in
					writing within thirty(30) days of the date on the invoice.
					You hereby waive any right to dispute a charge or receive a
					credit for a charge that you do not report within thirty
					(30) days of the date on the applicable invoice. To dispute
					an invoice, you musts end EMR AXIS a written itemized
					description of the specific items you dispute as contained
					in such invoice. The parties agree to use commercially
					reasonable efforts to timely resolve billing disputes.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceH2propreitaryRights">
					<b>PROPREITARY RIGHTS</b>
				</h2>
				<br />
				<p id="TermsOfServicePsiteService">
					<b>Site and Service</b>
					<br />
					EMR AXIS owns all right, title and interest in and to
					this Site and the Services. EMR AXIS reserves all
					rights not expressly granted to you in these Terms of
					Service.
				</p>
				<br />
				<p id="TermsOfServicePnamesMarking">
					<b>Names and Markings</b>
					<br />
					As between you and EMR AXIS, EMR AXIS owns all
					right, title and interest in and to the name “EMR AXIS”
					and all other service names, trade names and trade dress
					owned and/or operated by EMR AXIS, as well as all of
					EMR AXIS’s URLs, website domain names, graphics, logos,
					page headers, button icons, scripts and other markings
					(collectively, “Names and Markings”). You may not use any of
					the Names and Markings without our express, prior written
					permission. You shall not delete or in any other manner
					alter the copyright, trademark, and other proprietary
					notices appearing on this Site or in any way connected with
					the Service. We make no proprietary claim to any third party
					names, copyrights, service marks, trademarks, or trade dress
					appearing on this Site. Any third party names, copyrights,
					service marks, trademarks, or trade dress appearing on this
					Site are property of their respective owners. Without our
					express, prior written consent, you may only print,
					download, or otherwise use the Names and Markings for your
					own internal, non-commercial use consistent with these Terms
					of Service and applicable law. Except as otherwise permitted
					under applicable copyright laws, no other copying,
					distribution, redistribution, transmission, publication, or
					use is permitted.
				</p>
				<br />
				<p id="TermsOfServicePcustomerData">
					<b>Customer Data</b>
					<br />
					As between you and EMR AXIS, you exclusively own all
					right, title and interest in and to all Customer Data.
					Customer Data is deemed Confidential Information under these
					Terms of Service. For more information about how we handle
					your Customer Data, please read our “Privacy Policy”. For
					more information about how we handle Confidential
					Information, please see “Confidentiality” below.
				</p>
				<br />
				<p id="TermsOfServicePpersonalInformation">
					<b>Personal Information</b>
					<br />
					As between you and EMR AXIS, you also exclusively own
					all right, title and interest in and to all Personal
					Information. Personal Information is deemed Confidential
					Information under these Terms of Service. For more
					information about how we handle your Personal Information,
					please read our “Privacy Policy” and Data Processing
					Addendum. For more information about how we handle
					Confidential Information, please see “Confidentiality”
					below.
				</p>
				<br />
				<p id="TermsOfServicePaggregateInformation">
					<b>Aggregated Information</b>
					<br />
					As between you and EMR AXIS, EMR AXIS exclusively
					owns all right, title and interest in and to all Aggregated
					Information. Aggregated Information is not Confidential
					Information under these Terms of Service. For more
					information about how we handle Aggregated Information,
					please read our “Privacy Policy”.
				</p>
				<br />
				<p id="TermsOfServicePfeedback">
					<b>Feedback</b>
					<br />
					EMR AXIS exclusively owns all right, title and interest
					in and to any and all suggestions, enhancement requests,
					recommendations or other feedback you provide and relating
					to this Site and/or the Service (“Feedback”).
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceh2confidentiality">
					<b>CONFIDENTIALITY</b>
				</h2>
				<br />
				<p id="TermsOfServicePdefinition">
					<b>Definition</b>
					<br />
					<p id="TermsOfServicePconfidentialInformation">
						“Confidential Information” means any and all of the
						following information, disclosed or made available by
						either party (“Disclosing Party”) to the other party
						(“Receiving Party”), and any derivatives thereof, which
						is confidential and proprietary in nature, including,
						but not limited to, Customer Data, Personal Information
						and past, present and future Disclosing Party: business
						and financial records; business and marketing plans;
						contracts; sales; reports; billings; insurance filings;
						employees; customers; vendors; proof of concepts;
						products and/or services, in preliminary and final
						production form; pricing; intellectual property; source
						codes; object codes; technical knowledge; trade secrets;
						internal practices and procedures; feedback relating to
						any Confidential Information; other information relating
						to Disclosing Party’s business, including, without
						limitation, the terms and conditions of any Statement of
						Work; and any third-party confidential information which
						Disclosing Party may be authorized, from time to time,
						to review, have access to and/or use, including, without
						limitation. Confidential Information will not include
						information which (i) the Receiving Party lawfully had
						in its possession prior to disclosure by Disclosing
						Party; (ii) is now or subsequently becomes generally
						available to the public without a breach of this Privacy
						Policy by Receiving Party; (iii) Receiving Party can
						document as independently created without the use of any
						Confidential Information of the Disclosing Party; or
						(iv) Receiving Party can document as lawfully obtained
						from a third-party who has the unrestricted right to
						transfer the information to the public.
					</p>
				</p>
				<br />
				<p id="TermsOfServicePnonDisclosure">
					<b>
						Non-Disclosure and Non-Use of Confidential Information
					</b>
					<br />
					<p>
						Except to specifically perform their obligations or
						pursuant to their rights under the Terms of Service and
						this Privacy Policy, Receiving Party shall not, and
						Receiving Party shall cause Receiving Party’s
						principals, shareholders, members, directors, officers,
						employees, affiliates, consultants, representatives and
						agents (collectively, “Representatives”) not to (i)
						disclose any Confidential Information to any other
						person or entity for so long as the Confidential
						Information shall remain confidential, including,
						without limitation, not disclosing idle gossip or
						Confidential Information (i.e., personal or financial)
						to individuals within Disclosing Party or Receiving
						Party who do not need to know such information for a
						Receiving Party to perform their obligations under this
						Agreement; and (ii) use, directly or indirectly, any
						Confidential Information for its benefit or the benefit
						of any third party (except as otherwise required to
						provide you with the Service).
					</p>
				</p>
				<br />
				<p id="TermsOfServicePadditionalRestrictions">
					<b>Additional Restrictions</b>
					<br />
					<p>
						Receiving Party shall disclose the Confidential
						Information to a Representative if, and only if, the
						Representative needs to know the Confidential
						Information in the ordinary course of that
						Representative’s work for Receiving Party with respect
						to the Receiving Party performing their obligations
						consistent with the Terms of Service and this Privacy
						Policy. Receiving Party represents that Representatives
						receiving the Confidential Information are bound by
						similar restrictions of confidentiality not to disclose
						or use the Confidential Information as Receiving Party
						is bound by this Privacy Policy. Receiving Party may
						disclose the Confidential Information pursuant to a
						court order; provided, however, Receiving Party must
						provide Disclosing Party with sufficient notice for
						Disclosing Party to challenge the request for disclosure
						of such Confidential Information prior to such
						disclosure. Receiving Party shall promptly notify
						Disclosing Party of any unauthorized use or disclosure
						of Confidential Information, and Receiving Party shall
						promptly take any and all reasonable efforts to prevent
						further unauthorized use or disclosure of the
						Confidential Information.
					</p>
				</p>
				<br />
				<p id="TermsOfServicePownerShip">
					<b>Ownership of Confidential Information</b>
					<br />
					<p>
						Receiving Party agrees that all Confidential Information
						is and shall remain the exclusive property of Disclosing
						Party and Disclosing Party owns all Confidential
						Information, in tangible and intangible form, and any
						and all copies, reproductions and reductions to any
						media and all derivatives of such information,
						regardless if created by Disclosing Party or Receiving
						Party; provided, however, all Aggregated Information
						shall be owned solely by EMR AXIS, and not by any
						Customer.
					</p>
				</p>
				<br />
				<p id="TermsOfServicePreturnConfidential">
					<b>Return of Confidential Information</b>
					<br />
					<p>
						All Confidential Information, whether created by
						Disclosing Party, Receiving Party or a Representative,
						is and shall remain the property of Disclosing Party.
						Immediately upon request of Disclosing Party, Receiving
						Party shall promptly return to Disclosing Party, or
						certify to Disclosing Party as being destroyed, all
						originals, copies, reproductions and reductions to
						writing and/or an electronic format of any and all
						Confidential Information which Receiving Party has
						received or obtained; provided, however, EMR AXIS
						may keep all Aggregated Information, and may maintain a
						copy of any Personal Information required to manage
						EMR AXIS’s business.
					</p>
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceH2rights" className="text-start">
					<b>RIGHTS YOU GRANT TO EMR AXIS</b>
				</h2>
				<br />
				<p id="TermsOfServicePsubjectTermsofServices">
					Subject to these Terms of Service, including EMR AXIS’s
					confidentiality obligations as set forth in our “Privacy
					Policy”, you hereby grant EMR AXIS a limited,
					non-exclusive, royalty-free, fully paid-up, worldwide right
					and license to use the Customer Data and perform all acts
					with respect to the Customer Data as may be necessary for
					EMR AXIS to provide the Service. You also hereby assign
					to EMR AXIS all of your right, title, and interest in
					and to the Feedback, including, without limitation, all
					intellectual property rights relating to Feedback. At
					EMR AXIS’s request and expense, you will execute all
					reasonable documents and take such further acts as
					EMR AXIS may reasonably request to assist EMR AXIS
					to acquire, perfect and maintain such intellectual property
					rights in the Feedback
				</p>
				<br />
				<p id="TermsOfServicePsubmittingFeedback">
					By submitting Feedback, Customer Data and/or Personal
					Information to EMR AXIS, you represent and warrant to
					EMR AXIS that you are entitled to submit such Feedback,
					Customer Data and/or Personal Information to EMR AXIS
					and EMR AXIS is entitled to use such Feedback, Customer
					Data and/or Personal Information as set forth in these Terms
					of Service, without any restrictions or limitations and
					without any monetary or other obligation by EMR AXIS.
					From time to time, we may need your permission to do things
					with your Customer Data and/or Personal Information to be
					able to provide you with the Service. You are hereby
					providing us with your permission to do those things solely
					to provide you with the Service. This permission also
					extends to third parties, such as credit card processors, we
					work with to provide you with the Service.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceH2copyRight">
					<b>COPYRIGHT INFRINGEMENT NOTICE</b>
				</h2>
				<br />
				<p id="TermsOfServicePintellectualProperty">
					We respect the intellectual property rights of our customers
					and third parties. Our DMCA Policy addresses how we handle
					notices of alleged copyright infringement appearing on this
					Site.
				</p>
				<br />
				<p id="TermsOfServicePdesignatedAgent">
					EMR AXIS’s designated agent for notices of alleged
					copyright infringement is:
				</p>
				<p id="TermsOfServicePemrAxis">EMR AXIS, Inc.</p>
				<p id="TermsOfServicePagent">Attn: Designated Agent</p>
				<p id="TermsOfServicePcoitRd">9720 Coit Rd,</p>
				<p id="TermsOfServicePSte">Ste 220-314,</p>
				<p id="TermsOfServicePplano">Plano, TX 75025</p>
				<p id="TermsOfServicePemail">E-mail: info@emraxis.com</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceH2publicity">
					<b>PUBLICITY</b>
				</h2>
				<br />
				<p id="TermsOfServicePreference">
					EMR AXIS may reference and use your name, logos and
					trademarks, and may disclose the nature of the Service
					provided to you by EMR AXIS, in EMR AXIS’s
					business development and marketing efforts and materials,
					including, without limitation, on this Site.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceH2ThirdpartyWebsite">
					<b>LINKS TO THIRD PARTY WEBSITES</b>
				</h2>
				<br />
				<p id="TermsOfServicePlinkToWebsite">
					This Site may contain links to thirty party websites. These
					links do not constitute an endorsement by EMR AXIS of
					those websites, nor the products or services promoted on or
					offered through those websites. We are not responsible for
					the terms and conditions of use, privacy policy, practices,
					or the content of such third parties or their websites. We
					encourage our customers to be aware of when they leave this
					Site and to read the terms and conditions of use and privacy
					policy of each website for which there is a link from this
					Site. If you have a question about the terms and conditions
					of use, privacy policy, practices, or content of a third
					party website, please contact the applicable third party
					directly. You acknowledge and agree that we shall not be a
					party to, or in any way responsible for, any transaction
					involving products or services made available from third
					parties or for any content relating to any products or
					services offered by third parties.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceH2linkingSite">
					<b>LINKING TO THIS SITE</b>
				</h2>
				<br />
				<p id="TermsOfServicePsiteHomepage">
					You may link to this Site’s homepage, provided you do so in
					a way that is fair and legal and does not damage our
					reputation or take advantage of it, but you may not
					establish a link in such a way as to suggest any form of
					association, approval or endorsement on our part where none
					exists. You may not establish a link from any website that
					is not owned by you. This Site may not be framed on any
					other website, nor may you create a link to any part of this
					Site other than the homepage. You agree to cooperate with us
					in causing any unauthorized framing or linking to cease
					immediately. We reserve the right to withdraw linking
					permission without notice.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceH2disclaimer">
					<b>DISCLAIMER OF WARRANTIES</b>
				</h2>
				<br />
				<p id="TermsOfServicePwarrantyDisclaimers">
					YOU AGREE THAT THE WARRANTY DISCLAIMERS AND LIMITATIONS OF
					LIABILITY PROVISIONS SET FORTH BELOW ARE REASONABLE, REFLECT
					AN INFORMED, VOLUNTARY ALLOCATION OF THE RISKS BOTH KNOWN
					AND UNKNOWN THAT MAY EXIST IN CONNECTION WITH THESE TERMS OF
					SERVICE, AND ARE FUNDAMENTAL ELEMENTS OF THESE TERMS OF
					SERVICE WHICH MATERIALLY INDUCED EMR AXIS TO ENTER INTO
					THESE TERMS OF SERVICE.
				</p>
				<br />
				<p id="TermsOfServicePtrasmission">
					THE TRANSMISSION, STORAGE, VIEWING AND RETRIEVAL OF DATA AND
					FILES THROUGH THE INTERNET ARE SUBJECT TO A VARIETY OF
					CONDITIONS BEYOND OUR CONTROL THAT MAKE SUCH TRANSMISSION,
					STORAGE, VIEWING AND RETRIEVAL POTENTIALLY UNRELIABLE. THIS
					SITE AND THE SERVICE, INCLUDING, BUT NOT LIMITED TO, ALL
					CONTENT, LICENSES, FUNCTIONS, MATERIALS, AND INFORMATION ARE
					PROVIDED “AS IS” AND “AS AVAILABLE” WITH ALL FAULTS AND
					WITHOUT WARRANTIES OF ANY KIND.
				</p>
				<br />
				<p id="TermsOfServicePmaxExtend">
					TO THE MAXIMUM EXTENT PROVIDED BY LAW, WE HEREBY DISCLAIM
					ANY AND ALL REPRESENTATIONS, WARRANTIES AND CONDITIONS WITH
					RESPECT TO THESE TERMS OF SERVICE, THIS SITE AND THE
					SERVICE, EITHER EXPRESS OR IMPLIED, WHETHER ARISING UNDER
					ANY STATUTE, LAW, COMMERCIAL USAGE OR OTHERWISE, INCLUDING,
					WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF SATIFACTORY
					QUALITY, FITNESS FOR A PARTICULAR PURPOSE, AND
					NON-INFRINGEMENT.
				</p>
				<br />
				<p id="TermsOfServicePwarrant">
					WE DO NOT WARRANT THAT THIS SITE, THE SERVICE, OR ANY DATA,
					MATERIALS, OR CONTENT CONTAINED THEREIN, SHALL BE
					UNINTERRUPTED OR ERROR-FREE; THAT DEFECTS SHALL BE
					CORRECTED; THAT THIS SITE OR THE SERVICE ARE FREE OF VIRUSES
					OR OTHER HARMFUL COMPONENTS; THAT THIS SITE OR THE SERVICE
					WILL BE SECURE FROM UNAUTHORIZED ACCESS; OR THAT THIS SITE
					OR THE SERVICE WILL DETECT EVERY SECURITY OR OTHER
					VULNERABILITY OF YOUR DEVICE, NETWORK OR SYSTEM. WE DO NOT
					WARRANT THAT THIS SITE AND/OR THE SERVICE WILL MEET YOUR
					REQUIREMENTS, OR THAT THIS SITE AND/OR THE SERVICE ARE
					COMPATIBLE WITH ANY PARTICULAR HARDWARE OR SOFTWARE
					PLATFORM. WE DO NOT WARRANT OR MAKE ANY REPRESENTATION
					REGARDING THE USE OR THE RESULTS OF THE USE OF THIS SITE
					AND/OR THE SERVICE IN TERMS OF THEIR CORRECTNESS, ACCURACY,
					QUALITY, RELIABILITY, SECURITY, APPROPRIATENESS FOR A
					PARTICULAR TASK OR APPLICATION, CURRENTNESS, OR OTHERWISE.
					NO ORAL OR WRITTEN INFORMATION OR ADVICE PROVIDED BY
					EMR AXIS OR ANY OF OUR AUTHORIZED REPRESENTATIVES WILL
					CREATE A WARRANTY OR IN ANY WAY INCREASE THE SCOPE OF THIS
					WARRANTY. WE MAKE NO WARRANTY OF ANY KIND, WHETHER EXPRESS
					OR IMPLIED, WITH REGARD TO ANY THIRD PARTY PRODUCTS,
					SERVICES, CONTENT, DATA, MATERIAL, SOFTWARE, EQUIPMENT, OR
					HARDWARE. WE SHALL NOT BE LIABLE FOR YOUR USE OF THIS SITE
					AND/OR THE SERVICE, INCLUDING, WITHOUT LIMITATION, THE
					CONTENT AND ANY ERRORS CONTAINED THEREIN. YOU EXPRESSLY
					AGREE AND ACKNOWLEDGE THAT USE OF THIS SITE AND THE SERVICE
					IS AT YOUR SOLE RISK. THE DISCLAIMER OF WARRANTIES SET FORTH
					IN THESE TERMS OF SERVICE FORM AN ESSENTIAL BASIS OF THE
					BARGAIN BETWEEN THE PARTIES AND SHALL CONTINUE TO APPLY EVEN
					IF ANY EXCLUSIVE REMEDY HEREUNDER FAILS OF ITS ESSENTIAL
					PURPOSE.
				</p>
				<br />
				<p id="TermsOfServicePservicesNotFaultTolerant">
					This Site and the Service are not fault-tolerant and are not
					designed, manufactured oriented for any use requiring
					fail-safe performance in which the failure of this Site or
					the Service could lead to death, serious personal injury or
					severe physical or environmental damage (“High Risk
					Activities”). You agree NOT to use this Site or the Service
					in connection with any High Risk Activities.
				</p>
				<br />
				<p id="TermsOfServicePfactors">
					This Site and/or the Service may become unavailable due to
					any number of factors, including, without limitation,
					scheduled or unscheduled maintenance, technical failure of
					the software, telecommunications infrastructure, or the
					unavailability or interruption of access to the Internet.
					The disclaimers set forth in this section shall apply
					regardless of whether (i) we determine that your device,
					network or system is deemed secure; (ii) you perform such
					modifications to your device, network or system as we
					reasonably suggest in order for your device, network or
					system to be deemed “secure”, or (iii) otherwise.
				</p>
				<br />
				<p id="TermsOfServicePlaws">
					Some state laws do not allow disclaimers of implied
					warranties. If these laws apply to you, some or all of the
					above disclaimers, exclusions, or limitations may not apply
					to you.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceH2limitationLiablity">
					<b>LIMITATION OF LIABILITY</b>
				</h2>
				<br />
				<p id="TermsOfServicePbusinessInterruption">
					WE SHALL NOT BE RESPONSIBLE FOR ANY BUSINESS INTERRUPTIONS
					THAT MAY BE CAUSED BY YOUR USE OF THIS SITE AND/OR THE
					SERVICE OR YOUR INABILITY TO ACCESS THIS SITE AND/OR THE
					SERVICE. UNDER NO CIRCUMSTANCES SHALL WE BE LIABLE TO YOU
					FOR THE COST OF PROCUREMENT OF REPLACEMENT PRODUCTS,
					SOFTWARE, SERVICES, DATA, CONTENT OR MATERIAL. WE SHALL NOT
					BE LIABLE FOR ANY indirect, incidental, special,
					consequential, PUNITIVE or exemplary DAMAGES THAT ARE
					DIRECTLY OR INDIRECTLY RELATED TO THE USE OF, OR THE
					INABILITY TO USE, THIS SITE AND/OR THE SERVICE, EVEN IF WE
					HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE
					CUMULATIVE LIABILITY OF EMR AXIS TO YOU FOR ALL CLAIMS
					RELATING TO THESE TERMS OF SERVICE, YOUR USE OF THIS SITE
					AND/OR THE SERVICE, OR SUBMISSION OF INFORMATION THROUGH
					THIS SITE OR THE SERVICE, IN CONTRACT, TORT, OR OTHERWISE,
					SHALL NOT EXCEED THE TOTAL AMOUNT OF THE GREATER OF (I) ALL
					FEES PAID BY YOU TO EMR AXIS DURING THE MONTHLY BILLING
					PERIOD IMMEDIATELY PRECEDING THE DATE ON WHICH THE DAMAGE OR
					LOSS OCCURRED OR THE CAUSE OF ACTION AROSE; AND (II) $100.
					THE LIMITATION OF LIABILITY SET FORTH IN THESE TERMS OF
					SERVICE FORMS AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN THE
					PARTIES AND SHALL CONTINUE TO APPLY EVEN IF ANY EXCLUSIVE
					REMEDY HEREUNDER FAILS OF ITS ESSENTIAL PURPOSE.
				</p>
				<br />
				<p id="TermsOfServicePnationalLaws">
					Some state and national laws do not allow limitations on
					certain damages. If these laws apply to you, some or all of
					the above disclaimers, exclusions, or limitations may not
					apply to you.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceH2fileClaim">
					<b>LIMITATION ON TIME TO FILE CLAIMS</b>
				</h2>
				<br />
				<p id="TermsOfServicePclaim">
					ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR
					RELATING TO THESE TERMS OF SERVICE, THIS SITE AND/OR THE
					SERVICE MUST BE COMMENCED WITHIN SIX (6) MONTHS AFTER THE
					CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR
					CLAIM IS PERMANENTLY BARRED.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServicepindemnification">
					<b>INDEMNIFICATION</b>
				</h2>
				<br />
				<p id="TermsOfServicePclaimsExpenses">
					You hereby agree to defend, indemnify, and hold harmless
					EMR AXIS and its officers, directors, shareholders,
					employees, affiliates, independent contractors, agents, and
					representatives from and against all claims and expenses,
					including, but not limited to, attorneys’ fees and costs,
					arising out of, or attributable to (i) any breach or
					violation by you of these Terms of Service; (ii) your
					failure to provide accurate, complete, and/or current
					information when using this Site, registering to use or
					using the Service, and/or submitting information through
					this Site or the Service; (iii) your use or misuse of this
					Site or the Service; and (iv) any agreement between you and
					any third party.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceH2disputeResolution">
					<b>DISPUTE RESOLUTION</b>
				</h2>
				<br />
				<p id="TermsOfServicePconcern">
					Most concerns involving this Site and/or the Service can be
					resolved quickly and efficiently through our customer
					service department. If you are unable to resolve your
					concern within ten (10) business days of contacting our
					customer service department, then either party may file an
					arbitration proceeding pursuant to these Terms of Service to
					resolve the dispute.
				</p>
				<br />
				<p id="TermsOfServicePcontroversy">
					Any controversy or dispute not resolved through our customer
					service department and arising out of these Terms of
					Service, use of this Site, registering to use or using the
					Service, and/or the submission of any information through
					this Site or the Service, shall be submitted to final
					binding arbitration in Collin County, Texas before the
					American Arbitration Association under the commercial
					arbitration rules then administered by the American
					Arbitration Association. Any award or decision obtained from
					any such arbitration proceeding shall be final and binding
					on the parties, and judgment upon any award thus obtained
					may be entered in any court having jurisdiction thereof.
					Except as otherwise provided in these Terms of Service, no
					action at law or in equity based upon any claim arising out
					of or related to these Terms of Service, use of this Site,
					registering to use or using the Service, and/or the
					submission of any information through this Site or the
					Service, shall be instituted in any court by any party,
					except: (i) an action to compel arbitration pursuant to this
					section; (ii) an action to enforce an award obtained in an
					arbitration proceeding in accordance with this section; or
					(iii) an action for injunctive relief. Any arbitration
					relating to these Terms of Service, use of this Site,
					registering to use or using the Service, and/or the
					submission of any information through this Site or the
					Service, will take place on an individual basis and class
					arbitrations and class actions are hereby specifically
					agreed to as not permitted. You agree that you and
					EMR AXIS are each waiving their right to trial by jury
					or to participate in a class action.
				</p>
				<br />
				<p id="TermsOfServicePprevailing">
					The prevailing party in any arbitration proceeding or any
					action to compel arbitration, enforce an arbitration award,
					or seek injunctive relief and arising out of or relating to
					these Terms of Service use of this Site, registering to use
					or using the Service, and/or the submission of any
					information through this Site or the Service will be
					entitled to an award of their attorneys’ fees and costs
					incurred at trial and all levels of appeal. You also agree
					that service of any court paper may be affected upon you by
					mail or in such other manner as may be provided under
					applicable laws, rules of procedure or local rules. You
					acknowledge that we may be irreparably damaged if these
					Terms of Service are breached by you, and damages at law
					would be an inadequate remedy. In the event of a breach or
					threatened breach of any provision of these Terms of Service
					by you, we shall be entitled, in addition to all rights and
					remedies, to an injunction restraining such breach or
					threatened breach, without being required to show any actual
					damage or to post an injunction bond, or to a decree for
					specific performance of the provisions of these Terms of
					Service.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceH2goveringLaw">
					<b>GOVERNING LAW, JURISDICTION AND VENUE</b>
				</h2>
				<br />
				<p id="TermsOfServicePunitedNations">
					These Terms of Service shall be governed by and construed in
					accordance with the internal laws of the State of Texas,
					without giving effect to principles of conflicts of law. The
					application of the United Nations Convention on Contracts
					for the International Sale of Goods is expressly excluded.
					For any action to compel arbitration or enforce an
					arbitration award or seek injunctive relief pursuant to
					these Terms of Service, the parties hereby expressly consent
					to the jurisdiction and venue of the state and/or federal
					courts in and/or for Collin County, Texas, USA, and each
					party hereby expressly waives any objection to such venue
					based upon forum non-convenience or otherwise.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceH2forceMajeure">
					<b>FORCE MAJEURE</b>
				</h2>
				<br />
				<p id="TermsOfServicePobligations">
					Except for your obligation to pay EMR AXIS, neither
					party shall be liable for any delay in performing its
					obligations if such delay is caused by circumstances beyond
					the party’s reasonable control, including, without
					limitation, any delay caused by any act or omission of the
					other party, acts of God, war, terrorism, floods, windstorm,
					labor disputes, or delay of essential materials or services.
					The delayed party shall promptly notify the other party of
					the reasons for and the likely duration of the delay,
					whereupon an extension of time equal to the period of delay,
					but not greater than thirty (30) days, shall be granted to
					the delayed party. If the period of the delay shall exceed
					thirty (30) days, then the non-delayed party may cancel
					further performance of the delayed obligation without any
					penalty whatsoever.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceH2termSurvival">
					<b>TERM AND SURVIVAL</b>
				</h2>
				<br />
				<p id="TermsOfServicePagreement">
					Your agreement to be bound by these Terms of Service
					commences with your visiting or using this Site, registering
					to use or using the Service, or otherwise submitting
					information through this Site or the Service, and your
					agreement to be so bound will continue until right to use
					this Site and/or Service is either canceled or terminated,
					subject to the survival of the following provisions –
					Privacy Policy; Acceptable Use Policy; DMCA Policy; Payment;
					Cancellation; Non-Payment and Late Fees; Disputed Charges;
					Proprietary Rights – Site and service, Names and Markings,
					Customer Data, Personal Information, Aggregated Information
					and Feedback; Rights You Grant To EMR AXIS; Publicity;
					Links to Third Party Websites; Linking to this Site;
					Disclaimer of Warranties; Limitation of Liability;
					Limitation On Time To File Claims; Indemnification; Dispute
					Resolution; Governing Law, Venue, and Jurisdiction; Force
					Majeure; Term and Survival; Changes To Terms of Service;
					General Information; and any other obligation you have to
					EMR AXIS. If, following the cancellation or termination
					of your right to use this Site and/or the Service, you visit
					or use this Site, register to use or use the Service, or
					otherwise submit information through this Site or the
					Service, then you again agree to be bound by these Terms of
					Service.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceH2changesTermsofService">
					<b>CHANGES TO OUR TERMS OF SERVICE</b>
				</h2>
				<br />
				<p id="TermsOfServicePsoleDiscretion">
					At our sole discretion and at any time, we may amend these
					Terms of Service. You should review these Terms of Service
					for amendments each time you visit or use this Site or
					purchase or use the Service. For your convenience, we post
					on this Site the last date these Terms of Service were
					updated. If our Terms of Service are amended, the amended
					Terms of Service will take effect immediately for all users
					of this Site and the Service. Your continued use of this
					Site and/or the Service following an amendment will evidence
					your acceptance of the amended Terms of Service.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceH2generalInformation">
					<b>GENERAL INFORMATION</b>
				</h2>
				<br />
				<p id="TermsOfServicePnotTransferable">
					Our Privacy Policy, Acceptable Use Policy are hereby
					incorporated into and made a part of these Terms of Service
					by reference. These Terms of Service, as amended from time
					to time by EMR AXIS, represents the entire
					understanding and agreement between you and EMR AXIS
					regarding the subject matter of these Terms of Service and
					supersede any and all other previous agreements,
					understandings, or representations regarding the same. By
					visiting or using this Site, registering to use or using the
					Service, or submitting information through this Site or the
					Service, you consent to receive communications from
					EMR AXIS electronically. Although we may choose to
					communicate with you by other means, we may also choose to
					solely communicate with you electronically by e-mail or by
					posting notices on this Site. You agree that all agreements,
					notices, disclosures, and other communications that we send
					to you electronically satisfy any legal requirement that
					such communications be in writing. No waiver of any portion
					of these Terms of Service will be effective unless it is in
					writing and signed by an authorized representative of
					EMR AXIS. The failure of EMR AXIS to require
					performance of any obligation under these Terms of Service
					will not affect our right to enforce any provision of these
					Terms of Service at a subsequent time, and the waiver of any
					rights arising out of any breach will not be construed as a
					waiver of any rights arising out of any prior or subsequent
					breach. If any court of competent jurisdiction deems
					unlawful, void, or unenforceable any part of these Terms of
					Service, the applicable document as a whole will not be
					deemed unlawful, void, or unenforceable, but only that
					portion of the applicable document that is unlawful, void,
					or unenforceable will be stricken. Your rights and
					obligations under these Terms of Service are not assignable,
					transferable, or sub-licensable by you.
				</p>
			</section>
			<br />
			<br />
			<section>
				<h2 id="TermsOfServiceH2customerSupport">
					<b>CUSTOMER SUPPORT</b>
				</h2>
				<br />
				<p id="TermsOfServicePcontactUs">
					If you have questions, comments or concerns about these
					Terms of Service, our Privacy Policy, this Site or the
					Service, please contact EMR AXIS at:
				</p>
				<br />
				<p id="TermsOfServicepemrAxis">EMR AXIS, LLC</p>
				<p id="TermsOfServiceP9720coitRd">9720 Coit Rd,</p>
				<p id="TermsOfServicePSte220314">Ste 220-314,</p>
				<p id="TermsOfServicePplanotx75025">Plano, TX 75025</p>
				<p id="TermsOfServicePemailAddress">E-mail: info@emraxis.com</p>
			</section>
			<br />
		</div>
	);
};

export default TermsOfService;
