import React from "react";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

dayjs.extend(isBetween);


const MonthPicker = ({ selectedDate, views, setSelectedDate, setDayMonthSelectRef }) => {

    const handleDateChange = (newDate) => {
        // Check if both year and month are selected
        if (newDate && newDate.$M !== undefined && newDate.$y !== undefined) {
            setSelectedDate(newDate);
        }
      };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
                id="MonthDataCalanderPicker"
                views={views}
                openTo="month"
                label="Select Month and Year"
                value={selectedDate}
                sx={{
                    width: "300px !important",
                    marginTop: "28px !important",
                    backgroundColor: "bg-[#b72424] !important",
                }}
                onChange={handleDateChange}
                showDaysOutsideCurrentMonth
                displayWeekNumber={false}
                closeOnSelect={false}
                onMonthChange={() => {
                    setDayMonthSelectRef(null)
                }}
            />
        </LocalizationProvider>
    );
}

export default MonthPicker