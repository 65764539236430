import React from 'react'
import { setDocumentType, setIsCameraUpload, setShowDocumentUploadPopup, setShowUploadPopup } from '../../../../Redux/features/uploadDoc/uploadDocSlice';
import { useSelector, useDispatch } from 'react-redux';
import { setNotification } from '../../../../Redux/features/toast/toastSlice';


const ChooseDocument = ({ appointmentStatus }) => {

    var documentType = useSelector(state => state.document_upload.documentType);

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setShowDocumentUploadPopup(false))
        dispatch(setIsCameraUpload(false));
    }

    const handleChange = evt => {
        const { name, checked } = evt.target;
        dispatch(setDocumentType({ ...documentType, [name]: checked }))
    }

    const handleOk = () => {
        if (!documentType.doctor_note && !documentType.prescription && !documentType.lab_reports && !documentType.x_ray && !documentType.others) {
            dispatch(setNotification({ message: 'Please select a document type!', status: 'error', action: true }));
        } else {
            dispatch(setShowDocumentUploadPopup(false))
            dispatch(setShowUploadPopup(true))
        }
    }

    return (
        <div className="fixed inset-0 flex justify-center items-center p-8 z-[999998]">
            <div className="absolute inset-0 bg-black opacity-25 "></div>
            <div className="relative bg-white shadow-xl rounded-sm" style={{ width: "400px" }} >
                <div className="flex justify-between">
                    <div className='m-2'></div>
                    <div className='m-2'><h2 className='text-center text-lg font-semibold'></h2></div>
                    <button className="text-red-700 sm:justify-left disabled:cursor-not-allowed disabled:opacity-50 mt-2" onClick={handleClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-7 w-7 mr-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                    </button>
                </div>
                <div className='min-h-[200px] pl-8 pr-8 '>
                    <div className="font-xl text-black font-semibold mb-4 text-center w-full">
                        Please Select the Document type
                    </div>
                    <div className="flex items-center font-semibold border-b border-gray-300 py-2 mb-2">
                        <div className="flex-1 text-black">Doctor Note</div>
                        <input type="checkbox" id="doctor_note"
                            isdisabled={appointmentStatus === "Completed"}
                            name='doctor_note' onChange={(e) => { handleChange(e) }} className="mr-6 cursor-pointer disabled:cursor-not-allowed disabled:opacity-50" checked={documentType.doctor_note} />
                    </div>
                    <div className="flex items-center font-semibold border-b border-gray-300 py-2 mb-2">
                        <div className="flex-1 text-black">Prescription</div>
                        <input type="checkbox" id="prescription"
                            isdisabled={appointmentStatus === "Completed"} name='prescription'
                            onChange={(e) => { handleChange(e) }} className="mr-6 cursor-pointer disabled:cursor-not-allowed disabled:opacity-50" checked={documentType.prescription} />
                    </div>
                    <div className="flex items-center font-semibold border-b border-gray-300 py-2 mb-2">
                        <div className="flex-1 text-black">Lab Reports</div>
                        <input type="checkbox" id="lab_reports" name='lab_reports' onChange={(e) => { handleChange(e) }} className="mr-6 cursor-pointer" checked={documentType.lab_reports} />
                    </div>
                    <div className="flex items-center font-semibold border-b border-gray-300 py-2 mb-2">
                        <div className="flex-1 text-black">X Ray</div>
                        <input type="checkbox" id="x_ray" name='x_ray' onChange={(e) => { handleChange(e) }} className="mr-6 cursor-pointer" checked={documentType.x_ray} />
                    </div>
                    <div className="flex items-center font-semibold py-2 mb-2">
                        <div className="flex-1 text-black">Other</div>
                        <input type="checkbox" id="others" name='others' onChange={(e) => { handleChange(e) }} className="mr-6 cursor-pointer" checked={documentType.others} />
                    </div>
                    <div className="flex justify-center my-4">
                        <button className="border border-gray-100 rounded-md bg-red-800 text-white px-6 py-2" onClick={handleOk}>
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChooseDocument