import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DateFunction from '../../../utils/DateFunctions';
import store from '../../../API/store';
import DataDecode from '../../../utils/DataDecode';
import ReactToPrint from 'react-to-print';
import { setNotification } from '../../../Redux/features/toast/toastSlice';

const PrescriptionPrintPreview = ({ setPrintPreview, patient, medicines, nextReview, appointment, file }) => {

    const dispatch = useDispatch();
    var loggeduser = useSelector(state => state.login.loginuserData);
    var loggedusertoken = useSelector(state => state.login.userBarerToken);
    const [facilityData, setFacilityData] = useState({
        facility_address: '',
        phonenumber: ''
    });

    const fetchFacilityData = async () => {
        await store.GetFacilityinfo(loggedusertoken, DataDecode.encryptPayload(loggeduser.facilityID))
            .then((result) => {
                if (result.status == 201) {
                    result.json().then((res) => {
                        let response = DataDecode.decryptResponse(res);
                        setFacilityData(JSON.parse(response));
                    })
                }
            })
            .catch(err => {
                dispatch(setNotification({ message: 'Error Occurred!', status: 'error', action: true }));
            })
    }

    useEffect(() => {
        if (loggeduser.facilityID)
            fetchFacilityData()
    }, [loggeduser])

    const handlePrintMobile = () => {
        const mobileContent = document.getElementById("mobile-content-to-print");
        window.print(mobileContent);
    };

    const handlePrintLaptop = () => {
        const laptopContent = document.getElementById("laptop-content-to-print");
        window.print(laptopContent);
    };
    
    const createDossage = row => {
        let str = "";
        str += row.morning ? row.morning + " Morning, " : '';
        str += row.noon ? row.noon + " Noon, " : '';
        str += row.evening ? row.evening + " Evening, " : '';
        str += row.night ? row.night + " Night, " : '';
        return str.trim().replace(/,\s*$/, '');
    }

    return (
        <>
            <div className="fixed inset-0 overflow-hidden flex justify-center items-center z-50">
                <div className="absolute inset-0 bg-black opacity-25"></div>
                <div className=" relative bg-white shadow-xl p-8 max-h-[80vh] lg:max-h-[70vh] 2xl:max-h-[70vh] overflow-y-auto" style={{ width: "700px" }}>
                    {/* Mobile */}
                    <div className="lg:hidden" >
                        <div className="flex items-center justify-between ">
                            <div className="text-black font-semibold text-lg sm:mb:6">Print Preview</div>
                            <button className="text-red-700 sm:justify-left" onClick={() => { setPrintPreview(false) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-10 w-10 ">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                            </button>
                        </div>
                        <div className="" id="mobile-content-to-print">
                        <div className="max-w-4xl mx-auto bg-white p-8 shadow-md rounded-lg" id="content-to-print">
                            <div className='grid grid-cols-2 gap-2 border-b-2 pb-4 mb-4 w-full'>
                                <div>
                                    {loggeduser.facility_logo &&
                                        <img src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + loggeduser.facility_logo} className='h-10 w-auto' />
                                    }
                                </div>
                                <div>
                                    <div className="text-right m-2">
                                        <h1 className="text-lg font-bold">{loggeduser?.facility_name}</h1>
                                        {facilityData.facility_address && <p className="text-sm">{facilityData.facility_address}</p>}
                                        {facilityData.phonenumber && <p className="text-sm">Phone: {facilityData.phonenumber}</p>}
                                    </div>
                                </div>

                            </div>



                            <div className="mb-6">
                                <h2 className="text-md font-semibold">
                                    ID: {patient.patient_op_number} - {patient.patient_name} {(patient.gender ? '(' + patient.gender + ')' : '')} / {(patient && patient?.dob && DateFunction.GetAge(patient?.dob) )}
                                </h2>
                                {patient.address && <span className="block text-gray-700 mb-2">Address: {patient.address}</span>}
                                <h2 className="text-md font-semibold mb-2">Referred By: {appointment.doctor_name}</h2>
                                <p className='mb-2 font-semibold'>Prescription</p>
                                {file && (
                                    <img className='w-auto h-[400px] p-5 object-cover m-5' src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} alt="Prescription" />
                                )}
                                <div className='space-y-4'>
                                    {medicines.filter(a => a.medicine !== "").map((row, index) => (
                                        <div key={row.order_id} className='bg-white p-4 rounded-md shadow-md'>                                            
                                            <p className='text-md font-semibold mb-1'>{row.medicine_type.slice(0, 3)}. {row.medicine}</p>
                                            <p className='text-sm mb-1'>
                                                {row.morning || "-"} - {row.noon || "-"} - {row.night || "-"} ({!row.when_medidcine ? 'After Food' : 'Before Food'})
                                            </p>
                                            <p className='text-sm'>{row.duration ? `${row.duration} days` : "-"}</p>
                                        </div>
                                    ))}
                                </div>
                                {nextReview && <p className='mb-2 mt-2 font-semibold'><span>Follow Up On: {DateFunction.GetDate(nextReview)}</span></p>}
                                <p className='mb-2 mt-10 font-semibold'>{appointment.doctor_name}</p>
                            </div>

                        </div>
                        </div>
                        <div className="mt-2 flex justify-center">
                                <ReactToPrint
                                    trigger={() => <button className='px-4 py-2 border bg-blue-600 hover:bg-blue-700 text-white rounded-md' onClick={handlePrintMobile}>Print
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="inline size-6">
                                        <path d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z" />
                                    </svg>
        
                                </button> }
                                    content={() => document.getElementById("mobile-content-to-print")}
                                />
                            </div>

                    </div>
                    {/* Desktop */}
                    <div className="hidden lg:block" >
                        <div className="flex items-center justify-between ">
                            <div className="text-black font-semibold text-lg sm:mb:6">Print Preview</div>
                            <button className="text-red-700 sm:justify-left" onClick={() => { setPrintPreview(false) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-10 w-10 ">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                            </button>
                        </div>
                        <div className="" id="laptop-content-to-print">
                        <div className="max-w-4xl mx-auto bg-white p-8 shadow-md rounded-lg" id="content-to-print">
                            <div className='grid grid-cols-2 gap-2 border-b-2 pb-4 mb-4 w-full'>
                                <div>
                                    {loggeduser.facility_logo &&
                                        <img src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + loggeduser.facility_logo} className='h-10 w-auto' />
                                    }
                                </div>
                                <div>
                                    <div className="text-right m-2">
                                        <h1 className="text-lg font-bold">{loggeduser?.facility_name}</h1>
                                        {facilityData.facility_address && <p className="text-lg">{facilityData.facility_address}</p>}
                                        {facilityData.phonenumber && <p className="text-lg">Phone: {facilityData.phonenumber}</p>}
                                    </div>
                                </div>

                            </div>



                            <div className="mb-6">
                                <h2 className="text-md font-semibold">ID: {patient.patient_op_number} - {patient.FirstName + " " + patient.MiddleName?.charAt(0) + " " + patient.LastName} {(patient.gender ? '(' + patient.gender + ')' : '')} / {(patient && patient?.dob && DateFunction.GetAge(patient?.dob) )}</h2>
                                {patient.address && <span className="block text-gray-700 mb-2">Address: {patient.address}</span>}
                                <h2 className="text-md font-semibold mb-2">Referred By: {appointment.doctor_name}</h2>
                                <p className='mb-2 font-semibold'>Prescription</p>
                                {file &&
                                    <img className='w-auto h-[400px] p-5 object-cover m-5' src={process.env.REACT_APP_EMRAXIS_S3_URL + "/" + file.file_path} alt="Prescription" />
                                }
                                {medicines.filter(a => a.medicine !== "").length>0 &&
                                    <table className='table-auto w-full border rounded-md focus:outline-none'>
                                        <thead>
                                            <tr className='odd:bg-white even:bg-gray-100 border-b -py-2'>
                                                <th className='text-center pb-1 pl-2 font-semibold border-r'>#</th>
                                                <th className='text-left pb-1 pl-2 font-semibold border-r'>Medicine Name</th>
                                                <th className='text-left pb-1 pl-2 font-semibold border-r'>Morning</th>
                                                <th className='text-left pb-1 pl-2 font-semibold border-r'>Noon</th>
                                                <th className='text-left pb-1 pl-2 font-semibold border-r'>Night</th>
                                                <th className='text-left pb-1 pl-2 font-semibold'>Duration</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {medicines.filter(a => a.medicine !== "").map((row, index) => (
                                                <tr key={row.order_id} className='odd:bg-white even:bg-gray-100'>
                                                    <td className='text-center p-2 border-r'>{index + 1}</td>
                                                    <td className='text-left p-2 border-r'>{row.medicine_type.slice(0, 3)}. {row.medicine}
                                                        <br />
                                                        ({!row.when_medidcine ? 'After Food' : 'Before Food'})
                                                    </td>
                                                    <td className='text-left p-2 border-r'>
                                                        {row.morning || "-"}
                                                    </td>
                                                    <td className='text-left p-2 border-r'>
                                                        {row.noon || "-"}
                                                    </td>
                                                    <td className='text-left p-2 border-r'>
                                                        {row.night || "-"}
                                                    </td>
                                                    <td className='text-left p-2 border-r'>
                                                        {row.duration || "-"}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                }
                                {nextReview && <p className='mb-2 mt-2 font-semibold'><span>Follow Up On:  {DateFunction.GetDate(nextReview)}</span></p>}
                                <p className='mb-2 mt-10 font-semibold'>{appointment.doctor_name}</p>
                            </div>
                        </div>
                        </div>
                        <div className="mt-2 flex justify-center">
                                <ReactToPrint
                                    trigger={() =>  <button className='px-4 py-2 border bg-blue-600 hover:bg-blue-700 text-white rounded-md' onClick={handlePrintLaptop}>Print
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="inline size-6">
                                        <path d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z" />
                                    </svg>
        
                                </button>}
                                    content={() => document.getElementById("laptop-content-to-print")}
                                />
                            </div>
                    </div>
                </div>



            </div >
        </>
    )
}

export default PrescriptionPrintPreview
